import API from "@aws-amplify/api";
import Storage from "@aws-amplify/storage";
import { Modal } from "bootstrap";
import dayjs from "dayjs";
import React, { useState } from "react";
import Select from "react-select";
import { CandidateLeadInterface, CompanyInterface, EmployeeInterface, GenericDataNameInterface, OptionLabelValueInterface } from "../types";
import { CANDIDATE_ROLES, formatName, isOnlyRecruiter, isOnlyTeamLeader, isSuperAdmin, REST_API_NAME } from "../utils";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import "react-datepicker/dist/react-datepicker.css";


// import FileViewer from 'react-file-viewer';


const data: GenericDataNameInterface[] = [
    { id: 1, name: "Rashmi" },
    { id: 1, name: "Zubaina" },
    { id: 1, name: "Zubaida" },
    { id: 1, name: "Shruti" },
    { id: 1, name: "Mithi" },
    { id: 1, name: "Yousuf" },
    { id: 1, name: "Srinivas" },
    { id: 1, name: "Poojitha" },
    { id: 1, name: "Raheen" }
]

interface CandidatesStateInterface {
    loading: boolean,
    candidates: CandidateLeadInterface[],
    candidate: CandidateLeadInterface,
    mode: 'add' | 'edit',
    filterEmployee: string
    filterCreatedBy: string
    filterDate: Date | null
    employees: EmployeeInterface[]
    selectedMonth: number
    selectedYear: number
    cvUrl: string
    formats: any[]
}

const SIMULATION = false;

const SOURCES = [
    "Monster",
    "Naukri",
    "Times",
    "Google",
    "Just Diual",
    "Reference ",
    "Incoming",
    "Other"
]

const INDUSTRIES = [
    { name: 'Accounts', value: 'Accounts' },
    { name: 'Advertising', value: 'Advertising' },
]

class CandidateLeads extends React.Component<any, CandidatesStateInterface>{

    createUserModalRef: any
    createUserModal: any;

    inputPhoneRef: any;
    inputEmailRef: any;

    cvFileInputRef: any

    defaultLead: CandidateLeadInterface = {
        id: -1,
        name: '',
        number: '',
        email: '',
        cvKey: '',
        identityId: this.props.user.identityId,
        rating: 1,
        industry: '',
        comment: '',
        employee_name: '',
        created_on: '',
        created_by: '',
        roles: [] as string[]
    } as CandidateLeadInterface;

    constructor(props: any) {
        super(props);
        const identityId = this.props.user.identityId

        this.state = {
            loading: true,
            candidates: [],
            candidate: {
                id: -1,
                name: '',
                number: '',
                email: '',
                cvKey: '',
                identityId,
                rating: 1,
                roles: [] as string[]
            } as CandidateLeadInterface,
            mode: 'add',
            filterEmployee: '',
            filterCreatedBy: '',
            filterDate: null,
            employees: [],
            selectedMonth: (new Date()).getMonth() + 1,
            selectedYear: (new Date()).getFullYear(),
            cvUrl: '',
            formats: []

        }

        this.createUserModalRef = React.createRef();
        this.cvFileInputRef = React.createRef();
        this.inputPhoneRef = React.createRef();
        this.inputEmailRef = React.createRef();

    }

    async componentDidMount() {
        this.createUserModal = new Modal(this.createUserModalRef.current);
        this.createUserModalRef.current.addEventListener('hide.bs.modal', (event: any) => {

            this.setState({
                candidate: this.defaultLead,
            })

            // this.inputEmailRef.current.value = '';
            // this.inputPhoneRef.current.value = '';
            this.cvFileInputRef.current.value = '';

        })




        this.getData();
    }

    getData = async (filter?: { employee?: string, month: number, year: number, createdOn?: Date | null | undefined, createdBy?: string }) => {
        const sub = this.props.user.attributes.sub;
        let { filterEmployee, filterDate, filterCreatedBy } = this.state;
        let month = (new Date()).getMonth() + 1
        let year = (new Date()).getFullYear()
        let createdOn = null;
        let createdBy = '';


        if (filter && filter.employee != undefined) {
            filterEmployee = filter.employee
        }

        if (filter && filter.month != undefined) {
            month = filter.month
            year = filter.year
        }
        if (filter && filter.createdOn != undefined) {
            createdOn = dayjs(filter.createdOn).format('YYYY-MM-DD')
        }
        if (filter && filter.createdBy != undefined) {
            createdBy = filter.createdBy
        }

        if(isOnlyRecruiter(this.props.user) || isOnlyTeamLeader(this.props.user) ){
            filterEmployee=sub;
        }

        // const resultCandidates = await API.get(REST_API_NAME, '/candidates', {});
        const resultMasters = await API.get(REST_API_NAME, '/candidates/leads/initial-new', {
            queryStringParameters: {
                employee:  filterEmployee,
                month: month,
                year: year,
                createdOn,
                createdBy
            }
        });

        const employees = resultMasters.result.employees;
        const candidates = resultMasters.result.candidates.map((c: any) => { c.roles =  c.roles?c.roles.split(","):[]; return c; });


        this.setState({ loading: false, employees, candidates })
    }



    setField = async (field: keyof CandidateLeadInterface, value: string | boolean | number) => {
        if (this.state.candidate[field] == value) { return }
        console.log(field, value);
        value = typeof value === 'string' ? value.trim() : value;


        this.setState({ candidate: { ...this.state.candidate, [field]: value } });
    }
    setFields = (fields: Partial<CandidateLeadInterface>) => {
        this.setState({ candidate: { ...this.state.candidate, ...fields } })
    }

    uploadFile = async (file: File) => {
        console.log(file);
        if (!file) { return }
        const ts = +new Date();
        const userSub = this.props.user.attributes.sub;
        const key = userSub + '--' + ts + '-' + file.name.replace("'", "");
        const { candidate } = this.state;
        const { cvKey } = candidate;
        this.setState({ loading: true })

        if (SIMULATION) {
            this.setState({ candidate: { ...this.state.candidate, cvKey: key }, loading: false })
            return;
        }

        try {
            // if (cvKey) {
            //     if (window.confirm('Are you sure you want to delete ths exisiting CV and add a new one?')) {
            //         await Storage.remove(cvKey, {
            //             level: 'protected',
            //         });
            //         console.log('Removed', cvKey)
            //         //@todo log removing file
            //         // const result = await API.post(REST_API_NAME, `/candidates/`, { body: { ...candidate, companies: selectedCompanies } });

            //     }
            // }


            const fileAdded = await Storage.put(key, file, {
                level: 'protected',
                progressCallback(progress) {
                    console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                },

            });
            console.log('Added', fileAdded)
            if (!fileAdded.key) {
                alert('Please uplaod the file again');
                return;
            }
            const identityId = this.props.user.identityId
            this.setState({ loading: false, candidate: { ...this.state.candidate, identityId, cvKey: fileAdded.key } })

        } catch (error) {
            console.log('Error uploading file: ', error);
            alert('Error Uploading file')
            this.setState({ loading: false })

        }
    }

    deleteCandidate = async (candidate: CandidateLeadInterface) => {

        // const user = this.props.user;
        // if (!isSuperAdmin(user) && candidate.created_by !== user.attributes.sub) {
        //     toast.error('Not created by you');
        //     return;
        // }


        try {
            //@todo not removing resue
            // const candidate = this.state.candidates.find(c => c.id == id)!;
            // await Storage.remove(candidate.cvKey, {
            //     level: 'protected',
            // });
            await API.del(REST_API_NAME, `/candidates/leads/${candidate.id}`, {});
            this.setState({ candidates: this.state.candidates.filter(c => c.id !== candidate.id) })
        } catch (e) {
            console.log(e)
        }
    }

    addCandidate = async () => {
        const { candidates, candidate } = this.state
        candidate.identityId = this.props.user.identityId;
        if (!candidate.name) {
            alert('Name is required');
            return;
        }
        if (!candidate.number) {
            alert('Number is required');
            return;
        }
        if (!candidate.employee_name) {
            alert('Employee is required');
            return;
        }


        candidate.name = candidate.name.trim();
        this.setState({ loading: true, mode: 'add' })

        if (SIMULATION) {
            this.setState({
                loading: false,
                candidate: {
                    id: -1,
                    name: '',
                    number: '',
                    email: '',
                    cvKey: '',
                    identityId: this.props.user.identityId,
                    rating: 1,
                    employee_sub: "",
                    comment: '',
                    source: '',
                } as CandidateLeadInterface,
                candidates: [{ ...candidate, id: +new Date }, ...this.state.candidates],
            })
            this.createUserModal.toggle();
            this.cvFileInputRef.current.value = ''
            return;
        }

        try {

            const result = await API.post(REST_API_NAME, `/candidates/leads`, { body: { ...candidate } });

            this.setState({
                loading: false,
                candidate: this.defaultLead,
                candidates: [{ ...candidate, id: result.result.insertId }, ...this.state.candidates]
            })
            this.createUserModal.toggle();


            // window.location.reload();
        } catch (e) {
            console.log('Error', e);
            this.setState({ loading: false })
        }
    }

    updateCandidate = async () => {

        const { candidate } = this.state
        this.setState({ loading: true, mode: 'add' })
        candidate.identityId = this.props.user.identityId;

        if (SIMULATION) {
            this.setState({
                loading: false,
                candidate: this.defaultLead,
                candidates: this.state.candidates.map(c => c.id == candidate.id ? candidate : c)
            })
            this.createUserModal.toggle();
            this.cvFileInputRef.current.value = ''

            return;
        }

        candidate.comment = candidate.comment || '';



        try {
            await API.put(REST_API_NAME, `/candidates/leads/update/` + candidate.id, { body: { ...candidate } });
            this.setState({
                loading: false,
                candidate: this.defaultLead,
                candidates: this.state.candidates.map(c => c.id == candidate.id ? { ...candidate } : c)
            })
            this.createUserModal.toggle();
            toast.success('Updated candidate successfully')
            window.location.reload();

        } catch (e) {
            console.log('Error', e);
            this.setState({ loading: false })
            toast.error('Error')

        }

    }

    getFile = async (candidate: CandidateLeadInterface) => {

        const { cvKey } = candidate;
        const file = await Storage.get(cvKey, { level: 'protected', identityId: candidate.identityId });
        window.open(file)
        console.log(file)
    }
    viewFile = async (candidate: CandidateLeadInterface) => {

        const { cvKey } = candidate;
        const file = await Storage.get(cvKey, { level: 'protected', identityId: candidate.identityId });

        this.setState({ cvUrl: file })
        // window.open(file)
        console.log(file)
    }

    clearCandidate = () => {
        this.setState({
            candidate: this.defaultLead,
            mode: 'add',
        })
    }
    selectCandidate = async (candidate: CandidateLeadInterface) => {
        this.setState({ candidate, mode: 'edit' });

        const resultMasters = await API.get(REST_API_NAME, `/candidates/get-companies-assigned/`, {
            queryStringParameters: {
                number: candidate.number,
                email: candidate.email
            }
        });

        if (resultMasters.result.length) {
            const ids = resultMasters.result.map((r: any) => r.companyId)
            this.setState({ loading: false })
        }



    }

    selectCandidateRoles = async (roles: any) => {
        const c = roles.pop();
        this.setState({ candidate: { ...this.state.candidate, roles: c.map((c: OptionLabelValueInterface) => c.value) } });
    }


    setEmployee = (filterEmployee: string) => {
        this.setState({ filterEmployee })
        this.getData({ employee: filterEmployee, month: this.state.selectedMonth, year: this.state.selectedYear, createdOn: this.state.filterDate, createdBy: this.state.filterCreatedBy });
    }
    setCreatedBy = (filterCreatedBy: string) => {
        this.setState({ filterCreatedBy })
        this.getData({ employee: this.state.filterEmployee, createdBy: filterCreatedBy, month: this.state.selectedMonth, year: this.state.selectedYear, createdOn: this.state.filterDate });
    }


    updateCandidateRow = async (candidate: CandidateLeadInterface, field: keyof CandidateLeadInterface) => {

        try {
            await API.put(REST_API_NAME, `/candidates/leads/update-field/${field}/` + candidate.id, { body: { value: candidate[field] } });
            this.setState({
                loading: false,
                candidate: this.defaultLead,
                candidates: this.state.candidates.map(c => c.id == candidate.id ? { ...candidate } : c)
            })
            // this.createUserModal.toggle();
            // window.location.reload();

        } catch (e) {
            console.log('Error', e);
            this.setState({ loading: false })
        }

    }

    selectMonth = (selectedMonth: number) => {
        this.setState({ selectedMonth });
        this.getData({ month: selectedMonth, year: this.state.selectedYear })

    }

    selectYear = (selectedYear: number) => {
        this.setState({ selectedYear });
        this.getData({ month: this.state.selectedMonth, year: selectedYear })
    }

    selectFilterDate = (filterDate: any) => {
        this.setState({ filterDate: filterDate });
        this.getData({
            employee: this.state.filterEmployee,
            month: this.state.selectedMonth,
            year: this.state.selectedYear,
            createdOn: filterDate
        });

    }



    setColDataAuto = async (format: any, row: any, value: string) => {
        const { candidate } = this.state;
        console.log(row, value);

        const result = await API.put(REST_API_NAME, '/coordination/format-for-candidate-row/' + row.id, {
            body: {
                row,
                value,
                candidateId: candidate.id
            }
        })

        toast.success('Successfully added format data')
    }



    render() {
        const { formats, filterDate, cvUrl, selectedMonth, selectedYear, employees, candidates, candidate, loading, mode, filterEmployee } = this.state;
        let employeesHash: { [key: string]: EmployeeInterface } = {};
        employees.map((emp: EmployeeInterface) => employeesHash[emp.sub] = emp);

        // let params: any = {};
        // params = {
        //     name: {
        //         defaultValue: candidate.name
        //     }
        // }
        // if (mode == 'add') {

        //     params.name = {};
        // }
        // console.log('Params', params)
        const months = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


        let rowData: any = {};
        formats.map(f => {
            f.data.map((d: any) => {
                rowData[d.row_id] = d.value;
            })
        });
        const user = this.props.user;
        const superAdmin = isSuperAdmin(user)
        const sub = user.attributes.sub;


        return (
            <div className='my-5'>

                {SIMULATION ? <h1>SIMULATION MODE</h1> : ''}


                {/* { cvUrl== '' ? '' : <FileViewer
                    fileType={'application/pdf'}
                    filePath={cvUrl}
                    // errorComponent={CustomErrorComponent}
                    onError={console.log} />
                    }  */}

                <div className="row">

                    <div className='col-1'>
                        <div className="dropdown">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                {months[selectedMonth]}
                            </button>
                            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                                <li onClick={() => this.selectMonth(-1)}><a className="dropdown-item" href="#">NONE</a></li>
                                <li onClick={() => this.selectMonth(1)}><a className="dropdown-item" href="#">Jan</a></li>
                                <li onClick={() => this.selectMonth(2)}><a className="dropdown-item" href="#">Feb</a></li>
                                <li onClick={() => this.selectMonth(3)}><a className="dropdown-item" href="#">Mar</a></li>
                                <li onClick={() => this.selectMonth(4)}><a className="dropdown-item" href="#">Apr</a></li>
                                <li onClick={() => this.selectMonth(5)}><a className="dropdown-item" href="#">May</a></li>
                                <li onClick={() => this.selectMonth(6)}><a className="dropdown-item" href="#">Jun</a></li>
                                <li onClick={() => this.selectMonth(7)}><a className="dropdown-item" href="#">Jul</a></li>
                                <li onClick={() => this.selectMonth(8)}><a className="dropdown-item" href="#">Aug</a></li>
                                <li onClick={() => this.selectMonth(9)}><a className="dropdown-item" href="#">Sep</a></li>
                                <li onClick={() => this.selectMonth(10)}><a className="dropdown-item" href="#">Oct</a></li>
                                <li onClick={() => this.selectMonth(11)}><a className="dropdown-item" href="#">Nov</a></li>
                                <li onClick={() => this.selectMonth(12)}><a className="dropdown-item" href="#">Dec</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-1'>
                        <div className="dropdown">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                {selectedYear}
                            </button>
                            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                                <li onClick={() => this.selectYear(2021)}><a className="dropdown-item" href="#">2021</a></li>
                                <li onClick={() => this.selectYear(2022)}><a className="dropdown-item" href="#">2022</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-1">
                        <button onClick={() => { this.clearCandidate(); this.createUserModal.toggle() }} className='btn btn-success'>Add <i className="bi bi-person-plus-fill"></i></button>
                    </div>
                    <div className="col-2">
                        Employee
                        {/* <select className="form-control1" id="inputCompany" onChange={(evt) => { this.setRecruiter(evt.target.value) }} defaultValue={filterRecruiter}  >
                            <option></option>
                            {recruiters.map(r => <option key={"optr" + r.sub} value={r.sub}>{r.name}</option>)}
                        </select> */}
                        <Select
                            isClearable={true}
                            isMulti={false}
                            options={employees.map(r => ({ value: r.sub, label: r.name }))}
                            // onChange={(val) => this.setRecruiter(!val ? '' : val[0].map((v: any) => v.value).join(','))}
                            onChange={(val) => this.setEmployee(!val ? '' : val.value)}
                        />
                    </div>

                    <div className="col-2">
                        Created On
                        {/* <DatePicker
                            selected={filterDate}
                            onSelect={console.log} //when day is clicked
                            onChange={this.selectFilterDate} //only when value has changed
                            dateFormat={'dd/MM/yyyy'}
                        /> */}
                        <input type='date' className='form-control' onChange={(evt) => this.selectFilterDate(evt.target.valueAsDate)} />
                        {/* <button onClick={this.removeFilterDate}>R</button> */}

                    </div>
                    <div className="col-2">
                        Created By
                        <Select
                            isMulti={true}
                            options={employees.map(r => ({ value: r.sub, label: r.name }))}
                            onChange={(val) => this.setCreatedBy(val.map(v => v.value).join(','))}
                        />
                    </div>
                    {/* <div className="col-2">
                        <button onClick={() => {
                            try {
                                (window as any).getData1();
                            } catch (err) {
                                Sentry.captureException(err);
                            }
                        }} className='btn btn-primary'>Filter </button>
                    </div> */}
                </div>



                <table className='table table-bordered table-fixed-layout1 table-candidates1'>
                    <colgroup>
                        <col className='col1' />
                        <col className='col2' />
                        <col className='col3' />
                        <col className='col4' />
                        <col className='col5' />
                        <col className='col6' />
                        <col className='col7' />
                        <col className='col8' />
                        <col className='col9' />
                        <col className='col10' />
                        <col className='col11' />
                        <col className='col12' />
                        <col className='col13' />
                        <col className='col14' />
                    </colgroup>
                    <thead>
                        <tr>
                            <th></th>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Number</th>
                            <th>Email</th>
                            <th>Rating</th>
                            <th>Comment</th>
                            <th>Employee</th>
                            <th>Industry</th>
                            <th>Roles</th>
                            <th>Source</th>
                            <th>CreatedOn</th>
                            <th>CreatedBy</th>
                        </tr>
                    </thead>
                    <tbody>
                        {candidates.map((d, idx) => <tr key={'row-' + d.id} id={d.id + ""}>
                            <td>
                                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                    <div className="btn-group me-2" role="group" aria-label="First group">
                                        <button onClick={() => { this.getFile(d); }} disabled={loading} className='btn btn-info btn-sm'>
                                            <i className="bi bi-file-earmark-arrow-down"></i>
                                        </button>

                                        {/* <button onClick={() => { this.viewFile(d); }} disabled={loading} className='btn btn-info btn-sm'>
                                            <i className="bi bi-eye-fill"></i>
                                        </button> */}
                                        <button onClick={() => { this.selectCandidate(d); this.createUserModal.toggle() }} disabled={loading} className='btn btn-warning btn-sm'>
                                            <i className="bi bi-pencil-square"></i>
                                        </button>
                                        {/* <button onClick={() => { if (window.confirm('Are you sure you want to delete this user?')) { this.deleteCandidate(d); } }} disabled={loading || (!superAdmin && d.created_by !== sub)} className='btn btn-danger btn-sm'> */}
                                        <button onClick={() => { if (window.confirm('Are you sure you want to delete this user?')) { this.deleteCandidate(d); } }} disabled={loading} className='btn btn-danger btn-sm'>
                                            <i className="bi bi-trash-fill"></i>
                                        </button>
                                    </div>
                                </div>

                            </td>
                            <td>{idx + 1}</td>
                            <td>{d.name}</td>
                            <td>{d.number}</td>
                            <td>{d.email}</td>
                            <td>{d.rating}</td>
                            {/* <td>{d.comment}</td> */}
                            <Cell type='text' data={d} value={d.comment + ''} field={'comment'} onChange={(d) => this.updateCandidateRow(d, 'comment')} />
                            <td>{d.employee_name}</td>
                            <td>{d.industry}</td>
                            <td>{d.roles.join(',')}</td>
                            <td>{d.source}</td>
                            <td>{dayjs(d.created_on).format('DD/MM/YYYY h:m')}</td>
                            <td>{employeesHash[d.created_by] ? employeesHash[d.created_by].name : 'NA'}</td>


                        </tr>)}
                    </tbody>
                </table>



                <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.createUserModalRef} data-bs-backdrop='static' >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{mode == 'edit' ? 'Update' : 'Add'} Candidate</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='form'>
                                    <form className=" g-3">

                                        <div className="mb-3 row">
                                            <label htmlFor="inputName" className="col-sm-2 col-form-label">Name<span className='text-danger'>*</span></label>
                                            <div className="col-sm-10">
                                                <input type="text" maxLength={150} className="form-control" id="inputName" onBlur={(evt) => this.setField('name', formatName(evt.target.value))} defaultValue={candidate.name || ''} autoComplete='d off' />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label htmlFor="inputNumber" className="col-sm-2 col-form-label">Number<span className='text-danger'>*</span></label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputNumber" ref={this.inputPhoneRef} onBlur={(evt) => this.setField('number', evt.target.value.trim())} defaultValue={candidate.number || ''} autoComplete='d off' />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label htmlFor="inputEmail" className="col-sm-2 col-form-label">Email</label>
                                            <div className="col-sm-10">
                                                <input type="email" maxLength={150} className="form-control" id="inputEmail" ref={this.inputEmailRef} onBlur={(evt) => this.setField('email', evt.target.value)} defaultValue={candidate.email || ''} />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label htmlFor="inputRating" className="col-sm-2 col-form-label">Rating</label>
                                            <div className="col-sm-10">
                                                <input type="number" min={1} max={10} className="form-control" id="inputRating" onBlur={(evt) => this.setField('rating', evt.target.value)} defaultValue={candidate.rating} />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label htmlFor="inputCompany" className="col-sm-2 col-form-label">Employee<span className='text-danger'>*</span></label>
                                            <div className="col-sm-10">
                                                {/* <select className="form-control" id="inputCompany" onChange={(evt) => { this.setFields({ recruiter_name: evt.target.options[evt.target.selectedIndex].innerText, recruiter_sub: evt.target.value }) }} value={candidate.recruiter_sub}  >
                                                    <option></option>
                                                    {recruiters.map(r => <option key={"optr" + r.sub} value={r.sub}>{r.name}</option>)}
                                                </select> */}
                                                <Select
                                                    isMulti={false}
                                                    options={employees.map(r => ({ value: r.sub, label: r.name }))}
                                                    onChange={(val: any) => this.setFields({ employee_name: val.label, employee_sub: val.value })}
                                                    value={{ value: candidate.employee_sub, label: candidate.employee_name }}
                                                />
                                            </div>
                                        </div>


                                        <div className="mb-3 row">
                                            <label htmlFor="inputCompany" className="col-sm-2 col-form-label">CV</label>
                                            <div className="col-sm-10">
                                                {
                                                    mode == 'add'
                                                        ? ''
                                                        : <button type='button' onClick={() => { this.getFile(candidate); }} disabled={loading} className='btn btn-info btn-sm'>
                                                            View Existing CV<i className="bi bi-file-earmark-arrow-down"></i>
                                                        </button>
                                                }
                                                <input type="file" className="form-control" id="inputCompany" onChange={(evt) => this.uploadFile(evt.target.files![0])} ref={this.cvFileInputRef} defaultValue={candidate.cvKey} />
                                            </div>
                                        </div>


                                        <div className="mb-3 row">
                                            <label htmlFor="inputIndustry" className="col-sm-2 col-form-label">Industry<span className='text-danger'>*</span></label>
                                            <div className="col-sm-10">
                                                {/* <select className="form-control" id="inputCompany" onChange={(evt) => { this.setFields({ recruiter_name: evt.target.options[evt.target.selectedIndex].innerText, recruiter_sub: evt.target.value }) }} value={candidate.recruiter_sub}  >
                                                    <option></option>
                                                    {recruiters.map(r => <option key={"optr" + r.sub} value={r.sub}>{r.name}</option>)}
                                                </select> */}
                                                <Select
                                                    isMulti={false}
                                                    options={INDUSTRIES.map(r => ({ value: r.value, label: r.name }))}
                                                    onChange={(val: any) => this.setField('industry', val.value)}
                                                    value={INDUSTRIES.filter(i => i.value == candidate.industry).map(r => ({ value: r.value, label: r.name }))}
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label htmlFor="inputRoles" className="col-sm-2 col-form-label">Roles<span className='text-danger'>*</span></label>
                                            <div className="col-sm-10">
                                                <Select options={CANDIDATE_ROLES.map(c => ({ label: c, value: c + '' })) as any}
                                                    isMulti={true}
                                                    onChange={(e) => this.selectCandidateRoles([e] as unknown as CompanyInterface[])}
                                                    value={candidate.roles.map(c => ({ label: c, value: c + '' }))}
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label htmlFor="inputCompany" className="col-sm-2 col-form-label">Source<span className='text-danger'>*</span></label>
                                            <div className="col-sm-10">
                                                <select className="form-control" id="inputCompany" onChange={(evt) => { this.setFields({ source: evt.target.options[evt.target.selectedIndex].innerText }) }} value={candidate.source}  >
                                                    <option></option>
                                                    {SOURCES.map(s => <option key={'source-' + s}>{s}</option>)}
                                                </select>
                                            </div>
                                        </div>

                                        {

                                            <div className="mb-3 row">
                                                <label htmlFor="inputRating" className="col-sm-2 col-form-label">Comment</label>
                                                <div className="col-sm-10">
                                                    <input type="text" className="form-control" id="inputRating" onBlur={(evt) => this.setField('comment', evt.target.value)} defaultValue={candidate.comment} />
                                                </div>
                                            </div>

                                        }

                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={mode == 'edit' ? this.updateCandidate : this.addCandidate} disabled={loading}>{mode == 'edit' ? 'Update' : 'Save'}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

            </div >
        )
    }
}

export default CandidateLeads;




type CellType = "text" | "select" | "number";
type CellOption = { label: string, value: string };
// type CellOptions = {label: string, value: string };
interface CellPropsInterface {
    data: CandidateLeadInterface
    field: keyof CandidateLeadInterface
    value: string
    type: CellType
    list?: CellOption[]
    onChange: (d: CandidateLeadInterface) => void
    enabled?: boolean

}

const Cell = (props: CellPropsInterface) => {

    const [edit, setEdit] = useState(false);
    const [value, setValue] = useState(props.value);
    const enabled = props.enabled === undefined ? true : props.enabled


    const setEditable = () => {
        setEdit(true);
    }
    const changeValue = (e: any) => {
        const target = e.target;
        if (props.type == "select") {
            setValue(target.options[target.selectedIndex].innerText);
        } else {
            setValue(target.value)
        }
        setEdit(false);
        props.onChange({ ...props.data, [props.field]: target.value })
    }

    const renderElement = (type: CellType, list?: CellOption[]) => {
        let ele;
        switch (type) {
            case "text": ele = <input className={'form-control'} type='text' defaultValue={props.value} onBlur={changeValue} />; break;
            case "number": ele = <input className={'form-control'} type='number' defaultValue={props.value} onBlur={changeValue} />; break;
            case "select": ele = <select className={'form-select'} defaultValue={props.data[props.field] as string} onBlur={changeValue} >
                {list?.map(l => <option key={'opt-' + l.label} value={l.value}>{l.label}</option>)}
            </select>; break;
        }

        return ele;
    }


    return (<td onClick={!enabled ? () => { } : setEditable} className={enabled ? 'enabled' : 'bg-secondary'}>
        {edit
            ? renderElement(props.type, props.list)
            : value
        }
    </td>)

}