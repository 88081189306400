import API from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";
import Storage from "@aws-amplify/storage";
import { getRoles } from "@testing-library/dom";
import { Modal } from "bootstrap";
import dayjs from "dayjs";
import React from "react";
import { CandidateInterface, CompanyInterface, CoordinationInterface, EmployeeRoleType, GenericDataNameInterface } from "../types";
import { REST_API_NAME } from "../utils";
import Companies from "./Companies";
import { MultiSelect } from "react-multi-select-component";



const data: GenericDataNameInterface[] = [
    { id: 1, name: "Rashmi" },
    { id: 1, name: "Zubaina" },
    { id: 1, name: "Zubaida" },
    { id: 1, name: "Shruti" },
    { id: 1, name: "Mithi" },
    { id: 1, name: "Yousuf" },
    { id: 1, name: "Srinivas" },
    { id: 1, name: "Poojitha" },
    { id: 1, name: "Raheen" }
]

interface CandidatesStateInterface {
    loading: boolean,
    candidates: CoordinationInterface[],
    candidate: CoordinationInterface,
    mode: 'add' | 'edit'
    companies: CompanyInterface[]

}


class Coordination extends React.Component<any, CandidatesStateInterface>{

    createUserModalRef: any
    createUserModal: any;

    resetPasswordModalRef: any
    resetPasswordModal: any;

    addUserToGroupModalRef: any;
    addUserToGroupModal: any;

    constructor(props: any) {
        super(props);
        const identityId = this.props.user.identityId

        this.state = {
            loading: true,
            companies: [],
            candidates: [],
            candidate: {
                id: -1,
                name: '',
                number: '',
                email: '',
                cvKey: '',
                identityId
            } as CoordinationInterface,
            mode: 'add'

        }

        this.createUserModalRef = React.createRef();

    }

    async componentDidMount() {
        // this.createUserModal = new Modal(this.createUserModalRef.current);

        const result = await API.get(REST_API_NAME, '/coordination', {});
        const resultCompainies = await API.get(REST_API_NAME, '/companies', {});


        const candidates = result.result;

        let _cache: { [key: string]: any } = {};
        candidates.map((c: any) => {
            if (_cache[c.id]) {

                _cache[c.id].companies.push({
                    label:c.company,
                    value:c.companyId
                });
                _cache[c.id].hrs.push(c.hr_name)
                _cache[c.id].hr_feedback.push(c.hr_feedback)


            } else {
                _cache[c.id] = c;
                _cache[c.id].companies=[{
                    label:c.company,
                    value:c.companyId
                }]
                _cache[c.id].hrs=[c.hr_name]
                _cache[c.id].hr_feedback=[c.hr_feedback]
            }
        });

        console.log(_cache)
        

        // this.setState({ loading: false, companies: resultCompainies.result, candidates: result.result.map((t: any) => ({ ...t, companies: [] })) })
        this.setState({ loading: false, companies: resultCompainies.result, candidates:Object.values(_cache) })
    }


    setField = (field: keyof CandidateInterface, value: string | boolean | number) => {
        this.setState({ candidate: { ...this.state.candidate, [field]: value } })
    }

    getFile = async (candidate: CandidateInterface) => {

        const { cvKey } = candidate;
        const file = await Storage.get(cvKey, { level: 'protected', identityId: candidate.identityId });
        window.open(file)
        console.log(file)
    }


    clearCandidate = () => {
        this.setState({ candidate: {} as CoordinationInterface, mode: 'add' })
    }
    selectCandidate = (candidate: CoordinationInterface) => {
        this.setState({ candidate, mode: 'edit' })
    }

    selectCompany = (candididate: CoordinationInterface, company: any) => {
        candididate.companies = company;
        this.setState({ candidates: this.state.candidates.map(c => c.id == candididate.id ? candididate : c) })
    }



    alignCandidate = (candidate: CoordinationInterface) => {

        console.log('Align Candidate', candidate)

    }

    sendToCoordniator = async (candidate: CoordinationInterface) => {
        console.log('Align Candidate', candidate);
        try {
            await API.post(REST_API_NAME, `/candidates/send-to-coordinator`, { body: candidate });
            this.setState({
                loading: false,
                candidate: {} as CoordinationInterface,
            })
            this.createUserModal.toggle();
        } catch (e) {
            console.log('Error', e);
            this.setState({ loading: false })
        }

    }



    render() {
        const { candidates, candidate, companies, loading, mode } = this.state;

        const options = companies.map((c: CompanyInterface) => ({ value: c.id, label: c.name }))
        return (
            <div className='my-5'>
                <button onClick={() => { this.clearCandidate(); this.createUserModal.toggle() }} className='btn btn-primary'>Add <i className="bi bi-person-plus-fill"></i></button>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Number</th>
                            <th>Companies</th>

                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {candidates.map((d, idx) => <tr key={'row-' + d.id}>
                            <td>{idx + 1}</td>
                            <td>{d.name}</td>
                            <td>{d.number}</td>
                            <td>

                                <MultiSelect
                                    options={options}
                                    value={d.companies}
                                    onChange={(e: any) => this.selectCompany(d, e)}
                                    labelledBy="Select"
                                />
                            </td>
                            {/* <td>{d.cvKey}</td> */}

                            <td>
                                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                    <div className="btn-group me-2" role="group" aria-label="First group">
                                        {/* <button onClick={() => { this.selectUser(d.sub); this.createUserModal.toggle() }} disabled={loading} className='btn btn-warning btn-sm'>
                                            <i className="bi bi-pencil-square"></i>
                                        </button>
                                        <button onClick={() => { this.selectUser(d.sub); this.resetPasswordModal.toggle() }} disabled={loading} className='btn btn-info btn-sm'>
                                            <i className="bi bi-key-fill"></i>
                                        </button>
                                        <button onClick={() => { this.selectUser(d.sub); this.getGroupsForUser(d.sub); this.addUserToGroupModal.toggle() }} disabled={loading} className='btn btn-primary btn-sm'>
                                            <i className="bi bi-person-video2"></i>
                                        </button>
                                         */}
                                        { }
                                        <button onClick={() => { this.sendToCoordniator(d); }} disabled={loading} className='btn btn-success btn-sm'>
                                            <i className="bi bi-box-arrow-right"></i>Send
                                        </button>
                                        <button onClick={() => { this.getFile(d); }} disabled={loading} className='btn btn-info btn-sm'>
                                            <i className="bi bi-file-earmark-arrow-down"></i>Download CV
                                        </button>
                                        <button onClick={() => { this.selectCandidate(d); this.createUserModal.toggle() }} disabled={loading} className='btn btn-warning btn-sm'>
                                            <i className="bi bi-pencil-square"></i>
                                        </button>
                                        {/* <button onClick={() => { if (window.confirm('Are you sure you want to delete this user?')) { this.deleteUser(d.id); } }} disabled={loading} className='btn btn-danger btn-sm'>
                                            <i className="bi bi-trash-fill"></i>
                                        </button> */}
                                    </div>
                                </div>

                            </td>
                        </tr>)}
                    </tbody>
                </table>




            </div>
        )
    }
}

export default Coordination;