import API from "@aws-amplify/api";
import React, { createRef, useEffect, useState } from "react";
import * as bootstrap from 'bootstrap';
import dayjs from "dayjs";
import { graphqlOperation } from "aws-amplify";
import { createJoiners } from "../graphql/mutations";
import * as subscriptions from '../graphql/subscriptions';
import { CallerInterface, CompanyInterface, EmployeeInterface } from "../types";
import { formatEmployeeName, isSuperAdmin, REST_API_NAME } from "../utils";
import Select from "react-select";



import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const lists = {
    months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    tls: [
        "Rashmi",
        "Zubaina",
        "Zubaida",
        "Shruti",
        "Mithi",
        "Yousuf",
        "Srinivas",
        "Poojitha",
        "Raheen",
        "Raj"
    ],
    status: [
        "Offer", "Joiner", "Drop"
    ],
    recruiters: [

    ],
    yesNoGeneric: ["yes", "no"],
    yesNo: [
        { value: 0, label: "No" },
        { value: 1, label: "Yes" }
    ]
}

interface TableFilterFieldInterface {
    [field: string]: string | any[]
}


interface CallerTrackerStateInterface {
    loading: boolean
    callers: CallerInterface[]
}

class CallerTracker extends React.Component<{ user: any }, CallerTrackerStateInterface> {

    ref: any;
    modal: any;
    subscription: any

    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            //new
            callers: [
                {
                    id: -1,
                    name: '111',
                    number:'2222',
                    email: '',
                    comment: '',
                    recruiter_name: '',
                    recruiter_sub: '',
                    source: '',
                    roles: [''],
                    created_by: '',
                    created_on: '',
                    tags: '',
                    tag_list: [{label:'',value:''}],
                    workLocations: ''
                }
            ]
        }
        this.ref = createRef();
    }


    async componentDidMount() {

    }

    componentWillUnmount() {
    }

    filterTable = () => { }

    updateSpecifiedField = (id: number, fieldName: string, value: string | number | boolean) => {
        this.setState({ callers: this.state.callers.map(j => j.id == id ? { ...j, [fieldName]: value } : j) })
    }

    canEdit = (createdBy: string) => {
        const userSub = this.props.user.attributes.sub;
        const userGroups = this.props.user.signInUserSession.idToken.payload['cognito:groups'] || [];
        if (userGroups.find((g: string) => g === 'superadmin' || g == 'admin')) {
            return true;
        }
        return userSub == createdBy;

    }



    render() {

        const { callers } = this.state;

        const filters = { companies: [] };


        return <div className='my-5'>
            {/* <button onClick={this.toggleModal}>Add</button> */}


            <table className='table table-striped1 table-bordered table-sm table-hover table-editable'>
                <colgroup>
                    <col></col>
                    <col></col>
                    <col></col>
                    <col></col>
                    <col className='qq'></col>
                    <col></col>
                    <col></col>
                    <col></col>
                    <col></col>
                    <col></col>
                    <col></col>
                    <col className='qq'></col>
                    <col className='qq'></col>
                    <col></col>
                </colgroup>
                <thead>
                    <tr>
                        <th></th>
                        {/* <th>Name</th> */}

                        <Col
                            title='Name'
                            // filterData={lists.status.sort((a: any, b: any) => a.localeCompare(b)).map((c: string) => ({ label: c, value: c }))}
                            filterFunction={this.filterTable}
                            filterType='text'
                            filterField='name'
                        />
                        <Col
                            title='Number'
                            // filterData={lists.status.sort((a: any, b: any) => a.localeCompare(b)).map((c: string) => ({ label: c, value: c }))}
                            filterFunction={this.filterTable}
                            filterType='text'
                            filterField='number'
                        />
                        <Col
                            title='Email'
                            // filterData={lists.status.sort((a: any, b: any) => a.localeCompare(b)).map((c: string) => ({ label: c, value: c }))}
                            filterFunction={this.filterTable}
                            filterType='text'
                            filterField='email'
                        />
                        <Col
                            title='Comment'
                            // filterData={[]}
                            filterFunction={this.filterTable}
                            filterType='text'
                            filterField='comment'
                        />
                        <Col
                            title='workLocations'
                            filterData={lists.months.map((c: string) => ({ label: c, value: c }))}
                            filterFunction={this.filterTable}
                            filterType='list-multi'
                            filterField='workLocations'
                        />
                        <Col
                            title='Tags'
                            filterData={(filters.companies || []).map((c: any) => ({ label: c.name, value: c.name }))}
                            filterFunction={this.filterTable}
                            filterType='list-multi'
                            filterField='tags'
                        />
                        <th>created_by</th>
                        <th>created_on</th>
                    </tr>
                </thead>
                <tbody>
                    {callers.map((caller,idx) => <tr key={'caller-' + caller.id}>
                        <td>{idx+1}</td>
                        <Cell type={"text"} id={caller.id} field='name' value={caller.name} updateSpecifiedField={this.updateSpecifiedField} />
                        <Cell type={"text"} id={caller.id} field='number' value={caller.number} updateSpecifiedField={this.updateSpecifiedField} />
                    </tr>)}
                </tbody>
            </table>


            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        </div>
    }
}


interface NewEntryRowPropsInterface {
    setField: (f: string, v: any) => void
    isSuperAdmin: boolean
    data: any
    filters: any
    recruiters: EmployeeInterface[]
    teamLeaders: EmployeeInterface[]
    saveData: (data: any) => void
    companies: CompanyInterface[]
}

const NewEntryRow = (props: NewEntryRowPropsInterface) => {

    const { companies, data, filters, isSuperAdmin, recruiters, teamLeaders, saveData } = props;

    const [form, setForm] = useState<any>(data);
    const [filtersObj, setFilters] = useState<any>(filters);
    const [companyFilter, setCompanyFilter] = useState('');
    const [recruiterFilter, setRecruiterFilter] = useState('');
    const [tlFilter, setTlFilter] = useState('');

    useEffect(() => {
        setForm(data)
        setFilters(filters)
    }, [data])
    useEffect(() => {
        setFilters(filters)
    }, [filters])

    return (<tr>
        <td></td>
        <td>
            <input type='text' placeholder='Name' onChange={(evt) => setForm({ ...form, name: evt.target.value })} value={form.name} />
        </td>
        <td>
            <input type='text' placeholder='Number' onChange={(evt) => setForm({ ...form, number: evt.target.value })} value={form.number} style={{ width: '90px' }} />
        </td>
        <td>

            {/* <select onChange={(evt) => this.setField('company', evt.target.value)}>
                <option></option>
                {filters.companies.map((c: any) => <option key={'c-' + c.name} value={c.id}>{c.name}</option>)}
            </select> */}

            <div className="btn-group">
                <button className="btn btn-info btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {form.company || 'Company'}
                </button>
                <ul className="dropdown-menu">
                    <li>
                        <div className="input-group input-group-sm mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-sm">Filter</span>
                            <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onKeyPress={(evt) => setCompanyFilter(evt.currentTarget.value.toLowerCase())} />
                        </div>
                    </li>
                    {filters.companies.filter((c: any) => companyFilter == '' ? true : c.name.toLowerCase().indexOf(companyFilter) != -1).map((c: any) => <li key={'c-' + c.name} onClick={(evt) => setForm({ ...form, invoice_amount: c.amount, invoice_amount_actual: c.actual_amount, company: c.name })} ><a className="dropdown-item" href="#" onClick={(e) => e.preventDefault()}>{c.name}</a></li>)}
                </ul>
            </div>
        </td>
        <td>
            {/* <input type='text' placeholder='Process' onChange={(evt) => setField('process', evt.target.value)} value={data.process} /> */}
            {/* <Hint options={filters.process}> */}
            <input
                onChange={(evt) => setForm({ ...form, process: evt.target.value })}
                value={form.process}
                list={'process-new-list-1'}
                style={{ width: '115px' }}
            />
            <datalist id="process-new-list-1">
                {(filtersObj.process || []).map((c: any) => <option key={'p-' + c} value={c} />)}
            </datalist>
            {/* </Hint> */}
        </td>
        <td>
            <input type='date' placeholder='DoJ' onChange={(evt) => setForm({ ...form, doj: evt.target.value })} value={form.doj} style={{ width: '112px' }} />
        </td>
        <td>
            <select onChange={(evt) => setForm({ ...form, month: evt.target.value })} value={form.month}>
                <option></option>
                {lists.months.map(months => <option key={'month-new-' + months}>{months}</option>)}
            </select>
        </td>
        <td>
            {/* <input type='text' placeholder='Status' onChange={(evt) => setField('status', evt.target.value)} /> */}
            <select onChange={(evt) => setForm({ ...form, status: evt.target.value })} value={form.status}>
                <option></option>
                {lists.status.map(status => <option key={'status-new-' + status}>{status}</option>)}
            </select>
        </td>
        <td>
            <input style={{ width: '80px' }} type='number' placeholder='Invoice Amount' onChange={(evt) => setForm({ ...form, invoice_amount: evt.target.value })} min={0} value={form.invoice_amount} />
        </td>
        {isSuperAdmin ? <td>
            <input style={{ width: '80px' }} type='number' placeholder='Invoice Amount Actual' onChange={(evt) => setForm({ ...form, invoice_amount_actual: evt.target.value })} min={0} value={form.invoice_amount_actual} />
        </td> : ''}

        <td>
            {/* <select onChange={(evt) => setForm({ ...form, recruiter_id: evt.target.value, recruiter: recruiters.find(tl => (tl.id + "") == evt.target.value)!.name })} value={form.recruiter_id}>
                <option></option>
                {recruiters.map(rec => <option key={'rec-new-' + rec.name} value={rec.id} data-id={rec.id}>{rec.name}</option>)}
            </select> */}

            <div className="btn-group">
                <button className="btn btn-info btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {form.recruiter || 'Recruiter'}
                </button>
                <ul className="dropdown-menu">
                    <li>
                        <div className="input-group input-group-sm mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-sm">Filter</span>
                            <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onKeyPress={(evt) => setRecruiterFilter(evt.currentTarget.value.toLowerCase())} />
                        </div>
                    </li>
                    {recruiters.filter((c: any) => recruiterFilter == '' ? true : c.name.toLowerCase().indexOf(recruiterFilter) != -1).map((c: any) => <li key={'c-' + c.name} onClick={() => setForm({ ...form, recruiter_id: c.id, recruiter: c.name })} ><a className="dropdown-item" href="#" onClick={(e) => e.preventDefault()}>{c.name}</a></li>)}
                </ul>
            </div>
        </td>
        <td>
            {/* <select onChange={(evt) => setForm({ ...form, tl_id: evt.target.value, tl: teamLeaders.find(tl => (tl.id + "") == evt.target.value)!.name })} value={form.tl_id}>
                <option></option>
                {teamLeaders.map(tl => <option key={'tl-new-' + tl.name} value={tl.id} data-id={tl.id}>{tl.name}</option>)}
            </select> */}

            <div className="btn-group">
                <button className="btn btn-info btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {form.tl || 'TL'}
                </button>
                <ul className="dropdown-menu">
                    <li>
                        <div className="input-group input-group-sm mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-sm">Filter</span>
                            <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onKeyPress={(evt) => setTlFilter(evt.currentTarget.value.toLowerCase())} />
                        </div>
                    </li>
                    {teamLeaders.filter((c: any) => tlFilter == '' ? true : c.name.toLowerCase().indexOf(tlFilter) != -1).map((c: any) => <li key={'c-' + c.name} onClick={() => setForm({ ...form, tl_id: c.id, tl: c.name })} ><a className="dropdown-item" href="#" onClick={(e) => e.preventDefault()}>{c.name}</a></li>)}
                </ul>
            </div>
        </td>
        <td>
            <input type='text' placeholder='ApplicantID' onChange={(evt) => setForm({ ...form, applicant_id: evt.target.value })} value={form.applicant_id} style={{ width: '75px' }} />
        </td>
        <td>
            {/* <input type='text' placeholder='Invoice Sent' onChange={(evt) => setField('invoice_sent', evt.target.value)} /> */}
            <select onChange={(evt) => setForm({ ...form, invoice_sent: evt.target.value })} value={form.invoice_sent}>
                <option></option>
                <option value={1}>Yes</option>
                <option value={0}>No</option>
            </select>
        </td>
        <td>
            {/* <input type='text' placeholder='Review' onChange={(evt) => setField('review', evt.target.value)} /> */}
            <select onChange={(evt) => setForm({ ...form, review: evt.target.value })} value={form.review}>
                <option></option>
                <option value={'yes'}>Yes</option>
                <option value={'no'}>No</option>
            </select>
        </td>
        <td>
            <input type='number' placeholder='Points' onChange={(evt) => setForm({ ...form, points: evt.target.value })} min={0} maxLength={2} style={{ width: '50px' }} value={form.points} />
        </td>
        <td>
            <button onClick={() => saveData(form)}>
                Save
            </button>
        </td>
    </tr>)
}

interface ColInterface {
    filterFunction?: (f: string, v: any) => void
    filterFunctionMultiple?: (f: string, op: 'blank' | '>' | '<' | '=', v: any) => void
    filterData?: any[]
    filterType: 'none' | 'list' | 'text' | 'date' | 'list-multi'
    title: string
    filterField: string
}

const Col = ({ title, filterFunction, filterFunctionMultiple, filterData = [], filterType = 'none', filterField }: ColInterface) => {

    const [showFilter, setShowFilter] = useState(false);

    let ele: any = '';
    switch (filterType) {
        case 'list': ele = <select className='' onChange={(e) => filterFunction!(filterField, e.target.value)}>
            <option value=''>ALL</option>
            {filterData.map((fc: any) => <option key={'filter-' + fc.label} value={fc.value}>{fc.label}</option>)}
            {/* {filterData.sort((a: any, b: any) => a.localeCompare(b)).map((fc: any) => <option key={'filter-' + fc}>{fc}</option>)} */}
        </select>; break;
        case 'list-multi': ele = <Select
            isMulti={true}
            onChange={(e) => filterFunction!(filterField, e)}
            options={[{ label: '', value: '' }, ...filterData.map((fc: any) => ({ ...fc }))]}
        />; break;
        case 'text': ele = <>
            {/* <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={(e) => filterFunctionMultiple!(filterField, 'blank' ,e.target.checked)} />
                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Blank</label>
            </div> */}
            <input onChange={(e) => filterFunction!(filterField, e.target.value)} />
        </>; break;
        case 'date': ele = <input type='date' className='' onChange={(e) => filterFunction!(filterField, e.target.value)} />; break;
    }

    return (<th>
        <div className='coloumn-label'>
            {title}
            <i className="bi bi-funnel-fill cursor-pointer" onClick={() => setShowFilter(!showFilter)}></i>
        </div>
        {showFilter && (!filterFunction
            ? ''
            :
            <div className='coloumn-filter'>
                {ele}
            </div>
        )}
    </th>)
}

type CellTypes = "text" | "date" | "list" | "boolean" | "number"


interface CellInterface {
    id: number
    field: string
    value: string
    type: CellTypes
    updateSpecifiedField: (r: number, f: string, v: string | number | boolean) => void
}
const Cell = ({ id, field, value, type, updateSpecifiedField }: CellInterface) => {


    let ele;

    const [edit, setEdit] = useState(false);

    const renderer = (v: any) => v


    switch (type) {
        case "text": ele = <input type={type} defaultValue={value} />; break;
    }

    return (<td className={''} onClick={() => setEdit(!edit)} id={'td-' + field + id}>
        {edit
            ? ele
            : (renderer ? renderer(value) : value)}
    </td>)
}

export default CallerTracker;