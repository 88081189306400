/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:f18b7b11-c4bf-4ef5-88ec-f620043d3a5f",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_zKiDv92zk",
    "aws_user_pools_web_client_id": "16b9cmj87cpes6g3ackbprr0qt",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "apia2dc579f",
            "endpoint": "https://wpiky68im6.execute-api.ap-south-1.amazonaws.com/dev",
            "region": "ap-south-1"
        },
        {
            "name": "AdminQueries",
            "endpoint": "https://bhlcrbfthg.execute-api.ap-south-1.amazonaws.com/dev",
            "region": "ap-south-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://wmt7ffwwb5d3fadjedqchkajwq.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "atss3bucket185258-dev",
    "aws_user_files_s3_bucket_region": "ap-south-1",
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
