import API from "@aws-amplify/api";
import Storage from "@aws-amplify/storage";
import { Modal, Tab } from "bootstrap";
import dayjs from "dayjs";
import React, { useState } from "react";
import Select from "react-select";
import { CandidateInterface, CandidateWithCompanies, CANDIDATE_TAGS, CompanyInterface, EmployeeInterface, JOB_TAGS, OptionLabelValueInterface, WORK_LOCATIONS } from "../types";
import { CANDIDATE_ROLES, formatEmployeeName, formatName, formatNameAllowSpaeInTheEnd, isOnlyRecruiter, isOnlyTeamLeader, isSuperAdmin, isTeamLeader, MAX_DAYS_TO_WAIT_TO_ALLOW_DUPLICATE, REST_API_NAME, SOURCES } from "../utils";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import "react-datepicker/dist/react-datepicker.css";
import FormatDataForm from "../components/Candidate/FormatDataForm";
import CandidateForm from "../components/Candidate/CandidateForm";


// import FileViewer from 'react-file-viewer';




interface CandidatesStateInterface {
    loading: boolean,
    candidates: CandidateWithCompanies[],
    candidate: CandidateWithCompanies,
    mode: 'add' | 'edit',
    recruiters: EmployeeInterface[]
    teamLeaders: EmployeeInterface[]
    filterTeamLeader: string
    filterRecruiter: string
    filterCreatedBy: string
    filterDate: Date | null
    filterDateFrom: Date | null
    filterDateTo: Date | null
    employees: EmployeeInterface[]
    companies: (CompanyInterface & { disabled: boolean })[]
    selectedCompanies: OptionLabelValueInterface[]
    selectedMonth: number
    selectedYear: number
    cvUrl: string
    formats: any[]
    filterText: string
    selectedTags: any
    selectedWorkLocations: any
    selectedTagsFilter: any
    selectedWorkLocationsFilter: any
    coordinationHistory: any
}

const SIMULATION = false;

// const SOURCES = [
//     "Monster",
//     "Naukri",
//     "Times",
//     "Google",
//     "Just Dial",
//     "Reference",
//     "Incoming",
//     "Other"
// ]

class Candidates extends React.Component<any, CandidatesStateInterface>{

    createUserModalRef: any
    createUserModal: any;

    resetPasswordModalRef: any
    resetPasswordModal: any;

    addUserToGroupModalRef: any;
    addUserToGroupModal: any;

    addFormatDataModalRef: any;
    addFormatDataModal: any;

    addFormatDataTabsRef: any;
    addFormatDataTabs: any;

    inputPhoneRef: any;
    inputEmailRef: any;

    coordHistoryModalRef: any
    coordHistoryModal: any


    cvFileInputRef: any

    defaultCandidate = {
        id: -1,
        name: '',
        number: '',
        email: '',
        cvKey: '',
        identityId: this.props.user.identityId,
        rating: 0,
        tl_rating: 0,
        comment: '',
        recruiter_name: this.props.user.attributes.name,
        recruiter_sub: this.props.user.attributes.sub,
        roles: [] as string[],
        team_leader_sub: '',
        team_leader_name: '',
        companies: "",
        created_on: '',
        created_by: '',
        source: ''
    } as CandidateWithCompanies

    constructor(props: any) {
        super(props);
        const identityId = this.props.user.identityId
        const sub = this.props.user.attributes.sub

        this.state = {
            loading: true,
            candidates: [],
            candidate: this.defaultCandidate,
            mode: 'add',
            recruiters: [],
            teamLeaders: [],
            filterTeamLeader: '',
            filterCreatedBy: '',
            filterRecruiter: '',
            filterDate: new Date,
            filterDateFrom: new Date,
            filterDateTo: new Date,
            employees: [],
            companies: [],
            selectedCompanies: [],
            selectedMonth: (new Date()).getMonth() + 1,
            selectedYear: (new Date()).getFullYear(),
            cvUrl: '',
            formats: [],
            filterText: '',
            selectedTags: [],
            selectedWorkLocations: [],
            selectedTagsFilter: [],
            selectedWorkLocationsFilter: [],
            coordinationHistory: { tl: [], recruiter: [] }
        }

        this.createUserModalRef = React.createRef();
        this.cvFileInputRef = React.createRef();
        this.addFormatDataModalRef = React.createRef();
        this.addFormatDataTabsRef = React.createRef();
        this.inputPhoneRef = React.createRef();
        this.inputEmailRef = React.createRef();
        this.coordHistoryModalRef = React.createRef();

    }

    async componentDidMount() {
        this.createUserModal = new Modal(this.createUserModalRef.current);
        this.createUserModalRef.current.addEventListener('hide.bs.modal', (event: any) => {
            const sub = this.props.user.attributes.sub;

            this.setState({
                candidate: this.defaultCandidate,
                selectedCompanies: [],
                selectedTags: []
            })

            // this.inputEmailRef.current.value = '';
            // this.inputPhoneRef.current.value = '';
            this.cvFileInputRef.current.value = '';

        })
        this.createUserModalRef.current.addEventListener('shown.bs.modal', async (event: any) => {
            const { candidate } = this.state;
            const ele = document.getElementById('inputRating') as HTMLInputElement;
            ele!.value = '' + candidate.rating;
            const ele2 = document.getElementById('inputTLRating') as HTMLInputElement;
            ele2!.value = candidate.tl_rating ? ('' + candidate.tl_rating) : '0';

            //Check for company assignmnets and disable comapnies already assigned
            if (candidate.id != -1) {


                this.setState({ loading: true })
                // const resultMasters = await API.get(REST_API_NAME, `/candidates/check-duplicate/${field}/` + value, {});
                // if (resultMasters.result.length) {
                //     toast.error(`Candidate with the given ${field} already exists`)
                //     if (field === 'email') {
                //         // this.inputEmailRef.current.value = '';
                //     } else if (field === 'number') {
                //         // this.inputPhoneRef.current.value = '';
                //     }
                // }
                const { candidate, companies } = this.state;
                const date_range = dayjs().subtract(MAX_DAYS_TO_WAIT_TO_ALLOW_DUPLICATE, 'days').format('YYYY/MM/DD 23:59:59');

                const companiesAssignedResult = await API.get(REST_API_NAME, `/candidates/get-companies-assigned/`, {
                    queryStringParameters: {
                        number: candidate.number,
                        email: candidate.email,
                        date_range
                    }
                });

                let companiesAssignedExisting = companiesAssignedResult.result
                //check if the assigned companie are not for the same candidate,
                //which will happen on edit
                // debugger;
                if (candidate.id) {
                    companiesAssignedExisting = companiesAssignedExisting.filter((c: any) => c.candidateId != candidate.id);
                }

                if (companiesAssignedExisting) {
                    const ids = companiesAssignedExisting.map((r: any) => r.companyId);
                    toast.error(`${candidate.name || 'Candidate'} has already been assigned to companies`);

                    //check if the selected company has already been added to this candidate. If so, block
                    const { selectedCompanies } = this.state;
                    const found = selectedCompanies.some(c => ids.indexOf(+c.value) == -1 ? false : true);
                    if (found) {
                        // alert('Found');
                        toast.error(`${candidate.name || 'Candidate'} has already been assigned to selected Companies.`);
                        // this.setState({ loading: true})
                        this.setState({
                            loading: false,
                            selectedCompanies: selectedCompanies.filter(c => ids.indexOf(+c.value) == -1 ? true : false),
                            companies: companies.map(c => { c.disabled = ids.indexOf(c.id) == -1 ? false : true; return c; })
                        })
                        return;

                    }



                    this.setState({ loading: false, companies: this.state.companies.map(c => { c.disabled = ids.indexOf(c.id) == -1 ? false : true; return c; }) })
                } else {
                    this.setState({ loading: false })
                }


            }

        })
        this.addFormatDataModal = new Modal(this.addFormatDataModalRef.current);
        this.addFormatDataModalRef.current.addEventListener('hidden.bs.modal', (event: any) => {
            const sub = this.props.user.attributes.sub;

            this.setState({
                candidate: this.defaultCandidate,
            })
            this.addFormatDataTabs.dispose();


        })
        this.addFormatDataModalRef.current.addEventListener('shown.bs.modal', (event: any) => {
            this.addFormatDataTabs = new Tab(this.addFormatDataTabsRef.current.querySelector('li:last-child a'));
        })


        this.coordHistoryModal = new Modal(this.coordHistoryModalRef.current)

        // const resultCV = await API.post(REST_API_NAME, `/candidates/check-cv/`, { body: { key:'qwqaa' } });


        this.getData();
    }

    getData = async (filter?: {
        recruiter?: string,
        tl?: string,
        month: number,
        year: number,
        createdOn?: Date | null | undefined,
        createdOnFrom?: Date | null | undefined,
        createdOnTo?: Date | null | undefined,
        createdBy?: string,
        tags: string,
        workLocations: string
    }) => {
        const sub = this.props.user.attributes.sub;
        let { filterRecruiter, filterTeamLeader, filterDate, filterDateFrom, filterDateTo, filterCreatedBy } = this.state;
        let month = (new Date()).getMonth() + 1
        let year = (new Date()).getFullYear()
        let createdOn = null, createdOnFrom = null, createdOnTo = null;
        let createdBy = '';

        if (filter && filter.createdOnFrom && filter.createdOnTo) {
            const diff = dayjs(filter.createdOnTo).diff(filter.createdOnFrom, 'day');
            if (diff > 31) {

            }
        }

        // debugger;

        if (filter && filter.recruiter != undefined) {
            filterRecruiter = filter.recruiter
        }
        if (filter && filter.tl != undefined) {
            filterTeamLeader = filter.tl
        }

        if (filter && filter.month != undefined) {
            month = filter.month
            year = filter.year
        }
        if (filter && filter.createdOn != undefined) {
            createdOn = dayjs(filter.createdOn).format('YYYY-MM-DD')
        } else {
            //if no create date is specified, filter for the current date
            createdOn = dayjs().format('YYYY-MM-DD')
        }

        if (filter && filter.createdOnFrom != undefined) {
            createdOnFrom = dayjs(filter.createdOnFrom).format('YYYY-MM-DD') + ' 00:00:00'
        } else {
            //if no create date is specified, filter for the current date
            createdOnFrom = dayjs().format('YYYY-MM-DD') + ' 00:00:00'
        }
        if (filter && filter.createdOnTo != undefined) {
            createdOnTo = dayjs(filter.createdOnTo).format('YYYY-MM-DD') + ' 23:59:59'
        } else {
            //if no create date is specified, filter for the current date
            createdOnTo = dayjs().format('YYYY-MM-DD') + ' 23:59:59'
        }


        if (filter && filter.createdBy != undefined) {
            createdBy = filter.createdBy
        }

        this.setState({ loading: true })


        let PAGE = 0;
        // const resultCandidates = await API.get(REST_API_NAME, '/candidates', {});
        const resultMasters = await API.get(REST_API_NAME, '/candidates/masters/initial-v3', {
            // const resultMasters = await API.get(REST_API_NAME, '/candidates/masters/initial-v2', {
            queryStringParameters: {
                recruiter: (isOnlyRecruiter(this.props.user)) ? sub : filterRecruiter,
                teamLeader: isOnlyTeamLeader(this.props.user) ? sub : filterTeamLeader,
                // month: month,
                // year: year,
                createdOn,
                createdOnFrom,
                createdOnTo,
                createdBy,
                page: PAGE,
                selectedWorkLocationsFilter: (filter && filter.workLocations != '') ? filter.workLocations : '',
                selectedTagsFilter: (filter && filter.tags != '') ? filter.tags : ''
            }
        });

        const employees = resultMasters.result.employees;
        const companies = resultMasters.result.companies;
        let candidatesALL = resultMasters.result.candidates;
        let candidatesCount = resultMasters.result.totalCandidates;

        let promises = [];
        if (candidatesCount > candidatesALL.length) {
            const iterations = candidatesCount / candidatesALL.length;
            for (let i = 1; i < iterations; i++) {
                // if (window.confirm(candidatesCount + '>' + candidatesALL.length)) {
                promises.push(API.get(REST_API_NAME, '/candidates/get-candidates', {
                    // const resultMasters = await API.get(REST_API_NAME, '/candidates/masters/initial-v2', {
                    queryStringParameters: {
                        recruiter: (isOnlyRecruiter(this.props.user)) ? sub : filterRecruiter,
                        teamLeader: isOnlyTeamLeader(this.props.user) ? sub : filterTeamLeader,
                        // month: month,
                        // year: year,
                        createdOn,
                        createdOnFrom,
                        createdOnTo,
                        createdBy,
                        page: PAGE + i,
                        selectedWorkLocationsFilter: (filter && filter.workLocations != '') ? filter.workLocations : '',
                        selectedTagsFilter: (filter && filter.tags != '') ? filter.tags : ''
                    }
                }))
                // }
            }

            return Promise.all(promises)
                .then(results => {

                    results.map(r => candidatesALL.push(...r.result.candidates));
                    console.log(candidatesALL.length)

                    const candidates = candidatesALL.map((candidate: CandidateWithCompanies) => {
                        let _companiesStr = candidate.companies || "";
                        let _companies = _companiesStr.split(',');
                        const _companiesList = _companies.map(c => {
                            const [label, value] = c.split(':');
                            return { label, value }
                        })
                        candidate.companies_list = _companiesList;

                        const tags = candidate.tags ? candidate.tags.split(';') : [];
                        let _tags: OptionLabelValueInterface[] = [];
                        tags.map((t: string) => {
                            const _t = CANDIDATE_TAGS.find(c => c.value == t)!;
                            _tags.push(_t)
                        })
                        candidate.tag_list = _tags;


                        //need to do this as our type is string[] snd serve returns csv values
                        candidate.roles = ((candidate.roles || "") as unknown as string).split(',');

                        candidate.recruiter_name = formatEmployeeName(candidate.recruiter_name)
                        candidate.team_leader_name = formatEmployeeName(candidate.team_leader_name)
                        return candidate;
                    })

                    const recruiters = employees;//employees.filter((e: EmployeeInterface) => e.role.indexOf('recruiter') != -1);
                    const teamLeaders = employees.filter((e: EmployeeInterface) => e.role.indexOf('teamleader') != -1 || e.role.indexOf('superadmin') != -1);

                    this.setState({ loading: false, companies, employees, recruiters, teamLeaders, candidates })

                })


        }



        const candidates = candidatesALL.map((candidate: CandidateWithCompanies) => {
            let _companiesStr = candidate.companies || "";
            let _companies = _companiesStr.split(',');
            const _companiesList = _companies.map(c => {
                const [label, value] = c.split(':');
                return { label, value }
            })
            candidate.companies_list = _companiesList;
            //need to do this as our type is string[] snd serve returns csv values
            candidate.roles = ((candidate.roles || "") as unknown as string).split(',');

            candidate.recruiter_name = formatEmployeeName(candidate.recruiter_name)
            candidate.team_leader_name = formatEmployeeName(candidate.team_leader_name)
            return candidate;
        })

        const recruiters = employees;//employees.filter((e: EmployeeInterface) => e.role.indexOf('recruiter') != -1);
        const teamLeaders = employees.filter((e: EmployeeInterface) => e.role.indexOf('teamleader') != -1 || e.role.indexOf('superadmin') != -1);

        this.setState({ loading: false, companies, employees, recruiters, teamLeaders, candidates })
    }



    setField = async (field: keyof CandidateInterface, value: string | boolean | number) => {
        if (this.state.candidate[field] == value) { return }
        console.log(field, value);
        value = typeof value === 'string' ? value.replace(/[^ -~]+/g, "").trim() : value;

        if (field === 'number' || field == 'email') {
            this.setState({ loading: true })
            // const resultMasters = await API.get(REST_API_NAME, `/candidates/check-duplicate/${field}/` + value, {});
            // if (resultMasters.result.length) {
            //     toast.error(`Candidate with the given ${field} already exists`)
            //     if (field === 'email') {
            //         // this.inputEmailRef.current.value = '';
            //     } else if (field === 'number') {
            //         // this.inputPhoneRef.current.value = '';
            //     }
            // }
            const { candidate, companies } = this.state;
            const date_range = dayjs().subtract(MAX_DAYS_TO_WAIT_TO_ALLOW_DUPLICATE, 'days').format('YYYY/MM/DD 23:59:59');

            const companiesAssignedResult = await API.get(REST_API_NAME, `/candidates/get-companies-assigned/`, {
                queryStringParameters: {
                    number: field === 'number' ? value : candidate.number,
                    email: field === 'email' ? value : candidate.email,
                    date_range
                }
            });

            let companiesAssignedExisting = companiesAssignedResult.result
            //check if the assigned companie are not for the same candidate,
            //which will happen on edit
            // debugger;
            if (candidate.id) {
                companiesAssignedExisting = companiesAssignedExisting.filter((c: any) => c.candidateId != candidate.id);
            }

            if (companiesAssignedExisting.length) {
                const ids = companiesAssignedExisting.map((r: any) => r.companyId);
                toast.error(`${candidate.name || 'Candidate'} has already been assigned to companies`);

                //check if the selected company has already been added to this candidate. If so, block
                const { selectedCompanies } = this.state;
                const found = selectedCompanies.some(c => ids.indexOf(+c.value) == -1 ? false : true);
                if (found) {
                    // alert('Found');
                    toast.error(`${candidate.name || 'Candidate'} has already been assigned to selected Companies.`);
                    // this.setState({ loading: true})
                    this.setState({
                        loading: false,
                        selectedCompanies: selectedCompanies.filter(c => ids.indexOf(+c.value) == -1 ? true : false),
                        companies: companies.map(c => { c.disabled = ids.indexOf(c.id) == -1 ? false : true; return c; })
                    })
                    return;

                }



                this.setState({
                    loading: false,
                    candidate: { ...this.state.candidate, [field]: value },
                    companies: this.state.companies.map(c => { c.disabled = ids.indexOf(c.id) == -1 ? false : true; return c; })
                })
            } else {

                //enable all companies as none have been assigned
                this.setState({
                    loading: false,
                    candidate: { ...this.state.candidate, [field]: value },
                    companies: this.state.companies.map(c => { c.disabled = false; return c; })
                })

            }

        } else {
            this.setState({ candidate: { ...this.state.candidate, [field]: value } });
        }
    }
    setFields = (fields: Partial<CandidateInterface>) => {
        this.setState({ candidate: { ...this.state.candidate, ...fields } })
    }

    uploadFile = async (file: File) => {
        console.log(file);
        if (!file) { return }
        const ts = +new Date();
        const userSub = this.props.user.attributes.sub;
        const _name = file.name.split('.');
        const name = (_name[0] ? _name[0] : file.name).replace("'", "").replace(/[^\x20-\x7E]/g, '').replace(/[^\w ]/, '').toLowerCase() + '.' + _name[1];
        const extension = file.name.split('.').pop();
        console.log('Name:', name);
        const key = userSub + '--' + ts + '-' + name;

        const { candidate } = this.state;
        const { cvKey } = candidate;
        this.setState({ loading: true })

        if (SIMULATION) {
            this.setState({ candidate: { ...this.state.candidate, cvKey: key }, loading: false })
            return;
        }

        try {
            // if (cvKey) {
            //     if (window.confirm('Are you sure you want to delete ths exisiting CV and add a new one?')) {
            //         await Storage.remove(cvKey, {
            //             level: 'protected',
            //         });
            //         console.log('Removed', cvKey)
            //         //@todo log removing file
            //         // const result = await API.post(REST_API_NAME, `/candidates/`, { body: { ...candidate, companies: selectedCompanies } });
            //     }
            // }

            const fileAdded = await Storage.put(key, file, {
                level: 'protected',
                progressCallback(progress) {
                    console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                },
            });
            console.log('Added', fileAdded)
            if (!fileAdded.key) {
                alert('Please uplaod the file again');
                return;
            }
            const identityId = this.props.user.identityId
            this.setState({ loading: false, candidate: { ...this.state.candidate, identityId, cvKey: fileAdded.key } })
        } catch (error) {
            console.log('Error uploading file: ', error);
            alert('Error Uploading file')
            this.setState({ loading: false })
        }
    }

    deleteCandidate = async (candidate: CandidateInterface) => {

        const user = this.props.user;
        if (!isSuperAdmin(user) && candidate.created_by !== user.attributes.sub) {
            toast.error('Not created by you');
            return;
        }

        try {
            //@todo not removing resue
            // const candidate = this.state.candidates.find(c => c.id == id)!;
            // await Storage.remove(candidate.cvKey, {
            //     level: 'protected',
            // });
            await API.del(REST_API_NAME, `/candidates/${candidate.id}`, {});
            this.setState({ candidates: this.state.candidates.filter(c => c.id !== candidate.id) })
        } catch (e) {
            console.log(e)
        }
    }

    addCandidate = async () => {
        const { candidates, candidate, selectedCompanies, selectedTags, selectedWorkLocations } = this.state
        candidate.identityId = this.props.user.identityId;
        const sub = this.props.user.attributes.sub;

        if (!candidate.name) {
            alert('Name is required');
            return;
        }
        if (!candidate.number) {
            alert('Number is required');
            return;
        }
        if (!candidate.recruiter_name) {
            alert('Recruiter is required');
            return;
        }
        if (!candidate.team_leader_name) {
            alert('Team Leader is required');
            return;
        }
        if (!candidate.cvKey) {
            alert('CV is required');
            return;
        }
        if (!candidate.source) {
            alert('Source is required');
            return;
        }
        if (!candidate.roles.length) {
            alert('Role is required');
            return;
        }
        if (!selectedCompanies.length) {
            alert('Company is required');
            return;
        }
        if (candidate.rating == 0) {
            alert('Rating is required');
            return;
        }

        candidate.name = candidate.name.trim();
        candidate.tags = selectedTags.length ? (selectedTags.map((r: OptionLabelValueInterface) => r.value).join(';') + ';') : ''
        candidate.workLocations = selectedWorkLocations.length ? (selectedWorkLocations.map((r: OptionLabelValueInterface) => r.value).join(';') + ';') : ''
        this.setState({ loading: true, mode: 'add' })

        if (SIMULATION) {
            this.setState({
                loading: false,
                candidate: this.defaultCandidate,
                candidates: [{ ...candidate, id: +new Date }, ...this.state.candidates],
                selectedCompanies: []
            })
            this.createUserModal.toggle();
            this.cvFileInputRef.current.value = ''
            return;
        }

        try {
            const key = 'protected/' + candidate.identityId + '/' + candidate.cvKey
            const resultCV = await API.post(REST_API_NAME, `/candidates/check-cv/`, { body: { key } });
            if (!resultCV.success) {
                alert('Please re-upload the CV');
                return;
            }

            const result = await API.post(REST_API_NAME, `/candidates/`, { body: { ...candidate, companies: selectedCompanies } });

            window.location.reload();


            this.setState({
                loading: false,
                candidate: this.defaultCandidate,
                candidates: [{ ...candidate, id: result.result.insertId, companies_list: selectedCompanies }, ...this.state.candidates]
            })
            this.createUserModal.toggle();


        } catch (e) {
            console.log('Error', e);
            this.setState({ loading: false })
        }
    }

    updateCandidate = async () => {

        const { candidate, selectedCompanies, selectedTags, selectedWorkLocations } = this.state
        this.setState({ loading: true, mode: 'add' })
        candidate.identityId = this.props.user.identityId;
        const sub = this.props.user.attributes.sub;

        if (SIMULATION) {
            this.setState({
                loading: false,
                candidate: this.defaultCandidate,
                candidates: this.state.candidates.map(c => c.id == candidate.id ? candidate : c)
            })
            this.createUserModal.toggle();
            this.cvFileInputRef.current.value = ''

            return;
        }

        candidate.tl_rating = candidate.tl_rating || null as any;
        candidate.comment = candidate.comment || '';


        //check if companies selection has changed
        ////if so, chekc for duplicates
        //////DUpliactes are allowed after 15 days. So this cant be done at db index level
        // if(selectedCompanies.length!=candidate.companies_list.length){
        //     alert('Companies changed')
        //     return;
        // }
        // const companiesChanged = selectedCompanies.filter(s=>!candidate.companies_list.some(c=>s.value==c.value));
        // console.log('Changed Companies',companiesChanged);
        // this.setState({ loading: false, mode: 'add' })

        // return;

        if (!candidate.name) {
            alert('Name is required');
            return;
        }
        if (!candidate.number) {
            alert('Number is required');
            return;
        }
        if (!candidate.recruiter_name) {
            alert('Recruiter is required');
            return;
        }
        if (!candidate.team_leader_name) {
            alert('Team Leader is required');
            return;
        }
        if (!candidate.cvKey) {
            alert('CV is required');
            return;
        }
        if (!candidate.source) {
            alert('Source is required');
            return;
        }
        if (!candidate.roles.length) {
            alert('Role is required');
            return;
        }
        if (!selectedCompanies.length) {
            alert('Company is required');
            return;
        }

        if (candidate.rating == 0) {
            alert('Rating is required');
            return;
        }

        candidate.tags = selectedTags.length ? (selectedTags.map((r: OptionLabelValueInterface) => r.value).join(';') + ';') : ''
        candidate.workLocations = selectedWorkLocations.length ? (selectedWorkLocations.map((r: OptionLabelValueInterface) => r.value).join(';') + ';') : ''



        try {
            await API.put(REST_API_NAME, `/candidates/update/` + candidate.id, { body: { ...candidate, companies: selectedCompanies } });

            window.location.reload();


            this.setState({
                loading: false,
                candidate: this.defaultCandidate,
                candidates: this.state.candidates.map(c => c.id == candidate.id ? { ...candidate, companies_list: selectedCompanies } : c)
            })
            this.createUserModal.toggle();
            toast.success('Updated candidate successfully')

        } catch (e) {
            console.log('Error', e);
            this.setState({ loading: false })
            toast.error('Error')

        }

    }

    getFile = async (candidate: CandidateInterface) => {

        const { cvKey } = candidate;
        const file = await Storage.get(cvKey, { level: 'protected', identityId: candidate.identityId });
        window.open(file)
        console.log(file)
    }
    viewFile = async (candidate: CandidateInterface) => {

        const { cvKey } = candidate;
        const file = await Storage.get(cvKey, { level: 'protected', identityId: candidate.identityId });

        this.setState({ cvUrl: file })
        // window.open(file)
        console.log(file)
    }

    clearCandidate = () => {
        const sub = this.props.user.attributes.sub;
        this.setState({
            candidate: this.defaultCandidate,
            mode: 'add',
            selectedCompanies: []
        })
    }
    selectCandidate = async (candidate: CandidateWithCompanies) => {

        const tags = (candidate.tags == "" || !candidate.tags) ? [] : candidate.tags.split(';');
        let _tags: OptionLabelValueInterface[] = [];
        tags.map((t: string) => {
            const _t = CANDIDATE_TAGS.find(c => c.value == t);
            _t && _tags.push(_t)
        })

        const workLocations = (candidate.workLocations == "" || !candidate.workLocations) ? [] : candidate.workLocations.split(';');
        let _workLocations: OptionLabelValueInterface[] = [];
        workLocations.map((t: string) => {
            const _t = WORK_LOCATIONS.find(c => c.value == t);
            _t && _workLocations.push(_t)
        })

        this.setState({ candidate, selectedTags: _tags, selectedWorkLocations: _workLocations, mode: 'edit', selectedCompanies: candidate.companies_list });

        const resultMasters = await API.get(REST_API_NAME, `/candidates/get-companies-assigned/`, {
            queryStringParameters: {
                number: candidate.number,
                email: candidate.email
            }
        });

        if (resultMasters.result.length) {
            const ids = resultMasters.result.map((r: any) => r.companyId)
            this.setState({ loading: false, companies: this.state.companies.map(c => { c.disabled = ids.indexOf(c.id) == -1 ? false : true; return c; }) })
        }



    }

    setRecruiter = (filterRecruiter: string) => {
        this.setState({ filterRecruiter })

        const _workLocations = this.state.selectedWorkLocationsFilter.map((o: any) => o.value).join(';');
        const workLocations = _workLocations == '' ? '' : ('' + _workLocations + ';');

        const _tags = this.state.selectedTagsFilter.map((o: any) => o.value).join(';');
        const tags = _tags == '' ? '' : ('' + _tags + ';');

        this.getData({
            recruiter: filterRecruiter,
            tl: this.state.filterTeamLeader,
            month: this.state.selectedMonth,
            year: this.state.selectedYear,
            createdOn: this.state.filterDate,
            createdOnFrom: this.state.filterDateFrom,
            createdOnTo: this.state.filterDateTo,
            createdBy: this.state.filterCreatedBy,
            tags: tags,
            workLocations: workLocations
        });
    }
    setTeamLeader = (filterTeamLeader: string) => {
        this.setState({ filterTeamLeader })

        const _workLocations = this.state.selectedWorkLocationsFilter.map((o: any) => o.value).join(';');
        const workLocations = _workLocations == '' ? '' : ('' + _workLocations + ';');

        const _tags = this.state.selectedTagsFilter.map((o: any) => o.value).join(';');
        const tags = _tags == '' ? '' : ('' + _tags + ';');


        this.getData({
            recruiter: this.state.filterRecruiter,
            tl: filterTeamLeader,
            month: this.state.selectedMonth,
            year: this.state.selectedYear,
            createdOn: this.state.filterDate,
            createdOnFrom: this.state.filterDateFrom,
            createdOnTo: this.state.filterDateTo,
            createdBy: this.state.filterCreatedBy,
            tags: tags,
            workLocations: workLocations

        });
    }
    setCreatedBy = (filterCreatedBy: string) => {
        this.setState({ filterCreatedBy })

        const _workLocations = this.state.selectedWorkLocationsFilter.map((o: any) => o.value).join(';');
        const workLocations = _workLocations == '' ? '' : ('' + _workLocations + ';');

        const _tags = this.state.selectedTagsFilter.map((o: any) => o.value).join(';');
        const tags = _tags == '' ? '' : ('' + _tags + ';');

        this.getData({
            recruiter: this.state.filterRecruiter,
            tl: this.state.filterTeamLeader,
            createdBy: filterCreatedBy,
            month: this.state.selectedMonth,
            year: this.state.selectedYear,
            createdOn: this.state.filterDate,
            createdOnFrom: this.state.filterDateFrom,
            createdOnTo: this.state.filterDateTo,
            tags: tags,
            workLocations: workLocations
        });
    }

    selectCompany = async (company: any) => {
        const c = company.pop();
        this.setState({ selectedCompanies: c });
        const { candidate, selectedCompanies, companies } = this.state;
        // debugger;
        // const { result } = await API.get(REST_API_NAME, `/candidates/check-duplicate-entries`, {
        //     queryStringParameters: {
        //         number: candidate.number,
        //         email: candidate.email,
        //         companies: c.map((c: any) => c.value).join(',')
        //     }
        // });
        // if (result.length) {
        //     // result.map(r)
        //     const ids = result.map((r: any) => r.companyId)
        //     toast.error(`${candidate.name} has already been assigned to company`);
        //     this.setState({ companies: this.state.companies.map(c => { c.disabled = ids.indexOf(c.id) == -1; return c; }) })
        // } else {

        // }



    }
    selectCandidateRoles = async (roles: any) => {
        const c = roles.pop();
        this.setState({ candidate: { ...this.state.candidate, roles: c.map((c: OptionLabelValueInterface) => c.value) } });
    }

    updateCandidateRow = async (candidate: CandidateWithCompanies, field: keyof CandidateWithCompanies) => {

        try {
            await API.put(REST_API_NAME, `/candidates/update-field/${field}/` + candidate.id, { body: { value: candidate[field] } });
            this.setState({
                loading: false,
                candidate: this.defaultCandidate,
                candidates: this.state.candidates.map(c => c.id == candidate.id ? { ...candidate } : c)
            })
            // this.createUserModal.toggle();
            // window.location.reload();

        } catch (e) {
            console.log('Error', e);
            this.setState({ loading: false })
        }

    }

    selectMonth = (selectedMonth: number) => {
        this.setState({ selectedMonth, filterDate: null });
        this.getData({
            month: selectedMonth, year: this.state.selectedYear, tags: this.state.selectedTagsFilter,
            workLocations: this.state.selectedWorkLocationsFilter
        })

    }

    selectYear = (selectedYear: number) => {
        this.setState({ selectedYear });
        this.getData({
            month: this.state.selectedMonth, year: selectedYear, tags: this.state.selectedTagsFilter,
            workLocations: this.state.selectedWorkLocationsFilter
        })
    }

    selectFilterDate = (filterDate: any) => {
        this.setState({ filterDate: filterDate });

        const _workLocations = this.state.selectedWorkLocationsFilter.map((o: any) => o.value).join(';');
        const workLocations = _workLocations == '' ? '' : ('' + _workLocations + ';');

        const _tags = this.state.selectedTagsFilter.map((o: any) => o.value).join(';');
        const tags = _tags == '' ? '' : ('' + _tags + ';');


        this.getData({
            recruiter: this.state.filterRecruiter,
            tl: this.state.filterTeamLeader,
            month: this.state.selectedMonth,
            year: this.state.selectedYear,
            createdOn: filterDate,
            tags: tags,
            workLocations: workLocations
        });

    }
    selectFilterDateFrom = (filterDateFrom: any) => {
        this.setState({ filterDateFrom });
        // this.setState({ filterDate: filterDate });

        const _workLocations = this.state.selectedWorkLocationsFilter.map((o: any) => o.value).join(';');
        const workLocations = _workLocations == '' ? '' : ('' + _workLocations + ';');

        const _tags = this.state.selectedTagsFilter.map((o: any) => o.value).join(';');
        const tags = _tags == '' ? '' : ('' + _tags + ';');



        // this.getData({
        //     workLocations: workLocations, month: this.state.selectedMonth, year: this.state.selectedYear,
        //     tags: tags
        // })

        this.getData({
            recruiter: this.state.filterRecruiter,
            tl: this.state.filterTeamLeader,
            month: this.state.selectedMonth,
            year: this.state.selectedYear,
            createdOnFrom: filterDateFrom,
            createdOnTo: this.state.filterDateTo,
            tags: tags,
            workLocations: workLocations
        });

    }
    selectFilterDateTo = (filterDateTo: any) => {
        this.setState({ filterDateTo });
        // this.setState({ filterDate: filterDate });

        const _workLocations = this.state.selectedWorkLocationsFilter.map((o: any) => o.value).join(';');
        const workLocations = _workLocations == '' ? '' : ('' + _workLocations + ';');

        const _tags = this.state.selectedTagsFilter.map((o: any) => o.value).join(';');
        const tags = _tags == '' ? '' : ('' + _tags + ';');

        this.getData({
            recruiter: this.state.filterRecruiter,
            tl: this.state.filterTeamLeader,
            month: this.state.selectedMonth,
            year: this.state.selectedYear,
            createdOnFrom: this.state.filterDateFrom,
            createdOnTo: filterDateTo,
            tags: tags,
            workLocations: workLocations
        });

    }

    showAddFormatDataModal = async (candidate: CandidateWithCompanies) => {

        if (!candidate.companies) {
            alert('No Companies Added');
            return;
        }
        this.setState({ loading: true, formats: [] });

        //get formats for companies

        const companyIds = candidate.companies.split(',').map(c => c.split(':')[1]).join(',');
        const formats = await API.get(REST_API_NAME, '/coordination/format-for-companies/' + candidate.id + '/' + companyIds, {})
        console.log(formats)


        const { data } = formats;
        let companyFormat: any = {};
        formats.result.records.map((f: any) => {
            if (!f.format_id) { return }
            const company = this.state.companies.find(c => c.id == f.company_id)!;
            const records = data;
            // const record = records.find((rec: any) => rec.format_id = f.format_id)
            if (!company) {
                alert('Format doesnt exist for ' + f.name)
                return false;
            }
            companyFormat[f.format_id] = companyFormat[f.format_id] || { formatId: f.format_id, companyId: f.company_id, companyName: company.name, name: f.name, rows: [], data: records };

            companyFormat[f.format_id].rows.push(f)
            // const company = this.state.companies.find(c => c.id == f.company_id)!
            // companyFormat[f.company_id] = companyFormat[f.company_id] || { companyId: f.company_id, companyName: company.name, name: f.name, rows: [] };
            // companyFormat[f.company_id].rows.push(f)
        })

        this.addFormatDataModal.toggle();


        this.setState({ candidate, formats: Object.values(companyFormat), loading: false });

    }

    addCandidateFormatData = async (data: any) => {
        console.log(data);
        const { candidate } = this.state;

        const result = await API.post(REST_API_NAME, '/coordination/format-for-candidate', {
            body: {
                candidateId: candidate.id,
                rows: data
            }
        })

        toast.success('Successfully added format data')
    }
    updateCandidateFormatData = (data: any) => {
        console.log(data)
    }

    setColDataAuto = async (format: any, row: any, value: string) => {
        const { candidate } = this.state;
        console.log(row, value);
        if (value.trim() == '') {
            return;
        }
        this.setState({ loading: true })

        const result = await API.put(REST_API_NAME, '/coordination/format-for-candidate-row/' + row.id, {
            body: {
                row,
                value,
                candidateId: candidate.id
            }
        })
        this.setState({ loading: false })


        toast.success('Successfully added format data')
    }
    checkCV = async (candidate: CandidateInterface) => {

        // const {candidates}=this.state;
        // const keys=candidates.map(candidate=>'protected/'+candidate.identityId+'/'+candidate.cvKey)

        const result = await API.post(REST_API_NAME, '/candidates/check-cv/', {
            body: {
                key: 'protected/' + candidate.identityId + '/' + candidate.cvKey
                // keys:keys.join(',')
            }
        })


    }

    setFiltertext = (filterText: string) => this.setState({ filterText })


    search = async () => {
        const { filterText } = this.state;
        this.setState({
            loading: true,
        })

        try {
            const candidatesResult = await API.get(REST_API_NAME, `/candidates/search/`, {
                queryStringParameters: {
                    filter: filterText
                }
            });

            const candidates = candidatesResult.result.map((candidate: CandidateWithCompanies) => {
                let _companiesStr = candidate.companies || "";
                let _companies = _companiesStr.split(',');
                const _companiesList = _companies.map(c => {
                    const [label, value] = c.split(':');
                    return { label, value }
                })
                candidate.companies_list = _companiesList;
                //need to do this as our type is string[] snd serve returns csv values
                candidate.roles = ((candidate.roles || "") as unknown as string).split(',')
                return candidate;
            })
            this.setState({
                loading: false,
                candidates
            })
            // this.createUserModal.toggle();
            // window.location.reload();

        } catch (e) {
            console.log('Error', e);
            this.setState({ loading: false })
        }


    }

    selectCandidateTagForFilter = (opt: any) => {
        const _tags = opt.map((o: any) => o.value).join(';');
        const tags = _tags == '' ? '' : ('' + _tags + ';');

        const _workLocations = this.state.selectedWorkLocationsFilter.map((o: any) => o.value).join(';');
        const workLocations = _workLocations == '' ? '' : ('' + _workLocations + ';');


        this.setState({ selectedTagsFilter: opt });
        this.getData({
            tags: tags, month: this.state.selectedMonth, year: this.state.selectedYear,
            workLocations: workLocations
        })
    }

    selectCandidateWorkLocationForFilter = (opt: any) => {
        const _workLocations = opt.map((o: any) => o.value).join(';');
        const workLocations = _workLocations == '' ? '' : ('' + _workLocations + ';');

        const _tags = this.state.selectedTagsFilter.map((o: any) => o.value).join(';');
        const tags = _tags == '' ? '' : ('' + _tags + ';');



        this.setState({ selectedWorkLocationsFilter: opt })
        this.getData({
            workLocations: workLocations, month: this.state.selectedMonth, year: this.state.selectedYear,
            tags: tags
        })
    }


    selectCandidateTag = (opt: any) => {
        const _tags = opt.map((o: any) => o.value).join(',');
        const tags = _tags == '' ? '' : (',' + _tags + ',');
        this.setState({ selectedTags: opt, candidate: { ...this.state.candidate, tags } })
    }

    selectCandidateWorkLocation = (opt: any) => {
        const _workLocations = opt.map((o: any) => o.value).join(',');
        const workLocations = _workLocations == '' ? '' : (',' + _workLocations + ',');
        this.setState({ selectedWorkLocations: opt, candidate: { ...this.state.candidate, workLocations } })
    }


    getCoordnationHistory = async (candidateId: number) => {
        this.setState({ loading: true });

        const resultMasters = await API.get(REST_API_NAME, `/candidates/get-coordination-history/` + candidateId, {});

        this.setState({ loading: false, coordinationHistory: { tl: resultMasters.result, recruiter: resultMasters.assignments } });
        this.coordHistoryModal.show();
    }

    render() {
        const { coordinationHistory, selectedTagsFilter, selectedWorkLocationsFilter, selectedTags, selectedWorkLocations, formats, filterText, filterDate, cvUrl, selectedMonth, selectedYear, selectedCompanies, companies, employees, recruiters, teamLeaders, candidates, candidate, loading, mode, filterTeamLeader, filterRecruiter } = this.state;
        let employeesHash: { [key: string]: EmployeeInterface } = {};
        employees.map((emp: EmployeeInterface) => employeesHash[emp.sub] = emp);



        // let params: any = {};
        // params = {
        //     name: {
        //         defaultValue: candidate.name
        //     }
        // }
        // if (mode == 'add') {

        //     params.name = {};
        // }
        // console.log('Params', params)
        const months = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


        let rowData: any = {};
        formats.map(f => {
            f.data.map((d: any) => {
                rowData[d.row_id] = d.value;
            })
        });
        const user = this.props.user;
        const superAdmin = isSuperAdmin(user)
        const sub = user.attributes.sub;


        return (
            <div className='my-5'>

                {SIMULATION ? <h1>SIMULATION MODE</h1> : ''}



                {/* { cvUrl== '' ? '' : <FileViewer
                    fileType={'application/pdf'}
                    filePath={cvUrl}
                    // errorComponent={CustomErrorComponent}
                    onError={console.log} />
                    }  */}


                {/* <div className="collapse" id="collapseExample">
                    <div className="card card-body">
                        Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
                    </div>
                </div> */}
                <div className=" d-none d-lg-block d-md-block" >
                    <div className="row" >
                        <div className="col-lg-1 col-sm-12">
                            <button disabled={loading} onClick={() => { this.clearCandidate(); this.createUserModal.toggle() }} className='btn btn-success'>Add <i className="bi bi-person-plus-fill"></i></button>
                        </div>
                        <div className="col-lg-2 col-sm-12">
                            Recruiter
                            {/* <select className="form-control1" id="inputCompany" onChange={(evt) => { this.setRecruiter(evt.target.value) }} defaultValue={filterRecruiter}  >
                            <option></option>
                            {recruiters.map(r => <option key={"optr" + r.sub} value={r.sub}>{r.name}</option>)}
                        </select> */}
                            <Select
                                isClearable={true}
                                isMulti={false}
                                options={recruiters.map(r => ({ value: r.sub, label: r.name }))}
                                // onChange={(val) => this.setRecruiter(!val ? '' : val[0].map((v: any) => v.value).join(','))}
                                onChange={(val) => this.setRecruiter(!val ? '' : val.value)}
                            />
                        </div>
                        <div className="col-lg-2 col-sm-12">
                            Team Leader
                            {/* <select className="form-control1" id="inputCompany" onChange={(evt) => { this.setTeamLeader(evt.target.value) }} defaultValue={filterTeamLeader}  >
                            <option></option>
                            {teamLeaders.map(tl => <option key={"optt" + tl.sub} value={tl.sub}>{tl.name}</option>)}
                        </select> */}
                            <Select
                                isClearable={true}
                                isMulti={false}
                                options={teamLeaders.map(r => ({ value: r.sub, label: r.name }))}
                                // onChange={(val)=>this.setTeamLeader(val.map(v=>v.value).join(','))}
                                onChange={(val) => this.setTeamLeader(!val ? '' : val.value)}
                            />
                        </div>
                        <div className="col-lg-2 col-sm-12">
                            From Date
                            {/* <DatePicker
                            selected={filterDate}
                            onSelect={console.log} //when day is clicked
                            onChange={this.selectFilterDate} //only when value has changed
                            dateFormat={'dd/MM/yyyy'}
                        /> */}
                            <input type='date' className='form-control' onChange={(evt) => this.selectFilterDateFrom(evt.target.valueAsDate)} defaultValue={dayjs(filterDate).format('YYYY-MM-DD')} />
                            {/* <button onClick={this.removeFilterDate}>R</button> */}

                        </div>
                        <div className="col-lg-2 col-sm-12">
                            To Date
                            {/* <DatePicker
                            selected={filterDate}
                            onSelect={console.log} //when day is clicked
                            onChange={this.selectFilterDate} //only when value has changed
                            dateFormat={'dd/MM/yyyy'}
                        /> */}
                            <input type='date' className='form-control' onChange={(evt) => this.selectFilterDateTo(evt.target.valueAsDate)} defaultValue={dayjs(filterDate).format('YYYY-MM-DD')} />
                            {/* <button onClick={this.removeFilterDate}>R</button> */}

                        </div>
                        <div className="col-lg-2 col-sm-12">
                            Created By
                            <Select
                                isMulti={true}
                                options={employees.map(r => ({ value: r.sub, label: r.name }))}
                                onChange={(val) => this.setCreatedBy(val.map(v => v.value).join(','))}
                            />
                        </div>

                        <div className='row'>
                            <div className="col-lg-3 col-sm-12">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Tags</label>
                                <div className="col-sm-10">
                                    <Select
                                        isMulti={true}
                                        options={CANDIDATE_TAGS}
                                        onChange={this.selectCandidateTagForFilter}
                                        value={selectedTagsFilter}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-3 col-sm-12">
                                <label htmlFor="inputPassword" className="col-sm-12 col-form-label">Work Locations</label>
                                <div className="col-sm-10">
                                    <Select
                                        isMulti={true}
                                        options={WORK_LOCATIONS}
                                        onChange={this.selectCandidateWorkLocationForFilter}
                                        value={selectedWorkLocationsFilter}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-1 col-sm-12">
                                <button className="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" >
                                    <i className="bi bi-search"></i>
                                </button>
                            </div>
                        </div>




                        <div className="collapse" id="collapseExample">
                            <div className="card card-body">
                                <div className='col-5'>
                                    <div className="input-group flex-nowrap">
                                        <span className="input-group-text" id="addon-wrapping">
                                            <i className="bi bi-search"></i>
                                        </span>
                                        <input type="text" className="form-control" placeholder="Name, Number or Email" aria-label="Username" aria-describedby="addon-wrapping" onChange={(evt) => this.setFiltertext(evt.target.value)} />
                                        <button className="btn btn-outline-secondary" type="button" onClick={this.search} disabled={loading || !filterText}>Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" d-block d-sm-none" >
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button disabled={loading} onClick={() => { this.clearCandidate(); this.createUserModal.toggle() }} className='btn btn-success'>Add <i className="bi bi-person-plus-fill"></i></button>
                        </div>
                        <div className="">
                            <a className="btn btn-primary" data-bs-toggle="collapse" href="#filtersCollapse" role="button" aria-expanded="false" aria-controls="filtersCollapse">
                                Show Filters
                            </a>
                        </div>
                    </div>

                    <div className="row collapse" id='filtersCollapse'>

                        <div className="col-lg-2 col-sm-12">
                            Recruiter
                            {/* <select className="form-control1" id="inputCompany" onChange={(evt) => { this.setRecruiter(evt.target.value) }} defaultValue={filterRecruiter}  >
                            <option></option>
                            {recruiters.map(r => <option key={"optr" + r.sub} value={r.sub}>{r.name}</option>)}
                        </select> */}
                            <Select
                                isClearable={true}
                                isMulti={false}
                                options={recruiters.map(r => ({ value: r.sub, label: r.name }))}
                                // onChange={(val) => this.setRecruiter(!val ? '' : val[0].map((v: any) => v.value).join(','))}
                                onChange={(val) => this.setRecruiter(!val ? '' : val.value)}
                            />
                        </div>
                        <div className="col-lg-2 col-sm-12">
                            Team Leader
                            {/* <select className="form-control1" id="inputCompany" onChange={(evt) => { this.setTeamLeader(evt.target.value) }} defaultValue={filterTeamLeader}  >
                            <option></option>
                            {teamLeaders.map(tl => <option key={"optt" + tl.sub} value={tl.sub}>{tl.name}</option>)}
                        </select> */}
                            <Select
                                isClearable={true}
                                isMulti={false}
                                options={teamLeaders.map(r => ({ value: r.sub, label: r.name }))}
                                // onChange={(val)=>this.setTeamLeader(val.map(v=>v.value).join(','))}
                                onChange={(val) => this.setTeamLeader(!val ? '' : val.value)}
                            />
                        </div>
                        <div className="col-lg-2 col-sm-12">
                            From Date
                            {/* <DatePicker
                            selected={filterDate}
                            onSelect={console.log} //when day is clicked
                            onChange={this.selectFilterDate} //only when value has changed
                            dateFormat={'dd/MM/yyyy'}
                        /> */}
                            <input type='date' className='form-control' onChange={(evt) => this.selectFilterDateFrom(evt.target.valueAsDate)} defaultValue={dayjs(filterDate).format('YYYY-MM-DD')} />
                            {/* <button onClick={this.removeFilterDate}>R</button> */}

                        </div>
                        <div className="col-lg-2 col-sm-12">
                            To Date
                            {/* <DatePicker
                            selected={filterDate}
                            onSelect={console.log} //when day is clicked
                            onChange={this.selectFilterDate} //only when value has changed
                            dateFormat={'dd/MM/yyyy'}
                        /> */}
                            <input type='date' className='form-control' onChange={(evt) => this.selectFilterDateTo(evt.target.valueAsDate)} defaultValue={dayjs(filterDate).format('YYYY-MM-DD')} />
                            {/* <button onClick={this.removeFilterDate}>R</button> */}

                        </div>
                        <div className="col-lg-2 col-sm-12">
                            Created By
                            <Select
                                isMulti={true}
                                options={employees.map(r => ({ value: r.sub, label: r.name }))}
                                onChange={(val) => this.setCreatedBy(val.map(v => v.value).join(','))}
                            />
                        </div>
                        <div className="col-lg-1 col-sm-12">
                            <button className="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" >
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                        <div className="collapse" id="collapseExample">
                            <div className="card card-body">
                                <div className='col-sm-12'>
                                    <div className="input-group flex-nowrap">
                                        <span className="input-group-text" id="addon-wrapping">
                                            <i className="bi bi-search"></i>
                                        </span>
                                        <input type="text" className="form-control" placeholder="Name, Number or Email" aria-label="Username" aria-describedby="addon-wrapping" onChange={(evt) => this.setFiltertext(evt.target.value)} />
                                        <button className="btn btn-primary" type="button" onClick={this.search} disabled={loading || !filterText}>Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {loading ? <div className="spinner-border text-success" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div> : ''}

                <table className='table table-bordered table-fixed-layout1 table-candidates1'>
                    <colgroup>
                        <col className='col1' />
                        <col className='col2' />
                        <col className='col3' />
                        <col className='col4' />
                        <col className='col5' />
                        <col className='col6' />
                        <col className='col7' />
                        <col className='col8' />
                        <col className='col9' />
                        <col className='col10' />
                        <col className='col11' />
                        <col className='col12' />
                        <col className='col13' />
                        <col className='col14' />
                    </colgroup>
                    <thead>
                        <tr>
                            <th></th>
                            <th>#</th>
                            <th>Name</th>
                            <th>Number</th>
                            <th>Email</th>
                            <th>Rec Rating</th>
                            <th>Companies</th>
                            <th>TL Rating</th>
                            <th>TL Comments</th>
                            <th>Recruiter</th>
                            <th>TL</th>
                            <th>Roles</th>
                            <th>Source</th>
                            <th>CreatedOn</th>
                            <th>CreatedBy</th>
                        </tr>
                    </thead>
                    <tbody>
                        {candidates.map((d, idx) => <tr key={'row-' + d.id} id={d.id + ""}>
                            <td>
                                <div className="btn-toolbar candidate-action-buttons d-none d-md-block" role="toolbar" aria-label="Toolbar with button groups">
                                    <div className="btn-group me-2" role="group" aria-label="First group">
                                        <i className="bi bi bi-clock-history history-button cursor-pointer" onClick={() => { !loading && this.getCoordnationHistory(d.id); }} ></i>

                                        <i className="bi bi-file-earmark-arrow-down download-button cursor-pointer" onClick={() => { !loading && this.getFile(d); }} ></i>


                                        {/* <button onClick={() => { this.checkCV(d); }} disabled={loading} className='btn btn-info btn-sm'>
                                            <i className="bi bi-plus"></i>
                                        </button> */}
                                        <i className="bi bi-file-earmark-spreadsheet format-button cursor-pointer" onClick={() => { !(loading || !!!d.companies) && this.showAddFormatDataModal(d); }}></i>

                                        {/* <button onClick={() => { this.viewFile(d); }} disabled={loading} className='btn btn-info btn-sm'>
                                            <i className="bi bi-eye-fill"></i>
                                        </button> */}
                                        <i className="bi bi-pencil-square edit-button cusror-pointer" onClick={() => { if (loading) { return }; this.selectCandidate(d); this.createUserModal.toggle() }} ></i>


                                        {/* <button onClick={() => { if (window.confirm('Are you sure you want to delete this user?')) { this.deleteCandidate(d); } }} disabled={loading || (!superAdmin && d.created_by !== sub)} className='btn btn-danger btn-sm'> */}
                                        <i className="bi bi-trash-fill delete-button" onClick={() => { if (window.confirm('Are you sure you want to delete this user?')) { this.deleteCandidate(d); } }}></i>


                                    </div>
                                </div>

                                <div className="dropdown d-lg-none d-md-none d-sm-block d-xs-block">
                                    <button className="btn btn-primary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        {/* <i className="bi bi-three-dots"></i> */}
                                        {/* Actions */}
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li onClick={(evt) => { evt.stopPropagation(); !loading && this.getFile(d); }} >
                                            <a className="dropdown-item download-button1" href="#" onClick={(evt) => evt.preventDefault()}>
                                                <i className="bi bi-file-earmark-arrow-down download-button cursor-pointer" onClick={() => { !loading && this.getFile(d); }} ></i>
                                                Download
                                            </a>
                                        </li>
                                        <li onClick={(evt) => { evt.stopPropagation(); !(loading || !!!d.companies) && this.showAddFormatDataModal(d); }}>
                                            <a className="dropdown-item format-button1" href="#" onClick={(evt) => evt.preventDefault()}>
                                                <i className="bi bi-file-earmark-spreadsheet format-button cursor-pointer" onClick={(evt) => { evt.stopPropagation(); !(loading || !!!d.companies) && this.showAddFormatDataModal(d); }}></i>
                                                Format
                                            </a>
                                        </li>
                                        <li onClick={(evt) => { evt.stopPropagation(); if (loading) { return }; this.selectCandidate(d); this.createUserModal.toggle() }}>
                                            <a className="dropdown-item edit-button1" href="#" onClick={(evt) => evt.preventDefault()}>
                                                <i className="bi bi-pencil-square edit-button cusror-pointer" onClick={(evt) => { evt.stopPropagation(); if (loading) { return }; this.selectCandidate(d); this.createUserModal.toggle() }} ></i>
                                                Edit
                                            </a>
                                        </li>
                                        <li onClick={(evt) => { evt.stopPropagation(); if (window.confirm('Are you sure you want to delete this user?')) { this.deleteCandidate(d); } }}>
                                            <a className="dropdown-item delete-button1" href="#" onClick={(evt) => evt.preventDefault()}>
                                                <i className="bi bi-trash-fill delete-button" onClick={() => { if (window.confirm('Are you sure you want to delete this user?')) { this.deleteCandidate(d); } }}></i>
                                                Delete
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                            <td>{idx + 1}</td>
                            <td>{d.name}</td>
                            <td>{d.number}</td>
                            <td>{d.email}</td>
                            <td>{d.rating}</td>
                            <td>{d.companies_list.map(c => c.label).join(',')}</td>
                            {/* <td>{d.tl_rating}</td> */}
                            <Cell type='number' data={d} value={(d.tl_rating || '') + ''} field={'tl_rating'} onChange={(d) => this.updateCandidateRow(d, 'tl_rating')} />
                            {/* <td>{d.comment}</td> */}
                            <Cell type='text' data={d} value={d.comment + ''} field={'comment'} onChange={(d) => this.updateCandidateRow(d, 'comment')} />
                            <td>{d.recruiter_name}</td>
                            <td>{d.team_leader_name}</td>
                            <td>{d.roles.join(',')}</td>
                            <td>{d.source}</td>
                            <td>{dayjs(d.created_on).format('DD/MM/YY hh:mm')}</td>
                            <td>{employeesHash[d.created_by] ? formatEmployeeName(employeesHash[d.created_by].name) : 'NA'}</td>
                            {/* <td>{d.cvKey}</td> */}


                        </tr>)}
                    </tbody>
                </table>



                <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.createUserModalRef} data-bs-backdrop='static' >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{mode == 'edit' ? 'Update' : 'Add'} Candidate</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='form'>
                                    <form className=" g-3">

                                        <div className="mb-3 row">
                                            <label htmlFor="inputName" className="col-sm-2 col-form-label">Name<span className='text-danger'>*</span></label>
                                            <div className="col-sm-10">
                                                <input type="text" maxLength={150} className="form-control" id="inputName"
                                                    pattern="a-zA-Z"
                                                    onBlur={(evt) => this.setField('name', formatName(evt.target.value))}
                                                    defaultValue={candidate.name || ''} autoComplete='d off' />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label htmlFor="inputNumber" className="col-sm-2 col-form-label">Number<span className='text-danger'>*</span></label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputNumber" ref={this.inputPhoneRef} onBlur={(evt) => this.setField('number', evt.target.value.trim())} defaultValue={candidate.number || ''} autoComplete='d off' disabled={loading} />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label htmlFor="inputEmail" className="col-sm-2 col-form-label">Email</label>
                                            <div className="col-sm-10">
                                                <input type="email" maxLength={150} className="form-control" id="inputEmail" ref={this.inputEmailRef} onBlur={(evt) => this.setField('email', evt.target.value)} defaultValue={candidate.email || ''} disabled={loading} />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label htmlFor="inputCV" className="col-sm-2 col-form-label">CV<span className='text-danger'>*</span></label>
                                            <div className="col-sm-10">
                                                {
                                                    mode == 'add'
                                                        ? ''
                                                        : <button type='button' onClick={() => { this.getFile(candidate); }} disabled={loading} className='btn btn-info btn-sm'>
                                                            View Existing CV<i className="bi bi-file-earmark-arrow-down"></i>
                                                        </button>
                                                }
                                                <input type="file" className="form-control" id="inputCV" onChange={(evt) => this.uploadFile(evt.target.files![0])} ref={this.cvFileInputRef} defaultValue={candidate.cvKey} />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">

                                            <label htmlFor="inputRecruiter" className="col-sm-2 col-form-label">Recruiter<span className='text-danger'>*</span></label>
                                            <div className="col-sm-6">
                                                {/* <select className="form-control" id="inputCompany" onChange={(evt) => { this.setFields({ recruiter_name: evt.target.options[evt.target.selectedIndex].innerText, recruiter_sub: evt.target.value }) }} value={candidate.recruiter_sub}  >
                                                    <option></option>
                                                    {recruiters.map(r => <option key={"optr" + r.sub} value={r.sub}>{r.name}</option>)}
                                                </select> */}
                                                <Select
                                                    isMulti={false}
                                                    options={recruiters.map(r => ({ value: r.sub, label: r.name }))}
                                                    onChange={(val: any) => this.setFields({ recruiter_name: val.label, recruiter_sub: val.value })}
                                                    // value={{ value: candidate.recruiter_sub, label: candidate.recruiter_name }}
                                                    value={recruiters.filter(r => r.sub == candidate.recruiter_sub).map(r => ({ label: r.name, value: r.sub }))[0]}
                                                />
                                            </div>
                                            <label htmlFor="inputRating" className="col-sm-2 col-form-label">Rating</label>
                                            <div className="col-sm-2">
                                                <input type="number" min={0} max={10} className="form-control" id="inputRating" onBlur={(evt) => this.setField('rating', evt.target.value)} defaultValue={candidate.rating} />
                                            </div>

                                        </div>


                                        <div className="mb-3 row">
                                            <label htmlFor="inputTL" className="col-sm-2 col-form-label">TL<span className='text-danger'>*</span></label>
                                            <div className="col-sm-6">
                                                <select className="form-control" id="inputTL" onChange={(evt) => { this.setFields({ team_leader_name: evt.target.options[evt.target.selectedIndex].innerText, team_leader_sub: evt.target.value }) }} value={candidate.team_leader_sub}  >
                                                    <option></option>
                                                    {teamLeaders.map(tl => <option key={"optt" + tl.sub} value={tl.sub}>{tl.name}</option>)}
                                                </select>
                                            </div>
                                            {
                                                (isTeamLeader(this.props.user) || isSuperAdmin(this.props.user))
                                                    ? <>
                                                        <label htmlFor="inputTLRating" className="col-sm-2 col-form-label">Rating</label>
                                                        <div className="col-sm-2">
                                                            <input type="number" min={0} max={10} className="form-control" id="inputTLRating" onBlur={(evt) => this.setField('tl_rating', evt.target.value)} defaultValue={candidate.tl_rating} />
                                                        </div>
                                                    </>
                                                    : ''
                                            }

                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="inputCompany" className="col-sm-2 col-form-label">Source<span className='text-danger'>*</span></label>
                                            <div className="col-sm-10">
                                                <select className="form-control" id="inputCompany" onChange={(evt) => { this.setFields({ source: evt.target.options[evt.target.selectedIndex].innerText }) }} value={candidate.source}  >
                                                    <option></option>
                                                    {SOURCES.map(s => <option key={'source-' + s}>{s}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="inputCompany" className="col-sm-3 col-form-label">Companies<span className='text-danger'>*</span></label>
                                            <div className="col-sm-9">
                                                <Select options={companies.map(c => ({ label: c.name, value: c.id + '' })) as any}
                                                    isMulti={true}
                                                    onChange={(e) => this.selectCompany([e] as unknown as CompanyInterface[])}
                                                    value={selectedCompanies}
                                                    isOptionDisabled={(opt) => companies.find(c => c.id == +opt.value)!.disabled}
                                                    isDisabled={candidate.number == '' && candidate.email == ''}
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label htmlFor="inputRoles" className="col-sm-2 col-form-label">Roles<span className='text-danger'>*</span></label>
                                            <div className="col-sm-10">
                                                <Select options={CANDIDATE_ROLES.map(c => ({ label: c, value: c + '' })) as any}
                                                    isMulti={true}
                                                    onChange={(e) => this.selectCandidateRoles([e] as unknown as CompanyInterface[])}
                                                    value={candidate.roles.map(c => ({ label: c, value: c + '' }))}
                                                />
                                            </div>
                                        </div>


                                        {

                                            <div className="mb-3 row">
                                                <label htmlFor="inputComment" className="col-sm-2 col-form-label">Comment</label>
                                                <div className="col-sm-10">
                                                    <input type="text" className="form-control" id="inputComment" onBlur={(evt) => this.setField('comment', evt.target.value)} defaultValue={candidate.comment} />
                                                </div>
                                            </div>

                                        }


                                        <div className="mb-3 row">
                                            <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Tags</label>
                                            <div className="col-sm-10">
                                                <Select
                                                    isMulti={true}
                                                    options={CANDIDATE_TAGS}
                                                    onChange={this.selectCandidateTag}
                                                    value={selectedTags}
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Work Locations</label>
                                            <div className="col-sm-10">
                                                <Select
                                                    isMulti={true}
                                                    options={WORK_LOCATIONS}
                                                    onChange={this.selectCandidateWorkLocation}
                                                    value={selectedWorkLocations}
                                                />
                                            </div>
                                        </div>




                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={mode == 'edit' ? this.updateCandidate : this.addCandidate} disabled={loading}>{mode == 'edit' ? 'Update' : 'Save'}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.addFormatDataModalRef} data-bs-backdrop='static' >
                    <div className="modal-dialog modal-xl">
                        <FormatDataForm
                            loading={loading}
                            candidate={candidate}
                            addFormatDataTabsRef={this.addFormatDataTabsRef}
                            formats={formats}
                            mode={mode}
                            addCandidateData={this.addCandidateFormatData}
                            updateCandidateData={this.updateCandidateFormatData}
                            rowData={rowData}
                            setColDataAuto={this.setColDataAuto}
                        />
                    </div>
                </div>

                <div className="modal fade" id="coord-history" tabIndex={-1} ref={this.coordHistoryModalRef} data-bs-backdrop='static' >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">History</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <h3>Recruiter</h3>
                            <table className="table table-bordered table-condensed">
                                <thead>
                                    <tr>
                                        <th>Company</th>
                                        <th>Recruiter</th>
                                        <th>Assigned On</th>
                                        <th>Status</th>
                                        <th>Status Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {coordinationHistory.recruiter.map((c: any) => <tr key={'ch-' + c.id}>
                                        <td>{c.company}</td>
                                        <td>{c.employee}</td>
                                        <td>{dayjs(c.recruiter_assignement_on).format('DD/MM/YY hh:mmA')}</td>
                                        <td>{c.status}</td>
                                        <td>{c.status_on}<br />{c.status_by}</td>
                                    </tr>)}
                                </tbody>
                            </table>
                            <h3>TL</h3>
                            <table className="table table-bordered table-condensed">
                                <thead>
                                    <tr>
                                        <th>Company</th>
                                        <th>Assigned On</th>
                                        <th>Assigned By</th>
                                        <th>Aligned On</th>
                                        <th>Aligned By</th>
                                        <th>Status</th>
                                        <th>Feedback</th>
                                        <th>Feedback Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {coordinationHistory.tl.length ? '' : <tr><td colSpan={7}>Not assigned yet</td></tr>}
                                    {coordinationHistory.tl.map((c: any) => <tr key={'ch-' + c.id} className={c.select_status === 'Selected' ? "bg-success" : ""}>
                                        <td>{c.name}</td>
                                        <td>{dayjs(c.assigned_on).format('DD/MM/YY hh:mmA')}</td>
                                        <td>{employeesHash[c.assigned_by] ? employeesHash[c.assigned_by].name : ''}</td>
                                        <td>{c.aligned_on ? dayjs(c.aligned_on).format('DD/MM/YY hh:mmA') : ''}</td>
                                        <td>{employeesHash[c.aligned_by] ? employeesHash[c.aligned_by].name : ''}</td>
                                        <td>{c.final_status}</td>
                                        <td>{c.final_status_feedback}</td>
                                        <td>{c.final_status_feedback_date}</td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

            </div >
        )
    }
}

export default Candidates;

interface CandidteFormModalPropsInterface {

    modalRef: any
    candidate: CandidateWithCompanies
}





type CellType = "text" | "select" | "number";
type CellOption = { label: string, value: string };
// type CellOptions = {label: string, value: string };
interface CellPropsInterface {
    data: CandidateWithCompanies
    field: keyof CandidateWithCompanies
    value: string
    type: CellType
    list?: CellOption[]
    onChange: (d: CandidateWithCompanies) => void
    enabled?: boolean

}

const Cell = (props: CellPropsInterface) => {

    const [edit, setEdit] = useState(false);
    const [value, setValue] = useState(props.value);
    const enabled = props.enabled === undefined ? true : props.enabled


    const setEditable = () => {
        setEdit(true);
    }
    const changeValue = (e: any) => {
        const target = e.target;
        if (props.type == "select") {
            setValue(target.options[target.selectedIndex].innerText);
        } else {
            setValue(target.value)
        }
        setEdit(false);
        props.onChange({ ...props.data, [props.field]: target.value })
    }

    const renderElement = (type: CellType, list?: CellOption[]) => {
        let ele;
        switch (type) {
            case "text": ele = <input className={'form-control'} type='text' defaultValue={props.value} onBlur={changeValue} />; break;
            case "number": ele = <input className={'form-control'} type='number' defaultValue={props.value} onBlur={changeValue} />; break;
            case "select": ele = <select className={'form-select'} defaultValue={props.data[props.field] as string} onBlur={changeValue} >
                {list?.map(l => <option key={'opt-' + l.label} value={l.value}>{l.label}</option>)}
            </select>; break;
        }

        return ele;
    }


    return (<td onClick={!enabled ? () => { } : setEditable} className={enabled ? 'enabled' : 'bg-secondary'}>
        {edit
            ? renderElement(props.type, props.list)
            : value
        }
    </td>)

}

