import API from "@aws-amplify/api";
import { Modal } from "bootstrap";
import React, { useRef, useState } from "react";
import { CompanyInterface, COMPANY_TYPES, ContactInterface, EmailInterface, GenericDataNameInterface, JDCheckListInterface, PhoneInterface } from "../types";
import { isSuperAdmin, REST_API_NAME } from "../utils";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const data: GenericDataNameInterface[] = [
    { id: 1, name: "Rashmi" },
    { id: 1, name: "Zubaina" },
    { id: 1, name: "Zubaida" },
    { id: 1, name: "Shruti" },
    { id: 1, name: "Mithi" },
    { id: 1, name: "Yousuf" },
    { id: 1, name: "Srinivas" },
    { id: 1, name: "Poojitha" },
    { id: 1, name: "Raheen" }
]

type OperationType = 'add' | 'edit'
interface ContactFormInterface extends ContactInterface {
    operation: OperationType
}
interface PhoneFormInterface extends PhoneInterface {
    operation: OperationType
    rowId: number
}
interface EmailFormInterface extends EmailInterface {
    operation: OperationType
    rowId: number
}
interface CompaniesStateInterface {
    loading: boolean,
    companies: CompanyInterface[],
    data: CompanyInterface,
    selectedCompany: CompanyInterface,
    contacts: ContactFormInterface[]
    checkList: JDCheckListInterface[]
}


class Companies extends React.Component<any, CompaniesStateInterface>{

    createUserModalRef: any
    createUserModal: any;

    resetPasswordModalRef: any
    resetPasswordModal: any;

    addUserToGroupModalRef: any;
    addUserToGroupModal: any;

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            companies: [],
            selectedCompany: {} as CompanyInterface,
            data: {} as CompanyInterface,
            contacts: [] as ContactFormInterface[],
            //new
            checkList: []
        }

        this.createUserModalRef = React.createRef();
    }

    async componentDidMount() {
        this.createUserModal = new Modal(this.createUserModalRef.current);

        const result = await API.get(REST_API_NAME, '/companies/with-contacts', {});

        let _c: any = {};
        let companies: CompanyInterface[] = [];
        result.result.map((company: any) => {
            company.phones = company.phones || '';
            company.emails = company.emails || '';
            company.id = company.companyId;
            const cl = company.checkList || '';
            if (cl != '') {
                let list: JDCheckListInterface[] = [];
                cl.split(',').map((c: string) => {
                    const [item, id] = c.split(':')
                    list.push({
                        id: +id,
                        item,
                        selected: false
                    })
                })
                company.checkList = list;
            }

            if (_c[company.companyId]) {
                _c[company.companyId].contacts.push({
                    title: company.title,
                    name: company.contactName,
                    id: company.contactId,
                    phones: company.phones.split(',').map((p: any) => ({ id: p.split(':')[1], phone: p.split(':')[0] })),
                    emails: company.emails.split(',').map((e: any) => ({ id: e.split(':')[1], email: e.split(':')[0] }))
                });
            } else {
                _c[company.companyId] = company;
                _c[company.companyId].contacts = [];
                company.contactId && _c[company.companyId].contacts.push({
                    title: company.title,
                    name: company.contactName,
                    id: company.contactId,
                    phones: company.phones.split(',').map((p: any) => ({ id: p.split(':')[1], phone: p.split(':')[0] })),
                    emails: company.emails.split(',').map((e: any) => ({ id: e.split(':')[1], email: e.split(':')[0] }))
                });
            }
        })
        companies = Object.values(_c);

        this.setState({ loading: false, companies })
    }


    addCompany = async () => {
        const { data, contacts, checkList } = this.state;
        console.log('Saving', data, contacts);
        this.setState({ loading: true })
        data.contacts = contacts;
        data.checkList = checkList

        try {
            const result = await API.post(REST_API_NAME, `/companies`, {
                body: data
            });

            this.setState({ loading: false, companies: [...this.state.companies, { ...data, id: result.result.insertId }], data: {} as CompanyInterface })
            this.createUserModal.toggle();
            // window.location.reload();

        } catch (err) {
            this.setState({ loading: false })
        }

    }

    updateCompany = async () => {
        const { data, contacts } = this.state;
        // this.setState({ loading: true })
        data.contacts = contacts;
        console.log('Updating', data);


        return;
        await API.put(REST_API_NAME, `/companies/${data.id}`, { body: data });

        this.setState({ loading: false, companies: this.state.companies.map(c => c.id == data.id ? data : c), data: {} as CompanyInterface })
        this.createUserModal.toggle();
        // window.location.reload();

    }
    updateCompanyRow = async (company: CompanyInterface, data: any, field: keyof CompanyInterface) => {
        const { contacts, companies } = this.state;
        this.setState({ loading: true })
        data.contacts = contacts;
        console.log('Updating', data);
        try {
            const result = await API.put(REST_API_NAME, `/companies/update-company/${company.id}/${field}`, { body: { value: data[field] } });
            const _companies = companies.map(c => c.id === company.id ? { ...c, [field]: data[field] } : c)
            this.setState({ loading: false, companies: _companies })
            toast.success('Successfully updated company');
        } catch (e) {
            console.log('Error', e);
            toast.error('An Error occurred');
            this.setState({ loading: false })
        }


        return;
        await API.put(REST_API_NAME, `/companies/${data.id}`, { body: data });

        this.setState({ loading: false, companies: this.state.companies.map(c => c.id == data.id ? data : c), data: {} as CompanyInterface })
        this.createUserModal.toggle();
        // window.location.reload();

    }


    deleteCompany = async (company: CompanyInterface) => {
        try {
            await API.del('apia2dc579f', `/companies/${company.id}`, {});
            this.setState({ companies: this.state.companies.filter(tl => tl.id !== company.id) })
        } catch (e) {
            console.log(e)
        }
    }


    setField = (field: string, value: string) => {
        this.setState({ data: { ...this.state.data, [field]: value } })
    }

    selectCompany = (selectedCompany: CompanyInterface) => {
        const { companies } = this.state;
        const company = companies.find(tl => tl.id === selectedCompany.id)!

        this.setState({ selectedCompany, data: company, contacts: selectedCompany.contacts as ContactFormInterface[] })
    }
    unSelectCompany = () => {
        this.setState({ selectedCompany: {} as CompanyInterface })
    }

    addContact = () => {

        const contact: ContactFormInterface = {
            // isNew: true,
            operation: 'add',
            id: +new Date,
            name: '',
            title: '',
            reference_id: '',
            reference_table: '',
            emails: [],
            phones: []
        }

        this.setState({ contacts: [...this.state.contacts, contact] })

    }

    addPhone = (contact: ContactFormInterface) => {
        const contacts = this.state.contacts.map(c => {
            if (c.id == contact.id) {
                return {
                    ...contact,
                    phones: [...c.phones, { id: +new Date, phone: '', reference_id: '', reference_table: '', operation: 'add', rowId: +new Date }]
                }
            } else {
                return c;
            }
        })

        this.setState({ contacts })

    }
    addEmail = (contact: ContactFormInterface) => {
        const contacts = this.state.contacts.map(c => {
            if (c.id == contact.id) {
                return {
                    ...contact,
                    emails: [...c.emails, { id: +new Date, email: '', reference_id: '', reference_table: '', operation: 'add', rowId: +new Date }]
                }
            } else {
                return c;
            }
        })

        this.setState({ contacts })

    }

    changeTitleForContact = (contact: ContactFormInterface, value: string) => {

        const contacts = this.state.contacts.map(c => {
            if (c.id == contact.id) {
                return {
                    ...contact,
                    title: value,
                    operation: contact.operation || 'edit'
                }
            } else {
                return c;
            }
        }) as ContactFormInterface[]

        this.setState({ contacts })

    }
    changeNameForContact = (contact: ContactFormInterface, value: string) => {

        const contacts = this.state.contacts.map(c => {
            if (c.id == contact.id) {
                return {
                    ...contact,
                    name: value,
                    operation: contact.operation || 'edit'
                }
            } else {
                return c;
            }
        }) as ContactFormInterface[]

        this.setState({ contacts })

    }
    changePhoneForContact = (contact: ContactFormInterface, id: number, value: string) => {
        const phones = contact.phones.map(p => {
            if (p.id == id) {
                return { ...p, phone: value }
            } else {
                return p;
            }
        })

        const contacts = this.state.contacts.map(c => {
            if (c.id == contact.id) {
                return {
                    ...contact,
                    phones
                }
            } else {
                return c;
            }
        })

        this.setState({ contacts })

    }
    removePhoneForContact = (contact: ContactFormInterface, id: number) => {
        const phones = contact.phones.filter(p => p.id !== id)

        const contacts = this.state.contacts.map(c => {
            if (c.id == contact.id) {
                return {
                    ...contact,
                    phones
                }
            } else {
                return c;
            }
        })

        this.setState({ contacts })

    }
    changeEmailForContact = (contact: ContactFormInterface, id: number, value: string) => {
        const emails = contact.emails.map(p => {
            if (p.id == id) {
                return { ...p, email: value }
            } else {
                return p;
            }
        })

        const contacts = this.state.contacts.map(c => {
            if (c.id == contact.id) {
                return {
                    ...contact,
                    emails
                }
            } else {
                return c;
            }
        })

        this.setState({ contacts })

    }
    removeEmailForContact = (contact: ContactFormInterface, id: number) => {
        const emails = contact.emails.filter(p => p.id !== id)

        const contacts = this.state.contacts.map(c => {
            if (c.id == contact.id) {
                return {
                    ...contact,
                    emails
                }
            } else {
                return c;
            }
        })

        this.setState({ contacts })

    }

    removeEmailFromContact = (company: CompanyInterface, contact: ContactInterface, rowId: number) => {
        const emails = contact.emails.filter((e: any) => e.rowId !== rowId)

        const _companies = this.state.companies.map(_company => {
            if (company.id == _company.id) {
                const contacts = _company.contacts.map(_contact => {
                    if (_contact.id == contact.id) {
                        return {
                            ..._contact,
                            emails: [..._contact.emails.filter(p => (p as any).rowId != rowId)]
                        }
                    }
                    return _contact;
                })
                return { ..._company, contacts }

            }
            return _company;
        })

        this.setState({ companies: _companies })
    }
    removePhoneFromContact = (company: CompanyInterface, contact: ContactInterface, rowId: number) => {
        const phones = contact.phones.filter((e: any) => e.rowId !== rowId)



        const _companies = this.state.companies.map(_company => {
            if (company.id == _company.id) {
                const contacts = _company.contacts.map(_contact => {
                    if (_contact.id == contact.id) {
                        return {
                            ..._contact,
                            phones: [..._contact.phones.filter(p => (p as any).rowId != rowId)]
                        }
                    }
                    return _contact;
                })
                return { ..._company, contacts }

            }
            return _company;
        })

        this.setState({ companies: _companies })
    }


    updateContact = async (company: CompanyInterface, contact: ContactFormInterface, field: keyof ContactFormInterface) => {
        console.log(contact)
        let result;
        // if (contact.operation == 'add') {
        //     result = await API.post(REST_API_NAME, `/companies/${company.id}/contact/`, { body: { contact } });

        // }
        // return;

        result = await API.put(REST_API_NAME, `/companies/${company.id}/contact/${field}/${contact.id}`, { body: { value: contact[field] } });

        this.setState({ loading: false, companies: this.state.companies.map(c => c.id == company.id ? { ...company, contacts: company.contacts.map(cnt => cnt.id == contact.id ? contact : cnt) } : c), data: {} as CompanyInterface })

    }


    updateContactPhone = async (company: CompanyInterface, contact: ContactFormInterface, phone: PhoneFormInterface, field: keyof PhoneFormInterface) => {
        const { companies } = this.state;

        let result: any;
        let _companies;
        if (phone.id == -1) {
            result = await API.post(REST_API_NAME, `/companies/${company.id}/${contact.id}/contact-phone`, { body: { phone } });

            _companies = companies.map(_company => {
                if (company.id == _company.id) {
                    const contacts = _company.contacts.map(_contact => {
                        if (_contact.id == contact.id) {
                            return { ..._contact, phones: [..._contact.phones.filter(p => (p as any).rowId != phone.rowId), { id: result.result.insertId, phone: phone.phone, reference_table: 'contacts', reference_id: contact.id }] }
                        }
                        return _contact;
                    })
                    return { ..._company, contacts }

                }
                return _company;
            })


        } else {
            result = await API.put(REST_API_NAME, `/companies/${company.id}/${contact.id}/contact-phone/${field}/${phone.id}`, { body: { value: phone[field] } });

            _companies = companies.map(_company => {
                if (company.id == _company.id) {
                    const contacts = _company.contacts.map(_contact => {
                        if (_contact.id == contact.id) {
                            return { ..._contact, phones: _contact.phones.map(p => (p.id == phone.id ? { ...p, [field]: phone[field] } : p)) }
                        }
                        return _contact;
                    })
                    return { ..._company, contacts }

                }
                return _company;
            })

        }
        this.setState({ loading: false, companies: _companies as CompanyInterface[] })

        // this.setState({ loading: false, companies: this.state.companies.map(c => c.id == company.id ? { ...company, contacts: company.contacts.map(cnt => cnt.id == contact.id ? contact : cnt) } : c), data: {} as CompanyInterface })
    }

    updateContactEmail = async (company: CompanyInterface, contact: ContactFormInterface, email: EmailFormInterface, field: keyof EmailFormInterface) => {
        // if (email.id == -1) {
        //     const result = await API.post(REST_API_NAME, `/companies/${company.id}/${contact.id}/contact-email`, { body: { email } });
        // } else {
        //     const result = await API.put(REST_API_NAME, `/companies/${company.id}/contact-email/${field}/${email.id}`, { body: { value: email[field] } });
        // }

        // this.setState({ loading: false, companies: this.state.companies.map(c => c.id == company.id ? { ...company, contacts: company.contacts.map(cnt => cnt.id == contact.id ? contact : cnt) } : c), data: {} as CompanyInterface })

        // debugger;
        const { companies, data, loading, selectedCompany, contacts } = this.state;
        let result: any;
        let _companies;
        if (email.id == -1) {
            result = await API.post(REST_API_NAME, `/companies/${company.id}/${contact.id}/contact-email`, { body: { email } });

            _companies = companies.map(_company => {
                if (company.id == _company.id) {
                    const contacts = _company.contacts.map(_contact => {
                        if (_contact.id == contact.id) {
                            return { ..._contact, emails: [..._contact.emails.filter(p => (p as any).rowId != email.rowId), { id: result.result.insertId, email: email.email, reference_table: 'contacts', reference_id: contact.id }] }
                        }
                        return _contact;
                    })
                    return { ..._company, contacts }

                }
                return _company;
            })


        } else {
            result = await API.put(REST_API_NAME, `/companies/${company.id}/${contact.id}/contact-email/${field}/${email.id}`, { body: { value: email[field] } });

            _companies = companies.map(_company => {
                if (company.id == _company.id) {
                    const contacts = _company.contacts.map(_contact => {
                        if (_contact.id == contact.id) {
                            return { ..._contact, emails: _contact.emails.map(p => (p.id == email.id ? { ...p, [field]: email[field] } : p)) }
                        }
                        return _contact;
                    })
                    console.log(contacts)
                    return { ..._company, contacts }

                }
                return _company;
            })

        }
        this.setState({ loading: false, companies: _companies as CompanyInterface[] })

    }

    addPhoneForContact = (company: CompanyInterface, contact: ContactFormInterface) => {

        const { companies, data, loading, selectedCompany, contacts } = this.state;

        const _companies = companies.map(_company => {
            if (company.id == _company.id) {
                const contacts = _company.contacts.map(_contact => {
                    if (_contact.id == contact.id) {
                        return { ..._contact, phones: [..._contact.phones, { id: -1, phone: 'Enter Number', reference_table: 'contacts', reference_id: '', rowId: +new Date }] }
                    }
                    return _contact;
                })
                return { ..._company, contacts }

            }
            return _company;
        })
        this.setState({ companies: _companies })
    }
    addEmailForContact = (company: CompanyInterface, contact: ContactFormInterface) => {

        const { companies, data, loading, selectedCompany, contacts } = this.state;

        const _companies = companies.map(_company => {
            if (company.id == _company.id) {
                const contacts = _company.contacts.map(_contact => {
                    if (_contact.id == contact.id) {
                        return { ..._contact, emails: [..._contact.emails, { id: -1, email: 'Enter Mail', reference_table: 'contacts', reference_id: '', rowId: +new Date }] }
                    }
                    return _contact;
                })
                return { ..._company, contacts }

            }
            return _company;
        })
        this.setState({ companies: _companies })
    }


    addContactForCompany = async (company: CompanyInterface) => {
        this.setState({ loading: true })


        const contact: ContactFormInterface = {
            // isNew: true,
            operation: 'add',
            id: +new Date,
            name: 'Enter name',
            title: 'Enter Title',
            reference_id: company.id + '',
            reference_table: 'companies',
            emails: [],
            phones: []
        }

        let result: any;
        try {
            result = await API.post(REST_API_NAME, `/companies/${company.id}/contact/`, { body: { contact } });
            toast.success('Successfully added contact')
        } catch (err) {
            console.log('Error', err);
            toast.error('Error adding contact')
            this.setState({ loading: false })
            return;
        }

        const _companies = this.state.companies.map(c => {
            if (c.id == company.id) {
                c.contacts = [...c.contacts, { ...contact, id: result.result.insertId }]
            }
            return c;
        })
        this.setState({ companies: _companies, loading: false })

    }

    deleteContact = async (company: CompanyInterface, contact: ContactInterface) => {
        this.setState({ loading: true })
        const result = await API.del(REST_API_NAME, `/companies/contacts/${contact.id}`, {});
        const _companies = this.state.companies.map(c => {
            if (c.id == company.id) {
                c.contacts = c.contacts.filter(cc => cc.id != contact.id)
            }
            return c;
        })
        this.setState({ loading: false, companies: _companies })

    }
    deleteContactPhone = async (company: CompanyInterface, contact: ContactInterface, phone: PhoneInterface) => {
        this.setState({ loading: true })
        const result = await API.del(REST_API_NAME, `/companies/contact/${contact.id}/phones/${phone.id}`, {});
        const _companies = this.state.companies.map(c => {
            if (c.id == company.id) {
                c.contacts = c.contacts.map(cc => {
                    if (cc.id == contact.id) {
                        cc.phones = cc.phones.filter(p => p.id != phone.id)
                    }
                    return cc;
                })
            }
            return c;
        })
        this.setState({ loading: false, companies: _companies })

    }
    deleteContactEmail = async (company: CompanyInterface, contact: ContactInterface, email: EmailInterface) => {
        this.setState({ loading: true })
        const result = await API.del(REST_API_NAME, `/companies/contact/${contact.id}/emails/${email.id}`, {});
        const _companies = this.state.companies.map(c => {
            if (c.id == company.id) {
                c.contacts = c.contacts.map(cc => {
                    if (cc.id == contact.id) {
                        cc.emails = cc.emails.filter(e => e.id != email.id)
                    }
                    return cc;
                })
            }
            return c;
        })
        this.setState({ loading: false, companies: _companies })

    }


    addCheckList = () => {
        const { checkList } = this.state;

        const newCheckList = {
            id: checkList.length,
            item: 'Key Word',
            selected: false
        }
        this.setState({ checkList: [...checkList, newCheckList] })
    }
    addCheckListItemToCompany = async (company: CompanyInterface) => {
        const { companies } = this.state;
        const item = 'Key Word';
        this.setState({ loading: true })

        const { result } = await API.post(REST_API_NAME, '/companies/checklist/', {
            body: {
                companyId: company.id,
                item: item
            }
        });

        const _companies = companies.map(c => {
            if (company.id == c.id) {
                c.checkList = company.checkList || [];
                c.checkList.push({
                    id: result.insertId,
                    item,
                    selected: false
                })
                return c;
            }
            return c;
        })
        this.setState({ companies: _companies, loading: false })


    }
    updateCheckListItemForCompany = async (item: string, id: number, company: CompanyInterface) => {
        const { companies } = this.state;

        this.setState({ loading: true })

        const { result } = await API.put(REST_API_NAME, '/companies/checklist/', {
            body: {
                id,
                companyId: company.id,
                item: item
            }
        });

        const _companies = companies.map(c => {
            if (company.id == c.id) {
                c.checkList = company.checkList || [];
                c.checkList = c.checkList.map(cl => {
                    return cl.id == id ? { ...cl, item: item } : cl
                })

                return c;
            }
            return c;
        })
        this.setState({ companies: _companies, loading: false });



    }

    removeCheckListItemForCompany = async (company: CompanyInterface, id: number) => {
        const { companies } = this.state;

        const { result } = await API.del(REST_API_NAME, '/companies/checklist/' + id, {});

        const _companies = companies.map(c => {
            if (company.id == c.id) {
                c.checkList = company.checkList || [];
                c.checkList = c.checkList.filter((_c) => _c.id != id)
                return c;
            }
            return c;
        })
        this.setState({ companies: _companies, loading: false })
    }
    removeCheckListItem = (id: number) => this.setState({ checkList: this.state.checkList.filter(item => item.id != id) })
    updateCheckListItem = (itemText: string, id: number) => {

        let txt = itemText.replace(':', ' ').replace(',', ' ');
        this.setState({ checkList: this.state.checkList.map(cl => cl.id == id ? { ...cl, item: txt } : cl) })
    }


    render() {
        const { companies, data, loading, selectedCompany, contacts, checkList } = this.state;
        const user = this.props.user;
        let company: CompanyInterface | undefined;
        if (selectedCompany) {
            company = companies.find(tl => tl.id === selectedCompany.id)
        } else {
            company = undefined;
        }

        return (
            <div className='my-5'>
                <button onClick={() => { this.unSelectCompany(); this.createUserModal.toggle() }} className='btn btn-primary'>Add <i className="bi bi-person-plus-fill"></i></button>
                <table className='table table-bordered companies-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>S.No</th>
                            <th>Name</th>
                            {isSuperAdmin(user) ? <th>Amount</th> : ''}
                            {/* {isSuperAdmin(user) ? <th>Tax Amount</th> : ''} */}
                            {/* {isSuperAdmin(user) ? <th>Reg Link</th> : ''} */}
                            <th>Reg Link</th>
                            <th>Type</th>
                            <th>Contacts</th>
                        </tr>
                    </thead>
                    <tbody>
                        {companies.sort((a: CompanyInterface, b) => a.name.localeCompare(b.name)).map((company, idx) => <tr key={'company-' + company.id}>
                            <td>
                                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                    <div className="btn-group me-2" role="group" aria-label="First group">
                                        {/* <button onClick={() => { this.selectCompany(company); this.createUserModal.toggle() }} disabled={loading} className='btn btn-warning btn-sm'>
                                            <i className="bi bi-pencil-square"></i>
                                        </button> */}
                                        <button onClick={() => { if (window.confirm('Are you sure you want to delete this company?')) { this.selectCompany(company); this.deleteCompany(company); } }} disabled={loading} className='btn btn-danger btn-sm'>
                                            <i className="bi bi-trash-fill"></i>
                                        </button>
                                    </div>
                                </div>

                            </td>
                            <td>{idx + 1}</td>
                            {/* <td>{company.name}</td> */}
                            <td><Cell type='text' value={company.name} data={company} field={'name'} onChange={(c) => this.updateCompanyRow(company, c, 'name')} /></td>
                            {isSuperAdmin(user) ? <table>
                                <tbody>
                                    <tr>
                                        <td>Rs</td>
                                        <TDCell type='number' value={company.amount || 0} data={company} field={'amount'} onChange={(c) => this.updateCompanyRow(company, c, 'amount')} />
                                    </tr>
                                    <tr>
                                        <td>Tax Rs</td>
                                        <TDCell type='number' value={company.actual_amount || 0} data={company} field={'actual_amount'} onChange={(c) => this.updateCompanyRow(company, c, 'actual_amount')} />
                                    </tr>
                                    <tr>
                                        <td>Period</td>
                                        <TDCell type='number' value={company.payment_period || 0} data={company} field={'payment_period'} onChange={(c) => this.updateCompanyRow(company, c, 'payment_period')} />
                                    </tr>
                                    <tr>
                                        <td>CTC Percent</td>
                                        <TDCell type='number' value={company.ctc_percent || 0} data={company} field={'ctc_percent'} onChange={(c) => this.updateCompanyRow(company, c, 'ctc_percent')} />
                                    </tr>
                                </tbody>
                            </table> : ''}
                            {/* {isSuperAdmin(user) ? <TDCell type='number' value={company.amount || 0} data={company} field={'amount'} onChange={(c) => this.updateCompanyRow(company, c, 'amount')} /> : ''} */}
                            {/* {isSuperAdmin(user) ? <TDCell type='number' value={company.actual_amount || 0} data={company} field={'actual_amount'} onChange={(c) => this.updateCompanyRow(company, c, 'actual_amount')} /> : ''} */}
                            {/* {isSuperAdmin(user) ? <TDCell type='text' value={company.registration_link || ''} data={company} field={'registration_link'} onChange={(c) => this.updateCompanyRow(company, c, 'registration_link')} /> : ''} */}
                            <TDCell type='textarea' value={company.registration_link || ''} data={company} field={'registration_link'} onChange={(c) => this.updateCompanyRow(company, c, 'registration_link')} />

                            {/* <td>{company.type}</td> */}
                            <td><Cell type='select' list={COMPANY_TYPES} value={company.type || " - "} data={company} field={'type'} onChange={(c) => this.updateCompanyRow(company, c, 'type')} /></td>
                            <td>
                                {company.contacts.length || true
                                    ? <table className='table table-bordered table-sm'>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Title</th>
                                                <th>Phones</th>
                                                <th>Emails</th>
                                                <th>
                                                    <i className="bi bi-person-plus-fill small-plus-button-icon cursor-pointer" onClick={() => this.addContactForCompany(company)}></i>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className='contacts-container'>
                                            {company.contacts.map(contact => <tr key={'contact' + contact.id}>
                                                <td>
                                                    {/* {contact.name} */}
                                                    <Cell type='text' value={contact.name} data={contact as ContactFormInterface} field={'name'} onChange={(c) => this.updateContact(company, c, 'name')} />
                                                </td>
                                                <td>
                                                    {/* {contact.title} */}
                                                    <Cell type='text' value={contact.title} data={contact as ContactFormInterface} field={'title'} onChange={(c) => this.updateContact(company, c, 'title')} />
                                                </td>
                                                <td>
                                                    <ul className='list-group'>
                                                        {/* <li className="list-group-item p-0">
                                                            <button className='btn btn-sm btn-success' onClick={() => this.addPhoneForContact(company, contact as ContactFormInterface)}>
                                                                {contact.phones.length}<i className="bi bi-telephone-plus-fill"></i>
                                                            </button>
                                                        </li> */}
                                                        {contact && contact.phones.length == 0 ? <li className="list-group-item p-0" >
                                                            <button className='btn btn-sm btn-success' onClick={() => this.addPhoneForContact(company, contact as ContactFormInterface)}>
                                                                <i className="bi bi-telephone-plus-fill"></i>
                                                            </button>
                                                        </li>
                                                            : ''
                                                        }
                                                        {contact.phones.map(p => <li className="list-group-item contact-item-feature" key={p.phone}>
                                                            {/* {p.phone} */}
                                                            <i className="bi bi-telephone-plus-fill text-success cursor-pointer" onClick={() => this.addPhoneForContact(company, contact as ContactFormInterface)}></i>
                                                            <Cell type='text' remove={() => this.removePhoneFromContact(company, contact as ContactFormInterface, (p as any).rowId)} value={p.phone} data={p as PhoneFormInterface} field={'phone'} onChange={(phone) => this.updateContactPhone(company, contact as ContactFormInterface, phone as PhoneFormInterface, 'phone')} />
                                                            {
                                                                p.id == -1 ?
                                                                    ''
                                                                    : <i className="bi bi-trash-fill text-danger" onClick={() => { if (window.confirm('Are you sure you want to delete this phone?')) { this.deleteContactPhone(company, contact, p); } }}></i>
                                                            }
                                                        </li>
                                                        )}
                                                    </ul>
                                                </td>
                                                <td>
                                                    <ul className="list-group" >
                                                        {/* <li className="list-group-item p-0" >
                                                            <button className='btn btn-sm btn-success' onClick={() => this.addEmailForContact(company, contact as ContactFormInterface)}>
                                                                {contact.emails.length}<i className="bi bi-plus"></i>
                                                            </button>
                                                         </li>  */}
                                                        {contact && contact.emails.filter(e => e.id != undefined).length == 0 ? <li className="list-group-item p-0" >
                                                            {/* <button className='btn btn-sm btn-success' onClick={() => this.addEmailForContact(company, contact as ContactFormInterface)}>
                                                                <i className="bi bi-plus"></i>
                                                            </button> */}
                                                            <i className="bi bi-plus-circle-fill text-success cursor-pointer" onClick={() => this.addEmailForContact(company, contact as ContactFormInterface)}></i>

                                                        </li>
                                                            : ''
                                                        }
                                                        {contact && (contact.emails.filter(e => e.id != undefined).map((e,idx) => <li className="list-group-item contact-item-feature" key={e.email+idx} data-id={JSON.stringify(e)}>
                                                            {/* {e.email} */}
                                                            <i className="bi bi-plus-circle-fill text-success cursor-pointer" onClick={() => this.addEmailForContact(company, contact as ContactFormInterface)}></i>
                                                            <Cell type='text' remove={() => this.removeEmailFromContact(company, contact as ContactFormInterface, (e as any).rowId)} value={e.email} data={e as EmailFormInterface} field={'email'} onChange={(email) => this.updateContactEmail(company, contact as ContactFormInterface, email as EmailFormInterface, 'email')} />
                                                            {
                                                                e.id == -1 ?
                                                                    ''
                                                                    : <i className="bi bi-trash-fill text-danger" onClick={() => { if (window.confirm('Are you sure you want to delete this email?')) { this.deleteContactEmail(company, contact, e); } }}></i>
                                                            } </li>
                                                        ))}
                                                    </ul>
                                                </td>
                                                <td>
                                                    <i className="bi bi-trash-fill text-danger" onClick={() => { if (window.confirm('Are you sure you want to delete this contact?')) { this.deleteContact(company, contact); } }} ></i>
                                                </td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                    : ''
                                }
                            </td>
                            <td>
                                <div className="col-sm-10" style={{ width: '200px' }}>
                                    <div>
                                        <button className="btn btn-success btn-sm" type='button' disabled={loading} onClick={() => this.addCheckListItemToCompany(company)}>Add</button>
                                    </div>
                                    <small>
                                        Use '/' to separate variations
                                        ex: React/ React JS/ ReactJS

                                    </small>
                                    <ul className="list-group">
                                        {(company.checkList || []).map(({ item, id }) => <li key={'chk-' + id + item} className="list-group-item p-0">
                                            <div className="input-group mb-1">
                                                <input type='text' className='form-control p-0 font-12' defaultValue={item} maxLength={100} onBlur={(evt) => this.updateCheckListItemForCompany(evt.currentTarget.value, id, company)} />
                                                <button className="btn btn-outline-secondary p-0" type="button" id="button-addon2" disabled={loading} onClick={() => this.removeCheckListItemForCompany(company, id)}><i className="ms-1 bi bi-trash text-danger" ></i></button>
                                            </div>
                                        </li>)}
                                    </ul>
                                </div>
                            </td>

                        </tr>)}
                    </tbody>
                </table>


                <div className="modal modal-xl fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.createUserModalRef} data-bs-backdrop1='static' >
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{company ? 'Edit' : 'Add'} Company</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>

                            <div className="modal-body">
                                <div className='form'>
                                    <form className=" g-3">

                                        <div className="mb-3 row">
                                            <label htmlFor="inputName" className="col-sm-2 col-form-label">Name</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputName" onChange={(evt) => this.setField('name', evt.target.value)} value={data.name} maxLength={45} />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="inputName" className="col-sm-2 col-form-label">Type</label>
                                            <div className="col-sm-10">
                                                {/* <input type="text" className="form-control" id="inputName" onChange={(evt) => this.setField('type', evt.target.value)} value={data.type} /> */}
                                                <select className="form-control" onChange={(evt) => this.setField('type', evt.target.value)} value={data.type}>
                                                    {COMPANY_TYPES.map((l, idx) => <option key={'ctl-' + idx} value={l.value}>{l.label}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="inputName" className="col-sm-2 col-form-label">Amount</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputName" onChange={(evt) => this.setField('amount', evt.target.value)} value={data.amount} />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="inputName" className="col-sm-2 col-form-label">Actual Amount</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputName" onChange={(evt) => this.setField('actual_amount', evt.target.value)} value={data.actual_amount} />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label htmlFor="inputName" className="col-sm-2 col-form-label">Percent</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputName" onChange={(evt) => this.setField('ctc_percent', evt.target.value)} value={data.ctc_percent} />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">

                                            <label htmlFor="inputName" className="col-sm-2 col-form-label">Key Words</label>
                                            <div className="col-sm-10">
                                                <div>
                                                    <button className="btn btn-success btn-sm" type='button' onClick={this.addCheckList}>Add</button>
                                                </div>
                                                <small>
                                                    Use '/' to separate variations
                                                    ex: React/ React JS/ ReactJS

                                                </small>
                                                <ul className="list-group">
                                                    {checkList.map(({ item, id }) => <li key={'chk-' + id} className="list-group-item">
                                                        <div className="input-group mb-3">
                                                            <input type='text' className='form-control' defaultValue={item} maxLength={100} onKeyUp={(evt) => this.updateCheckListItem(evt.currentTarget.value, id)} />
                                                            <button className="btn btn-outline-secondary" type="button" id="button-addon2"><i className="ms-1 bi bi-trash text-danger" onClick={() => this.removeCheckListItem(id)}></i></button>
                                                        </div>
                                                    </li>)}
                                                </ul>
                                            </div>
                                        </div>



                                        <div className="mb-3 row">
                                            {/* <label htmlFor="inputName" className="col-sm-2 col-form-label">Type</label> */}
                                            <div className="col-sm-10">
                                                <button className='btn btn-success' type='button' onClick={this.addContact}>Add Contact</button>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <table className='table table-bordered table-compact'>
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Title</th>
                                                        <th>Name</th>
                                                        <th>Number</th>
                                                        <th>Email</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {contacts.map((contact, idx: number) => <tr key={'contact-row-' + contact.id}>
                                                        <td>{idx + 1}</td>
                                                        <td>
                                                            <input type='text' value={contact.title} onChange={(evt) => this.changeTitleForContact(contact, evt.target.value)} />
                                                        </td>
                                                        <td>

                                                            <input type='text' value={contact.name} onChange={(evt) => this.changeNameForContact(contact, evt.target.value)} />

                                                        </td>
                                                        <td>
                                                            <div>
                                                                <button type='button' onClick={() => this.addPhone(contact)}>Add</button>
                                                            </div>
                                                            <ul>
                                                                {contact.phones.map(phone => <li key={'p-' + phone.id}>
                                                                    <input type='text' value={phone.phone} onChange={(evt) => this.changePhoneForContact(contact, phone.id, evt.target.value)} />
                                                                    <i className="bi bi-trash-fill text-danger cursor-pointer" onClick={() => this.removePhoneForContact(contact, phone.id)}></i>
                                                                </li>)}
                                                            </ul>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <button type='button' onClick={() => this.addEmail(contact)}>Add</button>
                                                            </div>
                                                            <ul>
                                                                {contact.emails.map(email => <li key={'e-' + email.id}>
                                                                    <input type='text' value={email.email} onChange={(evt) => this.changeEmailForContact(contact, email.id, evt.target.value)} />
                                                                    <i className="bi bi-trash-fill text-danger cursor-pointer" onClick={() => this.removeEmailForContact(contact, email.id)}></i>
                                                                </li>)}
                                                            </ul>
                                                        </td>
                                                    </tr>)}
                                                </tbody>

                                            </table>
                                        </div>

                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={company ? this.updateCompany : this.addCompany} disabled={loading}>{company ? 'Edit' : 'Add'} Company</button>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />


            </div>
        )
    }
}

type CellType = "text" | "select" | "number" | "textarea";
type CellOption = { label: string, value: string };
// type CellOptions = {label: string, value: string };
interface CellPropsInterface {
    data: any//ContactFormInterface
    field: any//ContactFormInterface
    value: string | number
    type: CellType
    list?: CellOption[]
    onChange: (d: any /*ContactFormInterface*/) => void
    remove?: (d: any) => void
}

const Cell = (props: CellPropsInterface) => {

    const [edit, setEdit] = useState(false);
    const [value, setValue] = useState(props.value);
    const ref = useRef<any>();


    const setEditable = () => {
        setEdit(true);
        setInterval(() => ref && ref.current && ref.current.focus(), 100)
    }
    const changeValue = (e: any) => {
        const target = e.target;
        let val = null;
        if (props.type == "select") {
            val = target.options[target.selectedIndex].innerText
        } else {
            val = target.value;
        }
        if (val) {
            setValue(val);
            setEdit(false);
            props.onChange({ ...props.data, [props.field]: target.value })
        } else {
            setEdit(false);
            props.remove!(props.data)
        }
    }

    const renderElement = (type: CellType, list?: CellOption[]) => {
        let ele;
        switch (type) {
            case "text": ele = <input ref={ref} className={'form-control'} type='text' defaultValue={props.data.id == -1 ? '' : props.value} onBlur={changeValue} />; break;
            case "textarea": ele = <textarea defaultValue={props.value} onBlur={changeValue} rows={3} cols={5}>{props.value}</textarea>; break;
            case "number": ele = <input className={'form-control'} type='number' defaultValue={props.value} onBlur={changeValue} />; break;
            case "select": ele = <select className={'form-select'} defaultValue={props.data[props.field] as string} onBlur={changeValue} >
                {list?.map(l => <option key={'opt-' + l.label} value={l.value}>{l.label}</option>)}
            </select>; break;
        }

        return ele;
    }


    return (<div onClick={setEditable}>
        {edit
            ? renderElement(props.type, props.list)
            : value
        }
    </div>)

}

const TDCell = (props: CellPropsInterface) => {

    const [edit, setEdit] = useState(false);
    const [value, setValue] = useState(props.value);


    const setEditable = () => {
        setEdit(true);
    }
    const changeValue = (e: any) => {
        const target = e.target;
        if (props.type == "select") {
            setValue(target.options[target.selectedIndex].innerText);
        } else {
            setValue(target.value)
        }
        setEdit(false);
        props.onChange({ ...props.data, [props.field]: target.value })
    }

    const renderElement = (type: CellType, list?: CellOption[]) => {
        let ele;
        switch (type) {
            case "text": ele = <input className={'form-control'} type='text' defaultValue={props.value} onBlur={changeValue} />; break;
            case "textarea": ele = <textarea className={'form-control'} defaultValue={props.value} onBlur={changeValue} rows={3} cols={35}></textarea>; break;
            case "number": ele = <input className={'form-control'} type='number' defaultValue={props.value} onBlur={changeValue} />; break;
            case "select": ele = <select className={'form-select'} defaultValue={props.data[props.field] as string} onBlur={changeValue} >
                {list?.map(l => <option key={'opt-' + l.label} value={l.value}>{l.label}</option>)}
            </select>; break;
        }

        return ele;
    }


    return (<td onClick={setEditable}>
        <div className='tdcell-div'>
            {edit
                ? renderElement(props.type, props.list)
                : value
            }
        </div></td>)

}



export default Companies;