
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect} from "react-router-dom";


import Amplify, { API, Auth } from "aws-amplify";
import awsExports from "./aws-exports";
import { useEffect, useState } from "react";

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
// import { listCategories } from "./graphql/queries";
import JoinerTracker from "./pages/JoinerTracker";
import Employees from "./pages/Employees";


import './assets/css/App.css';
import { isSuperAdmin, isOnlyRecruiter, REST_API_NAME } from "./utils";
import Dashboard from "./pages/Dashboard";
import Companies from "./pages/Companies";
import InvoicesToSend from "./pages/InvoicesToSend";
import Candidates from "./pages/Candidates";
import Coordination from "./pages/Coordination";
import Formats from "./pages/Formats";
import FormatData from "./pages/FormatData";
import * as Sentry from "@sentry/react";
import CandidateLeads from "./pages/CandidateLeads";
import Masters from "./pages/Masters";
import CoordinationTracker2 from "./pages/CoordinationTracker2";
import Candidates2 from "./pages/Candidates2";
import Jobs from "./pages/Jobs";
import CoordinationTrackerList from "./pages/CoordinationTrackerList";
import { CompanyInterface, EmployeeInterface } from "./types";
import ATSNav from "./components/ATSNav/ATSNav";
import LoginPage from "./pages/LoginPage/LoginPage";
import CompaniesAssigned from "./pages/CompaniesAssigned";
import CoordinationTracker3 from "./pages/CoordinationTracker3";
import Subscribers from "./pages/Subscribers";
import CandidateSearch from "./pages/CandidateSearch";
import DashboardNew from "./pages/DashboardNew";
import CallerTracker from "./pages/CallerTracker";
import GridReport from "./pages/Reports/GridReport";



Amplify.configure(awsExports);

function App() {

  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [employees, setEmployees] = useState<EmployeeInterface[]>([]);
  const [companies, setCompanies] = useState<CompanyInterface[]>([]);
  const [masters, setMasters] = useState<any>({});

  const getUserCredentials = async (callback: Function) => {
    try {

      const user = await Auth.currentAuthenticatedUser();
      //@todo investigate why amplify has this quirk
      //were getting user creds twice
      const credentials = await Auth.currentUserCredentials();

      console.log("identityId", credentials.identityId);
      user.identityId = credentials.identityId;
      setUser(user);
      setLoading(false);
      // console.log(user);
      callback();

      Sentry.setUser({ email: user.attributes.email });
    } catch (e) {
      setLoading(false);

    }

    // callback()
  }


  useEffect(() => {
    getUserCredentials(() => {
      Promise.all([
        API.get(REST_API_NAME, `/companies/`, {}),
        API.get(REST_API_NAME, `/employees/all`, {})
      ])
        .then(([resultCompanies, resultEmployees]) => {
          setEmployees(resultEmployees.result)
          setCompanies(resultCompanies.result)
          // setMasters({
          //   employees:resultEmployees.result,
          //   companies:resultCompanies.result
          // })
        })
    });


  }, []);

  // useEffect(() => {
  //   user && setMasters({s:1})

  // }, [user]);

  const signOut = async () => {
    await Auth.signOut();
    window.location.href = '/'
  }

  const signIn = async (username: string, password: string) => {
    try {
      const user = await Auth.signIn(username, password);
    } catch (e) {
      // Toast
      return e;
    }
    return user;
  }

  // const chrome= navigator.userAgentData?.brands?.some((b: { brand: string; }) => b.brand === 'Google Chrome')

  return (
    user
      ? <div className="App">
        <Router>
          <div>
           

            {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
            {/* <NavBarOld user={user} /> */}
            <ATSNav user={user} signOut={signOut} />

           
            <main className="container ms-0 p-0">
              <Switch>

                <Redirect exact path="/" to={'/dashboard'} />

                <Route exact path="/dashboard-old">
                  <Dashboard user={user} />
                </Route>

                <Route exact path="/dashboard">
                  <DashboardNew user={user} employees={employees} />
                </Route>

                <Route exact path="/invoices-to-send">
                  {isSuperAdmin(user) ? <InvoicesToSend user={user} /> : <Dashboard user={user} />}
                </Route>

                <Route exact path="/formats">
                  <Formats user={user} />
                </Route>

                <Route exact path="/format-data">
                  <FormatData user={user} />
                </Route>

                <Route exact path="/candidate-leads">
                  <CandidateLeads user={user} />
                </Route>
                <Route exact path="/candidates">
                  <Candidates user={user} />
                </Route>
                <Route exact path="/candidates2">
                  <Candidates2 user={user} />
                </Route>
                <Route exact path="/coordination">
                  {isSuperAdmin(user) ? <Coordination user={user} /> : <Dashboard user={user} />}
                </Route>
                <Route exact path="/masters">
                  {isSuperAdmin(user) ? <Masters user={user} /> : <Dashboard user={user} />}
                </Route>
                <Route exact path="/subscribers">
                    <Subscribers user={user} /> : <Dashboard user={user} />
                </Route>
                <Route exact path="/caller-tracker">
                  {isSuperAdmin(user) ? <CallerTracker user={user} /> : <Dashboard user={user} />}
                </Route>

                <Route exact path="/jobs">
                  {/* {isOnlyRecruiter(user) ? <Jobs user={user} /> : <Dashboard user={user} />} */}
                  <Jobs user={user} />
                </Route>
                <Route exact path="/login">
                  {/* {isOnlyRecruiter(user) ? <Jobs user={user} /> : <Dashboard user={user} />} */}
                  <LoginPage signIn={signIn} />
                </Route>

                <Route exact path="/coordination-tracker">
                  {/* <CoordinationTracker user={user} /> */}
                  <CoordinationTracker2 user={user} />
                </Route>
                <Route exact path="/coordination-tracker-3">
                  {/* <CoordinationTracker user={user} /> */}
                  {isSuperAdmin(user) ?  <CoordinationTracker3 user={user} companies={companies} employees={employees} /> : '' }
                </Route>

                <Route exact path="/coordination-tracker-list/:status/:tlsub" component={(params: any) => <CoordinationTrackerList  {...params} user={user} />} />

                <Route exact path="/coordination-tracker2">
                  <CoordinationTracker2 user={user} />
                </Route>

                <Route exact path="/joiner-tracker/:month/:year" component={(params: any) => !isOnlyRecruiter(user) ? <JoinerTracker user={user} companies={companies} employees={employees} {...params} /> : <Dashboard user={user} />} />
               
                <Route exact path="/companies">
                  {!isOnlyRecruiter(user) ? <Companies user={user} /> : <Dashboard user={user} />}
                </Route>
                <Route exact path="/employees">
                  {!isOnlyRecruiter(user) ? <Employees user={user} /> : <Dashboard user={user} />}
                </Route>

                <Route exact path="/companies-assigned">
                  {!isOnlyRecruiter(user) ? <CompaniesAssigned user={user} /> : <Dashboard user={user} />}
                </Route>

                <Route exact path="/candidates-search">
                  {!isOnlyRecruiter(user) ? <CandidateSearch user={user} companies={companies} employees={employees} /> : <Dashboard user={user} />}
                </Route>
                <Route exact path="/grid">
                  {!isOnlyRecruiter(user) ? <GridReport  /> : <Dashboard user={user} />}
                </Route>

                {/* <Route exact path="/project/:id" component={(params: any) => <Project  {...params} user={user} />} /> */}
                {/* <Route exact path="/project-group/:id/:group" component={(params: any) => <ProjectGroup  {...params} />} /> */}

              </Switch>

            </main>



          </div>
        </Router>

      </div>
      :
      !loading ? <LoginPage signIn={signIn} /> : <></>

    // : <>
    //   <AmplifyAuthenticator>
    //     <AmplifySignIn slot="sign-in" hideSignUp></AmplifySignIn>
    //   </AmplifyAuthenticator>
    // </>

  );
}

// export default withAuthenticator(App, false, [
//   <MySignIn/>,
//   <AmplifyConfirmSignIn/>,
// ]);

// export default withAuthenticator(App);
export default App;
