import API from "@aws-amplify/api";
import Storage from "@aws-amplify/storage";
import React, { createRef } from "react";
import Select from "react-select";
import { CompanyInterface, GenericDataNameInterface, JobInterface, JOB_TAGS, RoleInterface } from "../types";
import { CANDIDATE_ROLES, REST_API_NAME } from "../utils";

import { Editor } from '@tinymce/tinymce-react';

import styles from '../assets/css/job.module.css';
import odnLogo from '../assets/images/logo.png';

import html2canvas from 'html2canvas';

interface MastersStateInterface {
    loading: boolean,
    url: string
    key: string
    image: any
    result: any,
    roles: RoleInterface[],
    role: RoleInterface,
    job: JobInterface,
    jobs: JobInterface[],
    selectedOptions: any,
    companies: CompanyInterface[]
    JDContent: string
}


class Jobs extends React.Component<any, MastersStateInterface>{

    createUserModalRef: any
    createUserModal: any;

    resetPasswordModalRef: any
    resetPasswordModal: any;

    addUserToGroupModalRef: any;
    addUserToGroupModal: any;

    editorRef: any;

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            url: '',
            key: '',
            image: '',
            result: {},
            roles: CANDIDATE_ROLES.map(s => ({ role_name: s, id: +s })),
            role: { role_name: '', id: -1 },
            job: {} as JobInterface,
            jobs: [],
            selectedOptions: [],
            companies: [],
            JDContent: ''
        }

        this.editorRef = createRef();


    }

    async componentDidMount() {

        // const result: any = await API.get(REST_API_NAME, '/general/pre-signed', {});

        Promise.all([
            API.get(REST_API_NAME, `/general/jobs`, {}),
            API.get(REST_API_NAME, `/companies`, {}),
        ])
            .then(([jobsResult, companiesResult]) => {
                this.setState({
                    loading: false,
                    jobs: jobsResult.result.records.map((j: any) => {
                        j.tags = j.tags || '';
                        return j;
                    }),
                    companies: companiesResult.result
                    // url: result.uploadURL, key: result.Key, 
                    // result: result.data,
                })
            })


    }

    setField = (field: keyof JobInterface, value: string | number) => this.setState({ job: { ...this.state.job, [field]: value } })

    checkName = (name: string) => {
        return name.replace('(', '').replace(')', '')
    }
    onFileChange = async (e: any) => {
        let files = e.target.files || e.dataTransfer.files
        if (!files.length) return
        // this.createImage(files[0])
        const name = files[0].name
        const { data }: any = await API.get(REST_API_NAME, '/jobs/presigned/' + this.checkName(name), {});
        this.uploadImage1(files[0], data.url, data.fields)

        // this.uploadImage1(files[0])
    }

    uploadImage1 = async (file: any, url: string, fields: any) => {
        console.log('Upload clicked')
        // Get the presigned URL
        // console.log('Response: ', response)
        // console.log('Uploading: ', this.image)


        const formData = new FormData();

        // formData.append("Content-Type", 'image/jpg');

        Object.entries(fields).forEach(([k, v]) => {
            formData.append(k, v as any);
        });

        // formData.append("key", 'prj1.jpg');
        // formData.append("bucket", 'odnpublicfolder');
        formData.append("file", file);

        const result1 = await fetch(url, {
            // method: 'PUT',
            method: 'POST',
            body: formData
        })
        const key = this.checkName(file.name);
        this.setState({
            loading: false, image: `https://odnpublicfolder.s3.ap-south-1.amazonaws.com/${key}`, job: {
                ...this.state.job, image_key: `https://odnpublicfolder.s3.ap-south-1.amazonaws.com/${key}`
            }
        })
        // Final URL for the user doesn't need the query string params
        console.log('URL ', url.split('?')[0]);
    }


    save = async () => {
        const { job } = this.state;
        const jd = this.editorRef.current.getContent();
        job.jd = jd;
        console.log(job);
        // return;
        const result = await API.post(REST_API_NAME, `/general/job`, { body: job });

        window.location.reload();
    }
    edit = async () => {
        const { job } = this.state;
        const jd = this.editorRef.current.getContent();
        job.jd = jd;
        const result = await API.put(REST_API_NAME, `/general/job/` + job.id, { body: job });

        window.location.reload();
    }
    deleteJob = async (job: JobInterface) => {
        this.setState({ loading: true })
        const result = await API.del(REST_API_NAME, `/general/job/${job.id}`, { body: job });

        if (job.image_key) {
            const key = job.image_key.split('/').pop();
            const resultDel = await API.del(REST_API_NAME, '/jobs/' + key, {});
        }
        this.setState({ loading: false, jobs: this.state.jobs.filter(j => j.id !== job.id) })
    }

    editJob = (job: JobInterface) => {
        const opts = (job.tags || '').split(',').filter(f => f != "")
        let _opts: any = [];
        opts.map(o => {
            console.log(o)
            const t = JOB_TAGS.find(f => f.value == o);
            if (t) {
                _opts.push(t)
            }
        })
        console.log(_opts);

        document.getElementById('form-container')?.scrollIntoView();


        // })



        this.setState({ selectedOptions: _opts, job, image: job.image_key })
    }

    setJDContent = (d: string) => this.setState({ JDContent: d })



    selectOption = (opt: any) => {
        this.setState({ selectedOptions: opt, job: { ...this.state.job, tags: "," + opt.map((o: any) => o.value).join(',') + ',' } })
    }


    appendCanvas=async ()=>{
       
        
        // html2canvas(document.getElementById('job-post')!).then(function(canvas) {
        //     document.getElementById('job-post-container')!.appendChild(canvas);
        // });
        const downloadImage = (blob:any, fileName:string) => {
            const fakeLink = window.document.createElement("a");
            fakeLink.style.display = "none";
            fakeLink.download = fileName;
            
            fakeLink.href = blob;
            
            document.body.appendChild(fakeLink);
            fakeLink.click();
            document.body.removeChild(fakeLink);
            
            fakeLink.remove();
            };

            const canvas = await html2canvas(document.getElementById('job-post')!);
            const image = canvas.toDataURL("image/png", 1.0);
            downloadImage(image, 'img.png');
            
            
            
    }



    render() {
        const { loading, selectedOptions, image, jobs, job, companies, JDContent } = this.state;

        const companyOption = companies.map(c => ({ label: c.name, value: c.id }));
        const company = companies.find(c => c.id == job.company_id);
        let selectedCompany = company ? [{ label: company.name, value: company.id }] : {};

        return (
            <div className='my-5' >
                <h1 id='form-container'> Jobs</h1>

                <div className='row' >
                    <div className='col-lg-6 col-md-6 col-sm-12 d-none'>
                        {image && <img src={image} className="w-50 img-thumbnail" alt="..."></img>}
                        <input type="file" onChange={this.onFileChange} />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 mx-5'>
                        <div>
                            <div className="mb-3 row">
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Company</label>
                                <div className="col-sm-10">
                                    <Select
                                        isMulti={false}
                                        value={selectedCompany}
                                        options={[{ label: '', value: -1 }, ...companyOption]}
                                        onChange={(evt: any) => this.setField('company_id', evt.value)}
                                    />
                                    {/* <input type="text" className="form-control" id="staticEmail" defaultValue={job.companyName} onChange={(evt) => this.setField('company_id', evt.target.value)} /> */}
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Company Name</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="staticEmail" defaultValue={job.title} onChange={(evt) => this.setField('title', evt.target.value)} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Qualification</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="staticEmail" defaultValue={job.qualification} onChange={(evt) => this.setField('qualification', evt.target.value)} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Designation</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="inputPassword" defaultValue={job.designation} onChange={(evt) => this.setField('designation', evt.target.value)} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Experience</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="inputPassword" defaultValue={job.experience} onChange={(evt) => this.setField('experience', evt.target.value)} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Communication</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="inputPassword" defaultValue={job.communication} onChange={(evt) => this.setField('communication', evt.target.value)} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Salary</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="inputPassword" defaultValue={job.salary} onChange={(evt) => this.setField('salary', evt.target.value)} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Type</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="inputPassword" defaultValue={job.work_type} onChange={(evt) => this.setField('work_type', evt.target.value)} />
                                    <small>Non IT, BPO, Non BPO etc</small>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Location</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="inputPassword" defaultValue={job.location} onChange={(evt) => this.setField('location', evt.target.value)} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Shift</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="inputPassword" defaultValue={job.shift} onChange={(evt) => this.setField('shift', evt.target.value)} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">JD</label>
                                <div className="col-sm-10">
                                    <Editor
                                        apiKey="jjepwn2j57vncw19bhbeiuao2rwly7qtkcf0clgbi1o68rbt"
                                        onInit={(evt, editor) => this.editorRef.current = editor}
                                        onChange={(evt, editor) => this.setJDContent(this.editorRef.current.getContent())}
                                        initialValue={job.jd}
                                        init={{
                                            height: 500,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime table paste code help wordcount'
                                            ],
                                            toolbar: 'undo redo | formatselect | ' +
                                                'bold italic backcolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'removeformat | help',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                        }}
                                    />

                                </div>
                            </div>


                            <div className="mb-3 row">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Tags</label>
                                <div className="col-sm-10">
                                    <Select
                                        isMulti={true}
                                        options={JOB_TAGS}
                                        onChange={this.selectOption}
                                        value={selectedOptions}
                                    />
                                </div>
                            </div>


                            <div className="mb-3 row">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Image Url</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control-plaintext" id="inputPassword" defaultValue={image} />
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Active</label>
                                <div className="col-sm-10">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={!!job.is_active} onChange={(evt) => this.setField('is_active', evt.target.checked ? 1 : 0)} />
                                        {/* <label className="form-check-label" htmlFor="" id="flexSwitchCheckChecked">Active</label> */}
                                    </div>

                                </div>
                            </div>

                            <button type='button' className='btn btn-success' onClick={job.id ? this.edit : this.save}>{job.id ? 'Update' : 'Save'}</button>

                            <button type='button' className='btn btn-info ms-5' onClick={this.appendCanvas}>Get Image</button>

                        </div>
                    </div>


                    <div className='col-lg-5 col-md-6 col-sm-12 d-none1' id='job-post-container'>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 d-none1' id='job-post' style={{'position':'fixed','left':'-1000px'}}>
                        <div className={`${styles.jobContainer}`}>
                            <div className="mb-3 row">
                                <div className={`col-sm-10 w-100 ${styles.logoContainer}`}>
                                    <img src={odnLogo} className={`${styles.odnLogo}`}/>
                                    <img src={odnLogo} className={`${styles.odnLogo} d-none`}/>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <div className={`col-sm-10 ${styles.jobTitle}`}>
                                    {job.title}
                                </div>
                            </div>

                            <div className={`mb-3 d-flex ${styles.labelRow}`}>
                                <div className={`mb-3 d-flex ${styles.labelRowLabel}`}>Qualification:</div>
                                <div className={`mb-3 d-flex ${styles.labelRowValue}`}>
                                    {job.qualification}
                                </div>
                            </div>
                            <div className={`mb-3 d-flex ${styles.labelRow}`}>
                                <div className={`mb-3 d-flex ${styles.labelRowLabel}`}>Experience:</div>
                                <div className={`mb-3 d-flex ${styles.labelRowValue}`}>
                                    {job.experience}
                                </div>
                            </div>
                            <div className={`mb-3 d-flex ${styles.labelRow}`}>
                                <div className={`mb-3 d-flex ${styles.labelRowLabel}`}>Shift:</div>
                                <div className={`mb-3 d-flex ${styles.labelRowValue}`}>
                                    {job.shift}
                                </div>
                            </div>
                            <div className={`mb-3 d-flex ${styles.labelRow}`}>
                                <div className={`mb-3 d-flex ${styles.labelRowLabel}`}>Salary:</div>
                                <div className={`mb-3 d-flex ${styles.labelRowValue}`}>
                                    {job.salary}
                                </div>
                            </div>
                            <div className={`mb-3 d-flex ${styles.labelRow}`}>
                                <div className={`mb-3 d-flex ${styles.labelRowLabel}`}>Language:</div>
                                <div className={`mb-3 d-flex ${styles.labelRowValue}`}>
                                    {job.communication}
                                </div>
                            </div>
                            <div className={`mb-3 d-flex ${styles.labelRow}`}>
                                <div className={`mb-3 d-flex ${styles.labelRowLabel}`}>Designation:</div>
                                <div className={`mb-3 d-flex ${styles.labelRowValue}`}>
                                    {job.designation}
                                </div>
                            </div>
                            <div className={`mb-3 d-flex ${styles.labelRow}`}>
                                <div className={`mb-3 d-flex ${styles.labelRowLabel}`}>Location:</div>
                                <div className={`mb-3 d-flex ${styles.labelRowValue}`}>
                                    {job.location}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div className='row d-none' >
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div>
                            <div className="mb-3 row">
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Company</label>
                                <div className="col-sm-10">
                                    <Select
                                        isMulti={false}
                                        value={selectedCompany}
                                        options={[{ label: '', value: -1 }, ...companyOption]}
                                        onChange={(evt: any) => this.setField('company_id', evt.value)}
                                    />
                                    {/* <input type="text" className="form-control" id="staticEmail" defaultValue={job.companyName} onChange={(evt) => this.setField('company_id', evt.target.value)} /> */}
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Title</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="staticEmail" defaultValue={job.title} onChange={(evt) => this.setField('title', evt.target.value)} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Qualification</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="staticEmail" defaultValue={job.qualification} onChange={(evt) => this.setField('qualification', evt.target.value)} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Designation</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="inputPassword" defaultValue={job.designation} onChange={(evt) => this.setField('designation', evt.target.value)} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Experience</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="inputPassword" defaultValue={job.experience} onChange={(evt) => this.setField('experience', evt.target.value)} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Communication</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="inputPassword" defaultValue={job.communication} onChange={(evt) => this.setField('communication', evt.target.value)} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Tags</label>
                                <div className="col-sm-10">
                                    <Select
                                        isMulti={true}
                                        options={JOB_TAGS}
                                        onChange={this.selectOption}
                                        value={selectedOptions}
                                    />
                                </div>
                            </div>


                            <div className="mb-3 row">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Image Url</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control-plaintext" id="inputPassword" defaultValue={image} />
                                </div>
                            </div>

                            <button type='button' className='btn btn-success' onClick={job.id ? this.edit : this.save}>{job.id ? 'Update' : 'Save'}</button>

                        </div>
                    </div>

                </div>






                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>#</th>
                            <th>Tags</th>
                            <th>Title</th>
                            <th>Qualification</th>
                            <th>Designation</th>
                            <th>Experience</th>
                            <th>Comunication</th>
                            <th>Image</th>
                        </tr>
                    </thead>
                    <tbody>
                        {jobs.map((job, idx) => <tr key={job.id}>
                            <td>
                                <button onClick={() => { if (window.confirm('Are you sure you want to delete this job?')) { this.deleteJob(job); } }} disabled={loading} className='btn btn-danger btn-sm'>
                                    <i className="bi bi-trash-fill"></i>
                                </button>
                                <button onClick={() => { this.editJob(job); }} disabled={loading} className='btn btn-warning btn-sm'>
                                    <i className="bi bi-pencil-fill"></i>
                                </button>
                            </td>
                            <td>{idx + 1}</td>
                            <td>{job.tags.split(',').filter(f => f).join(',')}</td>
                            <td>{job.title}</td>
                            <td>{job.qualification}</td>
                            <td>{job.designation}</td>
                            <td>{job.experience}</td>
                            <td>{job.communication}</td>
                            <td><a href={job.image_key}>View</a></td>
                        </tr>)}
                    </tbody>
                </table>


            </div >
        )
    }
}

export default Jobs;


const EditBox = () => {



    return (<div>

    </div>)
}