import { Tab } from "bootstrap";
import { format } from "path";
import { useEffect, useState } from "react";
import { ContactInterface, CoordinationTrackerInterface, EmailInterface, FormatRowInterface } from "../../types";


interface FormatDataView2Interface {
    loading: boolean,
    candidates: CoordinationTrackerInterface[],
    formats: any
    contacts: ContactInterface[]
    addFormatDataTabsRef: any
    subject: string
    changeSubject: (s: string) => void
    sendFormatEmail: (selectedEmails: EmailInterface[], html: string) => void
    selectFormat: (f: any) => void

}

const FormatDataView3 = ({
    candidates,
    formats,
    loading,
    contacts,
    addFormatDataTabsRef,
    subject,
    changeSubject,
    sendFormatEmail,
    selectFormat
}: FormatDataView2Interface) => {


    const [data, setData] = useState<any>({});


    let hash: any = {};
    formats && formats.map((fs: any) => {
        const _data = fs.data;
        _data.map((d: any) => {
            hash[d.candidate_id] = hash[d.candidate_id] || {};
            hash[d.candidate_id][d.row_id] = d.value;
        })
    })


    // console.log('hash ', hash)

    useEffect(() => {
        console.log('Loaded');

        formats.map((fs: any) => {
            const _data = fs.data;
            _data.map((d: any) => {
                hash[d.candidate_id] = hash[d.candidate_id] || {};
                hash[d.candidate_id][d.row_id] = d.value;
            })
        })
        console.log('hash 2', hash, formats)

        if (addFormatDataTabsRef.current) {
            console.log('Tabs Loaded');

            var triggerTabList = [].slice.call(addFormatDataTabsRef.current.querySelectorAll('a'))
            triggerTabList.forEach((triggerEl: any, idx) => {
                var tabTrigger = new Tab(triggerEl)

                triggerEl.addEventListener('click', (event: any) => {
                    event.preventDefault();
                    console.log(triggerEl);
                    const formatId = triggerEl.getAttribute('data-bs-target').replace('#tab-', '');
                    selectFormat(formatId)
                    setData({})
                })
                idx == 0 && triggerEl.click();
            });



        }
        return () => {
            if (addFormatDataTabsRef.current) {
                var triggerTabList = [].slice.call(addFormatDataTabsRef.current.querySelectorAll('a'))
                triggerTabList.forEach((triggerEl: any) => {
                    var tabTrigger = new Tab(triggerEl)

                    triggerEl.removeEventListener('click', (event: any) => {
                        event.preventDefault();
                        console.log(triggerEl)
                        setData({})
                    })
                })
            }

        }
    }, [formats])

    const [selectedEmails, setSelectedEmails] = useState<EmailInterface[]>([]);


    const selectEmail = (checked: boolean, contact: ContactInterface, email: EmailInterface) => {
        if (checked) {
            setSelectedEmails([...selectedEmails, email])
        } else {
            setSelectedEmails(selectedEmails.filter(s => s.id !== email.id))
        }

    }

    const sendFormat = () => {
        sendFormatEmail(selectedEmails, "")
    }


    return (<div className="">
        <ul className="nav nav-tabs" ref={addFormatDataTabsRef}>
            {formats.map((format: any, idx: number) => <li className="nav-item" key={'f-t-' + format.formatId}>
                <a className="nav-link" data-bs-toggle="tab" data-bs-target={"#tab-" + format.formatId} aria-current="page" href="#">{format.companyName}-{format.name}</a>
            </li>)}

        </ul>

        <div className="border-grey bg-white p-3 tab-content">
            {formats.map((format: any, idx: number) => <div key={'ft-d-' + format.formatId} className="tab-pane" id={"tab-" + format.formatId} role="tabpanel" aria-labelledby="home-tab">
                <table className='table table-bordered' id={'format-table-data-' + format.formatId} cellSpacing={0} style={{ "border": "1px solid #333" }}>
                    <thead>
                        <tr>
                            {/* <th style={{ "border": "1px solid #333" }}>Name</th> */}
                            {/* <th style={{ "border": "1px solid #333" }}>Number</th> */}
                            {format.rows.map((row: any) => <th key={'ft-row' + row.id} style={{ "border": "1px solid #333" }}>
                                {row.coloumn_title}
                            </th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {candidates.map((candidate: any) => hash[candidate.id] ? <tr key={'c-fv-' + candidate.id}>
                            {/* <td style={{ "border": "1px solid #333" }}>{candidate.name}</td> */}
                            {/* <td style={{ "border": "1px solid #333" }}>{candidate.number}</td> */}
                            {format.rows.map((row: any) => <td key={'ft-td-row' + row.id} style={{ "border": "1px solid #333" }}>
                                {hash[candidate.id] ? hash[candidate.id][row.id] : 'nc'}
                            </td>)}
                        </tr> : <></>
                        )}
                    </tbody>

                </table>



            </div>)}

        </div>
    </div >)
}
export default FormatDataView3;