import { useState } from "react";
import { CompanyCountInterface, COMPANY_TYPES, COMPANY_TYPE_ENUM, COMPANY_TYPE_TYPE, CoordinationFilterStatusType, FinalStatusType, OptionLabelValueInterface } from "../../types";
import { FINAL_STATUS_FIELDS, MONTHS } from "../../utils";
import {MonthSelector, YearSelector} from "../MonthSelector/MonthSelector";

interface FilterStateComponentProps {
    filterState: CoordinationFilterStatusType
    companies: CompanyCountInterface[]
    selectedCompany: CompanyCountInterface
    loading: boolean
    selectedMonth: string
    selectedYear: number

    selectMonth:(m: number)=>void
    selectYear:(y: number)=>void
    setFilter: (f: CoordinationFilterStatusType) => void
    selectCompany: (f: CompanyCountInterface) => void
    selectCompanyType: (f: COMPANY_TYPE_ENUM) => void
    setStatusFilter: (f: { label: FinalStatusType; value: FinalStatusType; }[]) => void
    showSearchCandidateModal: () => void
}
const FilterCoordinationStateComponent = ({ loading, filterState, companies, selectedCompany, selectedMonth, selectedYear, selectMonth, selectYear, setFilter, selectCompany, selectCompanyType, setStatusFilter, showSearchCandidateModal }: FilterStateComponentProps) => {


    const [filterCompany, setFilterCompany] = useState("")
    const [status, setStatus] = useState<{ label: FinalStatusType; value: FinalStatusType; }[]>([{ label: 'Status Not Set', value: 'Status Not Set' }])
    const [companyType, setCompanyType] = useState<typeof COMPANY_TYPES[0]>(COMPANY_TYPES[0]);

    const _companyType = (ct: typeof COMPANY_TYPES[0]) => {
        setCompanyType(ct);
        selectCompanyType(ct.label)
    }

    const _setStatus = (f: { label: FinalStatusType; value: FinalStatusType; }, checked: boolean) => {

        const _f = checked ? [...status, f] : status.filter(s => s.value != f.value);


        setStatus(_f);
        setStatusFilter(_f)
    }




    return (<div className='col-12'>
        <div className="btn-toolbar mt-1" role="toolbar" aria-label="Toolbar with button groups">
            <div className="btn-group me-2" role="group">
                <button id="btnGroupDrop1" type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    {companyType.label}
                </button>
                <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                    {COMPANY_TYPES.map((company, idx) => <li key={'ctl-' + idx} onClick={() => _companyType(company)}>
                        <a className="dropdown-item" href="#" onClick={(evt) => evt.preventDefault()}>{`${company.label}`}</a>
                    </li>)}
                </ul>
            </div>
            <div className="btn-group " role="group">
                <MonthSelector selectedMonth={selectedMonth} loading={false} selectMonth={(m)=>selectMonth(MONTHS.findIndex(a=>m===a))} />
            </div>
            <div className="btn-group me-2" role="group">
                <YearSelector selectedYear={selectedYear} loading={false} selectYear={selectYear} />
            </div>

            <div className="btn-group me-2" role="group" aria-label="First group">
                {/* <button type="button" className={"btn btn-primary " + (filterState == 'unassigned' ? 'active' : '')} onClick={() => this.setFilter('unassigned')}>Unassigned</button> */}
                <button type="button" className={"btn btn-primary " + (filterState == 'pending-assign' ? 'active btn-success' : '')} disabled={loading} onClick={() => setFilter('pending-assign')}>Pending</button>
                <button type="button" className={"btn btn-primary " + (filterState == 'assigned' ? 'active btn-success' : '')} disabled={loading} onClick={() => setFilter('assigned')}>Assigned</button>
                <button type="button" className={"btn btn-primary " + (filterState == 'aligned' ? 'active btn-success' : '')} disabled={loading} onClick={() => setFilter('aligned')}>Aligned</button>
                <button type="button" className={"btn btn-primary " + (filterState == 'selected' ? 'active btn-success' : '')} disabled={loading} onClick={() => setFilter('selected')}>Selected</button>
            </div>

            <div className="btn-group me-2" role="group">
                <button id="btnGroupDrop1" type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    {!!selectedCompany.name ? selectedCompany.name : 'Select Company'}
                </button>
                <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                    <li>
                        <div className="input-group mb-3">
                            <span className="input-group-text"><i className="bi bi-search"></i></span>
                            <input type="text" aria-label="Filter Companies" className="form-control" onKeyUp={(evt) => setFilterCompany(evt.currentTarget.value)} />
                        </div>
                    </li>
                    {companies.map(company => company.name.toLowerCase().indexOf(filterCompany) == -1
                        ? ''
                        : <li key={company.id} onClick={() => selectCompany(company)}><a className="dropdown-item" href="#" onClick={(evt) => evt.preventDefault()}>{`${company.name} (${company.cnt})`}</a></li>)}
                </ul>
            </div>

            <div className="btn-group me-2" role="group">
                <button id="btnGroupDrop1" type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    {!status.length ? 'Select Status' : status.map(s => s.label).join(', ')}
                </button>
                <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                    {FINAL_STATUS_FIELDS.map((f: { label: FinalStatusType, value: FinalStatusType }) => <li key={f.value}>
                        {/* <a className="dropdown-item" href="#" onClick={(evt) => evt.preventDefault()}>{`${company.name} (${company.cnt})`}</a> */}
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={!!status.find(s => s.value == f.value)} onChange={evt => _setStatus(f, evt.target.checked)} />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                {f.label}
                            </label>
                        </div>
                    </li>)}
                </ul>
            </div>

            <div className="input-group me-3">
                {/* <button className="btn btn-primary" type="button" disabled={loading} onClick={showSearchCandidateModal}>Search By Candidate</button> */}
            </div>
        </div>
    </div>)
}

export default FilterCoordinationStateComponent;