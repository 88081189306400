import API from "@aws-amplify/api";
import { a } from "@aws-amplify/ui";
import dayjs from "dayjs";
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { promises } from "fs";
import React from "react";
import { formatEmployeeName, isSuperAdmin, REST_API_NAME, YEARS } from "../utils";

import List from "list.js"
import { EmployeeInterface } from "../types";

dayjs.extend(weekOfYear)

class DashboardNew extends React.Component<{ user: any, employees: EmployeeInterface[] }, any> {



    constructor(props: any) {
        super(props);
        const dt = new Date();
        this.state = {
            loading: true,
            selectMonth: dt.getMonth() + 1,
            selectYear: dt.getFullYear(),
            tlSuccess: [],
            recFTSuccess: [],
            recPTSuccess: [],
            recFTSuccessReportTeams: {},
            recPTSuccessReportTeams: {},
            teams: {},
            reports: {
                candidatesCreated: [],
                candidatesSuccessPercent: [],
                candidatesAlignedFeedback: [],
                resultSuccessRecruiter: []
            }
        }
    }

    async componentDidMount() {
        this.getData();
    }

    timeout: any

    getData = async () => {
        const { selectMonth, selectYear } = this.state;

        try {

            Promise.all([
                // API.get(REST_API_NAME, `/candidates/reports/created/${selectMonth}/${selectYear}`, {}),
                API.get(REST_API_NAME, `/joiners/reports/success-tl/${selectMonth}/${selectYear}`, {}),
                API.get(REST_API_NAME, `/joiners/reports/success-recruiter-fulltime/${selectMonth}/${selectYear}`, {}),
                API.get(REST_API_NAME, `/joiners/reports/success-recruiter-parttime/${selectMonth}/${selectYear}`, {}),


            ])
                .then(([
                    { result: { records: tlSuccessRecords } },
                    { result: { records: recFTSuccessRecords } },
                    { result: { records: recPTSuccessRecords } },
                ]) => {

                    let tlSuccessReport: any[] = [];
                    tlSuccessRecords.map((t: any) => tlSuccessReport.push({ ...t, revenue:t.revenue||0,conversion: (!t.added) ? 0 : ((100 * (t.success / t.added) as number).toFixed(2)) }))
                    // console.log(tlSuccessReport.sort(({ conversion: c1 }: any, { conversion: c2 }: any) => c2 - c1))

                    let recFTSuccessReport: any[] = [];
                    recFTSuccessRecords.map((t: any) => recFTSuccessReport.push({ ...t, revenue:t.revenue||0,conversion: (!t.added) ? 0 : ((100 * (t.success / t.added) as number).toFixed(2)) }))
                    // console.log(recFTSuccessReport.sort(({ conversion: c1 }: any, { conversion: c2 }: any) => c2 - c1))

                    //split dashboard value based on team
                    let recFTSuccessReportTeams: any = {};
                    let teams: any = {};
                    recFTSuccessRecords.map((t: any) => {
                        if (t.team_id) {
                            teams[t.team_id] = { ...t };
                            recFTSuccessReportTeams[t.team_id] = recFTSuccessReportTeams[t.team_id] || [];
                            t.conversion = (!t.added) ? 0 : ((100 * (t.success / t.added) as number).toFixed(2));
                            t.revenue=t.revenue||0;
                            recFTSuccessReportTeams[t.team_id].push(t)
                        }

                    });

                    for (let key in recFTSuccessReportTeams) {
                        recFTSuccessReportTeams[key] = recFTSuccessReportTeams[key].sort(({ revenue: s1 }: any, { revenue: s2 }: any) => s2 - s1)
                    }




                    let recPTSuccessReport: any[] = [];
                    recPTSuccessRecords.map((t: any) => recPTSuccessReport.push({ ...t, revenue:t.revenue||0,conversion: (!t.added) ? 0 : ((100 * (t.success / t.added) as number).toFixed(2)) }))
                    let recPTSuccessReportTeams: any = {};
                    recPTSuccessRecords.map((t: any) => {
                        if (t.team_id) {
                            teams[t.team_id] = { ...t };
                            recPTSuccessReportTeams[t.team_id] = recPTSuccessReportTeams[t.team_id] || [];
                            t.conversion = (!t.added) ? 0 : ((100 * (t.success / t.added) as number).toFixed(2));
                            t.revenue=t.revenue||0;
                            recPTSuccessReportTeams[t.team_id].push(t)
                        }

                    });

                    for (let key in recPTSuccessReportTeams) {
                        recPTSuccessReportTeams[key] = recPTSuccessReportTeams[key].sort(({ revenue: s1 }: any, { revenue: s2 }: any) => s2 - s1)
                    }


                    // console.log(recPTSuccessReport.sort(({ conversion: c1 }: any, { conversion: c2 }: any) => c2 - c1))


                    this.setState({
                        loading: false,
                        tlSuccess: tlSuccessReport.sort(({ revenue: s1 }: any, { revenue: s2 }: any) => +s2 - +s1),
                        recFTSuccess: recFTSuccessReport.sort(({ revenue: s1 }: any, { revenue: s2 }: any) => s2 - s1),
                        recPTSuccess: recPTSuccessReport.sort(({ revenue: s1 }: any, { revenue: s2 }: any) => s2 - s1),
                        recFTSuccessReportTeams,
                        recPTSuccessReportTeams,
                        teams
                    })

                    var options = {
                        valueNames: ['name', 'added', 'success', 'percent', 'revenue'],
                        item: "<tr><td class='name'></td><td class='added'></td><td class='success'></td><td class='percent'></td><td class='revenue'></td></tr>"
                    };

                    var hackerList = new List('tl-table', options);
                    hackerList.sort && hackerList.sort('success', { order: 'desc' })

                    setTimeout(() => {
                        var hackerList = new List('tl-table', options);
                        hackerList.sort && hackerList.sort('success', { order: 'desc' })

                        Object.values(teams).map(({ team_id }: any) => {
                            // if (document.querySelector('#' + 'rec-ft-' + team_id + ' list')?.children) {
                                var list1 = new List('rec-ft-' + team_id, options);
                                list1.sort && hackerList.sort('success', { order: 'desc' })
                            // }
                            // if (document.querySelector('#' + 'rec-pt-' + team_id + ' list')?.children) {
                                var list2 = new List('rec-pt-' + team_id, options);
                                list2.sort && hackerList.sort('success', { order: 'desc' })
                            // }
                        })
                    }, 2000)

                   

                })



        } catch (e) {
            console.log('Error', e);
            this.setState({ loading: false })
        }

    }

    componentWillUnmount() {
        clearTimeout(this.timeout)
    }

    selectMonth = (month: number) => {
        this.setState({ selectMonth: month })
    }
    selectYear = (year: number) => {
        this.setState({ selectYear: year })
    }

    render() {

        const { reports, selectMonth, selectYear, tlSuccess, recFTSuccess, recPTSuccess, recFTSuccessReportTeams, recPTSuccessReportTeams, teams } = this.state
        const { user, employees } = this.props;


        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        return <div className='my-5'>

            <div className='container'>

                <div className='row'>
                    <div className='col-lg-1 col-3'>

                        <div className="dropdown">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                {months[selectMonth - 1]}
                            </button>
                            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                                {/* <li onClick={() => this.selectMonth('NONE')}><a className="dropdown-item" href="#">NONE</a></li> */}
                                <li onClick={() => this.selectMonth(1)}><a className="dropdown-item" href="#">Jan</a></li>
                                <li onClick={() => this.selectMonth(2)}><a className="dropdown-item" href="#">Feb</a></li>
                                <li onClick={() => this.selectMonth(3)}><a className="dropdown-item" href="#">Mar</a></li>
                                <li onClick={() => this.selectMonth(4)}><a className="dropdown-item" href="#">Apr</a></li>
                                <li onClick={() => this.selectMonth(5)}><a className="dropdown-item" href="#">May</a></li>
                                <li onClick={() => this.selectMonth(6)}><a className="dropdown-item" href="#">Jun</a></li>
                                <li onClick={() => this.selectMonth(7)}><a className="dropdown-item" href="#">Jul</a></li>
                                <li onClick={() => this.selectMonth(8)}><a className="dropdown-item" href="#">Aug</a></li>
                                <li onClick={() => this.selectMonth(9)}><a className="dropdown-item" href="#">Sep</a></li>
                                <li onClick={() => this.selectMonth(10)}><a className="dropdown-item" href="#">Oct</a></li>
                                <li onClick={() => this.selectMonth(11)}><a className="dropdown-item" href="#">Nov</a></li>
                                <li onClick={() => this.selectMonth(12)}><a className="dropdown-item" href="#">Dec</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-1 col-3'>
                        <div className="dropdown">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                {selectYear}
                            </button>
                            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                                {/* <li onClick={() => this.selectMonth('NONE')}><a className="dropdown-item" href="#">NONE</a></li> */}
                                {
                                    YEARS.map(year=><li onClick={() => this.selectYear(year)}><a className="dropdown-item" href="#">{year}</a></li>)
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-2 col-2'>
                        <button onClick={() => { this.getData() }} className='btn btn-primary'>Filter</button>
                    </div>
                </div>
                <hr />


                <div className='row justify-content-center'>
                    <div className='col'>
                        <h4>TL Performance</h4>
                        <div id='tl-table'>
                            <table className='table table-bordered' >
                                <thead>
                                    <tr>
                                        <th>
                                            TL
                                            <i className="bi bi-sort-alpha-down sort" data-sort="name"></i>
                                        </th>
                                        <th>
                                            Count
                                            <i className="bi bi-sort-alpha-down sort" data-sort="added"></i>
                                        </th>
                                        <th>
                                            Success
                                            <i className="bi bi-sort-alpha-down sort" data-sort="success"></i>
                                        </th>
                                        <th>%
                                            <i className="bi bi-sort-alpha-down sort" data-sort="percent"></i>
                                        </th>
                                        <th>Revenue
                                            <i className="bi bi-sort-alpha-down sort" data-sort="revenue"></i>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="list">
                                    {tlSuccess.map((tl: any) => <tr key={tl.name+tl.added}>
                                        <td>{tl.name}</td>
                                        <td className="added">{tl.added}</td>
                                        <td className="success">{tl.success}</td>
                                        <td className="percent">{tl.conversion}</td>
                                        <td className="revenue">{tl.revenue}</td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='d-none'>
                            <h4>Full Timer Performance</h4>
                            <table className='table table-bordered' >
                                <thead>
                                    <tr>
                                        <th>
                                            Name
                                            <i className="bi bi-sort-alpha-down sort" data-sort="name"></i>
                                        </th>
                                        <th>
                                            Count
                                            <i className="bi bi-sort-alpha-down sort" data-sort="added"></i>
                                        </th>
                                        <th>
                                            Success
                                            <i className="bi bi-sort-alpha-down sort" data-sort="success"></i>
                                        </th>
                                        <th>%
                                            <i className="bi bi-sort-alpha-down sort" data-sort="percent"></i>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="list">
                                    {recFTSuccess.map((tl: any) => <tr key={tl.name}>
                                        <td className="added">{tl.added}</td>
                                        <td className="success">{tl.success}</td>
                                        <td className="percent">{tl.conversion}</td>
                                        <td className="revenue">{tl.revenue}</td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>

                        {Object.values(teams).map(({ team_id, team }: any) => {
                            return <div id={'rec-ft-' + team_id}>
                                <h4>{team} -  FullTime</h4>
                                <table className='table table-bordered' >
                                    <thead>
                                        <tr>
                                            <th>
                                                Name
                                                <i className="bi bi-sort-alpha-down sort" data-sort="name"></i>
                                            </th>
                                            <th>
                                                Count
                                                <i className="bi bi-sort-alpha-down sort" data-sort="added"></i>
                                            </th>
                                            <th>
                                                Success
                                                <i className="bi bi-sort-alpha-down sort" data-sort="success"></i>
                                            </th>
                                            <th>%
                                                <i className="bi bi-sort-alpha-down sort" data-sort="percent"></i>
                                            </th>
                                            <th>Revenue
                                                <i className="bi bi-sort-alpha-down sort" data-sort="revenue"></i>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="list">
                                        {recFTSuccessReportTeams[team_id] && recFTSuccessReportTeams[team_id].map((tl: any) => <tr>
                                            <td className="name">{tl.name}</td>
                                            <td className="added">{tl.added}</td>
                                            <td className="success">{tl.success}</td>
                                            <td className="percent">{tl.conversion}</td>
                                            <td className="revenue">{tl.revenue}</td>
                                        </tr>)}
                                    </tbody>

                                </table>
                            </div>
                        })}


                    </div>
                    <div className='col'>
                        <div className='d-none'>
                            <h4>Part Timer Performance</h4>
                            <table className='table table-bordered' >
                                <thead>
                                    <tr>
                                        <th>
                                            Name
                                            <i className="bi bi-sort-alpha-down sort" data-sort="name"></i>
                                        </th>
                                        <th>
                                            Count
                                            <i className="bi bi-sort-alpha-down sort" data-sort="added"></i>
                                        </th>
                                        <th>
                                            Success
                                            <i className="bi bi-sort-alpha-down sort" data-sort="success"></i>
                                        </th>
                                        <th>%
                                            <i className="bi bi-sort-alpha-down sort" data-sort="percent"></i>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="list">
                                    {recPTSuccess.map((tl: any) => <tr key={tl.name}>
                                        <td>{tl.name}</td>
                                        <td>{tl.added}</td>
                                        <td>{tl.success}</td>
                                        <td>{tl.conversion}</td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                        {Object.values(teams).map(({ team_id, team }: any) => {
                            return <div id={'rec-pt-' + team_id}>
                                <h4>{team} -  PartTime</h4>
                                <table className='table table-bordered' >
                                    <thead>
                                        <tr>
                                            <th>
                                                Name
                                                <i className="bi bi-sort-alpha-down sort" data-sort="name"></i>
                                            </th>
                                            <th>
                                                Count
                                                <i className="bi bi-sort-alpha-down sort" data-sort="added"></i>
                                            </th>
                                            <th>
                                                Success
                                                <i className="bi bi-sort-alpha-down sort" data-sort="success"></i>
                                            </th>
                                            <th>%
                                                <i className="bi bi-sort-alpha-down sort" data-sort="percent"></i>
                                            </th>
                                            <th>Revenue
                                                <i className="bi bi-sort-alpha-down sort" data-sort="revenue"></i>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="list">
                                        {recPTSuccessReportTeams[team_id] && recPTSuccessReportTeams[team_id].map((tl: any) => <tr>
                                            <td className="name">{tl.name}</td>
                                            <td className="added">{tl.added}</td>
                                            <td className="success">{tl.success}</td>
                                            <td className="percent">{tl.conversion}</td>
                                            <td className="revenue">{tl.revenue}</td>
                                        </tr>)}
                                    </tbody>

                                </table>
                            </div>
                        })}
                    </div>
                </div>

            </div>
        </div>
    }


}


export default DashboardNew;