

import { useEffect, useMemo, useState } from "react";
import { Document, Outline, Page, pdfjs } from "react-pdf";

import styles from './FileViewer.module.css'


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


interface FileViewerProps {
    url: string
    highlightWords:string[]

}
const FileViewer = ({ url, highlightWords }: FileViewerProps) => {


    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchText, setSearchText] = useState(new RegExp(highlightWords.join('|'),'i'));

    function onDocumentLoadSuccess({ numPages }: any) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset: number) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    function highlightPattern(text: any, pattern: any) {
        const splitText = text.split(pattern);

        if (splitText.length <= 1) {
            return text;
        }

        const matches: any = text.match(pattern);

        return splitText.reduce((arr: any, element: any, index: number) => (matches[index] ? [...arr, element,
        <mark key={index} className={styles.highlight}>
            {matches[index]}
        </mark>,
        ] : [...arr, element]), []);
    }

    const textRenderer = (textItem: any)=>highlightPattern(textItem.str, searchText);

    useEffect(()=>{
        setSearchText(new RegExp(highlightWords.join('|'),'i'))
    },[highlightWords])



    return url == ''
        ? <></>
        : <>
            <Document
                file={url}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page pageNumber={pageNumber} customTextRenderer={textRenderer} />
            </Document>

            <div>
                <p>
                    Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                </p>
                <button
                    type="button"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                >
                    Previous
                </button>
                <button
                    type="button"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                >
                    Next
                </button>
            </div>
        </>

}

export default FileViewer;