import { Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { CompanyCountInterface, CompanyInterface, CoordinationFilterStatusType, CoordinationTrackerInterface, FinalStatusType, JDCheckListInterface, OptionLabelValueInterface } from "../../types";
import { FINAL_STATUS_FIELDS } from "../../utils";
import FileViewer from "../FileViewer/FileViewer";



interface CoordinationReviewModalProps {
    url: string
    currentState: CoordinationFilterStatusType
    candidate: CoordinationTrackerInterface
    company: CompanyCountInterface & CompanyInterface
    checkList: JDCheckListInterface[]
    loading: boolean
    selectedCompanyDetailed: CompanyInterface,
    candidateFormats: any
    isRecruiter: boolean
    nextCandidate: () => void
    previousCandidate: () => void
    assign: () => void
    addToAlign: (c: CoordinationTrackerInterface) => void
    updateFinalStatus: (f: FinalStatusType, comments: string) => void
    updateTLRating: (r: number, c: CoordinationTrackerInterface) => void

}
const CoordinationReviewModal = ({
    url,
    currentState,
    candidate,
    company,
    checkList,
    loading,
    selectedCompanyDetailed,
    candidateFormats,
    isRecruiter,
    nextCandidate,
    previousCandidate,
    assign,
    addToAlign,
    updateFinalStatus,
    updateTLRating
}: CoordinationReviewModalProps) => {


    //Set comments for no format data found in pending assigned stage
    const preSetComments = (currentState === "pending-assign" && candidate.noFormatData) ? 'Please enter Format' : candidate.final_status_feedback;
    const [comments, setComments] = useState(preSetComments);
    const [JDCheckList, setJDCheckList] = useState<JDCheckListInterface[]>(checkList);
    const [allCheckListItemsSelected, setAllCheckListItemsSelected] = useState<boolean>(false);
    const [TLRating, setTLRating] = useState<number>(0);

    // useEffect(() => {
    //     setJDCheckList(checkList);
    // }, [candidate]);

    //rerun when current state changes.
    //happens when were coming from searhcing 
    // useEffect(() => {
    // console.log('In DDD', currentState)

    // currentState = candidate.assignedOn ? "assigned" : "pending-assign";
    
    // }, [currentState])


    const selectChekListItem = (item: JDCheckListInterface, selected: boolean) => {
        const _checkList = JDCheckList.map(c => c.id === item.id ? { ...c, selected } : c);
        setJDCheckList(_checkList)
        setAllCheckListItemsSelected(_checkList.every(c => c.selected))
    }

    const highlightWords = checkList.map(c => c.item.split('/')).flat();
    // console.log(highlightWords)

    const downloadFile = async () => {
        const { cvKey } = candidate;
        const file = await Storage.get(cvKey, { level: 'protected', identityId: candidate.identityId });
        window.open(file)
    }

    // console.log(allCheckListItemsSelected)
    // console.log(!!!candidate.noFormatData, allCheckListItemsSelected, currentState)
    // console.log(!(!!!candidate.noFormatData && allCheckListItemsSelected && currentState == "pending-assign"))
    // console.log(loading || !!comments || currentState == "pending-assign");
    // console.log(loading, !!comments, currentState, currentState == "pending-assign");

    const _updateFinalStatus = (finalStatus: FinalStatusType) => {
        if (finalStatus === "Reject" && (!comments || comments.trim() === "")) {
            alert('Please enter comments');
            return;
        }
        updateFinalStatus(finalStatus, comments)

    }


    return (<div className="modal-dialog modal-xl">
        <div className="modal-content modal-xl">
            <div className="modal-header">
                <button onClick={previousCandidate}>Previous</button>
                <h5 className="modal-title" id="exampleModalLabel">{candidate.name}</h5>
                <button onClick={nextCandidate}>Next</button>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body modal-x">
                <div className="row">
                    <div className="col">
                        <FileViewer
                            highlightWords={highlightWords}
                            url={url} />
                    </div>
                    <div className="col">
                        <div className="list mt-1">
                            <div>
                                {<div className="alert alert-info"><button className='btn btn-link' onClick={downloadFile}>Download CV</button></div>}
                                {candidate.noFormatData ? <div className="alert alert-danger">No Format Data</div> : ''}
                                {!!!candidate.tl_rating ? <>
                                    <div className="alert alert-danger">No TL Rating</div>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" id="basic-addon3">Rating</span>
                                        <input type="number" className="form-control" id="basic-url" min={0} max={10} onChange={(evt) => setTLRating(Number(evt.target.value))} />
                                        <button className="btn btn-primary" type="button" id="button-addon2" onClick={() => updateTLRating(TLRating, candidate)}>Update</button>
                                    </div>
                                </> : ''}
                            </div>
                            <ul className="list-group">
                                <li className="list-group-item bg-default">Checklist</li>
                                {checkList.map((item, idx) => <li className="list-group-item" key={'chk-' + idx}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id={"chk-" + idx} defaultChecked={item.selected} onChange={(evt) => selectChekListItem(item, evt.currentTarget.checked)} />
                                        <label className="form-check-label" htmlFor={"chk-" + idx}>
                                            {item.item}
                                        </label>
                                    </div>
                                </li>)}


                            </ul>
                        </div>

                        <table style={{ border: "1px solid black", borderCollapse: "collapse", width: "100%" }} width="100%">
                            {/* <thead>
                                <tr>
                                    {(selectedCompanyDetailed.format || []).map(f => <th key={'fth-' + f.title} style={{ border: "1px solid black", borderCollapse: "collapse", backgroundColor: "#71d5f5" }}>{f.title}</th>)}
                                </tr>
                            </thead> */}
                            <tbody>
                                {/* <tr>
                                    {(selectedCompanyDetailed.format || []).map((f, idx) => {
                                        const cand = candidateFormats[candidate.candidateId || candidate.id];
                                        return <td key={'ftd-' + candidate.candidateId + idx + f.id} style={{ border: "1px solid black", borderCollapse: "collapse" }}>{cand?cand[f.id]:''}</td>
                                    })}
                                </tr> */}
                                {(selectedCompanyDetailed.format || []).map((f, idx) => {
                                    const cand = candidateFormats[candidate.candidateId || candidate.id];
                                    return <tr key={'cand-f-' + f.id + idx}>
                                        <th style={{ border: "1px solid black", borderCollapse: "collapse", backgroundColor: "#71d5f5" }}>{f.title}</th>
                                        <td key={'ftd-' + candidate.candidateId + idx + f.id} style={{ border: "1px solid black", borderCollapse: "collapse" }}>{cand ? cand[f.id] : ''}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>

                        <div className="mt-1">
                            <div className="input-group">
                                <span className="input-group-text">Comments</span>
                                <textarea className="form-control" aria-label="With textarea" onKeyUp={(evt) => setComments(evt.currentTarget.value.trim())} defaultValue={comments}></textarea>
                            </div>
                        </div>
                        <div className="mt-1 d-flex justify-content-between">
                            {
                                currentState == "pending-assign"
                                    ? <button
                                        className="btn btn-primary"
                                        disabled={loading || !(!!!candidate.noFormatData && !!candidate.tl_rating && allCheckListItemsSelected && currentState == "pending-assign")}
                                        onClick={assign}
                                    >Assign</button>
                                    : ""}
                            {
                                (currentState == "assigned" || currentState == "aligned")
                                    ? <button
                                        className="btn btn-success"
                                        // disabled={loading || currentState == "aligned" || currentState == "selected" || currentState == "pending-assign"}
                                        onClick={() => addToAlign(candidate)}
                                    >Align</button>
                                    : ""}

                            <select className='form-select' onChange={(evt) => _updateFinalStatus(evt.target.value as FinalStatusType)}>
                                <option value={''}>Select Status</option>
                                {FINAL_STATUS_FIELDS.map((f: OptionLabelValueInterface) => <option key={f.value} value={f.value}>{f.label}</option>)}
                            </select>
                            {/* {
                                (currentState == "assigned" || currentState == "aligned")
                                    ? <button className="btn btn-danger"
                                    // disabled={loading || !!!comments || currentState == "pending-assign"}
                                    >Update</button>
                                    : ""
                            } */}
                        </div>
                    </div>
                </div>


            </div>
            <div className="modal-footer">
                {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                {/* <button type="button" className="btn btn-primary" onClick={this.sendToCoordniator} disabled={loading || !selectedCompanies.length || candidate.id == -1 || !!!formatDataCandidates[candidate.id]}>Assign</button> */}
            </div>
        </div>
    </div >)
}

export default CoordinationReviewModal;