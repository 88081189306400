import { API } from "aws-amplify";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { COMPANY_TYPES } from "../../types";
import { REST_API_NAME, REST_API_PATHS } from "../../utils";
import styles from './GridReport.module.css';


const GridReport = () => {



    const [reports, setReports] = useState([] as any)
    const [MAX_DAYS, setMAX_DAYS] = useState(31)
    const [rows, setRows] = useState([])
    const [headers, setHeaders] = useState([])
    const [companyType, setCompanyType] = useState<typeof COMPANY_TYPES[0]>(COMPANY_TYPES[0]);
    const [month, setMonth] = useState<number>((new Date()).getMonth() + 1);
    const [year, setYear] = useState<number>((new Date()).getFullYear());
    const [attributeToShow, setAttributeToShow] = useState<"sourced" | "assigned" | "aligned" | "selected" | "rejected" | "hold" | "all">("sourced");



    // let headers = [<th>Name</th>];
    // let cells = [];
    // for (let i = 0; i < MAX_DAYS; i++) {
    //     headers.push(<th key={'th-' + i} className={styles['cell']}>{i + 1}</th>)
    //     // cells.push(<td className="cell"></td>)
    // }

    // let rows: any = [];
    const getCol = (s: number, a: number, f: number) => {

        if (s > a || a > f) { return 'yellow' }
        else if (s === a && a >= f) { return 'orange' }
        else if (s === a && a === f) { return 'green' }


        if (s === a && a != f) { return 'yellow' }
        else if (s > a) { return 'orange' }
        else if (s === a && a > f) { return 'brown' }
        else if (a == f) { return 'green' }
    }



    const getData = async () => {
        const type = companyType.label;
       
        const { result } = await API.get(REST_API_NAME, REST_API_PATHS.REPORTS.GRID + `/${type}/${month}/${year}`, {})
        setReports(result.records);
        const MAX_DAYS = dayjs(`${year}-${month}-11`).daysInMonth();
        setMAX_DAYS(MAX_DAYS)

        let kk: any = {};
        const _rows: any = [];

        result.records.map((s: any) => {
            kk[s.name] = kk[s.name] || {
                company: s.name,
                rows: {}
            };
            const dt = s.dt.split('T')[0]
            kk[s.name].rows[dt] = kk[s.name].rows[dt] || s;
        })

        const NEW_DATA_ARR = Object.values(kk);

        NEW_DATA_ARR.map((d: any) => {
            let cols = []
            cols.push(<td>{d.company}</td>);
            let rowTotal = 0;
            let rowTotalAll = { sourced: 0, assigned: 0, aligned: 0, feedback: 0, selected: 0, rejected: 0, hold: 0 }
            for (let i = 0; i < MAX_DAYS; i++) {
                const dayKey = `${year}-${month < 10 ? '0' + month : month}-` + ((i + 1) < 10 ? '0' + (i + 1) : i).toString() as any;

                // const attributeToShow = attriubteToShow
                let val = d.rows[dayKey] ? d.rows[dayKey][attributeToShow] : '';
                val = val == 0 ? '' : val;
                rowTotal += val == '' ? 0 : Number(val);

                if (d.rows[dayKey]) {
                    rowTotalAll.sourced += +d.rows[dayKey].sourced;
                    rowTotalAll.assigned += +d.rows[dayKey].assigned;
                    rowTotalAll.aligned += +d.rows[dayKey].aligned;
                    rowTotalAll.feedback += +d.rows[dayKey].feedback;
                    rowTotalAll.selected += +d.rows[dayKey].selected;
                    rowTotalAll.rejected += +d.rows[dayKey].rejected;
                    rowTotalAll.hold += +d.rows[dayKey].hold;
                }

                cols.push(<td key={'td-' + dayKey + d.company} className={styles['cell']} style={{ background: val != '' ? getCol(d.rows[dayKey].sourced, d.rows[dayKey].aligned, d.rows[dayKey].feedback) : 'white' }}>
                    <span className={attributeToShow !== "all" ? "" : "d-none"}>{val}</span>
                    {d.rows[dayKey] ? <table className={attributeToShow === "all" ? "table-bordered w-100" : "d-none"}>
                        <tbody>
                            <tr>
                                <td>S</td>
                                <td>{d.rows[dayKey].sourced}</td>
                            </tr>
                            <tr>
                                <td>As</td>
                                <td>{d.rows[dayKey].assigned}</td>
                            </tr>
                            <tr>
                                <td>Al</td>
                                <td>{d.rows[dayKey].aligned}</td>
                            </tr>
                            <tr>
                                <td>F</td>
                                <td>{d.rows[dayKey].feedback}</td>
                            </tr>
                            <tr>
                                <td>Se</td>
                                <td>{d.rows[dayKey].selected}</td>
                            </tr>
                            <tr>
                                <td>R</td>
                                <td>{d.rows[dayKey].rejected}</td>
                            </tr>
                            <tr>
                                <td>H</td>
                                <td>{d.rows[dayKey].hold}</td>
                            </tr>
                        </tbody>

                    </table> : ''}
                </td>)
            }

            const conversion=100 * rowTotalAll.selected / rowTotalAll.sourced;
            if (attributeToShow === "all") {
                cols.push(<td><table className={attributeToShow === "all" ? "" : "d-none"}>
                    <tbody>
                        <tr>
                            <td>S</td>
                            <td>{rowTotalAll.sourced}</td>
                        </tr>
                        <tr>
                            <td>As</td>
                            <td>{rowTotalAll.assigned}</td>
                        </tr>
                        <tr>
                            <td>Al</td>
                            <td>{rowTotalAll.aligned}</td>
                        </tr>
                        <tr>
                            <td>F</td>
                            <td>{rowTotalAll.feedback}</td>
                        </tr>
                        <tr>
                            <td>Se</td>
                            <td>{rowTotalAll.selected}</td>
                        </tr>
                        <tr>
                            <td>R</td>
                            <td>{rowTotalAll.rejected}</td>
                        </tr>
                        <tr>
                            <td>H</td>
                            <td>{rowTotalAll.hold}</td>
                        </tr>
                        <tr>
                            <td>%</td>
                            <td>{isNaN(conversion)?0:conversion.toFixed(2)}</td>
                        </tr>
                    </tbody>

                </table></td>)
            } else {
                cols.push(<td>{rowTotal}</td>)
                cols.push(<td>{isNaN(conversion)?0:conversion.toFixed(2)}</td>)
            }
            _rows.push(<tr key={'r-' + d.company}>{cols}</tr>)
        })
        setRows(_rows);
        let h: any = [<th>Name</th>];
        for (let i = 0; i < MAX_DAYS; i++) {
            h.push(<th key={'th-' + i} className={styles['cell']}>{i + 1}</th>)
            // cells.push(<td className="cell"></td>)
        }
        h.push(<th key={'th-' + 32} className={styles['cell']}>Total</th>)
        h.push(<th key={'th-' + 33} className={styles['cell']}>%</th>)

        setHeaders(h)
    }

    const months = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


    return (<div className='my-5'>
        <div className="btn-toolbar mt-1" role="toolbar" aria-label="Toolbar with button groups">

            <div className="btn-group me-2" role="group">
                <button id="btnGroupDrop1" type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    {companyType.label}
                </button>
                <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                    {COMPANY_TYPES.map((company, idx) => <li key={'ctl-' + idx} onClick={() => setCompanyType(company)}>
                        <a className="dropdown-item" href="#" onClick={(evt) => evt.preventDefault()}>{`${company.label}`}</a>
                    </li>)}
                </ul>
            </div>
            <div className="btn-group me-2" role="group">
                <button id="btnGroupDrop1" type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    {months[month]}
                </button>
                <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                    {months.map((month, idx) => <li key={'ctlm-' + idx} onClick={() => setMonth(idx)}>
                        <a className="dropdown-item" href="#" onClick={(evt) => evt.preventDefault()}>{`${month}`}</a>
                    </li>)}
                </ul>
            </div>
            <div className="btn-group me-2" role="group">
                <button id="btnGroupDrop1" type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    {year}
                </button>
                <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                    {[2024, 2023, 2022, 2021].map((year, idx) => <li key={'ctlm-' + idx} onClick={() => setYear(year)}>
                        <a className="dropdown-item" href="#" onClick={(evt) => evt.preventDefault()}>{`${year}`}</a>
                    </li>)}
                </ul>
            </div>
            <div className="input-group me-3">
                <button className="btn btn-primary" type="button" onClick={getData}>Get Data</button>
            </div>
        </div>

        <br />
        <div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="attriubteToShow" id="inlineRadio1" value="option1" onClick={() => setAttributeToShow("sourced")} checked={attributeToShow === "sourced"} />
                <label className="form-check-label" htmlFor="inlineRadio1">Sourced</label>
            </div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="attriubteToShow" id="inlineRadio2" value="option2" onClick={() => setAttributeToShow("assigned")} checked={attributeToShow === "assigned"} />
                <label className="form-check-label" htmlFor="inlineRadio2">Assigned</label>
            </div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="attriubteToShow" id="inlineRadio3" value="option3" onClick={() => setAttributeToShow("aligned")} checked={attributeToShow === "aligned"} />
                <label className="form-check-label" htmlFor="inlineRadio3">Aligned</label>
            </div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="attriubteToShow" id="inlineRadio4" value="option4" onClick={() => setAttributeToShow("selected")} checked={attributeToShow === "selected"} />
                <label className="form-check-label" htmlFor="inlineRadio4">Selected</label>
            </div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="attriubteToShow" id="inlineRadio6" value="option4" onClick={() => setAttributeToShow("rejected")} checked={attributeToShow === "rejected"} />
                <label className="form-check-label" htmlFor="inlineRadio6">Rejected</label>
            </div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="attriubteToShow" id="inlineRadio7" value="option4" onClick={() => setAttributeToShow("hold")} checked={attributeToShow === "hold"} />
                <label className="form-check-label" htmlFor="inlineRadio7">Hold</label>
            </div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="attriubteToShow" id="inlineRadio5" value="option5" onClick={() => setAttributeToShow("all")} checked={attributeToShow === "all"} />
                <label className="form-check-label" htmlFor="inlineRadio5">All</label>
            </div>
        </div>
        <table className="table-bordered">
            <thead>
                <tr>
                    {headers}
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>

        </table>

    </div>)


}
export default GridReport;