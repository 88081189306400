export interface GenericDataNameInterface {
    id: number
    name: string
}

export type EmployeeRoleType = "recruiter" | "teamleader" | "coordinator" | "manager"

export interface EmployeeInterface {
    id: number
    sub: string
    name: string
    number: string
    email: string
    personal_email: string
    role: EmployeeRoleType
    team_id:number
    is_deleted?: boolean
    groups: string[]
    office: string
    personal: string
    doj: string
    salary: number
    target: number
    docs: string
    wfh: boolean;
    network: string;
    entity: string
    ir_sent: boolean
    ir_sent_acknowledgement: boolean
    disclaimer_sent: boolean
    disclaimer_sent_acknowledgement: boolean
    employment_type: "PartTime" | "FullTime";

}

export interface RoleInterface {
    id: number
    role_name: string
}

export interface CompanyInterface {
    id: number
    name: string
    type: string
    // hr_name:string
    // hr_number:string
    // hr_email:string
    // hr_manager_name:string
    // hr_manager_number:string
    // hr_manager_email:string
    contacts: ContactInterface[]
    amount: number
    actual_amount: number
    ctc_percent: number
    payment_period: number
    registration_link: string
    checkList?: JDCheckListInterface[];
    format?:{id:number,title:string}[]
}

export interface EmailInterface {
    id: number
    email: string
    reference_table: string
    reference_id: string
}
export interface PhoneInterface {
    id: number
    phone: string
    reference_table: string
    reference_id: string
}
export interface ContactInterface {
    id: number
    name: string
    title: string
    reference_table: string
    reference_id: string
    emails: EmailInterface[]
    phones: PhoneInterface[]
}

export interface CandidateLeadInterface {
    id: number
    name: string
    number: string
    email: string
    cvKey: string
    identityId: string //needed to retrive private s3 files
    rating: number
    comment: string
    industry: string
    employee_name: string
    employee_sub: string
    source: string
    roles: string[]
    created_by: string
    created_on: string
}
export interface CandidateInterface {
    id: number
    name: string
    number: string
    email: string
    cvKey: string
    identityId: string //needed to retrive private s3 files
    rating: number
    tl_rating: number
    comment: string
    recruiter_name: string
    recruiter_sub: string
    team_leader_name: string
    team_leader_sub: string
    source: string
    roles: string[]
    created_by: string
    created_on: string
    tags: any
    tag_list: OptionLabelValueInterface[]
    workLocations: any
}
export interface CandidateWithCompanies extends CandidateInterface {
    companies: string
    companies_list: OptionLabelValueInterface[]
}
export interface CoordinationFormatInterface {
    id: number
    name: string
    company_id: number

}

export interface FormatRowInterface {
    id: number
    coloumn_title: string
    mapping_table: string
    mapping_coloumn: string
}

export interface OptionLabelValueInterface {
    label: string
    value: string
}

export interface CoordinationInterface extends CandidateInterface {
    companies: OptionLabelValueInterface[]
}

export type FinalStatusType = "Accept" | "Reject" | "Select" | "Drop" | "Hold" | "Duplicate" | "Status Not Set";


export interface CoordinationTrackerInterface extends CoordinationInterface {


    sentId: number;
    hr_name: string;
    hr_feedback: string;
    company: string
    companyId: string
    candidateId: string
    created_by_name: string
    assigned_on: string
    assigned_by: string
    aligned_on: string
    aligned_by: string
    aligned_by_name: string
    assignedOn: string
    createdByName: string
    final_status: FinalStatusType
    final_status_date: string
    final_status_feedback: string
    cvExists?: boolean
    doj: string;
    join_month: string;

    offer_status: string;
    invoice_amount: number;
    invoice_amount_actual: number;
    invoice_sent: boolean;
    applicant_id: string;
    review: string;
    points: number;

    selected_in_company: number

    noFormatData: 1 | 0;
    candidateCompanyId:number
    candidateAssigedId:number
    status:FinalStatusType
    coordinationId: number;


}

export interface JobInterface {

    id: number
    company_id: number
    tags: string
    image_key: string
    designation: string
    communication: string
    title: string
    qualification: string
    experience: string
    jd: string
    salary: string;
    shift: string;
    location: string;
    work_type: string;
    is_active: 1 | 0

}


export interface CoordinationTrackerInterfaceWithCandidateInfo extends CoordinationTrackerInterface {
    candidate_created_on: string
    candidate_created_by: string
}
export enum MAIL_SENT_STATUS {
    MAIL_NOT_SENT = 0,
    MAIL_SENT = 1,
    MAIL_SENT_AND_NOT_SENT = 2
}


export const CANDIDATE_TAGS = [
    { label: 'FrontEnd', value: 'FrontEnd' },
    { label: 'BackEnd', value: 'BackEnd' },
    { label: 'FullStack', value: 'FullStack' },

    { label: 'JS', value: 'JS' },
    { label: 'React', value: 'React' },
    { label: 'Vue', value: 'Vue' },
    { label: 'Angular', value: 'Angular' },

    { label: 'Java', value: 'Java' },
    { label: 'PHP', value: 'PHP' },
    { label: 'Python', value: 'Python' },
    { label: 'Ruby', value: 'Ruby' },

    { label: 'BPO', value: 'Bpo' },
    { label: 'NonVoice', value: 'NonVoice' },
    { label: 'Voice', value: 'Voice' },
    { label: 'Sales', value: 'Sales' },

    { label: 'Digital Marketing', value: 'Digital Marketing' },
    { label: 'Social Media Manager', value: 'Social Media Manager' },
]

export const JOB_TAGS = [
    { label: 'IT', value: 'IT' },
    { label: 'Non IT', value: 'NonIT' },
    { label: 'BPO', value: 'BPO' },
    { label: 'Voice', value: 'Voice' },
    { label: 'Non Voice', value: 'NonVoice' },
    { label: 'International Voice', value: 'InternationalVoice' },
    { label: 'Semi Voice', value: 'SemiVoice' },

    { label: 'Work From Home Until Further Notice', value: 'WFH' },
    { label: 'Work From Office', value: 'WFO' },
    { label: 'Hybrid Work Model', value: 'HybridWorkModel' },

    { label: 'Tech Support', value: 'TechSupport' },
    { label: 'Customer Support', value: 'CustomerSupport' },

    { label: 'Interior Designer', value: 'InteriorDesigner' },
    { label: 'Coding Teacher', value: 'CodingTeacher' },
    { label: 'Healthcare', value: 'Healthcare' },
    { label: 'Retail', value: 'Retail' },
    { label: 'Front Office Executive', value: 'FrontOfficeExecutive' },
    { label: 'Receptionist', value: 'Receptionist' },

    { label: 'Day Shift', value: 'DayShift' },
    { label: 'Night Shift', value: 'NightShift' },
    { label: 'US Shift', value: 'USShift' },
    { label: 'UK Shift', value: 'UKShift' },
    { label: 'Australia Shift', value: 'AustraliaShift' },
    { label: 'Rotational Shift', value: 'RotationalShift' },
    { label: 'Rotational Off', value: 'RotationalOff' },

    { label: 'Bangalore', value: 'Bangalore' },
    { label: 'Chennai', value: 'Chennai' },
    { label: 'Hyderabad', value: 'Hyderabad' },
    { label: 'Kolkata', value: 'Kolkata' },
    { label: 'Gurgaon', value: 'Gurgaon' },


    { label: 'Sales International & Domestic', value: 'Sales' },
    { label: 'Inside Sales, Pre Sales, Soft Sales', value: 'InsideSales' },
    { label: 'Field Sales', value: 'FieldSales' },
    { label: 'Cold Calling Sales', value: 'ColdCallingSales' },




    { label: 'Above 40 Years', value: 'Above40years' },
    { label: 'Crypto Currency', value: 'CryptoCurrency' },
    { label: 'Graduates Only', value: 'GraduatesOnly' },
    { label: 'Graduates', value: 'Graduates' },
    { label: 'Graduate With Experience', value: 'GraduateWithExperience' },
    { label: 'Under Graduates', value: 'UnderGraduates' },
    { label: 'Under Graduate Fresher', value: 'UnderGraduateFresher' },
    { label: 'Under Graduate With Experience', value: 'UnderGraduateExperienced' },
    { label: 'Fresher', value: 'Fresher' },
    { label: '10th Pass', value: '10thPass' },
    { label: '12th Pass', value: '12thPass' },
    { label: 'Weekend Off', value: 'WeekendOff' },
    { label: 'Domestic Process', value: 'DomesticProcess' },
    { label: 'Healthcare', value: 'Healthcare' },


    { label: 'No Career Gaps', value: 'NoGaps' },

    { label: 'Hindi Mandatory', value: 'Hindi' },
    { label: 'Gujarati Mandatory', value: 'Gujarati' },
    { label: 'Kannada Mandatory', value: 'Kannada' },
    { label: 'Malayalam Mandatory', value: 'Malayalam' },
    { label: 'Tamil Mandatory', value: 'Tamil' },
    { label: 'Telugu Mandatory', value: 'Telugu' },
    { label: 'Arabic Mandatory', value: 'Arabic' },

    { label: 'Regional Languages', value: 'RegionalLanguages' },

    { label: 'Laptop Mandatory', value: 'LaptopMandatory' },
    { label: 'No BE or Btech', value: 'NoBEorBtech' },

    { label: 'Females Only', value: 'FemalesOnly' },

    { label: 'Ops Managers', value: 'OpsManager' },
    { label: 'Team Leaders', value: 'TeamLeader' },

    { label: 'Lateral/ Above Agent Level Roles', value: 'Lateral' },

    { label: 'HR & Admin Roles', value: 'HRAndADMIN' },

    { label: 'BFSI/ Finance', value: 'BFSIOrFinance' },

    { label: 'Sales And Marketing', value: 'Sales-And-Marketing' },
    { label: 'Social Media Marketing/ Growth Hacker', value: 'smm-growth-hacker' },
    { label: 'Digital Marketing', value: 'digital-marketing' },



]

export const WORK_LOCATIONS = [
    { label: 'WFH', value: 'WFH' },
    { label: 'WFO', value: 'WFO' },
    { label: 'Hybrid', value: 'Hybrid' },
    { label: 'Bangalore', value: 'Bangalore' },
    { label: 'Chennai', value: 'Chennai' },
    { label: 'Hyderabad', value: 'Hyderabad' },
    { label: 'Gurgaon', value: 'Gurgaon' },
    { label: 'Willing To Relocate', value: 'WillingToRelocate' }
];

export type CoordinationFilterStatusType = "not-selected" | "pending-assign" | "assigned" | "aligned" | "selected";

export interface CompanyCountInterface extends CompanyInterface {
    disabled: boolean
    cnt: number
    name: string
    id: number
    companyId: number
}

export type GenericObjectType = { [key: string]: any }

export interface JDCheckListInterface {
    id: number,
    item: string
    selected: boolean
};

export enum COMPANY_TYPE_ENUM {
    'BPO' = 'BPO',
    'Non BPO' = 'Non BPO',
    'IT' = 'IT',
    'BFSI' = 'BFSI'
}

type COMPANY_TYPES_TYPE = { label: COMPANY_TYPE_ENUM, value: COMPANY_TYPE_ENUM }

export const COMPANY_TYPES: COMPANY_TYPES_TYPE[] = [
    { label: COMPANY_TYPE_ENUM.BPO, value: COMPANY_TYPE_ENUM.BPO },
    { label: COMPANY_TYPE_ENUM["Non BPO"], value: COMPANY_TYPE_ENUM["Non BPO"] },
    { label: COMPANY_TYPE_ENUM.IT, value: COMPANY_TYPE_ENUM.IT },
    { label: COMPANY_TYPE_ENUM.BFSI, value: COMPANY_TYPE_ENUM.BFSI}
]


export type COMPANY_TYPE_TYPE = 'BPO' | 'Non BPO' | 'IT' | 'BFSI'

export enum UPDATE_FREQUENCY{
    'DAILY',
    'WEEKLY',
    'FORTNIGHTLY'
}


export interface TeamInterface{
    id:number
    name:string
    tl_sub: string
    tl_name: string

}


export interface CallerInterface{
    id: number
    name: string
    number: string
    email: string
    comment: string
    recruiter_name: string
    recruiter_sub: string
    source: string
    roles: string[]
    created_by: string
    created_on: string
    tags: any
    tag_list: OptionLabelValueInterface[]
    workLocations: any
}