import API from "@aws-amplify/api";
import dayjs from "dayjs";
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { promises } from "fs";
import React from "react";
import { formatEmployeeName, isSuperAdmin, REST_API_NAME } from "../utils";

import List from "list.js"
import {MonthSelector} from "../components/MonthSelector/MonthSelector";

// import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import DatePicker from 'react-date-picker';

dayjs.extend(weekOfYear)

class CompaniesAssigned extends React.Component<any, any> {


    recruiterPerformanceList: List | null = null;
    contributionList: List | null = null


    constructor(props: any) {
        super(props);
        const dt = new Date();
        this.state = {
            loading: true,
            selectMonth: dt.getMonth() + 1,
            selectYear: dt.getFullYear(),
            reports: {
                candidatesCreated: [],
                candidatesSuccessPercent: [],
                candidatesAlignedFeedback: [],
                resultSuccessRecruiter: []
            },
            //page
            fromDate: new Date(),
            toDate: new Date(),
            companiesAssigned: [],
            contribution: []
        }
    }

    async componentDidMount() {
        this.getData();


    }

    timeout: any

    getData = async () => {
        const { fromDate, toDate } = this.state;
        try {

            Promise.all([
                // API.get(REST_API_NAME, `/candidates/reports/created/${selectMonth}/${selectYear}`, {}),
                API.get(REST_API_NAME, `/candidates/reports/assigned/${dayjs(fromDate).format('YYYY-MM-DD')}/${dayjs(toDate).format('YYYY-MM-DD')}`, {}),
                API.get(REST_API_NAME, `/candidates/reports/contribution/${dayjs(fromDate).format('YYYY-MM-DD')}/${dayjs(toDate).format('YYYY-MM-DD')}`, {}),

            ])
                .then(([resultCompaniesAssigned, resultContribution]) => {



                    this.setState({
                        loading: false,
                        companiesAssigned: resultCompaniesAssigned.result,
                        contribution: resultContribution.result

                    });

                    const timer = setInterval(() => {
                        const ele = document.querySelector('#table-recruiter-performance .list');
                        if (ele && ele.children.length) {
                            clearInterval(timer)
                            if (!this.recruiterPerformanceList) {
                                let options = {
                                    valueNames: ['name', 'company', 'count']
                                };
                                this.recruiterPerformanceList = new List(`table-recruiter-performance`, options);
                            }
                        }
                    }, 1000)

                    setTimeout(() => {

                        // let options = {
                        //     valueNames: ['name', 'company', 'count']
                        // };
                        // if (!this.recruiterPerformanceList) {
                        //     this.recruiterPerformanceList = new List(`table-recruiter-performance`, options);
                        // }

                        // let options1 = {
                        //     valueNames: ['name', 'count', 'selects', 'percent']
                        // };

                        // if (!this.contributionList) {
                        //     this.contributionList = new List(`table-contribution`, options1)
                        // }
                        // this.recruiterPerformanceList!.sort && this.recruiterPerformanceList!.sort('count', { order: 'desc' })
                        // this.contributionList!.sort && this.contributionList!.sort('percent', { order: 'desc' })

                    }, 2000)



                })



        } catch (e) {
            console.log('Error', e);
            this.setState({ loading: false })
        }

    }

    componentWillUnmount() {
        clearTimeout(this.timeout)
    }

    selectMonth = (month: number) => {
        this.setState({ selectMonth: month })
    }
    selectYear = (year: number) => {
        this.setState({ selectYear: year })
    }

    setFromDate = (fromDate: Date) => this.setState({ fromDate })
    setToDate = (toDate: Date) => this.setState({ toDate })

    render() {

        const { fromDate, toDate, loading, companiesAssigned, contribution } = this.state

        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];



        return <div className='my-5'>

            <div className='container'>

                <div className='row'>
                    <div>
                        From
                        <DatePicker
                            onChange={this.setFromDate}
                            value={fromDate}
                        />
                        To
                        <DatePicker
                            onChange={this.setToDate}
                            value={toDate}
                        />
                    </div>

                    {/* <div>
                        Companies

                    </div> */}

                    <div className='col-lg-2 col-2'>
                        <button onClick={() => { this.getData() }} className='btn btn-primary' disabled={+toDate < +fromDate}>Filter</button>
                    </div>
                </div>
                <hr />
                <div className='row justify-content-center'>


                    <div className='col'>
                        <h4>Recruiter Performance</h4>
                        <div id={`table-recruiter-performance`}>


                            <table className='table table-bordered' >
                                <thead>
                                    <tr>
                                        <th>
                                            Recruiter
                                            <i className="bi bi-sort-alpha-down sort" data-sort="name"></i>
                                        </th>
                                        <th>
                                            Company
                                            <i className="bi bi-sort-alpha-down sort" data-sort="company"></i>
                                        </th>
                                        <th>
                                            Count
                                            <i className="bi bi-sort-alpha-down sort" data-sort="count"></i>
                                        </th>
                                        {/* <th>%
                                            <i className="bi bi-sort-alpha-down sort" data-sort="percent"></i>
                                        </th> */}
                                        {/* <th>Date</th> */}
                                    </tr>
                                </thead>
                                <tbody className="list">
                                    {companiesAssigned.map((c: any, idx: number) => <tr key={`${idx}-${c.recruiter_name}`}>
                                        <td>{c.recruiter_name}</td>
                                        <td>{c.name}</td>
                                        <td>{c.total}</td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div className='col'>
                        <h4>Contribution</h4>
                        <div id={`table-contribution`}>


                            <table className='table table-bordered' >
                                <thead>
                                    <tr>
                                        <th>
                                            Recruiter
                                            <i className="bi bi-sort-alpha-down sort" data-sort="name"></i>
                                        </th>
                                        <th>
                                            Total
                                            <i className="bi bi-sort-alpha-down sort" data-sort="count"></i>
                                        </th>
                                        <th>
                                            Selects
                                            <i className="bi bi-sort-alpha-down sort" data-sort="selects"></i>
                                        </th>
                                        <th>%
                                            <i className="bi bi-sort-alpha-down sort" data-sort="percent"></i>
                                        </th>
                                        {/* <th>Date</th> */}
                                    </tr>
                                </thead>
                                <tbody className="list">
                                    {contribution.map((c: any, idx: number) => <tr key={`${idx}-${c.recruiter_name}-${c.selects}`}>
                                        <td>{c.recruiter_name}</td>
                                        <td>{c.total}</td>
                                        <td>{c.selects}</td>
                                        <td>{c.contribution}</td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>

                    </div>




                </div>
            </div>
        </div>
    }


}


export default CompaniesAssigned;