import { useRef, useState } from "react";
import { CandidateInterface, CandidateWithCompanies, CompanyInterface, EmployeeInterface } from "../../types";
import { formatName, getFile, isSuperAdmin, isTeamLeader, SOURCES } from "../../utils";
import Select from "react-select";
import Storage from "@aws-amplify/storage";



interface CandidateFormPropsInterface {
    mode: 'add' | 'edit'
    recruiters: EmployeeInterface[]
    teamLeaders: EmployeeInterface[]
    companies: (CompanyInterface & { disabled: boolean })[]
    user: any
    addCandidate: (form: CandidateInterface) => void
    updateCandidate: (form: CandidateInterface) => void

}

const CandidateForm = (props: CandidateFormPropsInterface) => {

    const [form, setForm] = useState<CandidateWithCompanies>({} as CandidateWithCompanies);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedCompanies, setSelectedCompanies] = useState<any>([]);

    const inputPhoneRef = useRef<HTMLInputElement | null>(null);
    const inputEmailRef = useRef<HTMLInputElement | null>(null);
    const cvFileInputRef = useRef<HTMLInputElement | null>(null);

    const { mode, recruiters, teamLeaders, user, companies, addCandidate, updateCandidate } = props;

    const setField = (field: keyof CandidateInterface, value: string) => {
        setForm({ ...form, [field]: value })
    }

    const setFields = (fields: Partial<CandidateInterface>) => {
        setForm({ ...form, ...fields })
    }

    const uploadFile = async (file: File) => {
        console.log(file);
        if (!file) { return }
        const ts = +new Date();
        const userSub = props.user.attributes.sub;
        const key = userSub + '--' + ts + '-' + file.name.replace("'", "");
        const candidate = form;

        const { cvKey } = candidate;

        setLoading(true);


        try {
            // if (cvKey) {
            //     if (window.confirm('Are you sure you want to delete ths exisiting CV and add a new one?')) {
            //         await Storage.remove(cvKey, {
            //             level: 'protected',
            //         });
            //         console.log('Removed', cvKey)
            //         //@todo log removing file
            //         // const result = await API.post(REST_API_NAME, `/candidates/`, { body: { ...candidate, companies: selectedCompanies } });

            //     }
            // }

            const fileAdded = await Storage.put(key, file, {
                level: 'protected',
                progressCallback(progress) {
                    console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                },

            });
            console.log('Added', fileAdded)
            if (!fileAdded.key) {
                alert('Please uplaod the file again');
                return;
            }
            const identityId = props.user.identityId
            setForm({ ...form, identityId, cvKey: fileAdded.key })
            setLoading(false)

        } catch (error) {
            console.log('Error uploading file: ', error);
            alert('Error Uploading file')
            setLoading(false)

        }
    }

    const selectCompany = async (company: any) => {
        const c = company.pop();
        setSelectedCompanies({ selectedCompanies: c });

    }


    return (<div className="modal-dialog modal-xl">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{mode == 'edit' ? 'Update' : 'Add'} Candidate</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <div className='form'>
                    <form className=" g-3">

                        <div className='row'>
                            <div className='col-6'>
                                <div className="mb-3 row">
                                    <label htmlFor="inputName" className="col-sm-2 col-form-label">Name<span className='text-danger'>*</span></label>
                                    <div className="col-sm-10">
                                        <input type="text" maxLength={150} className="form-control" id="inputName" onBlur={(evt) => setField('name', formatName(evt.target.value))} defaultValue={form.name || ''} autoComplete='d off' />
                                    </div>
                                </div>


                                <div className="mb-3 row">
                                    <div className='col-6'>
                                        <div className="input-group mb1-3">
                                            <span className="input-group-text" id="basic-addon1"><i className="bi bi-telephone"></i><span className='text-danger'>*</span></span>
                                            <input type="text" className="form-control" id="inputNumber" ref={inputPhoneRef} onBlur={(evt) => setField('number', evt.target.value.trim())} defaultValue={form.number || ''} autoComplete='d off' />
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="input-group mb1-3">
                                            <span className="input-group-text" id="basic-addon1"><i className="bi bi-envelope"></i></span>
                                            <input type="email" maxLength={150} className="form-control" id="inputEmail" ref={inputEmailRef} onBlur={(evt) => setField('email', evt.target.value)} defaultValue={form.email || ''} />
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label htmlFor="inputCV" className="col-sm-2 col-form-label">CV<span className='text-danger'>*</span></label>
                                    <div className="col-sm-10">
                                        {
                                            mode == 'add'
                                                ? ''
                                                : <button type='button' onClick={() => { getFile(form); }} disabled={loading} className='btn btn-info btn-sm'>
                                                    View Existing CV<i className="bi bi-file-earmark-arrow-down"></i>
                                                </button>
                                        }
                                        <input type="file" className="form-control" id="inputCV" onChange={(evt) => uploadFile(evt.target.files![0])} ref={cvFileInputRef} defaultValue={form.cvKey} />
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label htmlFor="inputCompany" className="col-sm-2 col-form-label">Source<span className='text-danger'>*</span></label>
                                    <div className="col-sm-10">
                                        <select className="form-control" id="inputCompany" onChange={(evt) => { setFields({ source: evt.target.options[evt.target.selectedIndex].innerText }) }} value={form.source}  >
                                            <option></option>
                                            {SOURCES.map(s => <option key={'source-' + s}>{s}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                {/* <div className="mb-3 row">
                                    <label htmlFor="inputRating" className="col-sm-2 col-form-label">Rating<span className='text-danger'>*</span></label>
                                    <div className="col-sm-10">
                                        <input type="number" min={1} max={10} className="form-control" id="inputRating" onBlur={(evt) => setField('rating', evt.target.value)} defaultValue={form.rating} />
                                    </div>
                                </div> */}

                                <div className="mb-3 row">
                                    <div className='col-8'>
                                        <div className='row'>
                                            <label htmlFor="inputCompany" className="col-sm-3 col-form-label ">Recruiter<span className='text-danger'>*</span></label>
                                            <div className="col-sm-9">
                                                <Select
                                                    isMulti={false}
                                                    options={recruiters.map(r => ({ value: r.sub, label: r.name }))}
                                                    onChange={(val: any) => setFields({ recruiter_name: val.label, recruiter_sub: val.value })}
                                                    value={{ value: form.recruiter_sub, label: form.recruiter_name }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className='row'>
                                            <label htmlFor="inputRating" className="col-sm-4 col-form-label ">Rating<span className='text-danger'>*</span></label>
                                            <div className="col-sm-8">
                                                <input type="number" min={1} max={10} className="form-control" id="inputRating" onBlur={(evt) => setField('rating', evt.target.value)} defaultValue={form.rating} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <div className='col-6'>
                                        <div className='row'>
                                            <label htmlFor="inputCompany" className="col-sm-2 col-form-label ">TL<span className='text-danger'>*</span></label>
                                            <div className="col-sm-10">
                                                <Select
                                                    isMulti={false}
                                                    options={teamLeaders.map(r => ({ value: r.sub, label: r.name }))}
                                                    onChange={(val: any) => setFields({ team_leader_name: val.label, team_leader_sub: val.value })}
                                                    value={{ value: form.team_leader_sub, label: form.team_leader_name }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className='row'>
                                            {
                                                (isTeamLeader(props.user) || isSuperAdmin(props.user))
                                                    ? <>
                                                        <label htmlFor="inputRating" className="col-sm-6 col-form-label white-space-nowrap ">TL Rating</label>
                                                        <div className="col-sm-6">
                                                            <input type="number" min={1} max={10} className="form-control" id="inputRating" onBlur={(evt) => setField('tl_rating', evt.target.value)} defaultValue={form.tl_rating} />
                                                        </div>
                                                    </>
                                                    : ''
                                            }
                                        </div>
                                    </div>
                                </div>


                                <div className="mb-3 row">
                                    <label htmlFor="inputCompany" className="col-sm-2 col-form-label">Companies<span className='text-danger'>*</span></label>
                                    <div className="col-sm-10">
                                        <Select options={companies.map(c => ({ label: c.name, value: c.id + '' })) as any}
                                            isMulti={true}
                                            onChange={(e) => selectCompany([e] as unknown as CompanyInterface[])}
                                            value={selectedCompanies}
                                            isOptionDisabled={(opt) => companies.find(c => c.id == +opt.value)!.disabled}
                                            isDisabled={form.number == '' && form.email == ''}
                                        />
                                    </div>
                                </div>

                                <div className="mb-3 row">
                                    <label htmlFor="inputRating" className="col-sm-2 col-form-label">Comment</label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control" id="inputRating" onBlur={(evt) => setField('comment', evt.target.value)} defaultValue={form.comment} />
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                

                            </div>

                        </div>




                    </form>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" onClick={() => mode == 'edit' ? updateCandidate(form) : addCandidate(form)} disabled={loading}>{mode == 'edit' ? 'Update' : 'Save'}</button>
            </div>
        </div>
    </div>
    )
}


export default CandidateForm;