import API from "@aws-amplify/api";
import Storage from "@aws-amplify/storage";
import { Modal, Tab } from "bootstrap";
import dayjs from "dayjs";
import React from "react";
import { CandidateInterface, CompanyCountInterface, CompanyInterface, COMPANY_TYPE_ENUM, COMPANY_TYPE_TYPE, ContactInterface, CoordinationFilterStatusType, CoordinationTrackerInterface, EmailInterface, EmployeeInterface, FinalStatusType, FormatRowInterface, GenericObjectType, JDCheckListInterface, MAIL_SENT_STATUS, PhoneInterface } from "../types";
import { formatEmployeeName, isOnlyRecruiter, MONTHS, REST_API_NAME, REST_API_PATHS } from "../utils";

import { Editor } from '@tinymce/tinymce-react';


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import "react-datepicker/dist/react-datepicker.css";
import FilterCoordinationStateComponent from "../components/Coordination/FilterCoordinationStateComponent";


import { pdfjs } from "react-pdf";
import CoordinationReviewModal from "../components/Coordination/CoordinationReviewModal";
import { DateTimeDisplay } from "../components/MonthSelector/MonthSelector";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



interface CoordinationTrackerStateInterface {
    loading: boolean,
    coordinationTrackerDetails: CoordinationTrackerInterface[],
    filteredCandidates: CoordinationTrackerInterface[],

    candidate: CoordinationTrackerInterface,
    mode: 'add' | 'edit' | 'view'
    companies: CompanyCountInterface[]
    selectedCompanies: { label: string, value: string }[]
    // candidates: CandidateInterface[]
    startDateFilter: Date,
    endDateFilter: Date,
    filterCompany: CompanyInterface

    candidatesAligned: CoordinationTrackerInterface[]
    candidateToSendFormat: CoordinationTrackerInterface[]
    filter: any
    employees: EmployeeInterface[]
    waitignRoomFilterQuery: string,
    waitingRoomStatusFilter: "pending" | "completed",
    formatRows: FormatRowInterface[]
    formatData: any
    formatDataCandidates: any
    candidateFilter: any
    contacts: ContactInterface[]
    showDuplicates: boolean
    sendLinksForCompanies: { company: string, link: string }[],
    companiesAssignedByRecruiter: CompanyInterface[],
    //new
    filterComapnyInDropdown: string
    filterState: CoordinationFilterStatusType,
    filterStatus: ({ label: FinalStatusType, value: FinalStatusType })[],
    selectedMonth: number
    selectedYear: number
    selectedCompany: CompanyCountInterface & CompanyInterface
    selectedCompanyDetailed: CompanyInterface
    allCompanies: CompanyInterface[]
    employeeHash: GenericObjectType
    cvFilePath: string
    candidates: CoordinationTrackerInterface[],
    selectedCandidate: CoordinationTrackerInterface,
    checkList: JDCheckListInterface[]
    selectedCompanyType: COMPANY_TYPE_ENUM
    candidatesToAlign: CoordinationTrackerInterface[]
    selectedEmails: string[]
    emailSubject: string
    emailContent: string
    candidateFormats: any
    searchCriteria: string
    searchResults: {}[]
    searchResultsCandidates: {}[]
    searchResultsAssigned: {}[]
    selectedRecruiter: EmployeeInterface
    filterRecruiter: string




}

interface CoordinationTracker3Props { user: any, companies: CompanyInterface[], employees: EmployeeInterface[] }

class CoordinationTracker3 extends React.Component<CoordinationTracker3Props, CoordinationTrackerStateInterface>{

    sendCandidateToCompanyModal: any;
    sendCandidateToCompanyModalRef: any;

    toastRef: any
    toastEle: any

    formatModalRef: any
    formatModal: any

    addFormatDataModalRef: any;
    addFormatDataModal: any;

    addFormatDataTabsRef: any;
    addFormatDataTabs: any;

    sendCVEmailModalRef: any;
    sendCVEmailModal: any;

    cvViewModalRef: any;
    cvViewModal: any;

    alignModalRef: any;
    alignModal: any;

    searchCandidateRef: any;
    searchCandidateModal: any;

    editorRef: any;



    employeeHash: any;


    constructor(props: any) {
        super(props);
        const identityId = this.props.user.identityId
        const companies = this.props.companies
        const employees = this.props.employees

        let employeeHash: GenericObjectType = {};
        employees.map(e => employeeHash[e.sub] = e);

        const month = dayjs().month();
        const year = dayjs().year();

        this.state = {
            companies: [],
            coordinationTrackerDetails: [],
            filteredCandidates: [] as CoordinationTrackerInterface[],
            startDateFilter: new Date(year, month, 1),
            endDateFilter: new Date(year, month + 1, 1),
            candidate: {
                id: -1,
                name: '',
                number: '',
                email: '',
                cvKey: '',
                identityId,
            } as CoordinationTrackerInterface,
            mode: 'add',
            selectedCompanies: [],
            filterCompany: {} as CompanyInterface,

            candidatesAligned: [],
            candidateToSendFormat: [],
            filter: {},
            waitignRoomFilterQuery: '',
            waitingRoomStatusFilter: 'pending',
            formatRows: [],
            formatData: [],
            formatDataCandidates: {},
            candidateFilter: {},
            contacts: [] as ContactInterface[],
            showDuplicates: false,
            sendLinksForCompanies: [],
            companiesAssignedByRecruiter: [],
            //new
            loading: false,
            filterComapnyInDropdown: '',
            selectedMonth: (new Date()).getMonth() + 1,
            selectedYear: (new Date()).getFullYear(),
            selectedCompany: {} as any,
            filterState: 'not-selected',
            filterStatus: [{ label: 'Status Not Set', value: 'Status Not Set' }],
            selectedCompanyDetailed: {} as CompanyInterface,
            allCompanies: companies,
            employees: employees,
            employeeHash,
            cvFilePath: '',
            candidates: [] as CoordinationTrackerInterface[],
            selectedCandidate: {} as CoordinationTrackerInterface,
            checkList: [],
            selectedCompanyType: COMPANY_TYPE_ENUM.BPO,
            candidatesToAlign: [] as CoordinationTrackerInterface[],
            selectedEmails: [] as string[],
            emailSubject: 'One Degree North - Candidates - ' + dayjs().format('DD/MM/YYYY'),
            emailContent: '',
            candidateFormats: [],
            searchCriteria: '',
            searchResults: [],
            searchResultsCandidates: [],
            searchResultsAssigned: [],
            selectedRecruiter: {} as EmployeeInterface,
            filterRecruiter: ""


        }



        // this.sendCandidateToCompanyModalRef = React.createRef();
        // this.toastRef = React.createRef();
        // this.formatModalRef = React.createRef();
        // this.addFormatDataModalRef = React.createRef();
        // this.addFormatDataTabsRef = React.createRef();
        // this.sendCVEmailModalRef = React.createRef();

        this.cvViewModalRef = React.createRef();
        this.alignModalRef = React.createRef();
        this.addFormatDataTabsRef = React.createRef();
        this.editorRef = React.createRef();
        this.searchCandidateRef = React.createRef();




    }

    async componentDidMount() {

        // this.sendCandidateToCompanyModal = new Modal(this.sendCandidateToCompanyModalRef.current);
        // this.formatModal = new Modal(this.formatModalRef.current);
        // this.toastEle = new Toast(this.toastRef.current);

        // this.sendCVEmailModal = new Modal(this.sendCVEmailModalRef.current);

        // this.sendCVEmailModalRef.current.addEventListener('show.bs.modal', (event: any) => {

        //     console.log(this.state.candidatesAligned);
        //     const candidates = this.state.candidatesAligned;
        //     this.checkCVs(candidates)
        // })
        // this.sendCVEmailModalRef.current.addEventListener('hide.bs.modal', (event: any) => {
        //     this.setState({ candidatesAligned: [], candidateToSendFormat: [] })
        // })

        // this.addFormatDataModal = new Modal(this.addFormatDataModalRef.current);

        // this.addFormatDataModalRef.current.addEventListener('hidden.bs.modal', (event: any) => {
        //     this.addFormatDataTabs.dispose();
        // })
        // this.addFormatDataModalRef.current.addEventListener('shown.bs.modal', (event: any) => {
        //     this.addFormatDataTabs = new Tab(this.addFormatDataTabsRef.current.querySelector('li:last-child a'));
        // })



        // const checkUser = isSuperAdmin(this.props.user);
        // const month = dayjs().format('MMM');

        // // toast.info('Tracker 2');


        // const { result } = await API.get(REST_API_NAME, `/coordination/masters/initial-v3/${month}`, {});
        // const resultAllCompanies = result.allCompanies;
        // const resultCompanies = result.companies;
        // const resultEmployees = result.employees;


        this.cvViewModal = new Modal(this.cvViewModalRef.current);
        this.alignModal = new Modal(this.alignModalRef.current);
        this.searchCandidateModal = new Modal(this.searchCandidateRef.current);


        var triggerTabList = [].slice.call(document.querySelectorAll('#myTab a'))
        triggerTabList.forEach(function (triggerEl: HTMLDivElement) {
            var tabTrigger = new Tab(triggerEl)

            triggerEl.addEventListener('click', function (event) {
                event.preventDefault()
                tabTrigger.show()
            })
        })


        const { filterState, selectedMonth, selectedYear, selectedCompanyType } = this.state;
        // this.getCompaniesForFilterState(filterState, selectedMonth, selectedYear, selectedCompanyType);
    }

    componentDidUpdate(prevProps: CoordinationTracker3Props) {
        if (this.props.employees !== prevProps.employees) {
            const employees = this.props.employees

            let employeeHash: GenericObjectType = {};
            employees.map(e => employeeHash[e.sub] = e);
            this.setState({
                employeeHash,
                employees
            })
        }
    }

    getCompaniesForFilterState = async (filterState: CoordinationFilterStatusType, selectedMonth: number, selectedYear: number, type: COMPANY_TYPE_TYPE) => {
        switch (filterState) {
            case "pending-assign": this.getPending(selectedMonth, selectedYear, type); break;
            case "assigned": this.getAssigned(selectedMonth, selectedYear, type); break;
            case "aligned": this.getAligned(selectedMonth, selectedYear); break;
            case "selected": this.getSelected(); break;
        }
    }

    getSelected = async () => {

        const { selectedMonth, selectedYear } = this.state;

        // if (waitingRoomStatusFilter === 'pending') {
        //get aligned candidates where aligned_on is null
        const monthAsString = MONTHS[selectedMonth]
        const resultCandidates: any = await API.get(REST_API_NAME, `/coordination/selected/1/${monthAsString}/${selectedYear}/1`, {});


        let candidates: CoordinationTrackerInterface[] = []
        // if (resultCandidates.result.length) {
        candidates = resultCandidates.result;
        // } else {

        // }




        this.setState({
            loading: false,
            candidates,
            candidatesAligned: []
        });

        // }

    }
    filterByCompany = async (filterCompany: CompanyInterface, _filterState?: CoordinationFilterStatusType) => {
        const { filterState, selectedMonth, selectedYear } = this.state;

        const status = !_filterState ? filterState : _filterState;
        // if (waitingRoomStatusFilter === 'pending') {
        //get aligned candidates where aligned_on is null
        let mailSentStatus: MAIL_SENT_STATUS = MAIL_SENT_STATUS.MAIL_NOT_SENT;
        const resultCandidates: any = await API.get(REST_API_NAME, `/coordination/${status}/${filterCompany.id}/${selectedMonth}/${selectedYear}/${mailSentStatus}`, {});
        const resultFormats: any = await API.get(REST_API_NAME, '/coordination/format-for-company/' + filterCompany.id, {});
        const resultContacts: any = await API.get(REST_API_NAME, '/companies/contacts-for-company/' + filterCompany.id, {});

        const contacts = resultContacts.result.map((contact: any) => {
            const phonesString = contact.phones || '';
            const emailsString = contact.emails || '';

            let phones: PhoneInterface[] = [];
            phonesString.split(',').map((c: string) => {
                const [phone, id] = c.split(':')
                phones.push({ id: +id, phone, reference_id: '', reference_table: '' })
            })
            let emails: EmailInterface[] = [];
            emailsString.split(',').map((c: string) => {
                const [email, id] = c.split(':')
                emails.push({ id: +id, email, reference_id: '', reference_table: '' })
            })
            contact.phones = phones;
            contact.emails = emails;


            return contact;
        })

        let formatDataCandidates: any = {};
        let candidates: CoordinationTrackerInterface[] = []
        if (resultCandidates.result.length) {
            const candidateIds = resultCandidates.result.map((c: any) => {

                if (c.final_status == 'select') {
                    const companyName = c.selected_in_company_name;
                    let str = ''
                    str = `(${companyName})`
                    c.final_status += ' ' + str;
                }
                candidates.push(c)

                return c.id
            }).join(',');
            const { data } = await API.get(REST_API_NAME, '/coordination/format-for-company-candidates/' + candidateIds + '/' + filterCompany.id, {});
            data.map((c: any) => formatDataCandidates[c.candidate_id] = 1);
        } else {

        }




        this.setState({
            filterCompany,
            formatRows: resultFormats.result.records,
            // candidates: resultCandidates.result,
            candidates,
            formatDataCandidates,
            contacts,
            candidatesAligned: []
        });

        // }

    }
    getData = async (filterState: string, month: string, year?: string) => {
        const selectedYear = year || (new Date().getFullYear())

        const { result } = await API.get(REST_API_NAME, `/coordination/masters/initial-v3/${month}?year=${selectedYear}`, {});
        const resultCompainies = result.companies;
        const resultEmployees = result.employees;

        this.setState({
            loading: false,
            // candidates: resultCandidates, 
            companies: resultCompainies,
            employees: resultEmployees
        })

    }

    getCandidates = async (filterState: CoordinationFilterStatusType, companyId: number, selectedMonth: number, selectedYear: number) => {
        this.setState({ loading: true })
        switch (filterState) {
            case "pending-assign": this.getPendingCandidate(companyId, selectedMonth, selectedYear); break;
            case "assigned": this.getAssignedCandidate(companyId, selectedMonth, selectedYear); break;
            case "aligned": this.getAlignedCandidate(companyId, selectedMonth, selectedYear); break;
            case "selected": this.getSelected(); break;
            case "not-selected": this.setState({ loading: false }); break
        }
    }

    //Gets Companies with counts with ppending Assign
    getPending = async (month: number, year: number, type: COMPANY_TYPE_TYPE) => {
        // const { result } = await API.get(REST_API_NAME, `/coordination/companies-pending-assign/${month}/${year}/${type}`, {})
        const toObj = dayjs().set('month', month - 1).set('year', year);
        const to = toObj.format('YYYY-MM-DD');
        const from = toObj.subtract(3, 'months').format('YYYY-MM-DD');
        const { result } = await API.get(REST_API_NAME, `/coordination/companies-pending-assign-range/${from}/${to}/${type}`, {})
        this.setState({
            loading: false,
            companies: result,
        })
    }
    getPendingCandidate = async (companyId: number, month: number, year: number) => {

        const toObj = dayjs().set('month', month - 1).set('year', year);
        const to = toObj.format('YYYY-MM-DD');
        const from = toObj.subtract(3, 'months').format('YYYY-MM-DD');

        // const { result } = await API.get(REST_API_NAME, `/coordination/candidates-pending-assign/${companyId}/${month}/${year}`, {})
        const { result } = await API.get(REST_API_NAME, `/coordination/candidates-pending-assign-range/${companyId}/${from}/${to}`, {})
        this.setState({
            loading: false,
            candidates: result
        })
    }
    getPendingCandidatesForRecruiter = async (recruiterSub: string, month: number, year: number) => {

        const toObj = dayjs().set('month', month - 1).set('year', year);
        const to = toObj.format('YYYY-MM-DD');
        const from = toObj.subtract(3, 'months').format('YYYY-MM-DD');

        // const { result } = await API.get(REST_API_NAME, `/coordination/candidates-pending-assign/${companyId}/${month}/${year}`, {})
        const { result } = await API.get(REST_API_NAME, `/coordination/candidates-pending-assign-range-for-recruiter/${recruiterSub}/${from}/${to}`, {})
        this.setState({
            loading: false,
            candidates: result
        })
    }
    _getPendingCandidatesForRecruiter = async () => {
        const { selectedRecruiter, selectedMonth, selectedYear } = this.state;

        const toObj = dayjs().set('month', selectedMonth - 1).set('year', selectedYear);
        const to = toObj.format('YYYY-MM-DD');
        const from = toObj.subtract(3, 'months').format('YYYY-MM-DD');

        // const { result } = await API.get(REST_API_NAME, `/coordination/candidates-pending-assign/${companyId}/${month}/${year}`, {})
        const { result } = await API.get(REST_API_NAME, `/coordination/candidates-pending-assign-range-for-recruiter/${selectedRecruiter.sub}/${from}/${to}`, {})
        this.setState({
            loading: false,
            candidates: result
        })
    }


    getCandidatesBySearch = async () => {
        const { filterState } = this.state;
        this.setState({ loading: true })
        switch (filterState) {
            case "pending-assign": this._getPendingCandidatesForRecruiter(); break;
            // case "assigned": this.getAssignedCandidateForRecruiter(companyId, selectedMonth, selectedYear); break;
            // case "aligned": this.getAlignedCandidate(companyId, selectedMonth, selectedYear); break;
            // case "selected": this.getSelected(); break;
            // case "not-selected": this.setState({ loading: false }); break
        }
    }

    //Gets Companies with counts with ppending Align
    getAssigned = async (month: number, year: number, type: COMPANY_TYPE_TYPE) => {
        const { result } = await API.get(REST_API_NAME, `/coordination/companies-pending-align/${month}/${year}/${type}`, {})
        this.setState({
            loading: false,
            companies: result,
        })
    }
    getAssignedCandidate = async (companyId: number, month: number, year: number) => {
        const { result } = await API.get(REST_API_NAME, `/coordination/candidates-pending-align/${companyId}/${month}/${year}`, {})
        this.setState({
            loading: false,
            candidates: result
        })
    }

    _getAssignedCandidatesForRecruiter = async () => {
        const { selectedRecruiter, selectedMonth, selectedYear } = this.state;

        const toObj = dayjs().set('month', selectedMonth - 1).set('year', selectedYear);
        const to = toObj.format('YYYY-MM-DD');
        const from = toObj.subtract(3, 'months').format('YYYY-MM-DD');

        // const { result } = await API.get(REST_API_NAME, `/coordination/candidates-pending-assign/${companyId}/${month}/${year}`, {})
        const { result } = await API.get(REST_API_NAME, `/coordination/candidates-pending-align-for-recruiter/${selectedRecruiter.sub}/${selectedMonth}/${selectedYear}`, {})
        this.setState({
            loading: false,
            candidates: result
        })
    }

    //Gets Companies with counts for  Aligned
    getAligned = async (month: number, year: number) => {
        const { result } = await API.get(REST_API_NAME, `/coordination/companies-aligned/${month}/${year}`, {})
        this.setState({
            loading: false,
            companies: result,
        })
    }
    getAlignedCandidate = async (companyId: number, month: number, year: number) => {
        const { result } = await API.get(REST_API_NAME, `/coordination/candidates-aligned/${companyId}/${month}/${year}`, {})
        this.setState({
            loading: false,
            candidates: result
        })
    }

    setFilter = (filterState: CoordinationFilterStatusType) => {
        this.setState({ filterState, candidates: [], companies: [], loading: true, selectedCompany: {} as CompanyCountInterface, selectedCandidate: {} as CoordinationTrackerInterface });
        const { selectedMonth, selectedYear, selectedCompanyType } = this.state;
        this.getCompaniesForFilterState(filterState, selectedMonth, selectedYear, selectedCompanyType)
    }
    selectMonth = (selectedMonth: number) => {
        this.setState({ selectedMonth });
        // this.getData(this.state.filterState, selectedMonth)
    }
    selectYear = (selectedYear: number) => {
        this.setState({ selectedYear });
        // this.getData(this.state.filterState, this.state.selectedMonth, selectedYear)
    }

    _selectCompany = (selectedCompany: CompanyCountInterface) => {
        Promise.all([
            API.get(REST_API_NAME, '/companies/by-id/' + selectedCompany.companyId, {}),
            API.get(REST_API_NAME, '/companies/contacts-for-company/' + selectedCompany.companyId, {})
        ])
            .then(([{ result: checkResult }, { result: contactsResult }]) => {
                const _c = (checkResult.checkList || '').split(',').map((s: string) => s.split(':'));
                let checkList = _c.map(([item, id]: any) => ({ item, id, selected: false }))
                let contacts: ContactInterface[] = [];
                contactsResult.map((contact: any) => {
                    let emails = (contact.emails || '').split(',').map((c: string) => {
                        const [email, id] = c.split(':');
                        return { email, id }
                    })
                    contact.emails = emails;

                    let phones = (contact.phones || '').split(',').map((c: string) => {
                        const [phone, id] = c.split(':');
                        return { phone, id }
                    })
                    contact.phones = phones;


                    contacts.push(contact)
                })
                let format: any = [];

                checkResult.format = checkResult.format === null ? "" : checkResult.format;
                (checkResult.format || "").split(',').map((s: string) => {
                    const [title, id] = s.split(':');
                    format.push({ id, title })
                })
                format.sort((a: any, b: any) => a.id - b.id)

                this.setState({
                    loading: false,
                    selectedCompany,
                    selectedCompanyDetailed: {
                        ...checkResult,
                        contacts,
                        label: checkResult.name,
                        value: checkResult.id,
                        invoiceAmount: checkResult.amount,
                        invoiceAmountActual: checkResult.actual_amount,
                        format
                    },
                    selectedEmails: [],
                    candidatesToAlign: [],
                    checkList
                });

            })
    }
    selectCompany = async (selectedCompany: CompanyCountInterface) => {
        const { filterState, selectedMonth, selectedYear } = this.state;

        this.setState({ loading: true })
        this.getCandidates(filterState, selectedCompany.companyId, selectedMonth, selectedYear);

        //Get all details of company. We need invoice amount etc
        // const { result } = await API.get(REST_API_NAME, '/companies/by-id/' + selectedCompany.companyId, {});
        // const { result:contactsResult } = await API.get(REST_API_NAME, '/companies/contacts-for-company/' + selectedCompany.companyId, {});

        Promise.all([
            API.get(REST_API_NAME, '/companies/by-id/' + selectedCompany.companyId, {}),
            API.get(REST_API_NAME, '/companies/contacts-for-company/' + selectedCompany.companyId, {})
        ])
            .then(([{ result: checkResult }, { result: contactsResult }]) => {
                const _c = (checkResult.checkList || '').split(',').map((s: string) => s.split(':'));
                let checkList = _c.map(([item, id]: any) => ({ item, id, selected: false }))
                let contacts: ContactInterface[] = [];
                contactsResult.map((contact: any) => {
                    let emails = (contact.emails || '').split(',').map((c: string) => {
                        const [email, id] = c.split(':');
                        return { email, id }
                    })
                    contact.emails = emails;

                    let phones = (contact.phones || '').split(',').map((c: string) => {
                        const [phone, id] = c.split(':');
                        return { phone, id }
                    })
                    contact.phones = phones;


                    contacts.push(contact)
                })
                let format: any = [];

                checkResult.format = checkResult.format === null ? "" : checkResult.format;
                (checkResult.format || "").split(',').map((s: string) => {
                    const [title, id] = s.split(':');
                    format.push({ id, title })
                })
                format.sort((a: any, b: any) => a.id - b.id)

                this.setState({
                    loading: false,
                    selectedCompany,
                    selectedCompanyDetailed: {
                        ...checkResult,
                        contacts,
                        label: checkResult.name,
                        value: checkResult.id,
                        invoiceAmount: checkResult.amount,
                        invoiceAmountActual: checkResult.actual_amount,
                        format
                    },
                    selectedEmails: [],
                    candidatesToAlign: [],
                    checkList
                });

            })
    }

    selectCompanyType = async (selectedCompanyType: COMPANY_TYPE_ENUM) => {
        const { filterState, selectedMonth, selectedYear } = this.state;
        this.setState({ selectedCompanyType });
        if (filterState === "not-selected") { return }
        this.setState({ loading: true });
        this.getCompaniesForFilterState(filterState, selectedMonth, selectedYear, selectedCompanyType);
    }

    setFillterStatus = (f: { label: FinalStatusType; value: FinalStatusType; }[]) => {
        this.setState({ filterStatus: f })
    }

    updateCandidateRow = () => {

    }


    _selectCandidate = async (selectedCandidate: CoordinationTrackerInterface, company?: CompanyCountInterface) => {
        const { cvKey, id, candidateId } = selectedCandidate;

        console.log('CS', selectedCandidate.assignedOn ? 'Yes' : 'No', selectedCandidate)
        // const filterState: CoordinationFilterStatusType = selectedCandidate.assignedOn != null ? "assigned" : 'pending-assign'
        // this.setState({
        //     filterState,
        // })


        const cvFilePath = await Storage.get(cvKey, { level: 'protected', identityId: selectedCandidate.identityId });

        //candidate Id wont be there if its pending asdign
        const formatRecords = await this.checkIfFormatExistsForCandidate(Number(candidateId || id), company)
        const dataExists = formatRecords.some((s: any) => !!s.value)

        let formatDataHashed: GenericObjectType = {};
        formatRecords.map((f: any) => {
            formatDataHashed[f.id] = f.value;
        })

        let key = candidateId || id;


        console.log('FS', this.state.filterState);
        this.setState({
            loading: false,
            selectedCompany: { id: +selectedCandidate.companyId, companyId: +selectedCandidate.companyId, name: selectedCandidate.company } as any,
            cvFilePath,
            // filterState,
            candidateFormats: {
                ...this.state.candidateFormats,
                [key]: formatDataHashed
            },
            selectedCandidate: { ...selectedCandidate, noFormatData: dataExists ? 0 : 1 }
        })

        // window.open(file)
        // console.log(file)
    }
    selectCandidate = async (selectedCandidate: CoordinationTrackerInterface) => {

        const company = { id: +selectedCandidate.companyId, companyId: +selectedCandidate.companyId, name: selectedCandidate.company } as any
        this._selectCompany(company);

        this._selectCandidate(selectedCandidate, company);

        this.cvViewModal.toggle();
        this.searchCandidateModal.hide();

    }

    selectCandidateFromSearch = (selectedCandidate: CoordinationTrackerInterface) => {
        //select canddiate
        this.selectCandidate(selectedCandidate);
        //select state
        // const state=selectedCandidate.assignedOn
        //selectcompany
        // this.setState({ selectedCompany: { id: +selectedCandidate.companyId, cnt:0 } })
        //select company type
        //close modal
    }

    nextCandidate = () => {
        const { candidates, selectedCandidate, filterStatus, filterState } = this.state;
        const statuses = filterStatus.map(f => f.value);

        // const currentIndex = candidates.findIndex((c) => c.id == selectedCandidate.id);
        // const nextCandidate = candidates[currentIndex + 1];


        let candidatesFiltered = candidates.filter(c => {

            if (filterState === "selected") { return true }
            const f = statuses.some(s => {
                const field = filterState === "pending-assign" ? "status" : "final_status";
                if (s === 'Status Not Set') {
                    return !!!c[field]
                } else {
                    return c[field] === s
                }
            })
            return f;
        })

        const currentIndex = candidatesFiltered.findIndex((c) => c.id == selectedCandidate.id);
        const nextCandidate = candidatesFiltered[currentIndex + 1];



        if (nextCandidate) {
            this._selectCandidate(nextCandidate)
        } else {
            toast.success('No Candidates left', { delay: 2000 });
            this.cvViewModal.hide();
            this.alignModal.hide();
            this.setState({ loading: false })

        }
    }
    previousCandidate = () => {
        const { candidates, selectedCandidate } = this.state;
        const currentIndex = candidates.findIndex((c) => c.id == selectedCandidate.id);
        const nextCandidate = candidates[currentIndex - 1];
        if (nextCandidate) {
            this._selectCandidate(nextCandidate)
        }
    }

    checkIfFormatExistsForCandidate = async (candidateId: number, company?: CompanyCountInterface) => {
        const { selectedCompany } = this.state;
        const selCompany = company ? company : selectedCompany;

        const { result: { records } } = await API.get(REST_API_NAME, '/coordination/format-for-candidate-company/' + candidateId + '/' + selCompany.companyId, {});
        return records ? records : [];
    }

    assign = async () => {
        this.setState({ loading: true })
        let { candidates, selectedCandidate, selectedCompanyDetailed, sendLinksForCompanies } = this.state;
        // candidate.companies = [selectedCompanyDetailed]


        const html = sendLinksForCompanies.length ? document.getElementById('reg-links-table')!.innerHTML : ''

        try {
            const result = await API.post(REST_API_NAME, `/coordination/send-to-coordinator`, {
                body: {
                    candidate: {
                        ...selectedCandidate,
                        companies: [selectedCompanyDetailed]
                    },
                    html
                }
            });
            if (result.success) {
                this.nextCandidate();

                // this.cvViewModal.toggle();

                this.setState({
                    candidates: candidates.filter(c => c.id != selectedCandidate.id),
                    // selectedCandidate: {} as CoordinationTrackerInterface,
                    // checkList: checkList.map(c => { c.selected = false; return c }),
                    // loading: false

                })
            }
            // this.sendCandidateToCompanyModal.toggle();
            // this.setState({
            //     loading: false,
            //     candidate: {} as CoordinationTrackerInterface,
            //     sendLinksForCompanies: []
            // })

            // const c = selectedCompanies.map(c => ({ ...candidate, company: c.label, companyId: c.value, sentId: result.results.find((r: any) => r.company == c.value).insertId }))

            // this.setState({
            //     loading: false,
            //     // candidate: {} as CoordinationTrackerInterface,
            //     sendLinksForCompanies: [],
            //     coordinationTrackerDetails: [...this.state.coordinationTrackerDetails, ...c],
            //     candidate: {
            //         id: -1,
            //         name: '',
            //         number: '',
            //         email: '',
            //         cvKey: '',
            //         identityId: this.props.user.identityId,
            //     } as CoordinationTrackerInterface,
            //     selectedCompanies: []
            // });
            toast.success('Saved Successfully');


        } catch (e) {
            console.log('Error', e);
            this.setState({ loading: false })
        }

    }

    addToAlign = (candidate: CoordinationTrackerInterface) => {
        this.checkIfCVExists(candidate).then(r => {
            console.log(r)
        })
        this.setState({ candidatesToAlign: [...this.state.candidatesToAlign, candidate] })
        this.nextCandidate();
    }

    selectEmail = (checked: boolean, email: string) => {
        let { selectedEmails } = this.state
        if (checked) {
            selectedEmails.push(email)
        } else {
            selectedEmails = selectedEmails.filter(e => e != email)
        }
        this.setState({ selectedEmails })
    }

    showAlignModal = () => {
        this.alignModal.show();
        const html = document.getElementById('table-format')?.innerHTML;
        const t = "<p>We've found these candidates suitable for your requirements.</p>"
        this.setState({ emailContent: t + html + '' })
    }

    setEmailSubject = (emailSubject: string) => this.setState({ emailSubject })
    setEmailContent = (emailContent: string) => this.setState({ emailContent })


    checkIfCVExists = async (candidate: CoordinationTrackerInterface) => {
        const result = await API.post(REST_API_NAME, '/candidates/check-cv/', {
            body: {
                key: 'protected/' + candidate.identityId + '/' + candidate.cvKey
            }
        })
        const success = result.success;
        if (!!!success) {
            toast.error(`Please upload a cv for ${candidate.name} and reload this page`);
            return false;
        }
        return true;

    }

    sendCandidatesAndFormat = async () => {
        const { candidatesToAlign, selectedEmails, emailSubject, emailContent } = this.state;

        // console.log('Aligned Candidated',candidatesToAlign)
        // console.log('Selected Emails',selectedEmails)
        // console.log('Selected Sub',emailSubject)
        // console.log('Selected Cont',emailContent)

        // const candidates = candidatesAligned.map(c => ({ identity: c.identityId, name: c.name, key: c.cvKey, sentId: c.sentId }));
        // console.log(candidates, html, subject)

        // return;
        const r = await API.post(REST_API_NAME, '/coordination/send-email-with-content', {
            body: {
                candidates: candidatesToAlign.map(c => ({ identity: c.identityId, name: c.name, key: c.cvKey, sentId: c.sentId, coordinationId: c.candidateAssigedId || c.coordinationId })),
                emails: selectedEmails,
                subject: emailSubject,
                html: emailContent
            }
        });
        if (r.success) {
            this.setState({
                candidatesToAlign: [],
                selectedEmails: [],
                emailSubject: '',
                emailContent: '',
                // selectedCandidate: {} as CoordinationTrackerInterface,
                // checkList: checkList.map(c => { c.selected = false; return c }),
                // loading: false

            });
            this.alignModal.hide();

        }

    }


    handleEditorChange = (emailContent: string) => {
        this.setState({ emailContent });
    }
    handleEditorBlur = (editor: any) => {
        this.setState({ emailContent: editor.target.getContent() });
    }

    updateFinalStatus = async (status: FinalStatusType | '', comments: string) => {
        const { selectedCandidate, selectedCompany } = this.state;
        const { employees, companies } = this.props;
        const selectedCandidateState = selectedCandidate.final_status === "Select"
            ? "select"
            : !!selectedCandidate.aligned_on
                ? "aligned"
                : !!selectedCandidate.assigned_on
                    ? "assigned"
                    : "pending-assign";


        let field = "";
        //Update candidate tl_comments if in pre assign stage
        if (selectedCandidateState === "pending-assign") {
            field = "comment";
            const id = selectedCandidate.candidateId || selectedCandidate.id
            const candidateCompanyId = selectedCandidate.candidateCompanyId;
            // console.log(selectedCandidate, comments, 'update')
            const result = await API.put(REST_API_NAME, '/candidates/update-field/comment/' + id, {
                body: {
                    value: comments
                }
            })

            // console.log(result)
            if (result.success) {
                toast.success('Comment Updated and Status changed to ' + status)
                this.nextCandidate();
            }
        } else if (selectedCandidateState === "assigned" || selectedCandidateState === "aligned") {
            field = "final_status";
            const coordinationId = selectedCandidate.coordinationId
            const candidateId = selectedCandidate.candidateId
            const candidateCompanyId = selectedCandidate.candidateCompanyId
            const selectedMonth = dayjs().format('MMM')
            // console.log('c id',id,'cand id',candidateId,status);
            const recruiter = employees.find(e => e.sub == selectedCandidate.recruiter_sub) || { id: -1, name: '' };
            const tl = employees.find(e => e.sub == selectedCandidate.team_leader_sub) || { id: -1, name: '' };
            const company = companies.find(c => c.id == +selectedCandidate.companyId)

            let promises = [
                API.put(REST_API_NAME, `/coordination/update/${field}/${coordinationId}`, { body: { value: status } }),
                API.put(REST_API_NAME, `/coordination/update/final_status_feedback/${coordinationId}`, { body: { value: comments } })
            ];

            // await API.put(REST_API_NAME, `/coordination/update/${field}/${coordinationId}`, { body: { value: status } });
            // await API.put(REST_API_NAME, `/coordination/update/final_status_feedback/${coordinationId}`, { body: { value: comments } });

            if (status == 'Select') {
                // const joinerResult = await API.put(REST_API_NAME, `/coordination/update/selected_in_company/${coordinationId}`, {
                promises.push(API.put(REST_API_NAME, `/coordination/update/selected_in_company/${coordinationId}`, {
                    body: {
                        value: selectedCompany.id,
                        candidateId: candidateId,
                        month: selectedMonth,
                        recruiter: selectedCandidate.recruiter_name,
                        recruiterId: recruiter.id,
                        recruiterSub: selectedCandidate.recruiter_sub,
                        tl: selectedCandidate.team_leader_name,
                        tlId: tl.id,
                        tlSub: selectedCandidate.team_leader_sub,
                        invoiceAmount: company?.amount,
                        invoiceAmountActual: company?.actual_amount
                    }
                }))

                // const dataCopy = {
                //     id: joinerResult.result2.insertId,
                //     dbId: joinerResult.result2.insertId,
                //     name: d.name,
                //     number: d.number,
                //     company: company?.name,
                //     process: '',
                //     doj: '',
                //     month: selectedMonth,
                //     recruiter: d.recruiter_name,
                //     recruiter_id: recruiter.id,
                //     status: 'Joiner',
                //     tl: d.team_leader_name,
                //     tl_id: tl.id,
                //     applicant_id: '',
                //     invoice_sent: 0,
                //     invoice_amount: company?.amount,
                //     invoice_amount_actual: company?.actual_amount,
                //     review: '',
                //     points: 0,
                //     created_by: d.created_by,
                //     type: 'joiner'
                // };

                // await API.graphql(graphqlOperation(createJoiners, { input: dataCopy }))
            }

            Promise.all(promises)
                .then(([a, b, joinerResult]) => {
                    this.nextCandidate();
                })


        }

    }

    updateTLRating = async (rating: number, coordinationTrackerInterface: CoordinationTrackerInterface) => {
        const { selectedCandidate, candidates } = this.state;

        const id = selectedCandidate.candidateId || selectedCandidate.id
        const result = await API.put(REST_API_NAME, '/candidates/update-field/tl_rating/' + id, {
            body: {
                value: rating
            }
        })

        // console.log(result)
        if (result.success) {
            toast.success('TL Rating Updated');
            selectedCandidate.tl_rating = rating;
            this.setState({ candidates: candidates.map(c => ((c.candidateId && c.candidateId === selectedCandidate.candidateId) || (c.id && c.id === selectedCandidate.id)) ? selectedCandidate : c), selectedCandidate: { ...selectedCandidate, tl_rating: rating } })
        }
    }

    showSearchCandidateModal = () => this.searchCandidateModal.show()

    setSearchCriteria = (searchCriteria: string) => this.setState({ searchCriteria })

    searchCandidate = async () => {

        const { searchCriteria } = this.state;

        const data = await API.get(REST_API_NAME, REST_API_PATHS.COORDINATION.SEARCH, {
            queryStringParameters: { q: searchCriteria }
        });

        let resultsCandidates: any[] = [];
        data.result.candidates.records.map((r: any) => resultsCandidates.push(r))

        let resultsAssigned: any[] = [];
        data.result.candidatesAssigned.records.map((r: any) => resultsAssigned.push(r))


        this.setState({ searchResultsCandidates: resultsCandidates, searchResultsAssigned: resultsAssigned })

    }

    selectRecruiter = (selectedRecruiter: EmployeeInterface) => this.setState({ selectedRecruiter })
    setFilterRecruiter = (filterRecruiter: string) => this.setState({ filterRecruiter })



    render() {

        const { loading, selectedMonth, selectedYear, filterRecruiter, selectedRecruiter, searchResultsCandidates, searchResultsAssigned, filterState, filterStatus, companies, selectedCompany, selectedCompanyDetailed, candidates, candidateFormats, selectedEmails, emailSubject, emailContent, employees, employeeHash, cvFilePath, selectedCandidate, checkList, candidatesToAlign } = this.state;

        const { user } = this.props;

        const selectedCandidateState = !!selectedCandidate.aligned_on ? "aligned" : !!selectedCandidate.assigned_on ? "assigned" : "pending-assign";

        const statuses = filterStatus.map(f => f.value);
        const candidatesFiltered = candidates.filter(c => {

            if (filterState === "selected") {
                return true;
            }
            const f = statuses.some(s => {
                const field = filterState === "pending-assign" ? "status" : "final_status";
                if (s === 'Status Not Set') {
                    return !!!c[field]
                } else {
                    return c[field] === s
                }
            })
            // console.log(c, f)
            return f;
            // debugger;

            // if(notSet){
            //     return !!!c.status
            // }

            // return true;
        })

        return (
            <>
                {loading ? 'Loading...' : ''}
                <div className="container-fluid">
                    <div className="row">
                        <FilterCoordinationStateComponent
                            loading={loading}
                            filterState={filterState}
                            companies={companies}
                            selectedCompany={selectedCompany}
                            selectedMonth={MONTHS[selectedMonth]}
                            selectedYear={selectedYear}
                            selectMonth={this.selectMonth}
                            selectYear={this.selectYear}
                            setFilter={this.setFilter}
                            selectCompany={this.selectCompany}
                            selectCompanyType={this.selectCompanyType}
                            setStatusFilter={this.setFillterStatus}
                            showSearchCandidateModal={this.showSearchCandidateModal}

                        />
                    </div>
                    <div className="row">
                        <div className="btn-toolbar mt-1" role="toolbar" aria-label="Toolbar with button groups">
                            <div className="btn-group me-2" role="group">
                                <button id="btnGroupDrop1" type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedRecruiter.name ? selectedRecruiter.name : 'Select Recruiter'}
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                    <li>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text"><i className="bi bi-search"></i></span>
                                            <input type="text" aria-label="Filter Companies" className="form-control" onKeyUp={(evt) => this.setFilterRecruiter(evt.currentTarget.value)} />
                                        </div>
                                    </li>
                                    {employees.filter(emp => emp.name.toLowerCase().indexOf(filterRecruiter.toLowerCase()) != -1).map((employee, idx) => <li key={'ctl-' + idx} onClick={() => this.selectRecruiter(employee)}>
                                        <a className="dropdown-item" href="#" onClick={(evt) => evt.preventDefault()}>{`${employee.name}`}</a>
                                    </li>)}
                                </ul>
                            </div>

                            <div className="btn-group me-2 d-none" role="group" aria-label="First group">
                                <input type="text" placeholder="Phone" />
                                <input type="text" placeholder="Email" />
                            </div>

                            <div className="btn-group me-2" role="group" aria-label="First group">
                                {/* <button type="button" className={"btn btn-primary "} onClick={() => this._getPendingCandidatesForRecruiter()}>Filter</button> */}
                                <button type="button" className={"btn btn-primary "} disabled={filterState.toString() === ""} onClick={() => this.getCandidatesBySearch()}>Filter</button>
                                {/* <button type="button" className={"btn btn-primary " + (filterState == 'unassigned' ? 'active' : '')} onClick={() => this.setFilter('unassigned')}>Unassigned</button> */}
                                {/* <button type="button" className={"btn btn-primary " + (filterState == 'pending-assign' ? 'active btn-success' : '')} disabled={loading} onClick={() => setFilter('pending-assign')}>Pending</button> */}
                                {/* <button type="button" className={"btn btn-primary " + (filterState == 'assigned' ? 'active btn-success' : '')} disabled={loading} onClick={() => setFilter('assigned')}>Assigned</button> */}
                                {/* <button type="button" className={"btn btn-primary " + (filterState == 'aligned' ? 'active btn-success' : '')} disabled={loading} onClick={() => setFilter('aligned')}>Aligned</button> */}
                                {/* <button type="button" className={"btn btn-primary " + (filterState == 'selected' ? 'active btn-success' : '')} disabled={loading} onClick={() => setFilter('selected')}>Selected</button> */}
                            </div>




                        </div>
                    </div>

                    {candidatesToAlign.length ? <button onClick={this.showAlignModal}>Send Aligned Candidates ({candidatesToAlign.length})</button> : ''}

                    <div className="row">
                        <table className='table table-bordered table-fixed-layout1 table-candidates1'>
                            <colgroup>
                                <col className='col1' />
                                <col className='col2' />
                                <col className='col3' />
                                <col className='col4' />
                                <col className='col5' />
                                <col className='col6' />
                                <col className='col7' />
                                <col className='col8' />
                                <col className='col9' />
                                <col className='col10' />
                                <col className='col11' />
                                <col className='col12' />
                                <col className='col13' />
                                <col className='col14' />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Number & Email</th>
                                    <th>Company</th>
                                    {/* <th>Email</th> */}
                                    <th>Rec Rating</th>
                                    <th>TL Rating</th>
                                    <th>TL Comments</th>
                                    <th>Rec & TL</th>
                                    {/* <th>TL</th> */}
                                    {/* <th>Roles</th> */}
                                    {/* <th>Source</th> */}
                                    <th>Created</th>
                                    <th>Assigned</th>
                                    <th>Aligned</th>
                                    {/* <th>CreatedBy</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {candidatesFiltered.map((d, idx) => <tr key={'row-' + d.id + idx} id={d.id + ""}>
                                    <td>
                                        <div className="btn-toolbar candidate-action-buttons d-none d-md-block" role="toolbar" aria-label="Toolbar with button groups">
                                            <div className="btn-group me-2" role="group" aria-label="First group">
                                                <i className="bi bi-eye-fill download-button cursor-pointer" onClick={() => { !loading && this.selectCandidate(d); }} ></i>
                                                {/* <i className="bi bi-file-earmark-spreadsheet format-button cursor-pointer" onClick={() => { !(loading || !!!d.companies) && this.showAddFormatDataModal(d); }}></i> */}
                                                {/* <i className="bi bi-pencil-square edit-button cusror-pointer" onClick={() => { if (loading) { return }; this.selectCandidate(d); this.createUserModal.toggle() }} ></i> */}
                                                {/* <i className="bi bi-trash-fill delete-button" onClick={() => { if (window.confirm('Are you sure you want to delete this user?')) { this.deleteCandidate(d); } }}></i> */}
                                            </div>
                                        </div>
                                        {/* 
                                        <div className="dropdown d-lg-none d-md-none d-sm-block d-xs-block">
                                            <button className="btn btn-primary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li onClick={(evt) => { evt.stopPropagation(); !loading && this.getFile(d); }} >
                                                    <a className="dropdown-item download-button1" href="#" onClick={(evt) => evt.preventDefault()}>
                                                        <i className="bi bi-file-earmark-arrow-down download-button cursor-pointer" onClick={() => { !loading && this.getFile(d); }} ></i>
                                                        Download
                                                    </a>
                                                </li>
                                                <li onClick={(evt) => { evt.stopPropagation(); !(loading || !!!d.companies) && this.showAddFormatDataModal(d); }}>
                                                    <a className="dropdown-item format-button1" href="#" onClick={(evt) => evt.preventDefault()}>
                                                        <i className="bi bi-file-earmark-spreadsheet format-button cursor-pointer" onClick={(evt) => { evt.stopPropagation(); !(loading || !!!d.companies) && this.showAddFormatDataModal(d); }}></i>
                                                        Format
                                                    </a>
                                                </li>
                                                <li onClick={(evt) => { evt.stopPropagation(); if (loading) { return }; this.selectCandidate(d); this.createUserModal.toggle() }}>
                                                    <a className="dropdown-item edit-button1" href="#" onClick={(evt) => evt.preventDefault()}>
                                                        <i className="bi bi-pencil-square edit-button cusror-pointer" onClick={(evt) => { evt.stopPropagation(); if (loading) { return }; this.selectCandidate(d); this.createUserModal.toggle() }} ></i>
                                                        Edit
                                                    </a>
                                                </li>
                                                <li onClick={(evt) => { evt.stopPropagation(); if (window.confirm('Are you sure you want to delete this user?')) { this.deleteCandidate(d); } }}>
                                                    <a className="dropdown-item delete-button1" href="#" onClick={(evt) => evt.preventDefault()}>
                                                        <i className="bi bi-trash-fill delete-button" onClick={() => { if (window.confirm('Are you sure you want to delete this user?')) { this.deleteCandidate(d); } }}></i>
                                                        Delete
                                                    </a>
                                                </li>
                                            </ul>
                                        </div> */}
                                    </td>
                                    <td>{idx + 1}</td>
                                    <td>{d.name}</td>
                                    <td>{d.number}<br />{d.email}</td>
                                    <td>{d.company}</td>
                                    <td>{d.rating}</td>
                                    <td>{d.tl_rating}</td>
                                    <td>{d.comment}</td>
                                    {/* <Cell type='text' data={d} value={d.comment ? d.comment + '' : ''} field={'comment'} onChange={(d) => this.updateCandidateRow(d, 'comment')} /> */}
                                    <td>{formatEmployeeName(d.recruiter_name)}<br />{formatEmployeeName(d.team_leader_name)}</td>
                                    {/* <td>{formatEmployeeName(d.team_leader_name)}</td> */}
                                    {/* <td>{d.roles.join(',')}</td> */}
                                    {/* <td>{d.source}</td> */}
                                    <td><DateTimeDisplay date={d.created_on} />  <br /> {employeeHash[d.created_by] ? formatEmployeeName(employeeHash[d.created_by].name) : ''}</td>
                                    <td><DateTimeDisplay date={d.assigned_on} />  <br /> {employeeHash[d.assigned_by] ? formatEmployeeName(employeeHash[d.assigned_by].name) : ''}</td>
                                    <td><DateTimeDisplay date={d.aligned_on} />  <br /> {employeeHash[d.aligned_by] ? formatEmployeeName(employeeHash[d.aligned_by].name) : ''}</td>
                                    {/* <td>{employeeHash[d.created_by] ? formatEmployeeName(employeeHash[d.created_by].name) : ''}</td> */}
                                    {/* <td>{employeesHash[d.created_by] ? formatEmployeeName(employeesHash[d.created_by].name) : 'NA'}</td> */}
                                    {/* <td>{d.cvKey}</td> */}


                                </tr>)}
                            </tbody>
                        </table>
                    </div>

                </div>


                <div className="modal fade" id="cvModal" tabIndex={-1} aria-labelledby="cvModal" aria-hidden="true" ref={this.cvViewModalRef} data-bs-backdrop="static">
                    <CoordinationReviewModal
                        key={'key-' + selectedCandidate.id}
                        loading={loading}
                        url={cvFilePath}
                        currentState={selectedCandidateState}
                        candidate={selectedCandidate}
                        company={selectedCompany}
                        checkList={checkList}
                        selectedCompanyDetailed={selectedCompanyDetailed}
                        candidateFormats={candidateFormats}
                        isRecruiter={isOnlyRecruiter(user)}
                        nextCandidate={this.nextCandidate}
                        previousCandidate={this.previousCandidate}
                        assign={this.assign}
                        addToAlign={this.addToAlign}
                        updateFinalStatus={this.updateFinalStatus}
                        updateTLRating={(n, c) => this.updateTLRating(n, c)}
                    //
                    // candidateFormats={candidateFormats}
                    />
                </div>

                <div className="modal fade" id="alignModal" tabIndex={-1} aria-labelledby="alignModal" aria-hidden="true" ref={this.alignModalRef} data-bs-backdrop="static">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Send Email</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    {((selectedCompanyDetailed.contacts || []).length)
                                        ? <table className='table table-bordered table-sm'>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Title</th>
                                                    <th>Phones</th>
                                                    <th>Emails</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(selectedCompanyDetailed.contacts || []).map(contact => <tr key={'contact' + contact.id}>
                                                    <td>{contact.name}</td>
                                                    <td>{contact.title}</td>
                                                    <td><ul>{(contact.phones || []).map(p => p.phone ? <li key={p.phone}>
                                                        {p.phone}
                                                    </li> : '')}</ul></td>
                                                    <td><ul>{(contact.emails || []).map(e => e.email ? <li key={e.email}>
                                                        {e.email}
                                                        <input type='checkbox' onChange={(evt) => this.selectEmail(evt.target.checked, e.email)} checked={!!selectedEmails.find(s => s == e.email)} />
                                                    </li> : '')}</ul></td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                        : ''
                                    }
                                </div>
                                <div className='form'>
                                    <div className='row'>
                                        <h3>Selected Candidates For CV</h3>
                                        <table className='table table-bordered table-condensed'>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Number</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {candidatesToAlign.map((ca, idx) => <tr key={idx + ca.identityId}>
                                                    <td>{ca.name}</td>
                                                    <td>{ca.number}</td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='row'>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Subject</span>
                                            <input type="text" className="form-control" value={emailSubject} onChange={(evt) => this.setEmailSubject(evt.target.value)} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col">
                                            <h3>Email Body</h3>
                                            <div id='table-format'>
                                                <table style={{ border: "1px solid black", borderCollapse: "collapse", width: "100%" }} width="100%">
                                                    <thead>
                                                        <tr>
                                                            {(selectedCompanyDetailed.format || []).map(f => <th key={'fth-' + f.title} style={{ border: "1px solid black", borderCollapse: "collapse", backgroundColor: "#71d5f5" }}>{f.title}</th>)}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {candidatesToAlign.map((ca, idx) => <tr key={'ftr' + ca.id + '-' + idx}>
                                                            {(selectedCompanyDetailed.format || []).map((f, idx) => {
                                                                const cand = candidateFormats[ca.candidateId || ca.id];
                                                                return <td key={'ftd-' + ca.candidateId + idx + f.id} style={{ border: "1px solid black", borderCollapse: "collapse" }}>{cand[f.id]}</td>
                                                            })}
                                                        </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <Editor
                                                apiKey="jjepwn2j57vncw19bhbeiuao2rwly7qtkcf0clgbi1o68rbt"
                                                onInit={(evt, editor) => this.editorRef.current = editor}
                                                onChange={(evt) => console.log(evt)}
                                                initialValue={emailContent}
                                                // value={emailContent}
                                                // onEditorChange={this.handleEditorChange}
                                                onBlur={this.handleEditorBlur}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                            />

                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button onClick={this.sendCandidatesAndFormat} className='btn btn-primary' disabled={!selectedEmails.length || loading}>Send<i className="bi bi-envelope"></i></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="alignModal" tabIndex={-1} aria-labelledby="alignModal" aria-hidden="true" ref={this.searchCandidateRef} data-bs-backdrop="static">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Search Candidate</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">

                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <input type='text' className='form-control' placeholder="Name, Phone or Email" onChange={(evt) => this.setSearchCriteria(evt.target.value)} />
                                            </td>
                                            <td>
                                                <button onClick={this.searchCandidate} className='btn btn-primary' disabled={loading}>Search</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <ul className="nav nav-tabs" id='myTab'>
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href="#candidates">Candidates</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#assigned">Assigned</a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="candidates" role="tabpanel" aria-labelledby="home-tab">
                                        <table className='table table-bordered table-fixed-layout1 table-candidates1'>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Number & Email</th>
                                                    <th>Company Selected</th>
                                                    <th>Rec Rating</th>
                                                    <th>TL Rating</th>
                                                    <th>TL Comments</th>
                                                    <th>Rec & TL</th>
                                                    {/* <th>TL</th> */}
                                                    {/* <th>Roles</th> */}
                                                    {/* <th>Source</th> */}
                                                    <th>Created</th>
                                                    <th>Assigned</th>
                                                    <th>Aligned</th>
                                                    {/* <th>CreatedBy</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {searchResultsCandidates.map((d: any, idx: number) => <tr key={'row-a-' + d.id + idx} id={d.id + ""}>
                                                    <td>
                                                        <div className="btn-toolbar candidate-action-buttons d-none d-md-block" role="toolbar" aria-label="Toolbar with button groups">
                                                            <div className="btn-group me-2" role="group" aria-label="First group">
                                                                <i className="bi bi-eye-fill download-button cursor-pointer" onClick={() => { !loading && this.selectCandidate(d); }} ></i>
                                                                {/* <i className="bi bi-file-earmark-spreadsheet format-button cursor-pointer" onClick={() => { !(loading || !!!d.companies) && this.showAddFormatDataModal(d); }}></i> */}
                                                                {/* <i className="bi bi-pencil-square edit-button cusror-pointer" onClick={() => { if (loading) { return }; this.selectCandidate(d); this.createUserModal.toggle() }} ></i> */}
                                                                {/* <i className="bi bi-trash-fill delete-button" onClick={() => { if (window.confirm('Are you sure you want to delete this user?')) { this.deleteCandidate(d); } }}></i> */}
                                                            </div>
                                                        </div>

                                                    </td>
                                                    <td>{idx + 1}</td>
                                                    <td>{d.name}</td>
                                                    <td>{d.number}<br />{d.email}</td>
                                                    <td>{d.company}</td>
                                                    <td>{d.rating}</td>
                                                    <td>{d.tl_rating}</td>
                                                    <td>{d.comment}</td>
                                                    {/* <Cell type='text' data={d} value={d.comment ? d.comment + '' : ''} field={'comment'} onChange={(d) => this.updateCandidateRow(d, 'comment')} /> */}
                                                    <td>{formatEmployeeName(d.recruiter_name)}<br />{formatEmployeeName(d.team_leader_name)}</td>
                                                    {/* <td>{formatEmployeeName(d.team_leader_name)}</td> */}
                                                    {/* <td>{d.roles.join(',')}</td> */}
                                                    {/* <td>{d.source}</td> */}
                                                    <td>{dayjs(d.created_on).format('DD/MM/YY hh:mm A')}<br />{employeeHash[d.created_by] ? formatEmployeeName(employeeHash[d.created_by].name) : ''}</td>
                                                    <td>{dayjs(d.created_on).format('DD/MM/YY hh:mm A')}<br />{employeeHash[d.created_by] ? formatEmployeeName(employeeHash[d.created_by].name) : ''}</td>
                                                    <td>{dayjs(d.aligned_on).format('DD/MM/YY hh:mm A')}<br />{employeeHash[d.aligned_by] ? formatEmployeeName(employeeHash[d.aligned_by].name) : ''}</td>
                                                    {/* <td>{employeeHash[d.created_by] ? formatEmployeeName(employeeHash[d.created_by].name) : ''}</td> */}
                                                    {/* <td>{employeesHash[d.created_by] ? formatEmployeeName(employeesHash[d.created_by].name) : 'NA'}</td> */}
                                                    {/* <td>{d.cvKey}</td> */}


                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="tab-pane fade" id="assigned" role="tabpanel" aria-labelledby="profile-tab">
                                        <table className='table table-bordered table-fixed-layout1 table-candidates1'>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Number & Email</th>
                                                    <th>Company Assigned</th>
                                                    <th>Rec Rating</th>
                                                    <th>TL Rating</th>
                                                    <th>TL Comments</th>
                                                    <th>Rec & TL</th>
                                                    {/* <th>TL</th> */}
                                                    {/* <th>Roles</th> */}
                                                    {/* <th>Source</th> */}
                                                    <th>Created</th>
                                                    <th>Assigned</th>
                                                    <th>Aligned</th>
                                                    {/* <th>CreatedBy</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {searchResultsAssigned.map((d: any, idx: number) => <tr key={'row-c-' + d.id} id={d.id + ""}>
                                                    <td>
                                                        <div className="btn-toolbar candidate-action-buttons d-none d-md-block" role="toolbar" aria-label="Toolbar with button groups">
                                                            <div className="btn-group me-2" role="group" aria-label="First group">
                                                                <i className="bi bi-eye-fill download-button cursor-pointer" onClick={() => { !loading && this.selectCandidate({ ...d, assignedOn: d.created_on }); }} ></i>
                                                                {/* <i className="bi bi-file-earmark-spreadsheet format-button cursor-pointer" onClick={() => { !(loading || !!!d.companies) && this.showAddFormatDataModal(d); }}></i> */}
                                                                {/* <i className="bi bi-pencil-square edit-button cusror-pointer" onClick={() => { if (loading) { return }; this.selectCandidate(d); this.createUserModal.toggle() }} ></i> */}
                                                                {/* <i className="bi bi-trash-fill delete-button" onClick={() => { if (window.confirm('Are you sure you want to delete this user?')) { this.deleteCandidate(d); } }}></i> */}
                                                            </div>
                                                        </div>

                                                    </td>
                                                    <td>{idx + 1}</td>
                                                    <td>{d.name}</td>
                                                    <td>{d.number}<br />{d.email}</td>
                                                    <td>{d.company}</td>
                                                    <td>{d.rating}</td>
                                                    <td>{d.tl_rating}</td>
                                                    <td>{d.comment}</td>
                                                    {/* <Cell type='text' data={d} value={d.comment ? d.comment + '' : ''} field={'comment'} onChange={(d) => this.updateCandidateRow(d, 'comment')} /> */}
                                                    <td>{formatEmployeeName(d.recruiter_name)}<br />{formatEmployeeName(d.team_leader_name)}</td>
                                                    {/* <td>{formatEmployeeName(d.team_leader_name)}</td> */}
                                                    {/* <td>{d.roles.join(',')}</td> */}
                                                    {/* <td>{d.source}</td> */}
                                                    <td>{dayjs(d.created_on).format('DD/MM/YY hh:mm A')}<br />{employeeHash[d.created_by] ? formatEmployeeName(employeeHash[d.created_by].name) : ''}</td>
                                                    <td>{dayjs(d.created_on).format('DD/MM/YY hh:mm A')}<br />{employeeHash[d.created_by] ? formatEmployeeName(employeeHash[d.created_by].name) : ''}</td>
                                                    <td>{dayjs(d.aligned_on).format('DD/MM/YY hh:mm A')}<br />{employeeHash[d.aligned_by] ? formatEmployeeName(employeeHash[d.aligned_by].name) : ''}</td>
                                                    {/* <td>{employeeHash[d.created_by] ? formatEmployeeName(employeeHash[d.created_by].name) : ''}</td> */}
                                                    {/* <td>{employeesHash[d.created_by] ? formatEmployeeName(employeesHash[d.created_by].name) : 'NA'}</td> */}
                                                    {/* <td>{d.cvKey}</td> */}


                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>




                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button onClick={this.searchCandidate} className='btn btn-primary' disabled={loading}>Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}





type CellType = "text" | "select" | "number";
type CellOption = { label: string, value: string };
// type CellOptions = {label: string, value: string };
interface CellPropsInterface {
    data: CandidateInterface
    field: keyof CandidateInterface
    value: string
    type: CellType
    list?: CellOption[]
    onChange: (d: CandidateInterface) => void
    enabled?: boolean

}






export default CoordinationTracker3;