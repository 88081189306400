import Storage from "@aws-amplify/storage";
import { CandidateInterface, FinalStatusType } from "./types";

export const isSuperAdmin = (user: any) => {
    const userGroups = user.signInUserSession.idToken.payload['cognito:groups'] || [];
    return !!userGroups.find((g: string) => g === 'superadmin')
}
export const isAdmin = (user: any) => {
    const userGroups = user.signInUserSession.idToken.payload['cognito:groups'] || [];
    return !!userGroups.find((g: string) => g === 'admin')
}
export const isTeamLeader = (user: any) => {
    const userGroups = user.signInUserSession.idToken.payload['cognito:groups'] || [];
    return !!userGroups.find((g: string) => g === 'teamleader')
}
export const isOnlyTeamLeader = (user: any) => {
    const userGroups = user.signInUserSession.idToken.payload['cognito:groups'] || [];
    return !!userGroups.find((g: string) => g === 'teamleader') && userGroups.length == 1
}
export const isRecruiter = (user: any) => {
    const userGroups = user.signInUserSession.idToken.payload['cognito:groups'] || [];
    return !!userGroups.find((g: string) => g === 'recruiter')
}
export const isOnlyRecruiter = (user: any) => {
    const userGroups = user.signInUserSession.idToken.payload['cognito:groups'] || [];
    return !!userGroups.find((g: string) => g === 'recruiter') && userGroups.length == 1
}



export const formatName = (str: string) => str.replace(/[^a-zA-z]/g, ' ').replace(/ +(?= )/g, '').toLowerCase().trim().split(' ').map(c => c.trim()).filter(c => !!c).map(c => { console.log(c); return c.charAt(0).toUpperCase() + c.slice(1) }).join(' ')
export const formatNameAllowSpaeInTheEnd = (str: string) => str.replace(/[^a-zA-z]/g, ' ').replace(/ +(?= )/g, '').toLowerCase().split(' ').map(c => c.trim()).filter(c => !!c).map(c => { console.log(c); return c.charAt(0).toUpperCase() + c.slice(1) }).join(' ')

export const formatEmployeeName = (name: string) => {
    if (!name) { return '-' }
    const fName = name.split(' ')[0];
    const lName = (name.split(' ')[1] || '').charAt(0);
    return fName + (lName == '' ? '' : ' ' + lName);
}

export const getFile = async (candidate: CandidateInterface) => {
    const { cvKey } = candidate;
    const file = await Storage.get(cvKey, { level: 'protected', identityId: candidate.identityId });
    window.open(file)
    console.log(file)
}

export const REST_API_NAME = 'apia2dc579f';

export const REST_API_PATHS = {
    EMPLOYEES: {
        LIST: "/employees",
        UPDATE: "/employees/update",
    },
    TEAMS: {
        CREATE: "/employees/create-team",
        UPDATE: "/employees/update-team",
        LIST: "/employees/teams",
        REMOVE: "/employees/delete-team"
    },
    REPORTS: {
        GRID: "/general/grid"
    },
    COORDINATION: {
        SEARCH: "/coordination/search"
    }
}


export const FORMAT_FIELDS: any = {
    CANDIDATE: [
        { key: 'name', label: 'Name' },
        { key: 'number', label: 'Number' },
        { key: 'email', label: 'Email' }
    ]
}

export const FINAL_STATUS_FIELDS: { label: FinalStatusType, value: FinalStatusType }[] = [
    { label: "Select", value: "Select" },
    { label: "Reject", value: "Reject" },
    { label: "Drop", value: "Drop" },
    { label: "Hold", value: "Hold" },
    { label: "Duplicate", value: "Duplicate" },
    { label: "Status Not Set", value: "Status Not Set" },
]
export const OFFER_STATUS_FIELDS: any = [
    { label: "Offer", value: "Offer" },
    { label: "Joiner", value: "Joiner" },
    { label: "Drop", value: "Drop" },
]

export const MONTHS = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const YEARS = [2021, 2022, 2023, 2024];

export const SOURCES = [
    "Monster",
    "Naukri",
    "Times",
    "Google",
    "Just Dial",
    "Shine.Com",
    "Reference",
    "Incoming",
    "Other"
]
export const CANDIDATE_ROLES = [
    "Accounts",
    "Agent Level",
    "Team Leader",
    "Assistant Manager",
    "Quality Analyst",
    "MIS Executive",
    "Business Development",
    "Sales",
    "Backend Developer",
    "Frontend Developer",
    "Fullstack Developer",
    "General - IT",
    "Manager",
    "Senior Manager",
    "Social Media Manager",
    "Digital Marketing",
]


export const MAX_DAYS_TO_WAIT_TO_ALLOW_DUPLICATE = 10;


