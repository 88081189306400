/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createJoiners = /* GraphQL */ `
  mutation CreateJoiners(
    $input: CreateJoinersInput!
    $condition: ModelJoinersConditionInput
  ) {
    createJoiners(input: $input, condition: $condition) {
      id
      dbId
      name
      number
      company
      process
      doj
      month
      recruiter
      recruiter_id
      status
      tl
      tl_id
      applicant_id
      invoice_sent
      invoice_amount
      invoice_amount_actual
      ctc
      review
      points
      created_by
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateJoiners = /* GraphQL */ `
  mutation UpdateJoiners(
    $input: UpdateJoinersInput!
    $condition: ModelJoinersConditionInput
  ) {
    updateJoiners(input: $input, condition: $condition) {
      id
      dbId
      name
      number
      company
      process
      doj
      month
      recruiter
      recruiter_id
      status
      tl
      tl_id
      applicant_id
      invoice_sent
      invoice_amount
      invoice_amount_actual
      ctc
      review
      points
      created_by
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteJoiners = /* GraphQL */ `
  mutation DeleteJoiners(
    $input: DeleteJoinersInput!
    $condition: ModelJoinersConditionInput
  ) {
    deleteJoiners(input: $input, condition: $condition) {
      id
      dbId
      name
      number
      company
      process
      doj
      month
      recruiter
      recruiter_id
      status
      tl
      tl_id
      applicant_id
      invoice_sent
      invoice_amount
      invoice_amount_actual
      ctc
      review
      points
      created_by
      type
      createdAt
      updatedAt
    }
  }
`;
