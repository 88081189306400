import API from "@aws-amplify/api";
import Storage from "@aws-amplify/storage";
import { Modal } from "bootstrap";
import dayjs from "dayjs";
import React, { useState } from "react";
import { CandidateInterface, CompanyInterface, ContactInterface, CoordinationInterface, CoordinationTrackerInterface, CoordinationTrackerInterfaceWithCandidateInfo, EmailInterface, EmployeeInterface, FormatRowInterface, GenericDataNameInterface, MAIL_SENT_STATUS, PhoneInterface } from "../types";
import { FINAL_STATUS_FIELDS, formatEmployeeName, FORMAT_FIELDS, isOnlyRecruiter, isSuperAdmin, OFFER_STATUS_FIELDS, REST_API_NAME, YEARS } from "../utils";

import Select from 'react-select'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import "react-datepicker/dist/react-datepicker.css";
// import { MonthSelector } from "../components/MonthSelector";
import { graphqlOperation } from "aws-amplify";
import { createJoiners } from "../graphql/mutations";
import ReactDatePicker from "react-datepicker";
import { MonthSelector } from "../components/MonthSelector/MonthSelector";

const data: GenericDataNameInterface[] = [
    { id: 1, name: "Rashmi" },
    { id: 1, name: "Zubaina" },
    { id: 1, name: "Zubaida" },
    { id: 1, name: "Shruti" },
    { id: 1, name: "Mithi" },
    { id: 1, name: "Yousuf" },
    { id: 1, name: "Srinivas" },
    { id: 1, name: "Poojitha" },
    { id: 1, name: "Raheen" }
]

interface CoordinationCompanyInterface extends CompanyInterface {
    disabled: boolean
    cnt: number
}

type filterStatusType = "assigned" | "aligned" | "selected";

type ReactSelectOptionType = { label: string, value: string }

interface CoordinationTrackerStateInterface {
    loading: boolean,
    coordinationTrackerDetails: CoordinationTrackerInterfaceWithCandidateInfo[],
    companies: CoordinationCompanyInterface[],
    filtersApplied: { field: string, value: string }[],//array with kets from cprd tracker and value
    selectedYear: string,

    companyCounts: { cnt: number, company: string }[],


    filteredCandidates: CoordinationTrackerInterface[],
    candidates: CoordinationTrackerInterface[],
    candidate: CoordinationTrackerInterface,
    mode: 'add' | 'edit' | 'view'
    allCompanies: CoordinationCompanyInterface[]
    selectedCompanies: ReactSelectOptionType[]
    filterCompanies: ReactSelectOptionType
    // candidates: CandidateInterface[]
    startDateFilter: Date,
    endDateFilter: Date,
    filterState: string,
    filterCompany: CompanyInterface
    selectedMonth: string
    candidatesAligned: CoordinationTrackerInterface[]
    candidateToSendFormat: CoordinationTrackerInterface[]
    selectedEmails: EmailInterface[]
    filter: any
    employees: EmployeeInterface[]
    waitignRoomFilterQuery: string,
    waitingRoomStatusFilter: "pending" | "completed",
    formatRows: FormatRowInterface[]
    formatData: any
    subject: string
    formats: any
    selectedFormatId: string
    formatDataCandidates: any
    candidateFilter: any
    contacts: ContactInterface[]
    showDuplicates: boolean
    sendLinksForCompanies: { company: string, link: string }[],
    companiesAssignedByRecruiter: CompanyInterface[]
}


class CoordinationTrackerList extends React.Component<{ user: any, match: any }, CoordinationTrackerStateInterface>{

    sendCandidateToCompanyModal: any;
    sendCandidateToCompanyModalRef: any;

    toastRef: any
    toastEle: any

    formatModalRef: any
    formatModal: any

    addFormatDataModalRef: any;
    addFormatDataModal: any;

    addFormatDataTabsRef: any;
    addFormatDataTabs: any;

    sendCVEmailModalRef: any;
    sendCVEmailModal: any;


    constructor(props: any) {
        super(props);
        const identityId = this.props.user.identityId

        const month = dayjs().month();
        const year = dayjs().year();

        this.state = {
            loading: true,
            companies: [],
            allCompanies: [],
            coordinationTrackerDetails: [],
            filtersApplied: [],
            selectedMonth: dayjs().format('MMM'),
            selectedYear: dayjs().format('YYYY'),
            companyCounts: [],
            filterCompanies: { value: '', label: '' },


            candidates: [] as CoordinationTrackerInterface[],
            filteredCandidates: [] as CoordinationTrackerInterface[],
            startDateFilter: new Date(year, month, 1),
            endDateFilter: new Date(year, month + 1, 1),
            candidate: {
                id: -1,
                name: '',
                number: '',
                email: '',
                cvKey: '',
                identityId,
            } as CoordinationTrackerInterface,
            mode: 'add',
            selectedCompanies: [],
            filterState: 'assigned',
            filterCompany: {} as CompanyInterface,

            candidatesAligned: [],
            candidateToSendFormat: [],
            selectedEmails: [] as EmailInterface[],
            filter: {},
            employees: [] as EmployeeInterface[],
            waitignRoomFilterQuery: '',
            waitingRoomStatusFilter: 'pending',
            formatRows: [],
            formatData: [],
            subject: 'One Degree North - Candidates - ' + dayjs().format('DD/MM/YYYY'),
            formats: [],
            selectedFormatId: '',
            formatDataCandidates: {},
            candidateFilter: {},
            contacts: [] as ContactInterface[],
            showDuplicates: false,
            sendLinksForCompanies: [],
            companiesAssignedByRecruiter: []

        }

        this.sendCandidateToCompanyModalRef = React.createRef();
        this.toastRef = React.createRef();
        this.formatModalRef = React.createRef();
        this.addFormatDataModalRef = React.createRef();
        this.addFormatDataTabsRef = React.createRef();
        this.sendCVEmailModalRef = React.createRef();


    }

    employeeHash: { [s: string]: EmployeeInterface } = {};
    companyHash: { [s: string]: CompanyInterface } = {};


    async componentDidMount() {
        // this.createUserModal = new Modal(this.createUserModalRef.current);

        // const result = await API.get(REST_API_NAME, '/coordination', {});
        // const resultCompainies = await API.get(REST_API_NAME, '/companies', {});
        // const resultCandidates = await API.get(REST_API_NAME, '/candidates', {});


        const { status, tlsub } = this.props.match.params;
        const filterStatus = this.state.filterState;
        const month = this.state.selectedMonth
        const year = this.state.selectedYear

        const months = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const monthNum = months.indexOf(month);

        console.log('P', status, tlsub);
        // const { result } = await API.get(REST_API_NAME, `/coordination/data`, {});
        const { result: resultCompanies } = await API.get(REST_API_NAME, `/companies/`, {});
        const { result: resultEmployees } = await API.get(REST_API_NAME, `/employees/all`, {});
        const { result: resultCompaniesCounts } = await API.get(REST_API_NAME, `/coordination/masters/initial-company-counts/${filterStatus}/${monthNum}/${year}`, {});

        resultEmployees.map((e: EmployeeInterface) => this.employeeHash[e.sub] = e);
        resultCompanies.map((e: CompanyInterface) => this.companyHash[e.id] = e);

        this.setState({
            loading: false,
            allCompanies: resultCompanies,
            employees: resultEmployees,
            companyCounts: resultCompaniesCounts.companies
            // coordinationTrackerDetails: result.map((c: any) => {

            //     c.assigned_by_name = this.employeeHash[c.created_by].name
            //     c.aligned_by_name = c.aligned_by ? this.employeeHash[c.aligned_by].name : '-'
            //     c.created_by_name = this.employeeHash[c.created_by].name
            //     return c;
            // })
        })
        // this.getData(this.state.filterState, this.state.selectedMonth);

        this.sendCandidateToCompanyModal = new Modal(this.sendCandidateToCompanyModalRef.current);

        window.addEventListener('scroll', (g) => {
            if (window.scrollY > 100) {
                document.querySelectorAll('.sticky-header th').forEach((e: any) => e.style && (e.style.position = 'sticky'))
            } else {
                document.querySelectorAll('.sticky-header th').forEach((e: any) => e.style && (e.style.position = ''))
            }

        })

        // this.formatModal = new Modal(this.formatModalRef.current);
        // this.toastEle = new Toast(this.toastRef.current);

        return;
        // const checkUser = isSuperAdmin(this.props.user);
        // const month = dayjs().format('MMM');

        // // toast.info('Tracker 2');


        // const { result } = await API.get(REST_API_NAME, `/coordination/masters/initial-v3/${month}`, {});
        // const resultAllCompanies = result.allCompanies;
        // const resultCompanies = result.companies;
        // const resultEmployees = result.employees;

        // this.setState({
        //     loading: false,
        //     // candidates: resultCandidates, 
        //     companies: resultCompanies,
        //     employees: resultEmployees,
        //     allCompanies: resultAllCompanies
        // })

        // this.sendCandidateToCompanyModal = new Modal(this.sendCandidateToCompanyModalRef.current);
        // this.formatModal = new Modal(this.formatModalRef.current);
        // this.toastEle = new Toast(this.toastRef.current);

        // this.sendCVEmailModal = new Modal(this.sendCVEmailModalRef.current);

        // this.sendCVEmailModalRef.current.addEventListener('show.bs.modal', (event: any) => {

        //     console.log(this.state.candidatesAligned);
        //     const candidates = this.state.candidatesAligned;
        //     this.checkCVs(candidates)
        // })
        // this.sendCVEmailModalRef.current.addEventListener('hide.bs.modal', (event: any) => {
        //     this.setState({ candidatesAligned: [], candidateToSendFormat: [] })
        // })

        // this.addFormatDataModal = new Modal(this.addFormatDataModalRef.current);

        // this.addFormatDataModalRef.current.addEventListener('hidden.bs.modal', (event: any) => {
        //     this.addFormatDataTabs.dispose();
        // })
        // this.addFormatDataModalRef.current.addEventListener('shown.bs.modal', (event: any) => {
        //     this.addFormatDataTabs = new Tab(this.addFormatDataTabsRef.current.querySelector('li:last-child a'));
        // })

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', (g) => {
            if (window.scrollY > 100) {
                document.querySelectorAll('.sticky-header th').forEach((e: any) => e.style && (e.style.position = 'sticky'))
            } else {
                document.querySelectorAll('.sticky-header th').forEach((e: any) => e.style && (e.style.position = ''))
            }

        })
    }



    checkCVs = async (candidates: CandidateInterface[]) => {

        const keys = candidates.map(candidate => 'protected/' + candidate.identityId + '/' + candidate.cvKey)

        const result = await API.post(REST_API_NAME, '/candidates/check-cvs/', {
            body: {
                keys: keys.join(',')
            }
        })

    }

    checkCV = async (candidate: CoordinationTrackerInterface) => {

        const result = await API.post(REST_API_NAME, '/candidates/check-cv/', {
            body: {
                key: 'protected/' + candidate.identityId + '/' + candidate.cvKey
            }
        })

        const success = result.success;
        if (!!!success) {
            // alert(`Please upload a cv for ${candidate.name} and reload this page`);
            toast.error(`Please upload a cv for ${candidate.name} and reload this page`);
            // this.setState({ candidatesAligned: this.state.candidatesAligned.filter(ca => ca.id != candidate.id) })
        }

        // this.setState({ coordinationTrackerDetails: this.state.coordinationTrackerDetails.map(ca => ca.id == candidate.id ? { ...candidate, cvExists: !!success } : ca) })


    }

    setField = (field: keyof CandidateInterface, value: string | boolean | number) => {
        this.setState({ candidate: { ...this.state.candidate, [field]: value } })
    }

    getFile = async (candidate: CandidateInterface) => {

        const { cvKey } = candidate;
        const file = await Storage.get(cvKey, { level: 'protected', identityId: candidate.identityId });
        window.open(file)
        console.log(file)
    }

    clearCandidate = () => {
        this.setState({ candidate: {} as CoordinationTrackerInterface, mode: 'add' })
    }
    selectCandidateOLD = async (candidate: CoordinationTrackerInterface[]) => {
        this.setState({ loading: true, candidate: candidate[0] });

        const { result } = await API.get(REST_API_NAME, '/coordination/companies-aligned-for-candidate/' + candidate[0].id, {});
        const companiesAligned = result.map((c: any) => c.companyId)


        this.setState({ loading: false, companies: this.state.companies.map(c => { c.disabled = companiesAligned.indexOf(c.id) == -1 ? false : true; return c }) });

    }
    selectCandidate = async (candidate: CoordinationTrackerInterface) => {
        this.setState({ loading: true });

        // const { result } = await API.get(REST_API_NAME, '/coordination/companies-aligned-for-candidate/' + candidate.id, {});
        const companiesAssignedByRecruiterResult = await API.get(REST_API_NAME, '/candidates/companies-assigned/' + candidate.id, {});


        let _c: any = {}
        const companiesAssigned = companiesAssignedByRecruiterResult.result.map((c: any) => _c[c.company_id] = true);
        // const companiesAligned = result.map((c: any) => c.companyId);

        if (!companiesAssignedByRecruiterResult.result.length) {
            toast.error('Candidate has been aligned to all companies mentioned by the respective recruiter.')
            this.setState({
                loading: false,
                candidate: {} as CoordinationTrackerInterface,
                // companies: this.state.allCompanies.map(c => { c.disabled = true; return c }),

                companiesAssignedByRecruiter: []
            });
            return;
        }
        this.setState({
            loading: false,
            candidate: candidate,
            // companies: this.state.allCompanies.map(c => { c.disabled = !!!_c[c.id]; return c }),
            companiesAssignedByRecruiter: this.state.allCompanies.filter(c => !!_c[c.id]),
        });
        // this.setState({ loading: false, companies: this.state.allCompanies.map(c => { c.disabled = companiesAligned.indexOf(c.id) == -1 ? false : true; return c }) });

    }

    selectCompany = (company: any) => {
        this.setState({ selectedCompanies: company.pop() })
    }
    setCompanyFilter = (company: any) => {
        this.setState({ candidateFilter: { ...this.state.candidateFilter, companies: company.pop() } })
    }
    setFilterField = (field: any, value: string) => {
        this.setState({ candidateFilter: { ...this.state.candidateFilter, [field]: value } })
    }

    alignCandidate = (candidate: CoordinationInterface) => {
        console.log('Align Candidate', candidate)
    }

    sendToCoordniator = async () => {
        let { candidate, selectedCompanies, companies, sendLinksForCompanies, allCompanies } = this.state;
        candidate.companies = selectedCompanies
        // const regData = selectedCompanies.map(c => {
        //     const company = companies.find(company => company.id == +c.value)!;
        //     return company.registration_link == null
        //         ? false
        //         : {
        //             company: company.name,
        //             link: company.registration_link
        //         }

        // }).filter(c => c != false);

        const _companies = candidate.companies.map(c => {
            const company = allCompanies.find(ac => ac.id == +c.value)!;
            return {
                ...c,
                invoiceAmount: company.amount,
                invoiceAmountActual: company.actual_amount
            }
        })

        const html = sendLinksForCompanies.length ? document.getElementById('reg-links-table')!.innerHTML : ''

        try {
            const result = await API.post(REST_API_NAME, `/coordination/send-to-coordinator`, {
                body: {
                    candidate: {
                        ...candidate,
                        companies: _companies
                    },
                    html
                }
            });
            this.sendCandidateToCompanyModal.toggle();
            // this.setState({
            //     loading: false,
            //     candidate: {} as CoordinationTrackerInterface,
            //     sendLinksForCompanies: []
            // })

            const c = selectedCompanies.map(c => ({ ...candidate, company: c.label, companyId: c.value, sentId: result.results.find((r: any) => r.company == c.value).insertId }))

            this.setState({
                loading: false,
                // candidate: {} as CoordinationTrackerInterface,
                sendLinksForCompanies: [],
                coordinationTrackerDetails: [...this.state.coordinationTrackerDetails as any, ...c],
                candidate: {
                    id: -1,
                    name: '',
                    number: '',
                    email: '',
                    cvKey: '',
                    identityId: this.props.user.identityId,
                } as CoordinationTrackerInterface,
                selectedCompanies: []
            });
            toast.success('Saved Successfully');


        } catch (e) {
            console.log('Error', e);
            this.setState({ loading: false })
        }

    }

    deleteRow = async (id: number) => {
        this.setState({ loading: true })

        try {
            await API.del(REST_API_NAME, `/coordination/` + id, {});
            this.setState({
                loading: false,
                coordinationTrackerDetails: this.state.coordinationTrackerDetails.filter((c: any) => c.id != id)
            })
        } catch (e) {
            console.log('Error', e);
            this.setState({ loading: false })
        }


    }


    updateField = async (d: CoordinationTrackerInterface, field: keyof CoordinationTrackerInterface) => {
        const result = await API.put(REST_API_NAME, `/coordination/update/${field}/${d.id}`, { body: { value: d[field] } });

        this.setState({
            loading: false,
            coordinationTrackerDetails: this.state.coordinationTrackerDetails.map((c: any) => c.id == d.id ? d : c)
        });
        // return;
        if (field == 'final_status') {
            if (d[field] == 'Select') {
                const joinerResult = await API.put(REST_API_NAME, `/coordination/update/selected_in_company/${d.id}`, {
                    body: {
                        value: d.companyId,
                        candidateId: d.candidateId
                    }
                });
            }
        } else if (field == 'companyId') {
            const { allCompanies } = this.state;
            const selectedCompany = allCompanies.find(c => (c.id + "") == d.companyId)!;
            d.company = selectedCompany.name;
            await API.put(REST_API_NAME, `/coordination/update/company/${d.sentId}`, { body: { value: d.company } });
        }

    }

    updateCell = async (d: CoordinationTrackerInterface, field: keyof CoordinationTrackerInterface) => {

        this.setState({ loading: true })
        // console.log(d,field)
        let { selectedCompanies, companies, selectedMonth, allCompanies, employees } = this.state;
        const candidate = d;
        const selectedCompany = companies.find(c => (c.id + "") == d.companyId)!;

        if (d.sentId) {
            try {
                d.company = selectedCompany.name;

                await API.put(REST_API_NAME, `/coordination/update/${field}/${d.sentId}`, { body: { value: d[field] } });
                if (field == 'companyId') {
                    await API.put(REST_API_NAME, `/coordination/update/company/${d.sentId}`, { body: { value: d.company } });
                } else if (field == 'final_status') {
                    if (d[field] == 'Select') {
                        const recruiter = employees.find(e => e.sub == d.recruiter_sub) || { id: -1, name: '' };
                        const tl = employees.find(e => e.sub == d.team_leader_sub) || { id: -1, name: '' };
                        const company = allCompanies.find(c => c.id == +d.companyId)
                        // debugger;
                        const joinerResult = await API.put(REST_API_NAME, `/coordination/update/selected_in_company/${d.sentId}`, {
                            body: {
                                value: selectedCompany.id,
                                candidateId: d.id,
                                month: selectedMonth,
                                recruiter: d.recruiter_name,
                                recruiterId: recruiter.id,
                                recruiterSub: d.recruiter_sub,
                                tl: d.team_leader_name,
                                tlId: tl.id,
                                tlSub: d.team_leader_sub,
                                invoiceAmount: company?.amount,
                                invoiceAmountActual: company?.actual_amount
                            }
                        });

                        const dataCopy = {
                            id: joinerResult.result2.insertId,
                            dbId: joinerResult.result2.insertId,
                            name: d.name,
                            number: d.number,
                            company: company?.name,
                            process: '',
                            doj: '',
                            month: selectedMonth,
                            recruiter: d.recruiter_name,
                            recruiter_id: recruiter.id,
                            status: 'Joiner',
                            tl: d.team_leader_name,
                            tl_id: tl.id,
                            applicant_id: '',
                            invoice_sent: 0,
                            invoice_amount: company?.amount,
                            invoice_amount_actual: company?.actual_amount,
                            review: '',
                            points: 0,
                            created_by: d.created_by,
                            type: 'joiner'
                        };

                        await API.graphql(graphqlOperation(createJoiners, { input: dataCopy }))



                    }
                }
                this.setState({
                    loading: false,
                    candidate: {} as CoordinationTrackerInterface,
                    candidates: this.state.candidates.map(c => c.sentId == d.sentId ? d : c),

                    coordinationTrackerDetails: this.state.coordinationTrackerDetails.map((c: any) => c.sentId == d.sentId ? d : c)
                })
            } catch (e) {
                console.log('Error', e);
                this.setState({ loading: false })
            }
        } else {

            d.companies = [{ label: selectedCompany?.name, value: selectedCompany?.id + "" }]
            // d.companies = [d.company]
            try {
                const result = await API.post(REST_API_NAME, `/coordination/send-to-coordinator`, { body: candidate });
                this.setState({
                    loading: false,
                    candidate: {} as CoordinationTrackerInterface,
                })
                // this.sendCandidateToCompanyModal.toggle();
                // const c = [{ ...candidate, company: selectedCompany.name, companyId: selectedCompany.id + "", sentId: result.results.find((r: any) => r.company == selectedCompany.id).insertId }]
                const c = this.state.coordinationTrackerDetails.map(c => {
                    if (c.id != d.id) {
                        return c;
                    }
                    c.companyId = selectedCompany.id + "";
                    c.company = selectedCompany.name;
                    c.sentId = result.results[0].insertId;
                    return c;
                })
                const c1 = this.state.candidates.map(c => {
                    if (c.id != d.id) {
                        return c;
                    }
                    c.companyId = selectedCompany.id + "";
                    c.company = selectedCompany.name;
                    c.sentId = result.results[0].insertId;
                    return c;
                })
                this.setState({
                    coordinationTrackerDetails: c,
                    candidates: c1

                });
                toast.success('Saved Successfully');

            } catch (e) {
                console.log('Error', e);
                this.setState({ loading: false })
            }

        }



    }

    getEmployeeName = (sub: string) => this.employeeHash[sub] ? this.employeeHash[sub].name : '-'
    getCompany = (sub: string) => this.companyHash[sub] ? this.companyHash[sub].name : '-'


    getData = async (filterState: string, selectedMonth: string, selectedYear: string, filterCompany: ReactSelectOptionType) => {
        // const { selectedMonth } = this.state;
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        if (!filterCompany.value) {
            return
        }

        const { status, tlsub } = this.props.match.params;
        const { filterCompanies } = this.state;
        this.setState({ loading: true })

        console.log('P', status, tlsub);

        const { result, total } = await API.get(REST_API_NAME, `/coordination/data`, {
            queryStringParameters: {
                month: months.findIndex(m => m == selectedMonth) + 1,
                year: selectedYear,
                filterState,
                company: filterCompany.value
            }
        });
        // const { result: resultCompanies } = await API.get(REST_API_NAME, `/companies/`, {});
        // const { result: resultEmployees } = await API.get(REST_API_NAME, `/employees/`, {});

        // resultEmployees.map((e: EmployeeInterface) => this.employeeHash[e.sub] = e)

        const page = 0;

        this.setState({
            // allCompanies: resultCompanies,
            // employees: resultEmployees,
            coordinationTrackerDetails: result.map((c: any) => {

                //@todo memo these
                c.company = this.getCompany(c.companyId);
                c.team_leader_name = this.getEmployeeName(c.team_leader_sub);
                c.recruiter_name = this.getEmployeeName(c.recruiter_sub);
                c.assigned_by_name = this.getEmployeeName(c.created_by);
                c.aligned_by_name = c.aligned_by ? this.getEmployeeName(c.aligned_by) : '-'
                c.created_by_name = this.getEmployeeName(c.created_by)
                return c;
            })
        });


        if (total > result.length) {

            const iterations = total / result.length;
            let promises = [];
            for (let i = 1; i < iterations; i++) {
                // if (window.confirm(candidatesCount + '>' + candidatesALL.length)) {
                promises.push(API.get(REST_API_NAME, `/coordination/data`, {
                    // const resultMasters = await API.get(REST_API_NAME, '/candidates/masters/initial-v2', {
                    queryStringParameters: {
                        month: months.findIndex(m => m == selectedMonth) + 1,
                        page: page + i,
                        filterState
                    }
                }))
                // }
            }

            Promise.all(promises)
                .then((results) => {

                    let data: any = result;
                    results.map(({ result }) => {
                        data.push(result.map((c: any) => {

                            //@todo memo these
                            c.company = this.getCompany(c.companyId);
                            c.team_leader_name = this.getEmployeeName(c.team_leader_sub);
                            c.recruiter_name = this.getEmployeeName(c.recruiter_sub);
                            c.assigned_by_name = this.getEmployeeName(c.created_by);
                            c.aligned_by_name = c.aligned_by ? this.getEmployeeName(c.aligned_by) : '-'
                            c.created_by_name = this.getEmployeeName(c.created_by)
                            return c;
                        }))
                    })

                    this.setState({
                        loading: false,
                        // allCompanies: resultCompanies,
                        // employees: resultEmployees,
                        coordinationTrackerDetails: data.flat()
                    });

                })

        } else {
            this.setState({
                loading: false
            });

        }





        // this.setState({
        //     loading: false,
        //     coordinationTrackerDetails:result
        //     // candidates: resultCandidates, 
        //     // companies: resultCompainies,
        //     // employees: resultEmployees
        // })

    }

    getSelected = async () => {

        const { companies, filterState, selectedMonth, selectedYear, startDateFilter, endDateFilter, coordinationTrackerDetails, waitingRoomStatusFilter } = this.state;

        // if (waitingRoomStatusFilter === 'pending') {
        //get aligned candidates where aligned_on is null
        const resultCandidates: any = await API.get(REST_API_NAME, `/coordination/selected/1/${selectedMonth}/${selectedYear}/1`, {});

        let _c: any = {};

        let formatDataCandidates: any = {};
        let candidates: CoordinationTrackerInterface[] = []
        if (resultCandidates.result.length) {
            const candidateIds = resultCandidates.result.map((c: any) => {

                if (c.final_status == 'Select' || c.final_status == 'select') {
                    const companyName = c.selected_in_company_name;
                    let str = ''
                    str = `(${companyName})`
                    c.final_status += ' ' + str;
                }

                //@todo memo these
                c.company = this.getCompany(c.companyId);
                c.team_leader_name = this.getEmployeeName(c.team_leader_sub);
                c.recruiter_name = this.getEmployeeName(c.recruiter_sub);
                c.assigned_by_name = this.getEmployeeName(c.created_by);
                c.aligned_by_name = c.aligned_by ? this.getEmployeeName(c.aligned_by) : '-'
                c.created_by_name = this.getEmployeeName(c.created_by)

                candidates.push(c)

                return c.id
            }).join(',');

        } else {

        }




        this.setState({
            candidates,
            coordinationTrackerDetails: candidates as any,
            candidatesAligned: []
        });

        // }

    }
    filterByCompany = async (filterCompany: CompanyInterface, _filterState?: filterStatusType) => {
        const { companies, filterState, selectedMonth, selectedYear, startDateFilter, endDateFilter, coordinationTrackerDetails, waitingRoomStatusFilter } = this.state;

        const status = !_filterState ? filterState : _filterState;
        // if (waitingRoomStatusFilter === 'pending') {
        //get aligned candidates where aligned_on is null
        let mailSentStatus: MAIL_SENT_STATUS = MAIL_SENT_STATUS.MAIL_NOT_SENT;
        const resultCandidates: any = await API.get(REST_API_NAME, `/coordination/${status}/${filterCompany.id}/${selectedMonth}/${selectedYear}/${mailSentStatus}`, {});
        const resultFormats: any = await API.get(REST_API_NAME, '/coordination/format-for-company/' + filterCompany.id, {});
        const resultContacts: any = await API.get(REST_API_NAME, '/companies/contacts-for-company/' + filterCompany.id, {});

        let _c: any = {};
        const contacts = resultContacts.result.map((contact: any) => {
            const phonesString = contact.phones || '';
            const emailsString = contact.emails || '';

            let phones: PhoneInterface[] = [];
            phonesString.split(',').map((c: string) => {
                const [phone, id] = c.split(':')
                phones.push({ id: +id, phone, reference_id: '', reference_table: '' })
            })
            let emails: EmailInterface[] = [];
            emailsString.split(',').map((c: string) => {
                const [email, id] = c.split(':')
                emails.push({ id: +id, email, reference_id: '', reference_table: '' })
            })
            contact.phones = phones;
            contact.emails = emails;


            return contact;
        })

        let formatDataCandidates: any = {};
        let candidates: CoordinationTrackerInterface[] = []
        if (resultCandidates.result.length) {
            const candidateIds = resultCandidates.result.map((c: any) => {

                if (c.final_status == 'Select') {
                    const companyName = c.selected_in_company_name;
                    let str = ''
                    str = `(${companyName})`
                    c.final_status += ' ' + str;
                }
                candidates.push(c)

                return c.id
            }).join(',');
            const { data } = await API.get(REST_API_NAME, '/coordination/format-for-company-candidates/' + candidateIds + '/' + filterCompany.id, {});
            data.map((c: any) => formatDataCandidates[c.candidate_id] = 1);
        } else {

        }




        this.setState({
            filterCompany,
            formatRows: resultFormats.result.records,
            // candidates: resultCandidates.result,
            candidates,
            formatDataCandidates,
            contacts,
            candidatesAligned: []
        });

        // }

    }

    removeFilterByCompany = () => this.setState({ filterCompany: {} as CompanyInterface })

    setFilter = async (filterState: filterStatusType) => {
        this.setState({ filterState, candidates: [] });

        const { selectedMonth, selectedYear } = this.state;
        const months = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const monthNum = months.indexOf(selectedMonth);

        const { result: resultCompaniesCounts } = await API.get(REST_API_NAME, `/coordination/masters/initial-company-counts/${filterState}/${monthNum}/${selectedYear}`, {});
        this.setState({ companyCounts: resultCompaniesCounts.companies })


        if (filterState == 'assigned' || filterState == 'aligned') {
            this.getData(filterState, this.state.selectedMonth, this.state.selectedYear, this.state.filterCompanies)

        } else if (filterState == 'selected') {

            // this.getSelected();
            this.getData(filterState, this.state.selectedMonth, this.state.selectedYear, this.state.filterCompanies)


        } else if (this.state.filterCompany.id) {
            this.filterByCompany(this.state.filterCompany, filterState)
        }
    }

    selectMonth = (selectedMonth: string) => {
        this.setState({ selectedMonth, loading: true });
        this.getData(this.state.filterState, selectedMonth, this.state.selectedYear, this.state.filterCompanies)
    }
    selectYear = (selectedYear: string) => {
        this.setState({ selectedYear, loading: true });
        this.getData(this.state.filterState, this.state.selectedMonth, selectedYear, this.state.filterCompanies)
    }

    checkCanDelete = (data: CoordinationTrackerInterface) => {
        const user = this.props.user;
        let f = isSuperAdmin(user)
            || data.created_by == this.props.user.attributes.sub;

        return f;
    }
    setStartDateFilter = (dt: Date) => {
        this.setState({ startDateFilter: dt })
    }
    setEndDateFilter = (dt: Date) => {
        this.setState({ endDateFilter: dt })
    }
    onChange = (dates: [Date, Date]) => {
        const [start, end] = dates;
        this.setState({ startDateFilter: start, endDateFilter: end })
    };

    addCandidateToAlignedCompany = async (d: CoordinationTrackerInterface, checked: boolean) => {
        let ca = this.state.candidatesAligned;
        if (checked) {
            ca.push(d);
            this.checkCV(d)
        } else {
            ca = ca.filter(c => d.sentId != c.sentId)
        }
        this.setState({ candidatesAligned: ca });

    }

    includeCandidateToSendFormat = async (d: CoordinationTrackerInterface, checked: boolean) => {
        let ca = this.state.candidateToSendFormat;
        if (checked) {
            ca.push(d)
        } else {
            ca = ca.filter(c => d.sentId != c.sentId)
        }
        this.setState({ candidateToSendFormat: ca });

    }

    sendCandidates = async () => {
        const { candidatesAligned, selectedEmails, subject } = this.state;

        this.setState({ loading: true })



        const r = await API.post(REST_API_NAME, '/coordination/send-email', {
            body: {
                candidates: candidatesAligned.map(c => ({ identity: c.identityId, name: c.name, key: c.cvKey, sentId: c.sentId })),
                emails: selectedEmails.map(e => e.email),
                subject
            }
        });
        if (!r.success) {
            // alert('Some CVs missing.');
            toast.error('Some CVs missing.')
            console.log(r);
            this.setState({ loading: false })

            return;
        }
        this.setState({ candidatesAligned: [], selectedEmails: [], loading: false })
        // alert('Mail Sent');
        toast.success('Mail Sent')

        console.log(r)
    }

    sendCandidatesAndFormat = async () => {
        const { candidatesAligned, selectedEmails, subject, selectedFormatId, companies, filterCompany, candidates } = this.state;

        const noCVS = candidatesAligned.filter(ca => ca.cvExists === false);
        if (noCVS.length) {
            const names = noCVS.map(c => c.name).join(', ');
            toast.error(`${names} dont have a CV`);
            return;
        }


        // this.setState({ loading: true });
        let html = '';
        const tableEle = document.getElementById('format-table-data-' + selectedFormatId)!;
        if (tableEle) {
            const inputs = tableEle?.querySelectorAll('input') || [];
            inputs.forEach((inp, idx) => {
                inp.parentElement!.innerHTML = inp.value;
            })
            html = tableEle.parentElement!.innerHTML;
        } else {
            toast.info('No Format found. Sending only CVs')
        }

        // const candidates = candidatesAligned.map(c => ({ identity: c.identityId, name: c.name, key: c.cvKey, sentId: c.sentId }));
        // console.log(candidates, html, subject)

        // return;
        let _companies = companies;
        let _candidates = candidates;
        const r = await API.post(REST_API_NAME, '/coordination/send-email-and-format', {
            body: {
                candidates: candidatesAligned.map(c => ({ identity: c.identityId, name: c.name, key: c.cvKey, sentId: c.sentId })),
                emails: selectedEmails.map(e => e.email),
                subject,
                html
            }
        });

        if (!r.success) {
            toast.error('Some CVs missing.');
            console.log(r);
            this.setState({ loading: false })

            return;
        } else {
            toast.success('Mail Sent');
            this.sendCVEmailModal.toggle();
            _companies = companies.map(c => c.id == filterCompany.id ? { ...c, cnt: +c.cnt - candidatesAligned.length } : c);
            _candidates = candidates.filter(c => candidatesAligned.some(ca => ca.id !== c.id));
        }

        this.setState({
            subject: 'One Degree North - Candidates - ' + dayjs().format('DD/MM/YYYY'),
            candidatesAligned: [],
            candidateToSendFormat: [],
            selectedEmails: [],
            loading: false,
            companies: _companies,
            candidates: _candidates,
        })
        // alert('Mail Sent');
        console.log(r)
    }


    selectEmail = (checked: boolean, contact: ContactInterface, email: EmailInterface) => {
        if (checked) {
            this.setState({ selectedEmails: [...this.state.selectedEmails, email] })
        } else {
            this.setState({ selectedEmails: this.state.selectedEmails.filter(s => s.id !== email.id) })
        }

    }

    filterTable = (field: string, value: string) => {

        let filtersApplied = this.state.filtersApplied;

        if (value) {
            const f = filtersApplied.find((f: any) => f.field == field);
            if (f) {
                filtersApplied = filtersApplied.filter((f: any) => f.field !== field);
            }
            filtersApplied.push({ field, value })
        } else {
            filtersApplied = filtersApplied.filter((f: any) => f.field !== field);
        }
        // this.setState({ filter: { [field]: value } })
        this.setState({ filtersApplied })
    }

    filterWaitingRoom = (waitignRoomFilterQuery: string) => this.setState({ waitignRoomFilterQuery })


    setStatusFilterWaitingRoom = async (waitingRoomStatusFilter: "pending" | "completed") => {

        const { filterState, selectedMonth } = this.state;

        // toast.info('Tracker 2');

        this.setState({
            loading: true,
            waitingRoomStatusFilter
        })

        if (waitingRoomStatusFilter == 'pending') {

            const { result } = await API.get(REST_API_NAME, `/coordination/masters/initial-v3/${selectedMonth}`, {});
            const resultAllCompanies = result.allCompanies;
            const resultCompanies = result.companies;
            const resultEmployees = result.employees;

            this.setState({
                loading: false,
                // candidates: resultCandidates, 
                companies: resultCompanies,
                employees: resultEmployees,
                allCompanies: resultAllCompanies
            })
        } else if (waitingRoomStatusFilter == 'completed') {

            const { result } = await API.get(REST_API_NAME, `/coordination/aligned-companies-count/${selectedMonth}`, {});
            const resultCompanies = result.companies;

            this.setState({
                loading: false,
                // candidates: resultCandidates, 
                companies: resultCompanies,
            })

        }


    }

    getDataForFormat = (format: FormatRowInterface, id: number) => {
        const { formatData } = this.state;
        const data = formatData.find((f: any) => f.id == id);

        return data ? data[format.mapping_coloumn] : ''
    }

    showFormat = async (candidates: any) => {

        const { formatRows, filterCompany } = this.state;
        if (!filterCompany.id || !candidates.length) { return }


        const candidateIds = candidates.map((c: CandidateInterface) => c.id).join(',');


        const formats = await API.get(REST_API_NAME, '/coordination/format-for-companies-candidates/' + candidateIds + '/' + filterCompany.id, {});

        const { data } = formats;
        let companyFormat: any = {};

        formats.result.records.map((f: any) => {
            if (!f.format_id) { return }
            const company = this.state.companies.find(c => c.id == f.company_id) || { name: '' };
            const records = data;
            // const record = records.find((rec: any) => rec.format_id = f.format_id)

            const _data = records.filter((r: any) => r.format_id == f.format_id);
            companyFormat[f.format_id] = companyFormat[f.format_id] || { formatId: f.format_id, companyId: f.company_id, companyName: company.name, name: f.name, rows: [], data: _data };

            companyFormat[f.format_id].rows.push(f)
            // const company = this.state.companies.find(c => c.id == f.company_id)!
            // companyFormat[f.company_id] = companyFormat[f.company_id] || { companyId: f.company_id, companyName: company.name, name: f.name, rows: [] };
            // companyFormat[f.company_id].rows.push(f)
        })
        this.setState({ formats: Object.values(companyFormat), loading: false });

        //@todo commenting since its a combined modal now
        // this.addFormatDataModal.toggle();

    }

    sendFormat = async (selectedEmails: EmailInterface[], html1: string) => {
        const { subject, selectedFormatId } = this.state;
        this.setState({ loading: true })
        const tableEle = document.getElementById('format-table-data-' + selectedFormatId)!;
        let html = '';
        const inputs = tableEle?.querySelectorAll('input') || [];
        inputs.forEach((inp, idx) => {
            inp.parentElement!.innerHTML = inp.value;
        })
        html = tableEle.parentElement!.innerHTML;
        const data = await API.post(REST_API_NAME, '/coordination/send-format', {
            body: {
                html,
                subject,
                emails: selectedEmails.map(e => e.email)
            }
        });
        if (data.success) {
            toast.success('Mail Sent');
            // this.formatModal.toggle();
            this.addFormatDataModal.toggle();
        } else {
            toast.error('Error Sending Mail')
        }
        this.setState({ loading: false })

    }
    sendFormatOLD = async () => {
        const { subject, selectedEmails, selectedFormatId } = this.state;
        this.setState({ loading: true })
        const tableEle = document.getElementById('format-table-data-' + selectedFormatId)!;
        let html = '';
        const inputs = tableEle?.querySelectorAll('input') || [];
        inputs.forEach((inp, idx) => {
            inp.parentElement!.innerHTML = inp.value;
        })
        html = tableEle.parentElement!.innerHTML;
        const data = await API.post(REST_API_NAME, '/coordination/send-format', {
            body: {
                html,
                subject,
                emails: selectedEmails.map(e => e.email)
            }
        });
        if (data.success) {
            toast.success('Mail Sent');
            // this.formatModal.toggle();
            this.addFormatDataModal.toggle();
        } else {
            toast.error('Error Sending Mail')
        }
        this.setState({ loading: false })

    }
    changeSubject = (subject: string) => this.setState({ subject })

    showSendModal = () => {

        const { candidateToSendFormat } = this.state;

        this.showFormat(candidateToSendFormat)
        this.sendCVEmailModal.toggle();
    }

    selectFormat = (formatId: string) => this.setState({ selectedFormatId: formatId })

    filterCandidates = async () => {

        const { candidateFilter } = this.state;

        if (candidateFilter.name) {
            candidateFilter.name = candidateFilter.name.trim();
            if (!candidateFilter.name) {
                return
            }
        }
        if (candidateFilter.number) {
            candidateFilter.number = candidateFilter.number.trim();
        }
        if (candidateFilter.email) {
            candidateFilter.email = candidateFilter.email.trim();
        }

        let q = {
            ...candidateFilter
        };
        if (candidateFilter.createdOn) {
            q.createdOn = dayjs(candidateFilter.createdOn).format('YYYY-MM-DD');
        }
        if (candidateFilter.recruiter_sub) {
            q.recruiter_sub = candidateFilter.recruiter_sub.value;
        }




        const data = await API.get(REST_API_NAME, '/candidates/filter', {
            queryStringParameters: q
        });

        this.setState({ filteredCandidates: data.result })

    }

    setToggleShowDuplicates = () => this.setState({ showDuplicates: !this.state.showDuplicates })

    addToSendLinksForCompanies = (company: { company: string, link: string }, checked: boolean) => {
        if (checked) {
            this.setState({ sendLinksForCompanies: [company, ...this.state.sendLinksForCompanies] })
        } else {
            this.setState({ sendLinksForCompanies: this.state.sendLinksForCompanies.filter(c => c.company != company.company) })
        }
    }

    selectFilterCompanies = (companies: any) => {
        this.setState({ filterCompanies: companies });
        this.getData(this.state.filterState, this.state.selectedMonth, this.state.selectedYear, companies)


    }

    render() {
        const user = this.props.user;
        const { companyCounts,
            coordinationTrackerDetails,
            filtersApplied,
            selectedYear,
            companiesAssignedByRecruiter, sendLinksForCompanies, allCompanies, showDuplicates, contacts, filteredCandidates, candidates, candidateFilter, candidateToSendFormat, formatDataCandidates, subject, formats, formatRows, waitingRoomStatusFilter, waitignRoomFilterQuery, employees, filter, selectedEmails, candidatesAligned, selectedMonth, filterCompany, filterState, startDateFilter, endDateFilter, candidate, companies, loading, mode, selectedCompanies } = this.state;

        //show only non disabled companies
        const optionsCompanies = companiesAssignedByRecruiter.map((c: CompanyInterface) => ({ value: c.id + '', label: c.name }))
        // const optionsCandidates = candidates.map((c: CandidateInterface) => ({ value: c.id, label: c.name, ...c }))
        const selectedCandidate = candidate.id == -1 ? [] : [{ value: candidate.id, label: candidate.name, ...candidate }]

        let _c: { [key: string]: CompanyInterface & { count: number } } = {};


        const coordinationTrackerDetailsFiltered: CoordinationTrackerInterface[] = coordinationTrackerDetails.filter((c: any) => {

            const f = filtersApplied.every((f: any) => {

                if (f.field == 'final_status_date') {
                    return dayjs(c[f.field]).format('DD/MM/YYYY') == f.value
                }

                return c[f.field] == f.value
            })

            return f;


        });

        console.log(coordinationTrackerDetails, coordinationTrackerDetailsFiltered)

        const candidatesFiltered = candidates.filter(ct => {
            if (filter.tl && ct.team_leader_sub != filter.tl) {
                return false;
            }
            if (filter.recruiter && ct.recruiter_sub != filter.recruiter) {
                return false;
            }
            if (filter.final_status && ct.final_status != filter.final_status) {
                return false;
            }

            return true
        })

        let FORMAT_FIELDS_HASHED: any = {};
        for (let k in FORMAT_FIELDS) {
            FORMAT_FIELDS_HASHED[k] = FORMAT_FIELDS_HASHED[k] || {};
            FORMAT_FIELDS[k].map((f: any) => FORMAT_FIELDS_HASHED[k][f.key] = f.label)
        }

        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <div className='my-5'>
                            <div className='row'>

                                <div className='col-lg-3'>
                                    <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                        <div className="btn-group me-2" role="group" aria-label="First group">
                                            <MonthSelector loading={loading} selectMonth={this.selectMonth} selectedMonth={selectedMonth} />
                                            <div className="dropdown">
                                                <button className="btn btn-primary dropdown-toggle" disabled={loading} type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                                    {selectedYear}
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                                                    {/* <li onClick={() => this.selectMonth('NONE')}><a className="dropdown-item" href="#">NONE</a></li> */}
                                                    {
                                    YEARS.map(year=><li onClick={() => this.selectYear(year+"")}><a className="dropdown-item" href="#">{year}</a></li>)
                                }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {isOnlyRecruiter(user)
                                    ? ''
                                    : <div className='col-lg-2'>
                                        <button onClick={() => { this.sendCandidateToCompanyModal.toggle() }} className='btn btn-primary' disabled={loading}>Assign <i className="bi bi-person-plus-fill"></i></button>
                                    </div>
                                }



                                <div className='col-lg-4'>
                                    <div className="btn-toolbar" role="toolbar" aria-label="Filter Status">
                                        <div className="btn-group me-2" role="group" aria-label="First group">
                                            <button type="button" className={"btn btn-primary " + (filterState == 'assigned' ? 'active btn-success' : '')} disabled={loading} onClick={() => this.setFilter('assigned')}>Assigned</button>
                                            <button type="button" className={"btn btn-primary " + (filterState == 'aligned' ? 'active btn-success' : '')} disabled={loading} onClick={() => this.setFilter('aligned')}>Aligned</button>
                                            <button type="button" className={"btn btn-primary " + (filterState == 'selected' ? 'active btn-success' : '')} disabled={loading} onClick={() => this.setFilter('selected')}>Selected</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-2'>
                                    <label>Company</label>
                                    <Select
                                        options={companyCounts.map((c: any) => ({ label: `${c.company} (${c.cnt})`, value: c.id }))}
                                        onChange={this.selectFilterCompanies}
                                    />
                                </div>

                                <div className='col-lg-2'>
                                    {/* <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={() => this.setToggleShowDuplicates()} />
                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Duplicates</label>
                                    </div> */}

                                    {/* <Select options={FINAL_STATUS_FIELDS.map((c:any) => ({ label: c.label, value: c.value })) as any}
                                            // isMulti={true}
                                            onChange={(e) => this.setFilterField('recruiter_sub', e)}
                                            value={candidateFilter.recruiter_sub}
                                        // isOptionDisabled={(opt) => companies.find(c => c.id == +opt.value)!.disabled}
                                        // isDisabled={candidate.number == '' && candidate.email == ''}
                                        /> */}

                                </div>

                                {/* <div className='col-2'>
                                        {candidateToSendFormat.length
                                            ? <button onClick={() => { this.showFormat(candidateToSendFormat) }} className='btn btn-primary' disabled={!!!filterCompany.id}>Send Format <i className="bi bi-person-plus-fill"></i></button>
                                            : ''
                                        }
                                    </div> */}

                                <div className='col-lg-2'>
                                    {
                                        !isOnlyRecruiter(user) && (candidatesAligned.length || candidateToSendFormat.length)
                                            ? <>
                                                <button onClick={() => this.showSendModal()} className='btn btn-primary' disabled={loading}>Send<i className="bi bi-envelope"></i></button>
                                            </>
                                            : ''
                                    }
                                </div>

                            </div>
                            <hr />
                            {loading ? <div className="spinner-border text-success" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> : ''}

                            {
                                <table className='table table-bordered table-hover sticky-header'>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Number</th>
                                            {/* <th>Company</th> */}
                                            <Col
                                                title='Company'
                                                filterData={allCompanies.map(e => ({ label: e.name, value: e.id }))}
                                                filterFunction={this.filterTable}
                                                filterType='list'
                                                filterField='companyId'
                                            />
                                            {/* <th>SentId</th> */}

                                            {/* <th>HR</th>
                                            <th>HR Feedback</th> */}

                                            {/* <th>Recruiter</th> */}
                                            <Col
                                                title='Rec'
                                                filterData={employees.filter(e => e.role.indexOf('recruiter') != -1).map(e => ({ label: e.name, value: e.sub }))}
                                                filterFunction={this.filterTable}
                                                filterType='list'
                                                filterField='recruiter_sub'
                                            />
                                            <Col
                                                title='TL'
                                                filterData={employees.filter(e => e.role.indexOf('teamleader') != -1).map(e => ({ label: e.name, value: e.sub }))}
                                                filterFunction={this.filterTable}
                                                filterType='list'
                                                filterField='team_leader_sub'
                                            />
                                            <th>TL Rating</th>
                                            <Col
                                                title='Status'
                                                filterData={FINAL_STATUS_FIELDS.map((e: any) => ({ label: e.label, value: e.value }))}
                                                filterFunction={this.filterTable}
                                                filterType='list'
                                                filterField='final_status'
                                            />
                                            <Col
                                                title='Status Date'
                                                filterData={[]}
                                                filterFunction={this.filterTable}
                                                filterType='date'
                                                filterField='final_status_date'
                                            />
                                            {/* <th>Status Date</th> */}


                                            <th>Feedback Comment</th>


                                            {/* <th>Final Status</th> */}
                                            <th>Assigned</th>
                                            {/* <th>Assigned On</th> */}
                                            <th>Aligned</th>
                                            {/* <th>Aligned On</th> */}
                                            <th>DoJ</th>

                                            <th>Month</th>
                                            <th>Offer Status</th>

                                            {(isSuperAdmin(user)) ?
                                                <>
                                                    <th>Amount</th>
                                                    <th>Tax Amount</th></>
                                                : ''}
                                            {/* <th>Amount</th>
                                            <th>Tax Amount</th> */}
                                            <th>invoice Sent</th>

                                            <th>Applicant Id</th>
                                            <th>Review</th>
                                            <th>Points</th>

                                            <th></th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {candidates.filter(d => showDuplicates ? d.final_status === 'Duplicate' : d.final_status !== 'Duplicate').map((candidate, idx) => <tr key={'tr--candidates-' + candidate.sentId}> */}
                                        {coordinationTrackerDetailsFiltered
                                            .map((candidate, idx) => <tr key={'tr--candidates-' + candidate.id}>
                                                {/* {candidatesFiltered.map((candidate, idx) => <tr key={'tr--candidates-' + candidate.sentId}> */}
                                                <td>
                                                    <div className="btn-toolbar candidate-action-buttons" role="toolbar" aria-label="Toolbar with button groups">
                                                        <div className="btn-group me-2" role="group" aria-label="First group">
                                                            <i className="bi bi-file-earmark-arrow-down download-button cursor-pointer" onClick={() => { !loading && this.getFile(candidate); }} ></i>
                                                            {
                                                                this.checkCanDelete(candidate)
                                                                    ? <i className="bi bi-trash-fill delete-button" onClick={() => { if (window.confirm('Are you sure you want to delete this row?')) { this.deleteRow(candidate.id); } }} ></i>
                                                                    : ''
                                                            }
                                                        </div>
                                                    </div>

                                                </td>
                                                <td>{idx + 1}</td>
                                                <td>{candidate.name}</td>
                                                {/* <td>{candidate.number}</td> */}
                                                <Cell enabled={true} disableEdit={true} valueType='html' renderer={(v: string, d) => {
                                                    return v.replace('/', '<br />')
                                                }} data={candidate} value={candidate.number} type={"text"} onChange={() => console.log} field={'number'} />
                                                <Cell data={candidate} type={"select"} value={candidate.company} list={companies.map(c => ({ ...c, label: c.name, value: c.id + "" }))} onChange={(d) => this.updateField(d, 'companyId')} field={'companyId'} />

                                                {/* <Cell data={candidate} type={"text"} value={candidate.hr_name} onChange={(d) => this.updateCell(d, 'hr_name')} field={'hr_name'} /> */}
                                                {/* <Cell data={candidate} type={"text"} value={candidate.hr_feedback} onChange={(d) => this.updateCell(d, 'hr_feedback')} field={'hr_feedback'} /> */}

                                                <td>{formatEmployeeName(candidate.recruiter_name)}</td>
                                                <td>{formatEmployeeName(candidate.team_leader_name)}</td>
                                                <td>{candidate.tl_rating}</td>
                                                <Cell enabled={true} data={candidate} type={"select"} renderer={(value: string, data: CoordinationTrackerInterface) => {
                                                    if (value == 'Select') {
                                                        // console.log('Select', data);
                                                        const c = allCompanies.find(c => c.id == data.selected_in_company);
                                                        const companyName = c ? c.name : '-';
                                                        value = 'select (' + companyName + ')'
                                                    }
                                                    return value;
                                                }} value={candidate.final_status} list={[{ label: "", value: "" }, ...FINAL_STATUS_FIELDS]} onChange={(d) => this.updateField(d, 'final_status')} field={'final_status'} />
                                                <td>{candidate.final_status_date ? dayjs(candidate.final_status_date).format('DD/MM/YYYY') : ''}</td>
                                                <Cell enabled={true} data={candidate} type={"text"} value={candidate.final_status_feedback} onChange={(d) => this.updateField(d, 'final_status_feedback')} field={'final_status_feedback'} />


                                                <td>{formatEmployeeName(candidate.created_by_name || '')}<br />{dayjs(filterState == 'assigned' ? candidate.assignedOn : candidate.assignedOn).format('DD/MM/YY')}</td>
                                                {/* <td>{dayjs(filterState == 'assigned' ? candidate.assignedOn : candidate.assignedOn).format('DD/MM/YY')}</td> */}

                                                <td>{formatEmployeeName(candidate.aligned_by_name)}<br />{candidate.aligned_on ? dayjs(candidate.aligned_on).format('DD/MM/YY') : '-'}</td>
                                                {/* <td>{candidate.aligned_on ? dayjs(candidate.aligned_on).format('DD/MM/YY') : '-'}</td> */}
                                                {/* <td>{candidate.doj ? dayjs(candidate.doj).format('DD/MM/YY') : '-'}</td> */}
                                                <Cell enabled={true} data={candidate} type={"date"} renderer={(v: string | number, d: CoordinationTrackerInterface) => (!v || v == 'null') ? '-' : (dayjs(v).format('DD/MM/YY'))} value={candidate.doj} onChange={(d) => this.updateField(d, 'doj')} field={'doj'} />

                                                <td>{candidate.join_month}</td>
                                                {/* <td>{candidate.offer_status}</td> */}
                                                <Cell enabled={true} data={candidate} type={"select"} value={candidate.offer_status} list={[{ label: "", value: "" }, ...OFFER_STATUS_FIELDS]} onChange={(d) => this.updateField(d, 'offer_status')} field={'offer_status'} />
                                                {(isSuperAdmin(user)) ?
                                                    <>
                                                        <td>{candidate.invoice_amount}</td>
                                                        <td>{candidate.invoice_amount_actual}</td>
                                                    </>
                                                    : ''}

                                                {/* <td>{candidate.invoice_sent}</td> */}
                                                <Cell enabled={true} data={candidate} type={"select"} renderer={(r, d) => r == "1" ? "Yes" : "No"} value={candidate.invoice_sent ? 1 : 0} list={[{ label: "", value: "" }, { label: "Yes", value: "" + 1 }, { label: "No", value: "" + 0 }]} onChange={(d) => this.updateField(d, 'invoice_sent')} field={'invoice_sent'} />

                                                <Cell enabled={true} data={candidate} type={"text"} value={candidate.applicant_id} onChange={(d) => this.updateField(d, 'applicant_id')} field={'applicant_id'} />
                                                <Cell enabled={true} data={candidate} type={"text"} value={candidate.review} onChange={(d) => this.updateField(d, 'review')} field={'review'} />

                                                {/* <td>{candidate.points}</td> */}
                                                <Cell enabled={true} data={candidate} type={"number"} value={candidate.points} onChange={(d) => this.updateField(d, 'points')} field={'points'} />


                                                <td>
                                                    {candidate.noFormatData}
                                                </td>

                                                {/* <td>{formatEmployeeName(candidate.created_by_name)}</td>
                                                <td>{dayjs(candidate.created_on).format('DD/MM/YY')}</td> */}




                                            </tr>)}
                                        {/* {coordinationTrackerDetailsFiltered.map((d, idx) => <tr key={'tr-' + d.sentId + '-' + d.id}>
                                    <td>
                                        <div className="btn-toolbar candidate-action-buttons" role="toolbar" aria-label="Toolbar with button groups">
                                            <div className="btn-group me-2" role="group" aria-label="First group">
                                                <i className="bi bi-file-earmark-arrow-down download-button cursor-pointer" onClick={() => { !loading && this.getFile(d); }} ></i>

                                                {
                                                    this.checkCanDelete(d)
                                                        ?
                                                        <i className="bi bi-trash-fill delete-button" onClick={() => { if (window.confirm('Are you sure you want to delete this row?')) { this.deleteRow(d.sentId); } }} ></i>
                                                        : ''

                                                }

                                            </div>
                                        </div>

                                    </td>
                                    <td>{idx + 1}</td>
                                    <td>{d.name}</td>
                                    <td>{d.number}</td>
                                    <Cell data={d} type={"select"} value={d.company} list={companies.map(c => ({ ...c, label: c.name, value: c.id + "" }))} onChange={(d) => this.updateCell(d, 'companyId')} field={'companyId'} />

                                    <Cell data={d} type={"text"} value={d.hr_name} onChange={(d) => this.updateCell(d, 'hr_name')} field={'hr_name'} />
                                    <Cell data={d} type={"text"} value={d.hr_feedback} onChange={(d) => this.updateCell(d, 'hr_feedback')} field={'hr_feedback'} />
                                    <td>{formatEmployeeName(d.recruiter_name)}</td>
                                    <td>{formatEmployeeName(d.team_leader_name)}</td>
                                    <td>{d.tl_rating}</td>
                                    <Cell enabled={!!d.aligned_on} data={d} type={"select"} value={d.final_status} list={[{ label: "", value: "" }, ...FINAL_STATUS_FIELDS]} onChange={(d) => this.updateCell(d, 'final_status')} field={'final_status'} />

                                    {!isOnlyRecruiter(user)
                                        ?
                                        <>
                                            <td className={d.cvExists === false ? 'bg-danger' : ''} >
                                                {
                                                    d.aligned_on
                                                        ? ('Mail Sent On:' + dayjs(d.aligned_on).format('DD/MM/YYYY HH:mm'))
                                                        : ''
                                                }
                                                <div>
                                                    {filterCompany.id ? <input type='checkbox' value={filterCompany.id} onChange={(evt) => this.addCandidateToAlignedCompany(d, evt.target.checked)} checked={candidatesAligned.some(c => c.id == d.id)} /> : ''}
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    {filterCompany.id ? <input type='checkbox' disabled={!!!formatDataCandidates[d.id]} value={filterCompany.id} onChange={(evt) => this.includeCandidateToSendFormat(d, evt.target.checked)} checked={candidateToSendFormat.some(c => c.id == d.id)} /> : ''}
                                                </div>
                                            </td>
                                        </>
                                        : ''
                                    }
                                </tr>)} */}
                                    </tbody>
                                </table>
                            }

                            <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.sendCandidateToCompanyModalRef} data-bs-backdrop="static">
                                <div className="modal-dialog modal-xl">
                                    <div className="modal-content modal-xl">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">Assign</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body modal-x">
                                            <div className='form'>
                                                <form className=" g-3">

                                                    <div className="mb-3 row">
                                                        <h2>Please enter any one of the fields to find candidates</h2>
                                                        <table className='table table-bordered'>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <input type='text' placeholder='Name' className='form-control' onBlur={(evt) => this.setFilterField('name', evt.target.value)} />
                                                                    </td>
                                                                    <td>
                                                                        <input type='text' placeholder='Phone' className='form-control' onBlur={(evt) => this.setFilterField('number', evt.target.value)} />
                                                                    </td>
                                                                    <td>
                                                                        <input type='text' placeholder='Email' className='form-control' onBlur={(evt) => this.setFilterField('email', evt.target.value)} />
                                                                    </td>
                                                                    <td>
                                                                        <Select options={employees.map(c => ({ label: c.name, value: c.sub + '' })) as any}
                                                                            // isMulti={true}
                                                                            onChange={(e) => this.setFilterField('recruiter_sub', e)}
                                                                            value={candidateFilter.recruiter_sub}
                                                                        // isOptionDisabled={(opt) => companies.find(c => c.id == +opt.value)!.disabled}
                                                                        // isDisabled={candidate.number == '' && candidate.email == ''}
                                                                        />
                                                                    </td>
                                                                    {/* <td>
                                                                            <Select options={companies.map(c => ({ label: c.name, value: c.id + '' })) as any}
                                                                                isMulti={true}
                                                                                onChange={(e) => this.setCompanyFilter([e] as unknown as CompanyInterface[])}
                                                                                value={candidateFilter.companies}
                                                                            // isOptionDisabled={(opt) => companies.find(c => c.id == +opt.value)!.disabled}
                                                                            // isDisabled={candidate.number == '' && candidate.email == ''}
                                                                            />
                                                                        </td> */}
                                                                    <td>
                                                                        <input type='date' className='form-control' onChange={(evt) => this.setFilterField('createdOn', evt.target.valueAsDate as any)} />
                                                                    </td>
                                                                </tr>
                                                                {/* <tr>
                                                                        <td>&nbsp;</td>
                                                                    </tr> */}
                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        <button className='btn btn-info btn-sm' disabled={loading} type='button' onClick={this.filterCandidates}>Find</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                        <table className='table table-bordered'>
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Name</th>
                                                                    <th>Number</th>
                                                                    <th>Email</th>
                                                                    <th>Recruiter</th>
                                                                    <th>Created On</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {filteredCandidates.map(candidate => <tr key={'fc-' + candidate.id}>
                                                                    <td>
                                                                        <input type='radio' name='select-candidate' value={candidate.id} onChange={(evt) => this.selectCandidate(candidate as CoordinationTrackerInterface)} />
                                                                    </td>
                                                                    <td>{candidate.name}</td>
                                                                    <td>{candidate.number}</td>
                                                                    <td>{candidate.email}</td>
                                                                    <td>{candidate.recruiter_name}</td>
                                                                    <td>{dayjs(candidate.created_on).format('DD/MM/YYYY hh:mm')}</td>
                                                                </tr>)}
                                                            </tbody>

                                                        </table>
                                                    </div>

                                                    {/* <div className="mb-3 row">
                                                            <label htmlFor="inputCompany" className="col-sm-2 col-form-label">Candidate</label>
                                                            <div className="col-sm-10">

                                                                <Select
                                                                    value={candidate as CandidateInterface}
                                                                    options={optionsCandidates}
                                                                    onChange={(e) => this.selectCandidate([e] as unknown as CoordinationTrackerInterface[])}
                                                                />

                                                            </div>
                                                        </div> */}


                                                    <div className="mb-3 row">
                                                        <label htmlFor="inputCompany" className="col-sm-2 col-form-label">Company</label>
                                                        <div className="col-sm-10">
                                                            {/* <input type="text" className="form-control" id="inputCompany" onChange={(evt) => this.setField('company', evt.target.value)} /> */}
                                                            <Select options={optionsCompanies}
                                                                isMulti={true}
                                                                onChange={(e) => this.selectCompany([e] as unknown as CoordinationTrackerInterface[])}
                                                                value={selectedCompanies}
                                                                isOptionDisabled={(opt) => allCompanies.find(c => c.id == +opt.value)!.disabled}
                                                                isDisabled={candidate.number == ''}
                                                            />


                                                            {/* <MultiSelect
                                                                    hasSelectAll={false}
                                                                    options={optionsCompanies}
                                                                    value={selectedCompanies}
                                                                    onChange={(e: any) => this.selectCompany(e)}
                                                                    labelledBy="Select"
                                                                /> */}


                                                            <div>
                                                                <h4>Send Registration Links</h4>
                                                                {selectedCompanies.map(opt => {
                                                                    const company = allCompanies.find(c => c.id == +opt.value);
                                                                    if (company && company.registration_link != null) {
                                                                        return (<div className="form-check form-switch" key={'mail-toggle-' + company.id}>
                                                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={(evt) => this.addToSendLinksForCompanies({ company: company.name, link: company.registration_link }, evt.currentTarget.checked)} />
                                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{company.name}</label>
                                                                        </div>)
                                                                    }
                                                                })}

                                                                <div id='reg-links-table'>
                                                                    <table className='table table-bordered' style={{ border: "1px solid black" }}>
                                                                        <thead style={{ border: "1px solid black" }}>
                                                                            <tr style={{ border: "1px solid black" }}>
                                                                                <th style={{ border: "1px solid black" }}>Company</th>
                                                                                <th style={{ border: "1px solid black" }}>Link</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody style={{ border: "1px solid black" }}>
                                                                            {sendLinksForCompanies.map((s, idx) => (<tr style={{ border: "1px solid black" }} key={'link-' + idx}>
                                                                                <td style={{ border: "1px solid black" }}>{s.company}</td>
                                                                                <td style={{ border: "1px solid black" }}>{s.link}</td>
                                                                            </tr>))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </form>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                            <button type="button" className="btn btn-primary" onClick={this.sendToCoordniator} disabled={loading || !selectedCompanies.length || candidate.id == -1}>Assign</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <ToastContainer
                                position="top-center"
                                autoClose={false}
                                hideProgressBar
                                newestOnTop
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />

                            <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: 11 }} data-bs-delay={2000}>
                                <div id="liveToast" className="toast hide bg-success" role="alert" aria-live="assertive" aria-atomic="true" ref={this.toastRef}>
                                    <div className="toast-header">
                                        <strong className="me-auto">Success</strong>
                                        {/* <small>11 mins ago</small> */}
                                        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                                    </div>
                                    <div className="toast-body bg-success">
                                        Data saved successfully.
                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                </div>
            </>
        )
    }
}

type CellType = "text" | "select" | "date" | "number";
type CellOption = { label: string, value: string };
// type CellOptions = {label: string, value: string };
interface CellPropsInterface {
    data: CoordinationTrackerInterface
    field: keyof CoordinationTrackerInterface
    value: string | number
    type: CellType

    list?: CellOption[]
    onChange: (d: CoordinationTrackerInterface) => void
    enabled?: boolean
    renderer?: (value: string, data: CoordinationTrackerInterface) => string
    disableEdit?: boolean
    valueType?: 'html'


}

const Cell = (props: CellPropsInterface) => {

    const [edit, setEdit] = useState(false);
    const [value, setValue] = useState(props.value);
    const enabled = props.enabled === undefined ? true : props.enabled


    const setEditable = () => {
        if (!!props.disableEdit) {
            return;
        }
        // console.log('Disabling editing')
        setEdit(true);
    }
    const changeValue = (e: any) => {
        const target = e.target;
        if (props.type == "select") {
            setValue(target.options[target.selectedIndex].innerText);
        } else {
            setValue(target.value)
        }
        setEdit(false);
        props.onChange({ ...props.data, [props.field]: target.value })
    }

    const renderElement = (type: CellType, list?: CellOption[]) => {
        let ele;
        switch (type) {
            case "date": ele = <input className={'form-control'} type='date' defaultValue={props.value} onBlur={changeValue} />; break;
            case "text": ele = <input className={'form-control'} type='text' defaultValue={props.value} onBlur={changeValue} />; break;
            case "number": ele = <input className={'form-control'} type='number' min={0} defaultValue={props.value} onBlur={changeValue} />; break;
            case "select": ele = <select className={'form-select'} defaultValue={props.data[props.field] as string} onBlur={changeValue} >
                {list?.map(l => <option key={'opt-' + l.label} value={l.value}>{l.label}</option>)}
            </select>; break;
        }

        return ele;
    }



    const renderBasedOnType = (value: string | number) => {
        return props.valueType === 'html'
            ? <span dangerouslySetInnerHTML={{ __html: value + '' }}></span>
            : value;
    }

    return (<td onClick={!enabled ? () => { } : setEditable} className={enabled ? 'enabled' : 'bg-secondary'}>
        {edit
            ? renderElement(props.type, props.list)
            : props.renderer ? renderBasedOnType(props.renderer(value + '', props.data)) : renderBasedOnType(value)
        }
    </td>)

}


interface ColInterface {
    filterFunction?: (f: string, v: any) => void
    filterData: any[]
    filterType: 'none' | 'list' | 'text' | 'date'
    title: string
    filterField: string

}

const Col = ({ title, filterFunction, filterData = [], filterType = 'none', filterField }: ColInterface) => {

    const [showFilter, setShowFilter] = useState(false);
    const [filterDate, setFilterDate] = useState<Date | null>(new Date);

    let ele: any = '';
    switch (filterType) {
        case 'list': ele = <select className='' onChange={(e) => filterFunction!(filterField, e.target.value)}>
            <option value=''>ALL</option>
            {filterData.map((fc: any) => <option key={'filter-' + fc.label} value={fc.value}>{fc.label}</option>)}
            {/* {filterData.sort((a: any, b: any) => a.localeCompare(b)).map((fc: any) => <option key={'filter-' + fc}>{fc}</option>)} */}
        </select>; break;
        case 'text': ele = <input />; break;
        // case 'date': ele = <input type='date' className='' onChange={(e) => filterFunction!(filterField, e.target.value)} />; break;
        case 'date': ele = <ReactDatePicker
            isClearable={true}
            dateFormat="dd/MM/yyyy"
            selected={filterDate}
            onChange={(e) => { console.log(e); setFilterDate(e as Date); filterFunction!(filterField, !e ? '' : dayjs(e as Date).format('DD/MM/YYYY')); }} />;
            break;
    }

    return (<th>
        <div className='coloumn-label'>
            {title}
            <i className="bi bi-funnel-fill cursor-pointer" onClick={() => setShowFilter(!showFilter)}></i>
        </div>
        {showFilter && (!filterFunction
            ? ''
            :
            <div className='coloumn-filter'>
                {ele}
            </div>
        )}
    </th>)
}

export default CoordinationTrackerList;