import API from "@aws-amplify/api";
import React, { createRef, useEffect, useState } from "react";
import * as bootstrap from 'bootstrap';
import dayjs from "dayjs";
import { graphqlOperation } from "aws-amplify";
import { createJoiners } from "../graphql/mutations";
import * as subscriptions from '../graphql/subscriptions';
import { CompanyInterface, EmployeeInterface } from "../types";
import { formatEmployeeName, isSuperAdmin, MONTHS, REST_API_NAME, YEARS } from "../utils";
import Select from "react-select";



import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const lists = {
    months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    tls: [
        "Rashmi",
        "Zubaina",
        "Zubaida",
        "Shruti",
        "Mithi",
        "Yousuf",
        "Srinivas",
        "Poojitha",
        "Raheen",
        "Raj"
    ],
    status: [
        "Offer", "Joiner", "Drop"
    ],
    payment: [
        "Yes", "No", "Not Eligible"
    ],
    recruiters: [

    ],
    yesNoGeneric: ["yes", "no"],
    yesNo: [
        { value: 0, label: "No" },
        { value: 1, label: "Yes" }
    ],
    yesNoAttrition: [
        { value: 0, label: "No" },
        { value: 1, label: "Yes" },
        { value: 2, label: "Attrition" }
    ],
    entity: [
        { value: 'ODN', label: 'ODN' },
        { value: 'ODN HR', label: 'ODN HR' },
    ]
}

interface TableFilterFieldInterface {
    [field: string]: string | any[]
}

interface JoinerTrackerStateInterface {
    joiners: any[],
    editJoinerRowData: any, // For edit fields
    newJoinerRowData: any,  //For New Rows 
    showModal: boolean,
    editRowId: number,
    editField: string,
    filters: any,
    filterField: string,
    query: string | string[],
    selectedMonth: string
    selectedYear: string
    autoSaveField: string
    autoSaveFieldId: string
    employees: EmployeeInterface[],
    recruiters: EmployeeInterface[],
    teamLeaders: EmployeeInterface[],
    isSuperAdmin: boolean
    companies: CompanyInterface[]
    companyFilter: string
    //multiple fields
    filterFields: TableFilterFieldInterface

}

class JoinerTracker extends React.Component<{ user: any, companies: CompanyInterface[], employees: EmployeeInterface[], match: any }, JoinerTrackerStateInterface> {

    ref: any;
    modal: any;
    subscription: any
    promises: any

    constructor(props: any) {
        super(props);

        const { month,year } = this.props.match.params;


        this.state = {
            joiners: [],
            showModal: false,
            editJoinerRowData: {},
            newJoinerRowData: {},
            editRowId: -1,
            editField: '',
            filters: {
                companies: [],
                process: [],
                recruiters: []
            },
            filterField: '',
            companyFilter: '',
            query: '',
            selectedMonth: month,//dayjs().format('MMM'),
            selectedYear: year,//dayjs().format('MMM'),
            autoSaveField: '',
            autoSaveFieldId: '',
            employees: [],
            recruiters: [],
            teamLeaders: [],
            isSuperAdmin: isSuperAdmin(this.props.user),
            companies: [],
            //multiple fields
            filterFields: {}
        }
        this.ref = createRef();
    }

    getData = (month: string, year?: string) => {
        let d = new Date();
        year = year ? year : d.getFullYear() + '';
        Promise.all([
            API.get(REST_API_NAME, '/joiners/' + month + '/' + year, {}),
        ])
            .then((joinerResults) => {
                const [joinerData] = joinerResults;
                const { companies } = this.props;

                let _c: { [key: string]: any } = {};
                let _p: { [key: string]: any } = {};
                let _r: { [key: string]: any } = {};

                let companiesHash: any = {};

                companies.map((c: any) => companiesHash[c.id] = c);
                // console.log('CH', companiesHash)
                joinerData.result.map((d: any) => {
                    _p[d.process] = 1;
                    // _r[d.recruiter] = 1;

                    const company = companiesHash[d.company_id];
                    const ctc_percent = company ? company.ctc_percent : 100;
                    // ctc_percent && console.log(ctc_percent,company,d.company_id);
                    d.invoice_amount_actual = !ctc_percent ? d.invoice_amount_actual : ctc_percent * d.ctc / 100;
                    // d.invoice_amount = company.ctc_percent;

                });


                this.setState({
                    joiners: joinerData.result,
                    filters: {
                        ...this.state.filters,
                        process: Object.keys(_p),
                        // recruiters: Object.keys(_r)
                    }
                });

            })
    }

    async componentDidMount() {
        // const data: any = await API.get(REST_API_NAME, '/joiners', {})
        // const joinerData: any = await API.get(REST_API_NAME, '/joiners', {});
        // const dataEmployees: any = await API.get(REST_API_NAME, '/employees/filter/for-joiner-tracker', {});

        const { selectedMonth,selectedYear } = this.state;
        Promise.all([
            API.get(REST_API_NAME, '/employees/filter/for-joiner-tracker', {}),
            API.get(REST_API_NAME, '/joiners/masters/initial', {}),
            API.get(REST_API_NAME, '/joiners/' + selectedMonth + '/' + selectedYear, {}),
        ]).then((results: any) => {
            const [dataEmployees1, masters, joinerData] = results;

            const dataEmployees = masters.result.employees;
            const companies = masters.result.companies;

            const recruiters = dataEmployees.filter((d: any) => d.role.indexOf('recruiter') != -1).sort((a: EmployeeInterface, b: EmployeeInterface) => a.name.localeCompare(b.name))
            //all employees must be shown, incuding admin, superadmin
            const teamLeaders = dataEmployees.filter((d: any) => { return (d.role.indexOf('teamleader') != -1 || d.role.indexOf('admin') != -1 || d.role.indexOf('superadmin') != -1 || d.role.indexOf('coordinator') != -1) }).sort((a: EmployeeInterface, b: EmployeeInterface) => a.name.localeCompare(b.name))

            // const [joinerData] = joinerResults.results;

            let _c: { [key: string]: any } = {};
            let _p: { [key: string]: any } = {};
            let _r: { [key: string]: any } = {};

            let companiesHash: any = {};

            companies.map((c: any) => companiesHash[c.id] = c);
            // console.log('CH', companiesHash)
            joinerData.result.map((d: any) => {
                _p[d.process] = 1;
                // _r[d.recruiter] = 1;

                const company = companiesHash[d.company_id];
                const ctc_percent = company ? company.ctc_percent : 100;
                // ctc_percent && console.log(ctc_percent,company,d.company_id);
                d.invoice_amount_actual = !ctc_percent ? d.invoice_amount_actual : ctc_percent * d.ctc / 100;
                // d.invoice_amount = company.ctc_percent;

            });


            const joiners = joinerData.result.map((j: any) => {
                j.days_elapsed = j.doj ? dayjs(new Date()).diff(j.doj, 'days') : -1
                const cid = j.company_id;
                const company = companies.find((c: { id: any; }) => c.id == cid);
                if (company) {
                    j.clawback = company.payment_period;
                } else {
                    j.clawback = 'NA';
                }
                return j;
            })

            this.setState({
                joiners,//: joinerData.result,

                filters: {
                    companies: companies.sort((a: any, b: any) => a.name.localeCompare(b.name)),
                    // process: [],
                    recruiters,
                    teamLeaders,
                    process: Object.keys(_p),
                },
                employees: dataEmployees,
                recruiters,
                teamLeaders,
                companies
            });

        })
        // this.getData(dayjs().format('MMM'))

        this.modal = new bootstrap.Modal(this.ref.current);



        // this.subscription = (API.graphql(
        //     graphqlOperation(subscriptions.onCreateJoiners)
        // ) as any).subscribe({
        //     next: ({ provider, value }: any) => {
        //         console.log({ provider, value });
        //         const subscribedJoiner = value.data.onCreateJoiners;
        //         const joiner = { ...subscribedJoiner, id: subscribedJoiner.dbId };

        //         const joinerExists = this.state.joiners.find(j => j.id == subscribedJoiner.dbId);
        //         const { selectedMonth } = this.state;
        //         const userSub = this.props.user.attributes.sub;

        //         //Other user has created
        //         if (userSub !== subscribedJoiner.created_by) {
        //             if (subscribedJoiner.month != selectedMonth) {
        //                 // console.log(subscribedJoiner, ' not in selected month');
        //                 return;
        //             }
        //         }
        //         console.log('L', joinerExists)
        //         if (joinerExists) {
        //             this.setState({ joiners: this.state.joiners.map(j => j.id == subscribedJoiner.dbId ? joiner : j) })
        //         } else {
        //             this.setState({ joiners: [...this.state.joiners, joiner] })
        //         }


        //     },
        //     error: (error: any) => console.warn(error)
        // });
    }

    componentWillUnmount() {
        // this.subscription && this.subscription.unsubscribe();
    }

    // componentDidUpdate(prevProps: any, prevState: any) {
    //     if (this.props.companies.length !== prevProps.companies.length) {
    //         this.getData(dayjs().format('MMM'))
    //     }

    // }

    toggleModal = () => {
        this.setState({ showModal: !this.state.showModal });
        this.modal.toggle();
    }

    setField = (field: string, value: string) => {
        const { teamLeaders, recruiters, companies } = this.state;
        let d = { ...this.state.newJoinerRowData, [field]: value };
        // debugger;
        if (field === 'tl_id') {
            d.tl = teamLeaders.find(tl => (tl.id + "") == value)!.name
        } else if (field === 'recruiter_id') {
            d.recruiter = recruiters.find(tl => (tl.id + "") == value)!.name
        } else if (field === 'company') {
            const company = companies.find(tl => (tl.id + "") == value) || { name: '', amount: 0, actual_amount: 0 }
            const amount = company.amount;
            const actual_amount = company.actual_amount;
            d.invoice_amount = amount;
            d.invoice_amount_actual = actual_amount;
            d.company = company.name;
        } else if (field === 'company') {
            const company = companies.find(tl => (tl.id + "") == value) || { name: '', amount: 0, actual_amount: 0 }
            const amount = company.amount;
            const actual_amount = company.actual_amount;
            d.invoice_amount = amount;
            d.invoice_amount_actual = actual_amount;
            d.company = company.name;
        }

        this.setState({ newJoinerRowData: d })
        // this.setState({ editJoinerRowData: { ...this.state.editJoinerRowData, [field]: value } })
    }

    setField1 = async (field: string, value: string) => {

        const userSub = this.props.user.attributes.sub;

        console.log('Saving', field, value);
        const data = { field, value }


        const result = await API.post(REST_API_NAME, '/joiners/auto-save', {
            body: data
        })
        // alert('Saved');
        // this.setState({ joiners: [...this.state.joiners, { ...data, id: result.result.insertId, created_by: userSub }] })
        console.log(result);

        const dataCopy = { [field]: value, dbId: result.result.insertId, created_by: userSub };
        // delete dataCopy.month;


        this.setState({ autoSaveField: field, autoSaveFieldId: result.result.insertId })
        // await API.graphql(graphqlOperation(createJoiners, { input: dataCopy }));



    }

    saveDataForm = (data: any) => {
        this.setState({ newJoinerRowData: data });
        this.saveData(data)
    }

    saveData = async (formData?: any) => {
        // check for name number and compamy
        //everything else should be light ornage if empty
        //
        const {
            newJoinerRowData,
            // editJoinerRowData: data, 
            selectedMonth,
            joiners
        } = this.state;

        const data = formData === undefined ? newJoinerRowData : formData;

        const userSub = this.props.user.attributes.sub;

        console.log('Saving', data);
        if (!data.name || !data.number || !data.company || !data.month) {
            alert('Name, Number, Company and Month are required');
            return false;
        }
        const joinerFound = joiners.find(j => j.number.trim() == data.number.trim());
        if (joinerFound) {
            alert(joinerFound.number + ' already exists.');
            return false;
        }


        const result = await API.post(REST_API_NAME, '/joiners', {
            body: data
        })
        toast.success('Saved');
        // this.setState({ joiners: [...this.state.joiners, { ...data, id: result.result.insertId, created_by: userSub }] })
        console.log(result);

        const dataCopy = { ...data, dbId: result.result.insertId, created_by: userSub };


        // await API.graphql(graphqlOperation(createJoiners, { input: dataCopy }))

        this.setState({
            editJoinerRowData: {
                name: '',
                number: '',
                company: '',
                process: '',
                doj: '',
                status: '',
                invoice_amount: 0,
                invoice_amount_actual: 0,
                invoice_sent: 0,
                recruiter: '',
                tl: '',
                applicant_id: '',
                review: '',
                points: 0,
                month: ''
            },
            newJoinerRowData: {
                name: '',
                number: '',
                company: '',
                process: '',
                doj: '',
                status: '',
                invoice_amount: 0,
                invoice_amount_actual: 0,
                invoice_sent: 0,
                recruiter: '',
                tl: '',
                applicant_id: '',
                review: '',
                points: 0,
                month: ''
            }
        })

        return true;

    }

    editData = async (id: number) => {
        const { editJoinerRowData: data } = this.state;
        console.log('Editing', data);
        const result = await API.post(REST_API_NAME, '/joiners/' + id, {
            body: data
        })
        this.setState({ joiners: [...this.state.joiners, { ...data, id: result.result.insertId }] })
        console.log(result)
    }

    setEditRowId = (id: number) => {
        this.setState({ editRowId: id, editJoinerRowData: this.state.joiners.find(j => j.id == id) })
    }

    onFieldChange = (field: string, value: string) => {
        this.setState({ editJoinerRowData: { ...this.state.editJoinerRowData, [field]: value } })
    }

    setEditable = (editRowId: number, editField: string) => {
        this.setState({ editRowId, editField, editJoinerRowData: this.state.joiners.find(j => j.id == editRowId) })
    }

    updateField = async () => {
        const { editJoinerRowData: data, editRowId, editField, teamLeaders, recruiters, filters } = this.state;
        const userSub = this.props.user.attributes.sub;

        console.log('Editing', data);
        let value = data[editField];

        let subFieldLabel = '';
        let subFieldValue = '';

        if (editField === 'tl_id') {
            const tl = teamLeaders.find(tl => (tl.id + "") == value)!;
            data.tl = tl.name
            subFieldLabel = 'tl_sub';
            subFieldValue = tl.sub;
        } else if (editField === 'recruiter_id') {

            const rec = recruiters.find(tl => (tl.id + "") == value)!
            data.recruiter = rec.name
            subFieldLabel = 'recruiter_sub';
            subFieldValue = rec.sub;

        } else if (editField === 'company') {
            const company = filters.companies.find((c: any) => (c.id + "") == value) || { amount: 0 }
            data.invoice_amount = company.amount
        } else if (editField === 'ctc') {
            value = (isNaN(value) || !value) ? 0 : value;
            if (value != 0 && data.ctc_percent) {
                data.invoice_amount_actual = Number(data.ctc_percent * data.ctc / 100).toFixed();
            }
        }

        this.setState({ editField: '', editRowId: -1, joiners: this.state.joiners.map(j => j.id == editRowId ? { ...j, [editField]: value } : j) })

        const result = await API.post(REST_API_NAME, '/joiners/field/' + editRowId + '/' + editField, {
            body: { value }
        })

        if (subFieldLabel) {
            const result = await API.post(REST_API_NAME, '/joiners/field/' + editRowId + '/' + subFieldLabel, {
                body: { value: subFieldValue }
            })
        }

        // const newJoiner = { ...this.state.joiners.find(j => j.id == editRowId), [editField]: value };

        // const dataCopy = { ...newJoiner, dbId: newJoiner.id, created_by: userSub };
        // delete dataCopy.location;
        // delete dataCopy.created_on;
        // delete dataCopy.updated_on;
        // delete dataCopy.updated_by;
        // delete dataCopy.id;
        // delete dataCopy.createdAt;
        // delete dataCopy.updatedAt;

        // await API.graphql(graphqlOperation(createJoiners, { input: dataCopy }))

        // this.setState({ joiners: this.state.joiners.map(j => j.id == editRowId ? { ...j, [editField]: value } : j) })
        console.log('Upted', result)
    }

    updateSpecifiedField = async (rowId: number, fieldName: string, fieldValue: string | number | boolean) => {

        // const { editJoinerRowData: data, editRowId, editField } = this.state;
        const { filters, teamLeaders, recruiters } = this.state;
        const userSub = this.props.user.attributes.sub;

        console.log('Editing', fieldName, fieldValue, rowId);
        let value = fieldValue;



        this.setState({ editField: '', editRowId: -1, joiners: this.state.joiners.map(j => j.id == rowId ? { ...j, [fieldName]: value } : j) })

        const result = await API.post(REST_API_NAME, '/joiners/field/' + rowId + '/' + fieldName, {
            body: { value }
        })

        let extraData: any = {};

        if (fieldName === 'tl_id') {
            const tl = teamLeaders.find(tl => (tl.id + "") == value)!;
            const name = tl.name
            const result1 = await API.post(REST_API_NAME, '/joiners/field/' + rowId + '/' + 'tl', {
                body: { value: name }
            })
            const result2 = await API.post(REST_API_NAME, '/joiners/field/' + rowId + '/' + 'tl_sub', {
                body: { value: tl.sub }
            })
            extraData['tl'] = name;
        } else if (fieldName === 'recruiter_id') {
            const recruiter = recruiters.find(tl => (tl.id + "") == value)!;
            const name = recruiter.name;
            const result1 = await API.post(REST_API_NAME, '/joiners/field/' + rowId + '/' + 'recruiter', {
                body: { value: name }
            })
            const result2 = await API.post(REST_API_NAME, '/joiners/field/' + rowId + '/' + 'recruiter_sub', {
                body: { value: recruiter.sub }
            })
            extraData['recruiter'] = name;

        } else if (fieldName === 'company') {

            const company = filters.companies.find((c: any) => (c.id + "") == value) || { name: '', amount: 0 }
            const result = await API.post(REST_API_NAME, '/joiners/field/' + rowId + '/' + 'company', {
                body: { value: company.name }
            })
            const resultAmount = await API.post(REST_API_NAME, '/joiners/field/' + rowId + '/' + 'invoice_amount', {
                body: { value: company.amount }
            })
            const resultAmountActual = await API.post(REST_API_NAME, '/joiners/field/' + rowId + '/' + 'invoice_amount_actual', {
                body: { value: company.actual_amount }
            })
            extraData['company'] = company.name;
            extraData['invoice_amount'] = company.amount;
            extraData['invoice_amount_actual'] = company.actual_amount;

        } else if (fieldName === 'status') {
            if (fieldValue === 'Drop') {
                extraData['invoice_amount'] = 0;
                extraData['invoice_amount_actual'] = 0;

                const resultAmount = await API.post(REST_API_NAME, '/joiners/field/' + rowId + '/' + 'invoice_amount', {
                    body: { value: 0 }
                })
                const resultAmountActual = await API.post(REST_API_NAME, '/joiners/field/' + rowId + '/' + 'invoice_amount_actual', {
                    body: { value: 0 }
                })


                this.setState({ joiners: this.state.joiners.map(j => j.id === rowId ? { ...j, status: 'Drop' } : j) })
            }
            else {
                const joiner = this.state.joiners.find(j => j.id == rowId);
                console.log(joiner);
                //@todo searhcing by company name
                //@todo start stroing company names. :egacy issue

                const company = filters.companies.find((c: any) => c.name == joiner.company) || { name: '', amount: 0 }
                // const result = await API.post(REST_API_NAME, '/joiners/field/' + rowId + '/' + 'company', {
                //     body: { value: company.name }
                // })
                const resultAmount = await API.post(REST_API_NAME, '/joiners/field/' + rowId + '/' + 'invoice_amount', {
                    body: { value: company.amount }
                })
                const resultAmountActual = await API.post(REST_API_NAME, '/joiners/field/' + rowId + '/' + 'invoice_amount_actual', {
                    body: { value: company.actual_amount }
                })
                extraData['company'] = company.name;
                extraData['invoice_amount'] = company.amount;
                extraData['invoice_amount_actual'] = company.actual_amount;

                this.setState({ joiners: this.state.joiners.map(j => j.id === rowId ? { ...j, status: fieldValue } : j) })


            }


            // debugger;
            // const company = filters.companies.find((c: any) => (c.id + "") == value) || { name: '', amount: 0 }
            // const result = await API.post(REST_API_NAME, '/joiners/field/' + rowId + '/' + 'company', {
            //     body: { value: company.name }
            // })
            // const resultAmount = await API.post(REST_API_NAME, '/joiners/field/' + rowId + '/' + 'invoice_amount', {
            //     body: { value: company.amount }
            // })
            // const resultAmountActual = await API.post(REST_API_NAME, '/joiners/field/' + rowId + '/' + 'invoice_amount_actual', {
            //     body: { value: company.actual_amount }
            // })
            // extraData['company'] = company.name;
            // extraData['invoice_amount'] = company.amount;
            // extraData['invoice_amount_actual'] = company.actual_amount;

        }
        else if (fieldName === 'ctc') {

            const joiner = this.state.joiners.find(j => j.id == rowId);
            const company = this.state.companies.find(j => j.id == joiner.company_id)!;
            value = (isNaN(Number(value)) || !value) ? 0 : value;
            if (value != 0 && company.ctc_percent) {
                joiner.invoice_amount_actual = Number(company.ctc_percent * joiner.ctc / 100).toFixed();
                this.setState({ joiners: this.state.joiners.map(j => j.id == rowId ? joiner : j) })
            }
        }


        const newJoiner = { ...this.state.joiners.find(j => j.id == rowId), [fieldName]: value, ...extraData };

        const dataCopy = { ...newJoiner, dbId: newJoiner.id, created_by: userSub };
        delete dataCopy.location;
        delete dataCopy.created_on;
        delete dataCopy.updated_on;
        delete dataCopy.updated_by;
        delete dataCopy.id;
        delete dataCopy.createdAt;
        delete dataCopy.updatedAt;

        //@todo need to add these fields
        delete dataCopy.candidate_id;
        delete dataCopy.company_id;
        delete dataCopy.tl_sub;

        // await API.graphql(graphqlOperation(createJoiners, { input: dataCopy }))

        // this.setState({ joiners: this.state.joiners.map(j => j.id == editRowId ? { ...j, [editField]: value } : j) })
        console.log('Upted 2', result)
    }

    removeRow = async (id: number) => {
        const result = await API.del(REST_API_NAME, '/joiners/' + id, {})

        this.setState({ joiners: this.state.joiners.filter(j => j.id != id) })
        console.log(result)

    }

    canEdit = (createdBy: string) => {
        const userSub = this.props.user.attributes.sub;
        const userGroups = this.props.user.signInUserSession.idToken.payload['cognito:groups'] || [];
        if (userGroups.find((g: string) => g === 'superadmin' || g == 'admin')) {
            return true;
        }
        return userSub == createdBy;

    }

    filterTable = (filterField: string, query: string | any[]) => {
        const { joiners, editRowId, editJoinerRowData: data, editField, filters, filterFields } = this.state;
        let queryStr: string | string[] = '';
        if (typeof query == 'string') {
            queryStr = query
        } else {
            //must be options
            queryStr = query.map(q => q.value)
        }

        this.setState({ filterField, query: queryStr, filterFields: { ...filterFields, [filterField]: query } });
    }

    selectMonth = (selectedMonth: string, e: any) => {
        e.preventDefault();
        this.setState({ selectedMonth });
        this.getData(selectedMonth);
        const selectedYear = this.state.selectedYear;

        window.history.pushState({}, '', '/joiner-tracker/' + selectedMonth + '/' + selectedYear);
    }
    selectYear = (selectedYear: string, e: any) => {
        e.preventDefault();
        const selectedMonth = this.state.selectedMonth;
        this.setState({ selectedYear });
        this.getData(selectedMonth, selectedYear);

        window.history.pushState({}, '', '/joiner-tracker/' + selectedMonth + '/' + selectedYear);
    }

    setCompanyFilter = (companyFilter: string) => {
        this.setState({ companyFilter });
    }

    render() {

        const { companyFilter, companies, joiners, editRowId,
            newJoinerRowData: data,
            editJoinerRowData /*: data, */,
            editField, filters, filterField, query, recruiters, teamLeaders, isSuperAdmin,
            selectedMonth, selectedYear,
            filterFields } = this.state;

        const userSub = this.props.user.attributes.sub;
        const user = this.props.user;
        let filteredData = joiners;
        if (filterField && query) {
            filteredData = joiners.filter(j => {
                if (filterField == 'doj') {
                    // console.log(dayjs(j[filterField]).format('YYYY-MM-DD'),query)
                    return dayjs(j[filterField]).format('YYYY-MM-DD') == query
                } else if (filterField == 'recruiter_id' || filterField == 'tl_id') {
                    return j[filterField] == query
                } else {
                    return filterField === 'company' ? (query.length == 0 || query.indexOf(j[filterField]) != -1) : j[filterField] ? (j[filterField] + "").indexOf(query as string) != -1 : 1
                }
            })
        }


        if (Object.keys(filterFields).length) {
            filteredData = joiners.filter((joiner) => {
                let f = true;
                for (let key in filterFields) {
                    const v = filterFields[key];
                    if (typeof v === "string") {
                        f = f && (v == "" ? true : joiner[key] == v)
                    } else {
                        f = f && (!v.length ? true : (v as any[]).some((_v) => _v.value == joiner[key]))
                    }
                }
                return f
            })
        }


        let totalAmount = 0;
        let totalAmountActual = 0;
        let totalPoints: number = 0;

        // console.log(filterFields, joiners)


        const TableRows = filteredData.map(d => {
            if (!d) { return '' }
            totalAmount += d.status === 'Drop' ? 0 : (Number(d.invoice_amount) || 0);
            totalAmountActual += d.status === 'Drop' ? 0 : (Number(d.invoice_amount_actual) || 0);
            totalPoints += Number(d.points) || 0;
            d.recruiter = formatEmployeeName(d.recruiter);
            d.tl = formatEmployeeName(d.tl);
            // d.number=d.number.replace('/','\n')
            return <TableRow
                isSuperAdmin={isSuperAdmin}
                key={'table-row-' + d.id}
                dataLists={{ teamLeaders, recruiters, companies }}
                updateSpecifiedField={this.updateSpecifiedField}
                canEdit={this.canEdit}
                removeRow={this.removeRow}
                updateField={this.updateField}
                editRowId={editRowId}
                editField={editField}
                setEditable={this.setEditable}
                formData={editJoinerRowData}
                userSub={userSub}
                editRow={this.setEditRowId}
                data={d}
                id={d.id}
                onFieldChange={this.onFieldChange}
            />
        });




        return <div className='my-5'>
            {/* <button onClick={this.toggleModal}>Add</button> */}

            <div className="row">
                <div className="col-1">
                    <div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                            {MONTHS[Number(selectedMonth)]}
                        </button>
                        <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                            <li onClick={(e) => this.selectMonth('NONE', e)}><a className="dropdown-item" href="#">NONE</a></li>
                            <li onClick={(e) => this.selectMonth('1', e)}><a className="dropdown-item" href="#">Jan</a></li>
                            <li onClick={(e) => this.selectMonth('2', e)}><a className="dropdown-item" href="#">Feb</a></li>
                            <li onClick={(e) => this.selectMonth('3', e)}><a className="dropdown-item" href="#">Mar</a></li>
                            <li onClick={(e) => this.selectMonth('4', e)}><a className="dropdown-item" href="#">Apr</a></li>
                            <li onClick={(e) => this.selectMonth('5', e)}><a className="dropdown-item" href="#">May</a></li>
                            <li onClick={(e) => this.selectMonth('6', e)}><a className="dropdown-item" href="#">Jun</a></li>
                            <li onClick={(e) => this.selectMonth('7', e)}><a className="dropdown-item" href="#">Jul</a></li>
                            <li onClick={(e) => this.selectMonth('8', e)}><a className="dropdown-item" href="#">Aug</a></li>
                            <li onClick={(e) => this.selectMonth('9', e)}><a className="dropdown-item" href="#">Sep</a></li>
                            <li onClick={(e) => this.selectMonth('10', e)}><a className="dropdown-item" href="#">Oct</a></li>
                            <li onClick={(e) => this.selectMonth('11', e)}><a className="dropdown-item" href="#">Nov</a></li>
                            <li onClick={(e) => this.selectMonth('12', e)}><a className="dropdown-item" href="#">Dec</a></li>
                        </ul>
                    </div>
                    {/* <div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                            {selectedMonth}
                        </button>
                        <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                            <li onClick={(e) => this.selectMonth('NONE', e)}><a className="dropdown-item" href="#">NONE</a></li>
                            <li onClick={(e) => this.selectMonth('Jan', e)}><a className="dropdown-item" href="#">Jan</a></li>
                            <li onClick={(e) => this.selectMonth('Feb', e)}><a className="dropdown-item" href="#">Feb</a></li>
                            <li onClick={(e) => this.selectMonth('Mar', e)}><a className="dropdown-item" href="#">Mar</a></li>
                            <li onClick={(e) => this.selectMonth('Apr', e)}><a className="dropdown-item" href="#">Apr</a></li>
                            <li onClick={(e) => this.selectMonth('May', e)}><a className="dropdown-item" href="#">May</a></li>
                            <li onClick={(e) => this.selectMonth('Jun', e)}><a className="dropdown-item" href="#">Jun</a></li>
                            <li onClick={(e) => this.selectMonth('Jul', e)}><a className="dropdown-item" href="#">Jul</a></li>
                            <li onClick={(e) => this.selectMonth('Aug', e)}><a className="dropdown-item" href="#">Aug</a></li>
                            <li onClick={(e) => this.selectMonth('Sep', e)}><a className="dropdown-item" href="#">Sep</a></li>
                            <li onClick={(e) => this.selectMonth('Oct', e)}><a className="dropdown-item" href="#">Oct</a></li>
                            <li onClick={(e) => this.selectMonth('Nov', e)}><a className="dropdown-item" href="#">Nov</a></li>
                            <li onClick={(e) => this.selectMonth('Dec', e)}><a className="dropdown-item" href="#">Dec</a></li>
                        </ul>
                    </div> */}

                </div>

                <div className="col-1">
                    <div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                            {selectedYear}
                        </button>
                        <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                            <li onClick={(e) => this.selectYear('NONE', e)}><a className="dropdown-item" href="#">NONE</a></li>
                           
                            {
                                    YEARS.map(year=><li onClick={(e) => this.selectYear(year+"",e)}><a className="dropdown-item" href="#">{year}</a></li>)
                                }
                        </ul>
                    </div>
                </div>
            </div>
            <small>Filters based on when the entry was created or date of join i.e when the candidates status was changed to Select or the date of join</small>

            <table className='table table-striped1 table-bordered table-sm table-hover table-editable'>
                <colgroup>
                    <col></col>
                    <col></col>
                    <col></col>
                    <col></col>
                    <col className='qq'></col>
                    <col></col>
                    <col></col>
                    <col></col>
                    <col></col>
                    <col></col>
                    <col></col>
                    <col></col>
                    <col></col>
                    <col></col>
                    <col className='qq'></col>
                    <col className='qq'></col>
                    <col></col>
                </colgroup>
                <thead>
                    <tr>
                        <th></th>
                        {/* <th>Name</th> */}
                        <Col
                            title='Name'
                            // filterData={lists.status.sort((a: any, b: any) => a.localeCompare(b)).map((c: string) => ({ label: c, value: c }))}
                            filterFunction={this.filterTable}
                            filterType='text'
                            filterField='name'
                        />
                        <Col
                            title='Number'
                            // filterData={lists.status.sort((a: any, b: any) => a.localeCompare(b)).map((c: string) => ({ label: c, value: c }))}
                            filterFunction={this.filterTable}
                            filterType='text'
                            filterField='number'
                        />
                        <Col
                            title='Company'
                            filterData={filters.companies.map((c: any) => ({ label: c.name, value: c.name }))}
                            filterFunction={this.filterTable}
                            filterType='list-multi'
                            filterField='company'
                        />
                        <Col
                            title='Process'
                            filterData={(filters.process || []).sort((a: any, b: any) => a.localeCompare(b)).map((c: string) => ({ label: c, value: c }))}
                            filterFunction={this.filterTable}
                            filterType='list'
                            filterField='process'
                        />
                        <Col
                            title='DoJ'
                            filterData={[]}
                            filterFunction={this.filterTable}
                            filterType='date'
                            filterField='doj'
                        />
                        <Col
                            title='Month'
                            filterData={lists.months.map((c: string) => ({ label: c, value: c }))}
                            filterFunction={this.filterTable}
                            filterType='list'
                            filterField='month'
                        />
                        <Col
                            title='Status'
                            filterData={lists.status.sort((a: any, b: any) => a.localeCompare(b)).map((c: string) => ({ label: c, value: c }))}
                            filterFunction={this.filterTable}
                            filterType='list'
                            filterField='status'
                        />

                        <Col
                            title='CTC'
                            // filterData={lists.status.sort((a: any, b: any) => a.localeCompare(b)).map((c: string) => ({ label: c, value: c }))}
                            filterFunction={this.filterTable}
                            filterType='text'
                            filterField='ctc'
                        />

                        <Col
                            title='Amount'
                            // filterData={lists.status.sort((a: any, b: any) => a.localeCompare(b)).map((c: string) => ({ label: c, value: c }))}
                            filterFunction={this.filterTable}
                            filterType='text'
                            filterField='invoice_amount'
                        />

                        {isSuperAdmin ?
                            <Col
                                title='Tax Amt'
                                // filterData={lists.status.sort((a: any, b: any) => a.localeCompare(b)).map((c: string) => ({ label: c, value: c }))}
                                filterFunction={this.filterTable}
                                filterType='text'
                                filterField='invoice_amount_actual'
                            />
                            : ''}
                        {/* <th>Amount</th> */}
                        <Col
                            title='Recruiter'
                            filterData={recruiters.map((c: EmployeeInterface) => ({ label: c.name, value: c.id }))}
                            filterFunction={this.filterTable}
                            filterType='list'
                            filterField='recruiter_id'
                        />
                        <Col
                            title='TL'
                            filterData={teamLeaders.map((c: EmployeeInterface) => ({ label: c.name, value: c.id }))}
                            filterFunction={this.filterTable}
                            filterType='list'
                            filterField='tl_id'
                        />
                        <th>Applicant ID</th>
                        {isSuperAdmin ? <>
                            <Col
                                title='Entity'
                                filterData={[{ label: 'ODN', value: 'ODN' }, { label: 'ODN HR', value: 'ODN HR' }]}
                                filterFunction={this.filterTable}
                                filterType='list'
                                filterField='entity'
                            />
                            <Col
                                title='Elapsed'
                                // filterData={[{ label: 'ODN', value: 'ODN' }, { label: 'ODN HR', value: 'ODN HR' }]}
                                filterFunction={this.filterTable}
                                filterType='text'
                                filterField='days_elapsed'
                            />
                            <Col
                                title='Period'
                                // filterData={[{ label: 'ODN', value: 'ODN' }, { label: 'ODN HR', value: 'ODN HR' }]}
                                filterFunction={this.filterTable}
                                filterType='text'
                                filterField='clawback'
                            />
                            <Col
                                title='Invoice'
                                filterData={[{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }, { label: 'Attrition', value: 2 }]}
                                filterFunction={this.filterTable}
                                filterType='list'
                                filterField='invoice_sent'
                            />
                            <Col
                                title='INV.NO'
                                // filterData={[{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }, { label: 'Attrition', value: 2 }]}
                                filterFunction={this.filterTable}
                                filterType='text'
                                filterField='invoice_no'
                            />
                            <Col
                                title='Invoice Date'
                                // filterData={[{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }, { label: 'Attrition', value: 2 }]}
                                filterFunction={this.filterTable}
                                filterType='date'
                                filterField='invoice_date'
                            />

                            <Col
                                title='Payment'
                                filterData={lists.payment.sort((a: any, b: any) => a.localeCompare(b)).map((c: string) => ({ label: c, value: c }))}
                                filterFunction={this.filterTable}
                                filterType='list'
                                filterField='payment'
                            />
                            <Col
                                title='Transaction Details'
                                // filterData={lists.status.sort((a: any, b: any) => a.localeCompare(b)).map((c: string) => ({ label: c, value: c }))}
                                filterFunction={this.filterTable}
                                filterType='text'
                                filterField='transaction_details'
                            />
                            <Col
                                title='Transation Date'
                                // filterData={lists.status.sort((a: any, b: any) => a.localeCompare(b)).map((c: string) => ({ label: c, value: c }))}
                                filterFunction={this.filterTable}
                                filterType='text'
                                filterField='transaction_date'
                            />

                        </>
                            :
                            ''}
                        <Col
                            title='Review'
                            filterData={[{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]}
                            filterFunction={this.filterTable}
                            filterType='list'
                            filterField='review'
                        />
                        <th>Points</th>
                        <Col
                            title='PT / FT'
                            filterData={[{ label: 'Part Time', value: 'PartTime' }, { label: 'Full Time', value: 'FullTime' }]}
                            filterFunction={this.filterTable}
                            filterType='list'
                            filterField='employment_type'
                        />
                        <th>Sal</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='summary-row'>
                        <td></td>
                        <td>
                            Total
                        </td>
                        <td>
                            {filteredData.length}
                        </td>
                        <td> </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                            {totalAmount}
                        </td>
                        {isSuperAdmin ? <td>{totalAmountActual}</td> : ''}

                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        {isSuperAdmin ? <>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </>
                            :
                            ''}
                        <td>
                            {totalPoints.toFixed(2)}
                        </td>
                        <td>
                        </td>
                    </tr>
                    {/* <NewEntryRow
                        setField={this.setField}
                        isSuperAdmin={isSuperAdmin}
                        data={data}
                        filters={filters}
                        recruiters={recruiters}
                        teamLeaders={teamLeaders}
                        companies={companies}
                        saveData={this.saveDataForm}

                    /> */}
                    {TableRows}

                    <tr className='summary-row'>
                        <td></td>
                        <td>
                            Total
                        </td>
                        <td>
                            {filteredData.length}
                        </td>
                        <td> </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                            {totalAmount}
                        </td>
                        {isSuperAdmin ? <td>{totalAmountActual}</td> : ''}

                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        {isSuperAdmin ? <>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </>
                            :
                            ''}
                        <td>
                            {totalPoints.toFixed(2)}
                        </td>
                        <td>
                        </td>
                    </tr>

                </tbody>
            </table>


            <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.ref}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className='form'>
                                <form className=" g-3">

                                    <div className="mb-3 row">
                                        <label htmlFor="inputName" className="col-sm-2 col-form-label">Name</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" id="inputName" onChange={(evt) => this.setField('name', evt.target.value)} />
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label htmlFor="inputNumber" className="col-sm-2 col-form-label">Number</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" id="inputNumber" onChange={(evt) => this.setField('number', evt.target.value)} />
                                        </div>
                                    </div>



                                    <div className="mb-3 row">
                                        <label htmlFor="inputCompany" className="col-sm-2 col-form-label">Company</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" id="inputCompany" onChange={(evt) => this.setField('company', evt.target.value)} />
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label htmlFor="inputProcess" className="col-sm-2 col-form-label">Process</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" id="inputProcess" onChange={(evt) => this.setField('process', evt.target.value)} />
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label htmlFor="inputDoj" className="col-sm-2 col-form-label">DoJ</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" id="inputDoj" onChange={(evt) => this.setField('doj', evt.target.value)} />
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label htmlFor="inputMonth" className="col-sm-2 col-form-label">Month</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" id="inputMonth" onChange={(evt) => this.setField('month', evt.target.value)} />
                                        </div>
                                    </div>


                                    <div className="mb-3 row">
                                        <label htmlFor="inputDoj" className="col-sm-2 col-form-label">Month</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" id="inputDoj" onChange={(evt) => this.setField('month', evt.target.value)} />
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <label htmlFor="inputDoj" className="col-sm-2 col-form-label">Recruiter</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" id="inputDoj" onChange={(evt) => this.setField('recruiter', evt.target.value)} />
                                        </div>
                                    </div>


                                    <div className="mb-3 row">
                                        <label htmlFor="inputDoj" className="col-sm-2 col-form-label">Location</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" id="inputDoj" onChange={(evt) => this.setField('location', evt.target.value)} />
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label htmlFor="inputDoj" className="col-sm-2 col-form-label">TL</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" id="inputDoj" onChange={(evt) => this.setField('tl', evt.target.value)} />
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label htmlFor="inputDoj" className="col-sm-2 col-form-label">Applicant ID</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" id="inputDoj" onChange={(evt) => this.setField('applicant_id', evt.target.value)} />
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={this.saveData}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>


            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        </div>
    }
}


interface NewEntryRowPropsInterface {
    setField: (f: string, v: any) => void
    isSuperAdmin: boolean
    data: any
    filters: any
    recruiters: EmployeeInterface[]
    teamLeaders: EmployeeInterface[]
    saveData: (data: any) => void
    companies: CompanyInterface[]
}

const NewEntryRow = (props: NewEntryRowPropsInterface) => {

    const { companies, data, filters, isSuperAdmin, recruiters, teamLeaders, saveData } = props;

    const [form, setForm] = useState<any>(data);
    const [filtersObj, setFilters] = useState<any>(filters);
    const [companyFilter, setCompanyFilter] = useState('');
    const [recruiterFilter, setRecruiterFilter] = useState('');
    const [tlFilter, setTlFilter] = useState('');

    useEffect(() => {
        setForm(data)
        setFilters(filters)
    }, [data])
    useEffect(() => {
        setFilters(filters)
    }, [filters])

    return (<tr>
        <td></td>
        <td>
            <input type='text' placeholder='Name' onChange={(evt) => setForm({ ...form, name: evt.target.value })} value={form.name} />
        </td>
        <td>
            <input type='text' placeholder='Number' onChange={(evt) => setForm({ ...form, number: evt.target.value })} value={form.number} style={{ width: '90px' }} />
        </td>
        <td>

            {/* <select onChange={(evt) => this.setField('company', evt.target.value)}>
                <option></option>
                {filters.companies.map((c: any) => <option key={'c-' + c.name} value={c.id}>{c.name}</option>)}
            </select> */}

            <div className="btn-group">
                <button className="btn btn-info btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {form.company || 'Company'}
                </button>
                <ul className="dropdown-menu">
                    <li>
                        <div className="input-group input-group-sm mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-sm">Filter</span>
                            <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onKeyPress={(evt) => setCompanyFilter(evt.currentTarget.value.toLowerCase())} />
                        </div>
                    </li>
                    {filters.companies.filter((c: any) => companyFilter == '' ? true : c.name.toLowerCase().indexOf(companyFilter) != -1).map((c: any) => <li key={'c-' + c.name} onClick={(evt) => setForm({ ...form, invoice_amount: c.amount, invoice_amount_actual: c.actual_amount, company: c.name })} ><a className="dropdown-item" href="#" onClick={(e) => e.preventDefault()}>{c.name}</a></li>)}
                </ul>
            </div>
        </td>
        <td>
            {/* <input type='text' placeholder='Process' onChange={(evt) => setField('process', evt.target.value)} value={data.process} /> */}
            {/* <Hint options={filters.process}> */}
            <input
                onChange={(evt) => setForm({ ...form, process: evt.target.value })}
                value={form.process}
                list={'process-new-list-1'}
                style={{ width: '115px' }}
            />
            <datalist id="process-new-list-1">
                {(filtersObj.process || []).map((c: any) => <option key={'p-' + c} value={c} />)}
            </datalist>
            {/* </Hint> */}
        </td>
        <td>
            <input type='date' placeholder='DoJ' onChange={(evt) => setForm({ ...form, doj: evt.target.value })} value={form.doj} style={{ width: '112px' }} />
        </td>
        <td>
            <select onChange={(evt) => setForm({ ...form, month: evt.target.value })} value={form.month}>
                <option></option>
                {lists.months.map(months => <option key={'month-new-' + months}>{months}</option>)}
            </select>
        </td>
        <td>
            {/* <input type='text' placeholder='Status' onChange={(evt) => setField('status', evt.target.value)} /> */}
            <select onChange={(evt) => setForm({ ...form, status: evt.target.value })} value={form.status}>
                <option></option>
                {lists.status.map(status => <option key={'status-new-' + status}>{status}</option>)}
            </select>
        </td>
        <td>
            <input style={{ width: '80px' }} type='number' placeholder='Invoice Amount' onChange={(evt) => setForm({ ...form, invoice_amount: evt.target.value })} min={0} value={form.invoice_amount} />
        </td>
        {isSuperAdmin ? <td>
            <input style={{ width: '80px' }} type='number' placeholder='Invoice Amount Actual' onChange={(evt) => setForm({ ...form, invoice_amount_actual: evt.target.value })} min={0} value={form.invoice_amount_actual} />
        </td> : ''}

        <td>
            {/* <select onChange={(evt) => setForm({ ...form, recruiter_id: evt.target.value, recruiter: recruiters.find(tl => (tl.id + "") == evt.target.value)!.name })} value={form.recruiter_id}>
                <option></option>
                {recruiters.map(rec => <option key={'rec-new-' + rec.name} value={rec.id} data-id={rec.id}>{rec.name}</option>)}
            </select> */}

            <div className="btn-group">
                <button className="btn btn-info btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {form.recruiter || 'Recruiter'}
                </button>
                <ul className="dropdown-menu">
                    <li>
                        <div className="input-group input-group-sm mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-sm">Filter</span>
                            <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onKeyPress={(evt) => setRecruiterFilter(evt.currentTarget.value.toLowerCase())} />
                        </div>
                    </li>
                    {recruiters.filter((c: any) => recruiterFilter == '' ? true : c.name.toLowerCase().indexOf(recruiterFilter) != -1).map((c: any) => <li key={'c-' + c.name} onClick={() => setForm({ ...form, recruiter_id: c.id, recruiter: c.name })} ><a className="dropdown-item" href="#" onClick={(e) => e.preventDefault()}>{c.name}</a></li>)}
                </ul>
            </div>
        </td>
        <td>
            {/* <select onChange={(evt) => setForm({ ...form, tl_id: evt.target.value, tl: teamLeaders.find(tl => (tl.id + "") == evt.target.value)!.name })} value={form.tl_id}>
                <option></option>
                {teamLeaders.map(tl => <option key={'tl-new-' + tl.name} value={tl.id} data-id={tl.id}>{tl.name}</option>)}
            </select> */}

            <div className="btn-group">
                <button className="btn btn-info btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {form.tl || 'TL'}
                </button>
                <ul className="dropdown-menu">
                    <li>
                        <div className="input-group input-group-sm mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-sm">Filter</span>
                            <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onKeyPress={(evt) => setTlFilter(evt.currentTarget.value.toLowerCase())} />
                        </div>
                    </li>
                    {teamLeaders.filter((c: any) => tlFilter == '' ? true : c.name.toLowerCase().indexOf(tlFilter) != -1).map((c: any) => <li key={'c-' + c.name} onClick={() => setForm({ ...form, tl_id: c.id, tl: c.name })} ><a className="dropdown-item" href="#" onClick={(e) => e.preventDefault()}>{c.name}</a></li>)}
                </ul>
            </div>
        </td>
        <td>
            <input type='text' placeholder='ApplicantID' onChange={(evt) => setForm({ ...form, applicant_id: evt.target.value })} value={form.applicant_id} style={{ width: '75px' }} />
        </td>
        <td>
            {/* <input type='text' placeholder='Invoice Sent' onChange={(evt) => setField('invoice_sent', evt.target.value)} /> */}
            <select onChange={(evt) => setForm({ ...form, invoice_sent: evt.target.value })} value={form.invoice_sent}>
                <option></option>
                <option value={1}>Yes</option>
                <option value={0}>No</option>
            </select>
        </td>
        <td>
            {/* <input type='text' placeholder='Review' onChange={(evt) => setField('review', evt.target.value)} /> */}
            <select onChange={(evt) => setForm({ ...form, review: evt.target.value })} value={form.review}>
                <option></option>
                <option value={'yes'}>Yes</option>
                <option value={'no'}>No</option>
            </select>
        </td>
        <td>
            <input type='number' placeholder='Points' onChange={(evt) => setForm({ ...form, points: evt.target.value })} min={0} maxLength={2} style={{ width: '50px' }} value={form.points} />
        </td>
        <td>
            <button onClick={() => saveData(form)}>
                Save
            </button>
        </td>
    </tr>)
}

interface ColInterface {
    filterFunction?: (f: string, v: any) => void
    filterFunctionMultiple?: (f: string, op: 'blank' | '>' | '<' | '=', v: any) => void
    filterData?: any[]
    filterType: 'none' | 'list' | 'text' | 'date' | 'list-multi'
    title: string
    filterField: string
}

const Col = ({ title, filterFunction, filterFunctionMultiple, filterData = [], filterType = 'none', filterField }: ColInterface) => {

    const [showFilter, setShowFilter] = useState(false);

    let ele: any = '';
    switch (filterType) {
        case 'list': ele = <select className='' onChange={(e) => filterFunction!(filterField, e.target.value)}>
            <option value=''>ALL</option>
            {filterData.map((fc: any) => <option key={'filter-' + fc.label} value={fc.value}>{fc.label}</option>)}
            {/* {filterData.sort((a: any, b: any) => a.localeCompare(b)).map((fc: any) => <option key={'filter-' + fc}>{fc}</option>)} */}
        </select>; break;
        case 'list-multi': ele = <Select
            isMulti={true}
            onChange={(e) => filterFunction!(filterField, e)}
            options={[{ label: '', value: '' }, ...filterData.map((fc: any) => ({ ...fc }))]}
        />; break;
        case 'text': ele = <>
            {/* <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={(e) => filterFunctionMultiple!(filterField, 'blank' ,e.target.checked)} />
                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Blank</label>
            </div> */}
            <input onChange={(e) => filterFunction!(filterField, e.target.value)} />
        </>; break;
        case 'date': ele = <input type='date' className='' onChange={(e) => filterFunction!(filterField, e.target.value)} />; break;
    }

    return (<th>
        <div className='coloumn-label'>
            {title}
            <i className="bi bi-funnel-fill cursor-pointer" onClick={() => setShowFilter(!showFilter)}></i>
        </div>
        {showFilter && (!filterFunction
            ? ''
            :
            <div className='coloumn-filter'>
                {ele}
            </div>
        )}
    </th>)
}

type CellTypes = "text" | "date" | "list" | "boolean" | "number"

const Cell = ({ id, field, value, allowEdit, cssClass, updateSpecifiedField, onFieldChange, formData, setEditable, editField, editRowId, updateField, type = "text", list = [], renderer, canEdit }: { cssClass?: string, updateSpecifiedField: (r: number, f: string, v: string | number | boolean) => void, updateField: () => void, editRowId: number, editField: string, setEditable: (i: number, f: string) => void, formData: any, id: number, field: string, value: string, allowEdit: boolean, onFieldChange: (f: string, v: string) => void, type?: CellTypes, list?: string[] | { label: string, value: number | string }[], renderer?: (v: any) => string, canEdit: (createdBy: string) => boolean }) => {
    const [edit, setEdit] = useState(editRowId == id && editField == field);
    const checkClick = () => {
        // allowEdit && setEditable(id, field)
        allowEdit && setEdit(true)
    }
    const initialValue = value;

    const [fieldName, setFieldName] = useState(field);
    const [fieldValue, setFieldValue] = useState(value);
    // const [initialValue,setInitialValue]=useState(formData[field] || '');
    let FV = value;//formData[field] || '';

    let ele;
    let val = value;
    // console.log('S', type, field, formData[field], FV, value);

    switch (type) {
        case "text": ele = <input type={type} defaultValue={FV} onChange={(evt) => FV = (evt.target.value)} onBlur={() => { setEdit(false); initialValue !== FV && updateSpecifiedField(id, fieldName, FV); }} />; break;
        case "number": ele = <input type={type} defaultValue={fieldValue} onChange={(evt) => setFieldValue(evt.target.value)} onBlur={() => { updateSpecifiedField(id, fieldName, fieldValue); setEdit(false); }} />; break;
        case "date":
            ele = <input type={type} defaultValue={dayjs(formData[field]).format('DD/MM/YYYY')} onChange={(evt) => setFieldValue(evt.target.value)} onBlur={() => { updateSpecifiedField(id, fieldName, fieldValue); setEdit(false); }} />;
            val = !value ? '' : dayjs(value).format('DD/MM/YYYY');
            break;
        case "boolean": ele = <input type={'checkbox'} defaultValue={fieldValue} onChange={(evt) => setFieldValue(evt.target.value)} onBlur={(evt) => { updateSpecifiedField(id, fieldName, evt.target.value); setEdit(false); }} />; break;
        // case "list": ele = <select defaultValue={formData[field] || ''} onChange={(evt) => onFieldChange(field, evt.target.value)} onBlur={updateField}>
        case "list": ele = <select defaultValue={FV} onChange={(evt) => setFieldValue(evt.target.value)} onBlur={() => { updateSpecifiedField(id, fieldName, fieldValue); setEdit(false); }}>
            <option key='opt-11'></option>
            {list.map((l: any) => <option key={'opt' + (typeof l === 'string' ? l : l.label)} value={typeof l === 'string' ? l : l.value}>{typeof l === 'string' ? l : l.label}</option>)}
        </select>; break;
        // case "text": ele = <input type={type} defaultValue={formData[field]} onChange={(evt) => onFieldChange(field, evt.target.value)} onBlur={updateField} />; break;
        // case "number": ele = <input type={type} defaultValue={formData[field]} onChange={(evt) => onFieldChange(field, evt.target.value)} onBlur={updateField} />; break;
        // case "date":
        //     ele = <input type={type} defaultValue={dayjs(formData[field]).format('DD/MM/YYYY')} onChange={(evt) => onFieldChange(field, evt.target.value)} onBlur={updateField} />;
        //     val = !value ? '' : dayjs(value).format('DD/MM/YYYY');
        //     break;
        // case "boolean": ele = <input type={'checkbox'} defaultValue={formData[field]} onChange={(evt) => onFieldChange(field, evt.target.value)} onBlur={updateField} />; break;
        // // case "list": ele = <select defaultValue={formData[field] || ''} onChange={(evt) => onFieldChange(field, evt.target.value)} onBlur={updateField}>
        // case "list": ele = <select  value={fieldValue} onChange={(evt) => setFieldValue(evt.target.value)} onBlur={()=>updateSpecifiedField(id,fieldName,fieldValue)}>
        //     <option key='opt-11'></option>
        //     {list.map((l: any) => <option key={'opt' + (typeof l === 'string' ? l : l.label)} value={typeof l === 'string' ? l : l.value}>{typeof l === 'string' ? l : l.label}</option>)}
        // </select>; break;
    }


    return (<td className={(formData[field] == '' ? 'no-value ' : '') + (cssClass ?? cssClass)} onClick={checkClick} id={'td-' + field + id}>
        {/* {editRowId == id && editField == field */}
        {edit
            ? ele
            : (renderer ? renderer(val) : val)}
    </td>)
}

const TableRow = ({ isSuperAdmin, updateSpecifiedField, editRowId, editField, userSub, data, editRow, id, onFieldChange, formData, setEditable, updateField, removeRow, canEdit, dataLists }: { isSuperAdmin: boolean, dataLists: { companies: CompanyInterface[], teamLeaders: EmployeeInterface[], recruiters: EmployeeInterface[] }, updateSpecifiedField: (r: number, f: string, v: string | number | boolean) => void, updateField: () => void, editRowId: number, editField: string, setEditable: (i: number, f: string) => void, formData: any, id: number, userSub: string, data: any, editRow: (i: number) => void, onFieldChange: (f: string, v: string) => void, removeRow: (id: number) => void, canEdit: (createdBy: string) => boolean }) => {



    return (
        <tr className={canEdit(data.created_by) ? 'editable' : 'not-editable'} key={'row-' + id}>
            <td>
                {/* <button onClick={() => editRow(data.id)}>E</button> */}
                {
                    canEdit(data.created_by)
                        ?
                        <i className="bi bi-trash text-danger cursor-pointer" onClick={() => window.confirm('Are you sure you want to delete this data?') && removeRow(id)}></i>

                        : ''
                }
            </td>

            <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='name' value={data.name} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} />
            <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} renderer={(v) => v.replace('/', '\n')} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='number' value={data.number} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} />
            <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} renderer={(v) => { const c = dataLists.companies.find((c) => c.id == v); return c ? c.name : '' }} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='company_id' value={data.company_id} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} type='list' list={dataLists.companies.map(c => ({ label: c.name, value: c.id }))} />
            {/* <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='company' value={data.company} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} type='list' list={dataLists.companies.map(c => ({ label: c.name, value: c.id }))} /> */}
            <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='process' value={data.process || ''} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} />
            <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='doj' value={data.doj} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} type='date' />
            {/* <Cell updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='month' value={data.month} allowEdit={userSub === data.created_by} onFieldChange={onFieldChange} formData={formData} type='list' list={lists['months']} /> */}

            <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='month' value={data.month} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} type='list' list={lists.months} />

            <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='status' value={data.status} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} type='list' list={lists['status']} />
            <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='ctc' value={data.ctc} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} type='number' />


            <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='invoice_amount' value={data.invoice_amount} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} type='number' />

            {isSuperAdmin ? <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='invoice_amount_actual' value={data.invoice_amount_actual} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} type='number' /> : ''}

            <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='recruiter_id' value={data.recruiter} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} type='list' list={dataLists.recruiters.map(tl => ({ label: tl.name, value: tl.id }))} />

            {/* <td>{data.location}</td> */}
            <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='tl_id' value={data.tl} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} type='list' list={dataLists.teamLeaders.map(tl => ({ label: tl.name, value: tl.id }))} />
            <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='applicant_id' value={data.applicant_id} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} type='text' />
            {/* <td>{data.applicant_id}</td> */}
            {/* <td>{data.invoice_sent}</td> */}

            {isSuperAdmin ? <>
                {/* Entity */}
                <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='entity' value={data.entity} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} type='list' list={lists['entity']} />
                {/* Elapsed */}
                <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='days_elapsed' value={data.days_elapsed} allowEdit={false} onFieldChange={onFieldChange} formData={formData} type='number' />
                {/* Clawback */}
                <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='clawback' value={data.clawback} allowEdit={false} onFieldChange={onFieldChange} formData={formData} type='text' />
                {/* Invoice */}
                <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} cssClass={data.invoice_sent === 0 ? 'no-value' : ''} renderer={(v) => v == 2 ? 'Attrition' : v == 1 ? 'Yes' : v == 0 ? 'No' : ''} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='invoice_sent' value={data.invoice_sent} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} type='list' list={lists['yesNoAttrition']} />
                <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='invoice_no' value={data.invoice_no} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} type='text' />
                <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='invoice_date' value={data.invoice_date} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} type='date' />
                {/* Payment fields */}
                <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='payment' value={data.payment} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} type='list' list={lists['payment']} />
                <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='transaction_details' value={data.transaction_details} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} type='text' />
                <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='transaction_date' value={data.transaction_date} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} type='text' />

            </> : ''}
            {/* <td>{data.invoice_amount}</td> */}

            {/* <td>{data.review}</td> */}
            <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='review' value={data.review} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} type='list' list={lists['yesNoGeneric']} />

            <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='points' value={data.points} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} type='number' />
            {/* <td>{data.points}</td> */}
            <td>{data.employment_type}</td>

            <Cell updateSpecifiedField={updateSpecifiedField} canEdit={canEdit} updateField={updateField} editRowId={editRowId} editField={editField} setEditable={setEditable} id={data.id} field='sal' value={data.sal} allowEdit={canEdit(data.created_by)} onFieldChange={onFieldChange} formData={formData} type='text' />
            {/* <td>{data.sal}</td> */}

        </tr>)
}
const EditRow = ({ userSub, data, editData, id, setField }: { setField: (f: string, v: string) => void, id: string, userSub: string, data: any, editData: (i: number) => void }) => {

    return (
        <tr className={userSub === data.created_by ? 'editable' : 'not-editable'} key={'row-' + id} >
            <td><input type='text' placeholder='Name' value={data.name} onChange={(evt) => setField('name', evt.target.value)} /></td>
            <td><input type='text' placeholder='Number' value={data.number} onChange={(evt) => setField('number', evt.target.value)} /></td>
            <td><input type='text' placeholder='Company' value={data.company} onChange={(evt) => setField('company', evt.target.value)} /></td>
            <td><input type='text' placeholder='Process' value={data.process} onChange={(evt) => setField('process', evt.target.value)} /></td>
            <td><input type='text' placeholder='DoJ' value={data.doj} onChange={(evt) => setField('doj', evt.target.value)} /></td>
            <td><input type='text' placeholder='Month' value={data.month} onChange={(evt) => setField('month', evt.target.value)} /></td>
            <td><input type='text' placeholder='Recruiter' value={data.recruiter} onChange={(evt) => setField('recruiter', evt.target.value)} /></td>
            <td><input type='text' placeholder='Status' value={data.status} onChange={(evt) => setField('status', evt.target.value)} /></td>
            <td><input type='text' placeholder='Location' value={data.location} onChange={(evt) => setField('location', evt.target.value)} /></td>
            <td><input type='text' placeholder='TL' value={data.tl} onChange={(evt) => setField('tl', evt.target.value)} /></td>
            <td><input type='text' placeholder='ApplicantID' value={data.applicant_id} onChange={(evt) => setField('applicant_id', evt.target.value)} /></td>
            <td><input type='text' placeholder='Invoice Sent' value={data.invoice_sent} onChange={(evt) => setField('invoice_sent', evt.target.value)} /></td>
            <td><input type='text' placeholder='Invoice Amount' value={data.invoice_amount} onChange={(evt) => setField('invoice_amount', evt.target.value)} /></td>
            <td><input type='text' placeholder='Review' value={data.review} onChange={(evt) => setField('review', evt.target.value)} /></td>
            <td><input type='text' placeholder='Points' value={data.points} onChange={(evt) => setField('points', evt.target.value)} /></td>
            <td>
                <button onClick={() => editData(data.id)}>S</button>
                {/* <button onClick={() => editRow(data.id)}>E</button> */}
                <button>D</button>
            </td>
        </tr>)
}

export default JoinerTracker;