import React, { Component } from 'react';
// import pageStyles from '../LoginPage/LoginPage.module.css';
import fieldStyles from './LoginPage.module.css'

import heroImage from '../LoginPage/assets/graphic1.svg';
import backgroundImg from '../LoginPage/assets/wave.svg';
import Field from './Field/Field';
import { Auth } from 'aws-amplify';
import { toast, ToastContainer } from 'react-toastify';
// import Login from './components/Login';

interface Istate {
    isLogin: boolean,
    values: {
        username: string,
        password: string
    },
    loading: boolean
}

const loginFields = [
    { type: 'email', label: 'Email Address', name: 'loginEmailAddress' },
    { type: 'password', label: 'Password', name: 'loginPassword' },
]

const registrationFields = [
    { type: 'text', label: 'User Name', name: 'userName' },
    { type: 'text', label: 'Email Address', name: 'registerEmailAddress' },
    { type: 'password', label: 'Password', name: 'registerPassword' },
    { type: 'password', label: 'Repeat Password', name: 'repeatPassword' },
]


class LoginPage extends Component<{ signIn: (u: string, p: string) => void }, Istate> {

    state: Istate = {
        isLogin: true,
        values: {
            username: '',
            password: ''
        },
        loading: false
    }

    handleValue = ({ name, value }: any) => {
        this.setState({ values: { ...this.state.values, [name]: value } })
    }

    handleSubmit: React.FormEventHandler<HTMLInputElement> = (e) => {
        e.preventDefault();
        console.log('value,', this.state.values)
    }

    signIn = async () => {
        const { values } = this.state;
        this.setState({ loading: true })
        try {
            //@todo hardcode only for prashanth, since its fomr a differnet domain
            const username = values.username == 'prashanth' ? 'prashanth@pyps.in' : values.username + '@onedegreenorth.in';
            const user = await Auth.signIn(username, values.password);
            window.location.reload();
            return user;
        } catch (e: any) {
            toast.error(e.message)
            this.setState({ loading: false })

            return e;
        }
    }

    enableButton = () => {
        const { values } = this.state;
        return !!!values.username.trim() || !!!values.password.trim()
    }


    render() {

        const { loading } = this.state;


        return (<div className={`${fieldStyles.wrapper} p-0`}>
            <div className={`${fieldStyles.sectionOne}`}>
                <img className={`${fieldStyles.vectorImg}`} src={heroImage} alt="" />
                <img src={backgroundImg} className={fieldStyles.bgImg} alt="" />
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fillOpacity={1} d="M0,128L48,112C96,96,192,64,288,69.3C384,75,480,117,576,138.7C672,160,768,160,864,133.3C960,107,1056,53,1152,42.7C1248,32,1344,64,1392,80L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
            </div>
            <div className={`${fieldStyles.sectionTwo}`}>

                <div className={`${fieldStyles.login}`}>
                    <h2>Welcome to ATS</h2>
                    <form onSubmit={(e: any) => this.handleSubmit(e)}>

                        <div className="input-group mb-3" style={{ width: "100%" }}>
                            <input type='text' name='username' className={`form-control`} id={'username'} placeholder="Username" onChange={(e) => this.handleValue(e.target)} />
                            <span className={`input-group-text ${fieldStyles.inputGroupText}`} id="basic-addon2">@onedegreenorth.in</span>
                            {/* <label htmlFor={'username'}>Username</label> */}
                        </div>
                        <div className="form-floating mb-3" style={{ width: "100%" }}>
                            <input type={'text'} name={'password'} className={`${fieldStyles.input} form-control`} id={'password'} placeholder="name@example.com" onChange={(e) => this.handleValue(e.target)} />
                            <label htmlFor={'password'}>Password</label>
                        </div>
                        <button className='btn btn-success' type='button' onClick={this.signIn} disabled={loading || this.enableButton()}>Log In</button>

                    </form>


                </div>

                {/* <div className={`${styles.footer}`}>
                        <p>Terms</p>
                        <p>Policy</p>
                        <p>Contact Us</p>
                    </div> */}
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
        );
    }
}

export default LoginPage;