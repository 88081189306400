import { Tab } from "bootstrap";
import { format } from "path";
import { useEffect, useState } from "react";
import { ContactInterface, FormatRowInterface } from "../../types";



const FormatDataForm = ({ setColDataAuto, rowData, candidates, candidate, addFormatDataTabsRef, formats, loading, mode, addCandidateData, updateCandidateData, type, sendFormat, selectedEmails, contacts, selectEmail }: any) => {


    const [data, setData] = useState<any>({});

    const setColData = (format: any, row: any, value: string) => {
        // debugger;
        let _data = { ...data }
        // _data[format.formatId]={[row.coloumn_title]: value};
        _data[row.id] = { rowId: row.id, companyId: row.company_id, formatId: row.format_id, [row.coloumn_title]: value, coloumn: row.coloumn_title, value }
        // setData({ companyId: format.companyId, formatId: format.formatId, ...data, [row.coloumn_title]: value })
        setData(_data)
    }


    let dataHashed: any = {};
    formats && formats.map((fs: any) => {
        fs.data && fs.data.map((f: any) => {
            dataHashed[f.row_id] = f
        })
    })
    console.log(dataHashed);

    const getData = (format: any, row: any) => {
        console.log('Get', format, row)
        const d = format.data.find((f: any) => f.row_id == row.id)
        return d ? d.value : ''
    }

    const _data = {};
    let hash: any = {};
    formats && formats.map((fs: any) => {
        const _data = fs.data;
        _data.map((d: any) => {
            hash[d.candidate_id] = hash[d.candidate_id] || {};
            hash[d.candidate_id][d.row_id] = d.value;
            // _data[d.row_id]={ 
            //     rowId: d.row_id, 
            //     companyId: d.company_id, 
            //     formatId: d.format_id, 
            //     coloumn: d.coloumn_title, 
            //     value:d.value 
            // }
        })
    })

    console.log(hash)

    useEffect(() => {
        console.log('Loaded');
        if (addFormatDataTabsRef.current) {
            console.log('Tabs Loaded');

            var triggerTabList = [].slice.call(addFormatDataTabsRef.current.querySelectorAll('a'))
            triggerTabList.forEach((triggerEl: any, idx:number) => {
                var tabTrigger = new Tab(triggerEl)

                triggerEl.addEventListener('click', function (event: any) {
                    event.preventDefault();
                    setData({})
                })

                idx==0&&triggerEl.click();

            })
        }

        // setData(_data)
        formats.map((format: any, idx: number) => {
            format.rows.map((row: any) => {
                const val = getData(format, row);
                console.log(val)
                setColData(format, row, val)
            })
        })


        return () => {

            if (addFormatDataTabsRef.current) {
                var triggerTabList = [].slice.call(addFormatDataTabsRef.current.querySelectorAll('a'))
                triggerTabList.forEach((triggerEl: any) => {
                    var tabTrigger = new Tab(triggerEl)

                    triggerEl.removeEventListener('click', function (event: any) {
                        event.preventDefault();
                        setData({})
                    })
                })
            }



        }
    }, [formats])





    return (<div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{mode == 'edit' ? 'Edit' : 'Add'} Format Data</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body modal-xl modal-lg">
            <div className='form'>
                <h2>{candidate.name}</h2>
                <hr />

                <div>
                    {(contacts && contacts.length)
                        ? <table className='table table-bordered table-sm'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Title</th>
                                    <th>Phones</th>
                                    <th>Emails</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contacts.map((contact: ContactInterface) => <tr key={'contact' + contact.id}>
                                    <td>{contact.name}</td>
                                    <td>{contact.title}</td>
                                    <td><ul>{contact.phones.map(p => p.phone ? <li key={p.phone}>
                                        {p.phone}
                                    </li> : '')}</ul></td>
                                    <td><ul>{contact.emails.map(e => e.email ? <li key={e.email}>
                                        {e.email}
                                        <input type='checkbox' onChange={(evt) => selectEmail(evt.target.checked, contact, e)} />
                                    </li> : '')}</ul></td>
                                </tr>)}
                            </tbody>
                        </table>
                        : ''
                    }
                </div>

                <ul className="nav nav-tabs" ref={addFormatDataTabsRef}>
                    {formats.map((format: any, idx: number) => <li className="nav-item" key={'f-t-' + format.formatId}>
                        <a className="nav-link" data-bs-toggle="tab" data-bs-target={"#tab-" + format.formatId} aria-current="page" href="#">{format.companyName}-{format.name}</a>
                    </li>)}

                </ul>

                <div className="border-grey bg-white p-3 tab-content">
                    {/* {formats.map((format: any, idx: number) => <div key={'ft-d-' + format.formatId} className="tab-pane" id={"tab-" + format.formatId} role="tabpanel" aria-labelledby="home-tab">
                        <table className='table table-bordered' id={'format-table-data'} style={{ "border": "1px solid #333" }}>
                            <thead>
                                <tr>
                                    {format.rows.map((row: any) => <th key={'ft-row' + row.id} style={{ "border": "1px solid #333" }}>
                                        {row.coloumn_title}
                                    </th>)}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {format.rows.map((row: any) => <td key={'ft-row' + row.id} style={{ "border": "1px solid #333" }}>
                                        {mode == 'view'
                                            ? getData(format, row)
                                            : <input type='text' className='form-control' onChange={(evt) => setColData(format, row, evt.target.value)} defaultValue={getData(format, row)} />
                                        }
                                    </td>)}

                                </tr>

                            </tbody>
                        </table>



                    </div>)} */}
                    {formats.map((format: any, idx: number) => {
                        // (<div key={'ft-d-' + format.formatId} className="tab-pane" id={"tab-" + format.formatId} role="tabpanel" aria-labelledby="home-tab">
                        //     <table className='table table-bordered' id={'format-table-data'} style={{ "border": "1px solid #333" }}>
                        //         <thead>
                        //             <tr>
                        //                 {/* <th></th> */}
                        //                 {format.rows.map((row: any) => <th key={'ft-row' + row.id} style={{ "border": "1px solid #333" }}>
                        //                     {row.coloumn_title}
                        //                 </th>)}
                        //             </tr>
                        //         </thead>
                        //         <tbody>
                        //             <tr>
                        //                 {format.rows.map((row: any) => <td key={'ft-row' + row.id} style={{ "border": "1px solid #333" }}>
                        //                     {mode == 'view'
                        //                         ? getData(format, row)
                        //                         : <input type='text' className='form-control' onChange={(evt) => setColData(format, row, evt.target.value)} defaultValue={getData(format, row)} />
                        //                     }
                        //                 </td>)}

                        //             </tr>

                        //         </tbody>
                        //     </table>



                        // </div>)

                        return <FormDataFormTab
                            key={format.formatId}
                            format={format}
                            setColData={setColData}
                            setColDataAuto={setColDataAuto}

                            rowData={rowData}
                        />
                    })}

                </div>

            </div>
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={loading}>Close</button>
            {
                type == 'send'
                    ? <button type="button" className="btn btn-primary" onClick={sendFormat} disabled={loading || !selectedEmails.length} >Send</button>
                    : ''// : <button type="button" className="btn btn-primary" onClick={() => mode == 'edit' ? updateCandidateData(Object.values(data)) : addCandidateData(Object.values(data))} disabled={loading}>{mode == 'edit' ? 'Edit' : 'Add'} Candidate Data</button>
            }
        </div>
    </div >)
}
export default FormatDataForm;

const FormDataFormTab = ({ rowData, format, setColData, setColDataAuto }: { rowData: any, format: any, setColData: (format: any, row: any, val: string) => void, setColDataAuto: (format: any, row: any, val: string) => void }) => {

    const getData = (format: any, row: any) => {
        console.log('Get', format, row)
        const d = format.data.find((f: any) => f.row_id == row.id)
        return d ? d.value : ''
    }

    // let rows = format.rows.map((row: any) => {
    //     setColData(format, row, getData(format, row))
    //     return { ...row, value: getData(format, row) }
    // })


    return (<div key={'ft-d-' + format.formatId} className="tab-pane" id={"tab-" + format.formatId} role="tabpanel" aria-labelledby="home-tab">
        <table className='table table-bordered' id={'format-table-data-' + format.formatId} style={{ "border": "1px solid #333" }}>
            <thead>
                <tr>
                    {/* <th></th> */}
                    {format.rows.map((row: any) => <th key={'ft-row' + row.id} style={{ "border": "1px solid #333" }}>
                        {/* <th> */}
                        {row.coloumn_title}
                        {/* </th> */}
                    </th>)}
                </tr>
            </thead>
            <tbody>
                {/* <tr>
                    {format.rows.map((row: any) => <td key={'ft-row' + row.id} style={{ "border": "1px solid #333" }}>
                        <input type='text' className='form-control' data-id={row.id} onChange={(evt) => setColData(format, row, evt.target.value)} defaultValue={rowData[row.id]} />
                    </td>)}
                </tr> */}
                <tr>
                    {format.rows.map((row: any) => <td key={'ft-row' + row.id} style={{ "border": "1px solid #333" }}>
                        {/* <input type='text' className='form-control' onChange={(evt) => setColData(format, row, evt.target.value)} defaultValue={getData(format, row)} /> */}
                        <input type='text' className='form-control' data-id={row.id} onBlur={(evt) => setColDataAuto(format, row, evt.target.value)} defaultValue={rowData[row.id]} />
                    </td>)}
                    {/* <td>AUTO</td> */}

                </tr>

            </tbody>
        </table>



    </div>)

}