import API from "@aws-amplify/api";
import React, { createRef, useState } from "react";
import dayjs from "dayjs";
import { EmployeeInterface } from "../types";
import { isSuperAdmin, REST_API_NAME } from "../utils";





interface JoinerTrackerStateInterface {
    // joiners: any[],
    data: any, // For edit fields
    newData: any,  //For New Rows 
    showModal: boolean,
    editRowId: number,
    editField: string,
    filters: any,
    filterField: string,
    query: string,
    selectedMonth: string
    autoSaveField: string
    autoSaveFieldId: string
    employees: EmployeeInterface[],
    recruiters: EmployeeInterface[],
    teamLeaders: EmployeeInterface[],
    isSuperAdmin: boolean

    //new Flow
    loading: boolean
    companies: CompanyInvoiceInterface[]
    selectedCompanyId: number
    joiners: JoinerInterface[],



}

interface CompanyInvoiceInterface {
    id: number
    companyId: number
    name: string
    amount: number
    total: number
}
interface JoinerInterface {
    id: number
    candidateName: string
    candidateNumber: string
    doj: string
    billable: number
    invoice_amount_actual: number
}

class InvoicesToSend extends React.Component<{ user: any }, JoinerTrackerStateInterface> {

    ref: any;
    modal: any;
    subscription: any

    constructor(props: any) {
        super(props);
        this.state = {
            joiners: [],
            showModal: false,
            data: {},
            newData: {},
            editRowId: -1,
            editField: '',
            filters: {
                companies: [],
                process: [],
                recruiters: []
            },
            filterField: '',
            query: '',
            selectedMonth: dayjs().format('MMM'),
            autoSaveField: '',
            autoSaveFieldId: '',
            employees: [],
            recruiters: [],
            teamLeaders: [],
            isSuperAdmin: isSuperAdmin(this.props.user),



            ///New flow
            loading: true,
            companies: [],
            selectedCompanyId: -1

        }
    }

    getData = () => {
        Promise.all([
            API.get(REST_API_NAME, '/joiners/invoices/invoices-to-be-raised', {}),
        ])
            .then((results) => {
                const [joinerData] = results;

                let joiners = joinerData.result;

                joiners = joiners.map((j: any) => {
                    const _tl: any = {};
                    j.tls = j.tls.split(',').map((t: any) => _tl[t] = 1);
                    j.tls = Object.keys(_tl).sort((a, b) => a.localeCompare(b)).join(',');
                    return j
                })


                this.setState({
                    joiners: joiners,
                });

            })
    }

    getCompanies = async () => {

        Promise.all([
            API.get(REST_API_NAME, '/joiners/invoices/get-billable-companies', {}),
        ])
            .then((results) => {
                const companies = results[0].result;



                this.setState({
                    companies,
                    loading: false
                });

            })

    }

    async componentDidMount() {
        // const data: any = await API.get(REST_API_NAME, '/joiners', {})
        // const joinerData: any = await API.get(REST_API_NAME, '/joiners', {});
        // const dataEmployees: any = await API.get(REST_API_NAME, '/employees/filter/for-joiner-tracker', {});

        // this.getData()
        this.getCompanies();



    }

    componentWillUnmount() {
    }

    getJoiners = async (selectedCompanyId: number) => {

        API.get(REST_API_NAME, `/joiners/invoices/get-joiners-for-company/${selectedCompanyId}`, {})
            .then((results) => {
                const joiners = results.result;



                this.setState({
                    joiners,
                    loading: false
                });


            })

    }

    selectCompany = (selectedCompanyId: number) => {
        this.setState({ selectedCompanyId });
        this.getJoiners(selectedCompanyId)
    }

    toggleBillable = (id: number, billable: boolean) => {
        this.setState({ loading: true });
        API.put(REST_API_NAME, `/joiners/invoices/toggle-billable/${id}/${billable ? 1 : 0}`, {})
            .then(({ success }) => {
                if (success) {
                    this.setState({ loading: false, joiners: this.state.joiners.map(j => j.id == id ? { ...j, billable: billable ? 1 : 0 } : j) })
                }
            })
    }

    deleteJoiner = (id: number) => {
        this.setState({ loading: true });
        API.del(REST_API_NAME, `/joiners/${id}`, {})
            .then((results) => {
                this.setState({ loading: false, joiners: this.state.joiners.filter(j => j.id !== id) })

            })
    }



    callZoho = () => {
        const url = `https://accounts.zoho.com/oauth/v2/token`
        const f = new FormData();
        f.append('code', "1000.d231a380465bd59f18d5a0ce865f4daf.02d727aa2d4e4c2767d4c4cf6e67de32");
        f.append('client_id', "1000.2KEIHBWIC8U09TWIE5OIHOH1CXBPYL");
        f.append('client_secret', "71d8025a3a62c93c19c30c37b227716a72d8210506");
        f.append('redirect_uri', "https://dev.d2vwivrbksya04.amplifyapp.com/invoice-zoho");
        f.append('grant_type', "authorization_code");
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: f
        })
            .then(r => r.json())
            .then(r => console.log(r))
    }

    render() {

        const { loading, companies, joiners, selectedCompanyId,
            editRowId, data, editField, filters, filterField, query, recruiters, teamLeaders, isSuperAdmin, selectedMonth } = this.state;
        const userSub = this.props.user.attributes.sub;
        let filteredData = joiners;


        const totalAmount = joiners.reduce((b, j) => (j.billable ? j.invoice_amount_actual : 0) + b, 0)


        return <div className='my-5 mx-5'>
            <div className="row">
                {/* <button onClick={this.callZoho}>ddd</button> */}
                {/* <a href='https://accounts.zoho.com/oauth/v2/auth?scope=ZohoInvoice.invoices.CREATE,ZohoInvoice.invoices.READ,ZohoInvoice.invoices.UPDATE,ZohoInvoice.invoices.DELETE&client_id=1000.2KEIHBWIC8U09TWIE5OIHOH1CXBPYL&state=testing&response_type=code&redirect_uri=https://dev.d2vwivrbksya04.amplifyapp.com/invoice-zoho&access_type=offline'>Zoho</a> */}
                <div className="col-lg-4 col-md-4">
                    <table className='table table-striped1 table-bordered table-sm table-hover'>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Company</th>
                                <th>Total</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {companies.map((d, idx) => {
                                if (!d) { return '' }
                                return <tr key={d.id} className={selectedCompanyId == d.companyId ? 'bg-success' : ''}>
                                    <td>{idx + 1}</td>
                                    <td>{d.name}</td>
                                    <td>{d.total}</td>
                                    <td>{d.amount}</td>
                                    <td>
                                        <button className='btn btn-primary btn-sm' onClick={() => this.selectCompany(d.companyId)}>
                                            {/* <i className="bi bi-plus-circle"></i> */}
                                            Select
                                        </button>

                                    </td>
                                </tr>
                            })}


                        </tbody>
                    </table>
                </div>
                <div className="col-lg-7 col-md-7">
                    {joiners.length ?
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Name</th>
                                    <th>Number</th>
                                    <th>DoJ</th>
                                    <th>Billable</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {joiners.map((j, idx) => <tr key={j.id + idx + 'candidate'} className={j.billable ? "text-success" : "text-danger"}>
                                    <td>{idx + 1}</td>
                                    <td>{j.candidateName}</td>
                                    <td>{j.candidateNumber}</td>
                                    <td>{dayjs(j.doj).format('DD/MM/YYYY')}</td>
                                    <td>
                                        <input type='checkbox' defaultChecked={!!j.billable} onChange={(evt) => this.toggleBillable(j.id, evt.target.checked)} />
                                        {/* {j.billable} */}
                                    </td>
                                    <td>
                                        {j.invoice_amount_actual}
                                    </td>
                                    <td>
                                        <span className={loading ? 'text-secondary' : 'text-danger'}>
                                            <i className="bi bi-trash-fill" onClick={() => !loading && this.deleteJoiner(j.id)}></i>
                                        </span>
                                    </td>
                                </tr>)}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>Total</td>
                                    <td><h5>{totalAmount} </h5></td>
                                </tr>
                            </tbody>
                        </table>
                        : ''
                    }
                </div>

            </div>


        </div>;





    }
}



export default InvoicesToSend;