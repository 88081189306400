import API from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";
import Storage from "@aws-amplify/storage";
import { getRoles } from "@testing-library/dom";
import { Modal } from "bootstrap";
import dayjs from "dayjs";
import React from "react";
import { CandidateInterface, CompanyInterface, CoordinationFormatInterface, CoordinationInterface, EmployeeRoleType, FormatRowInterface, GenericDataNameInterface } from "../types";
import { FORMAT_FIELDS, REST_API_NAME } from "../utils";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from "react-select";
import { format } from "path";



const data: GenericDataNameInterface[] = [
    { id: 1, name: "Rashmi" },
    { id: 1, name: "Zubaina" },
    { id: 1, name: "Zubaida" },
    { id: 1, name: "Shruti" },
    { id: 1, name: "Mithi" },
    { id: 1, name: "Yousuf" },
    { id: 1, name: "Srinivas" },
    { id: 1, name: "Poojitha" },
    { id: 1, name: "Raheen" }
]


interface FormatsInterface {
    loading: boolean,
    companies: CompanyInterface[],
    selectedCompany: CompanyInterface,
    filterCompanyQuery: string,
    formats: CoordinationFormatInterface[]
    formatName: string
    formatCompany: CompanyInterface
    formatRows: FormatRowInterface[]
    selectedFormat: CoordinationFormatInterface

}


class FormatData extends React.Component<any, FormatsInterface>{

    createUserModalRef: any
    createUserModal: any;


    constructor(props: any) {
        super(props);
        const identityId = this.props.user.identityId

        this.state = {
            loading: true,
            companies: [],

            selectedCompany: {} as CompanyInterface,
            filterCompanyQuery: '',
            formats: [],
            formatName: '',
            formatCompany: {} as CompanyInterface,
            formatRows: [] as FormatRowInterface[],
            selectedFormat: {} as CoordinationFormatInterface
        }

        this.createUserModalRef = React.createRef();

    }

    async componentDidMount() {
        this.createUserModal = new Modal(this.createUserModalRef.current);
        this.createUserModalRef.current.addEventListener('hidden.bs.modal', (event: any) => {

            this.setState({
                selectedCompany: {} as CompanyInterface

            })

        })

        const resultCompanies = await API.get(REST_API_NAME, '/companies', {});
        const result = await API.get(REST_API_NAME, '/coordination/formats', {});


        const formats = result.result.records;


        // this.setState({ loading: false, companies: resultCompainies.result, candidates: result.result.map((t: any) => ({ ...t, companies: [] })) })
        this.setState({ loading: false, companies: resultCompanies.result, formats })

    }


    filterCompany = (companyName: string) => {
        this.setState({ filterCompanyQuery: companyName.toLowerCase() })
    }
    selectCompany = async (selectedFormat: CoordinationFormatInterface) => {
        this.setState({ selectedFormat, selectedCompany: this.state.companies.find(c => c.id == selectedFormat.company_id)! });


        const result = await API.get(REST_API_NAME, '/coordination/coloumns-for-format/' + selectedFormat.id, {});
        this.setState({ loading: false, formatRows: result.result.records })

        console.log(result)
    }
    selectFormatCompany = (formatCompany: CompanyInterface) => {
        this.setState({ formatCompany })
        // this.setState({ selectedCompany})
    }


    setFormatName = (formatName: string) => this.setState({ formatName })


    updateFormat = () => { }
    saveFormat = async () => {
        const result = await API.post(REST_API_NAME, '/coordination/format', {
            body: {
                name: this.state.formatName,
                companyId: this.state.formatCompany.id
            }
        });
        toast.success('Successfully saved Format');
        window.location.reload();


    }

    addFormatRow = () => {
        const row = {
            id: +new Date,
            coloumn_title: '',
            mapping_table: '',
            mapping_coloumn: ''
        }
        this.setState({ formatRows: [...this.state.formatRows, row] })
    }

    removeFormatRow = (id: number) => {
        this.setState({ formatRows: this.state.formatRows.filter(fr => fr.id != id) })
    }

    updateFieldInFormatRow = (formatRow: FormatRowInterface, field: keyof FormatRowInterface, value: string) => {
        this.setState({ formatRows: this.state.formatRows.map(fr => fr.id != formatRow.id ? fr : { ...fr, [field]: value }) })
    }

    saveFormatRows = async () => {
        const { selectedFormat, formatRows } = this.state;
        const result = await API.post(REST_API_NAME, '/coordination/formats', {
            body: {
                formatId: selectedFormat.id,
                companyId: selectedFormat.company_id,
                rows: formatRows
            }
        });
        this.setState({ selectedCompany: {} as CompanyInterface, selectedFormat: {} as CoordinationFormatInterface })
        toast.success('Successfully saved Format');
        window.location.reload();
    }

    showModal = () => {
        this.setState({ selectedCompany: {} as CompanyInterface, formatRows: [] })
        this.createUserModal.toggle()
    }

    deleteFormat=async (format:CoordinationFormatInterface)=>{
        const result = await API.del(REST_API_NAME, '/coordination/format/'+format.id, {});
        this.setState({ selectedCompany: {} as CompanyInterface, selectedFormat: {} as CoordinationFormatInterface, formats:this.state.formats.filter(f=>f.id!=format.id) })
        toast.success('Successfully saved Format');
    }



    render() {
        const { filterCompanyQuery, selectedFormat, formatRows, formatCompany, companies, formats, loading, selectedCompany } = this.state;
        const optionsCompanies = companies.map((c: CompanyInterface) => ({ value: c.id + '', label: c.name, ...c }));





        return (
            <div className='my-5'>
                <div className="container-fluid">
                    <button onClick={() => { this.showModal() }} className='btn btn-primary'>Add Format<i className="bi bi-person-plus-fill"></i></button>

                    <div className="row">
                        <nav id="sidebarMenu" className="my-5 col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                            <div className="position-sticky pt-3">
                                <h3>Companies</h3>
                                <div>
                                    <input type='text' className='form-control' placeholder='Filter' onChange={(evt) => this.filterCompany(evt.target.value.toLowerCase())} />
                                </div>

                                <ul className="list-group">
                                    {/* {formats.sort((a, b) => a.name.localeCompare(b.name)).map((c, idx) => <li key={'li' + idx + c.name} onClick={() => this.selectCompany(c)} className={"company-filter list-group-item cursor-pointer d-flex justify-content-space-between" + (c.company_id == selectedCompany.id ? ' active' : '')} aria-current="true"> */}
                                    {formats.filter(c => filterCompanyQuery == '' || c.name.toLowerCase().indexOf(filterCompanyQuery) != -1).sort((a, b) => a.name.localeCompare(b.name)).map((c, idx) => <li key={'li' + idx + c.name} onClick={() => this.selectCompany(c)} className={"company-filter list-group-item cursor-pointer d-flex justify-content-space-between" + (c.id == selectedFormat.id ? ' active' : '')} aria-current="true">
                                        <span>{c.name}</span>
                                        <span className='text-danger'>
                                            <i className="bi bi-trash-fill" onClick={()=>this.deleteFormat(c)}></i>
                                        </span>
                                    </li>)}
                                </ul>

                            </div>
                        </nav>

                        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">

                            <div className='my-5'>
                                <h3>{selectedCompany.id ? `${selectedCompany.name} - ${selectedFormat.name}` : ''}</h3>
                                <div>
                                    <button className='btn btn-sm btn-success' onClick={this.addFormatRow}>Add Row</button>
                                </div>
                                <table className='table table-sm table-bordered'>
                                    <thead>
                                        <tr>
                                            <th>
                                                Coloumn
                                            </th>
                                            <th>
                                                Table
                                            </th>
                                            <th>
                                                Field
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formatRows.map(formatRow => <tr>
                                            <td><input type='text' value={formatRow.coloumn_title} onChange={(evt) => this.updateFieldInFormatRow(formatRow, 'coloumn_title', evt.target.value)} /></td>
                                            <td>
                                                <select value={formatRow.mapping_table} onChange={(evt) => this.updateFieldInFormatRow(formatRow, 'mapping_table', evt.target.value)}>
                                                    <option></option>
                                                    <option value='CANDIDATE'>Candidate</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select value={formatRow.mapping_coloumn} onChange={(evt) => this.updateFieldInFormatRow(formatRow, 'mapping_coloumn', evt.target.value)}>
                                                    <option></option>
                                                    {formatRow.mapping_table == '' ? '' : FORMAT_FIELDS[formatRow.mapping_table].map((col: any, idx: number) => <option key={formatRow.id + col.label + idx} value={col.key}>{col.label}</option>)}
                                                </select>

                                            </td>
                                            <td>
                                                <button onClick={() => this.removeFormatRow(formatRow.id)}>Del</button>
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </table>
                                <div>
                                    <button className='btn btn-sm btn-success' onClick={this.saveFormatRows} >Save</button>
                                </div>


                            </div>
                        </main>
                    </div>
                </div>


                <div className="modal modal-xl fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.createUserModalRef} data-bs-backdrop='static' >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{selectedCompany.id ? 'Edit' : 'Add'} Company</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='form'>
                                    <form className=" g-3">

                                        <div className="mb-3 row">
                                            <label htmlFor="inputName" className="col-sm-2 col-form-label">Name</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputName" onChange={(evt) => this.setFormatName(evt.target.value)} />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="inputName" className="col-sm-2 col-form-label">Company</label>
                                            <div className="col-sm-10">
                                                <Select
                                                    options={optionsCompanies}
                                                    onChange={(e) => this.selectFormatCompany(e as unknown as CompanyInterface)}
                                                    value={formatCompany as CompanyInterface} />
                                            </div>
                                        </div>



                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={selectedCompany.id ? this.updateFormat : this.saveFormat} disabled={loading}>{selectedCompany.id ? 'Edit' : 'Add'} Company</button>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        )
    }
}

export default FormatData;