import dayjs from "dayjs";
import { memo } from "react";
import { MONTHS, YEARS } from "../../utils";


interface MonthSelectorPropsInterface {
    selectedMonth: string
    loading: boolean
    selectMonth: (month: string) => void
}

export const MonthSelector = (props: MonthSelectorPropsInterface) => {
    const {
        selectedMonth,
        loading,
        selectMonth
    } = props;
    return (<div className="dropdown">
        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false" disabled={loading}>
            {selectedMonth}
        </button>
        <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
            <li onClick={(a) => {a.preventDefault();selectMonth('NONE')}}><a className="dropdown-item" href="#">NONE</a></li>
            {MONTHS.map(m=><li onClick={(a) => {a.preventDefault();selectMonth(m)}} className={selectedMonth===m?'bg-primary':''}><a className="dropdown-item" href="#">{m}</a></li>)}
        </ul>
    </div>)
}

interface YearSelectorPropsInterface {
    selectedYear: number
    loading: boolean
    selectYear: (year: number) => void
}

export const YearSelector = (props: YearSelectorPropsInterface) => {
    const {
        selectedYear,
        loading,
        selectYear
    } = props;
    return (<div className="dropdown">
        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false" disabled={loading}>
            {selectedYear}
        </button>
        <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
            {YEARS.map(m=><li onClick={(a) => {a.preventDefault();selectYear(m)}} className={selectedYear===m?'bg-primary':''}><a className="dropdown-item" href="#">{m}</a></li>)}
        </ul>
    </div>)
}

export const DateTimeDisplay = memo(({ date }: { date: string }) => {
    return (<span>{!!!date ? '' : dayjs(date).format('DD/MM/YY HH:mm')}</span>)
});
