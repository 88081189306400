import API from "@aws-amplify/api";
import { a } from "@aws-amplify/ui";
import dayjs from "dayjs";
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { promises } from "fs";
import React from "react";
import { formatEmployeeName, isSuperAdmin, REST_API_NAME, YEARS } from "../utils";

import List from "list.js"

dayjs.extend(weekOfYear)

class Dashboard extends React.Component<any, any> {



    constructor(props: any) {
        super(props);
        const dt = new Date();
        this.state = {
            loading: true,
            selectMonth: dt.getMonth() + 1,
            selectYear: dt.getFullYear(),
            reports: {
                candidatesCreated: [],
                candidatesSuccessPercent: [],
                candidatesAlignedFeedback: [],
                resultSuccessRecruiter: []
            }
        }
    }

    async componentDidMount() {
        this.getData();
    }

    timeout:any

    getData = async () => {
        const { selectMonth, selectYear } = this.state;
        try {

            Promise.all([
                // API.get(REST_API_NAME, `/candidates/reports/created/${selectMonth}/${selectYear}`, {}),
                API.get(REST_API_NAME, `/joiners/reports/success/${selectMonth}/${selectYear}`, {}),
                API.get(REST_API_NAME, `/coordination/reports/aligned-awaiting-feedback/${selectMonth}/${selectYear}`, {}),
                API.get(REST_API_NAME, `/joiners/reports/success-recruiter/${selectMonth}/${selectYear}`, {}),

            ])
                .then(([resultCoordination, resultCoordinationFeedback, resultSuccessRecruiter]) => {

                    let hash: any = {};
                    // resultCoordination.result.map((r: any) => {
                    //     const key = r.id + dayjs(r.aligned_on).format('DD/MM/YYYY');
                    //     hash[key] = hash[key] || { ...r }
                    //     hash[key].total = +hash[key].total + +r.total;
                    // })
                    const alignedCount = Object.values(hash).sort((a: any, b: any) => +dayjs(a.aligned_on) - +dayjs(b.aligned_on))

                    let hash2: any = {};
                    resultCoordinationFeedback.result.map((r: any) => {
                        const key = r.id + dayjs(r.aligned_on).format('DD/MM/YYYY');
                        hash2[key] = hash2[key] || { ...r }
                        hash2[key].total = +hash2[key].total + +r.total;
                    })
                    const feedbackCount = Object.values(hash2).sort((a: any, b: any) => +dayjs(a.aligned_on) - +dayjs(b.aligned_on))

                    // const candidatesCount = resultCandidates.result.sort((a: any, b: any) => +dayjs(a.created_on) - +dayjs(b.created_on))



                    // let weeks: any = {};
                    // for (let i = 1; i < 54; i++) {
                    //     weeks[i] = false;
                    // }
                    // resultCoordination.result.map((r: any) => {
                    //     weeks[r.week] = weeks[r.week] || [];
                    //     weeks[r.week].push(r)
                    // });
                    // const dataInWeeks = Object.values(weeks).filter(f => f)
                    this.setState({
                        loading: false,
                        reports: {
                            // candidatesCreated: candidatesCount,
                            resultCoordinationC: resultCoordination.c,
                            resultCoordinationJ: resultCoordination.j,
                            candidatesSuccessPercent: resultCoordination.result,//.sort((a: any, b: any) => a.name.localeCompare(b.name)),//alignedCount,//resultCordination.result,
                            // candidatesSuccessPercent: dataInWeeks.sort((a: any, b: any) => b.week - a.week),//alignedCount,//resultCordination.result,
                            candidatesAlignedFeedback: resultCoordinationFeedback.result,//feedbackCount

                            resultSuccessRecruiter: resultSuccessRecruiter.result,
                            resultSuccessRecruiterC: resultSuccessRecruiter.c,
                            resultSuccessRecruiterJ: resultSuccessRecruiter.j,

                        }
                    })

                    this.timeout=setTimeout(() => {
                        Object.values(resultSuccessRecruiter.result).map((data: any, idx: number) => {
                            let options = {
                                valueNames: ['name', 'added', 'success', 'percent']
                            };
                            const d: any = Object.values(data);
                            const list = new List(`table${d[0].week}`, options);
                            list.sort && list.sort('success', { order: 'desc' })

                            const l = new List(`table-rec${d[0].week}`, options)
                            l.sort && l.sort('success', { order: 'desc' })

                        })



                        if (document.querySelector('#table-monthly list')?.children) {
                            let optionsM = {
                                valueNames: ['name', 'added', 'success', 'percent']
                            };
                            const list = new List(`table-monthly`, optionsM);
                            list.sort('success', { order: 'desc' })
                        }


                        if (document.querySelector(`table-monthly-rec list`)?.children) {
                            let optionsMRec = {
                                valueNames: ['name', 'added', 'success', 'percent']
                            };
                            const listrec = new List(`table-monthly-rec`, optionsMRec)
                            listrec.sort('success', { order: 'desc' })
                        }

                        if (document.querySelector(`feedback list`)?.children) {
                            let options1 = {
                                valueNames: ['name', 'total']
                            };
                            const list1 = new List(`feedback`, options1)
                        }

                        if (document.querySelector(`candidates list`)?.children) {
                            let options2 = {
                                valueNames: ['name', 'total']
                            };
                            const list2 = new List(`candidates`, options2)
                        }
                    }, 1000)

                })
            // const resultCandidates = await API.get(REST_API_NAME, `/candidates/reports/created/${selectMonth}/${selectYear}`, {});
            // // const resultCordination = await API.get(REST_API_NAME, `/coordination/reports/aligned/${selectMonth}/${selectYear}`, {});
            // const resultCoordination = await API.get(REST_API_NAME, `/joiners/reports/success/${selectMonth}/${selectYear}`, {});
            // const resultCoordinationFeedback = await API.get(REST_API_NAME, `/coordination/reports/aligned-awaiting-feedback/0/0`, {});



        } catch (e) {
            console.log('Error', e);
            this.setState({ loading: false })
        }

    }

    componentWillUnmount(){
        clearTimeout(this.timeout)
    }

    selectMonth = (month: number) => {
        this.setState({ selectMonth: month })
    }
    selectYear = (year: number) => {
        this.setState({ selectYear: year })
    }

    render() {

        const { reports, selectMonth, selectYear, loading } = this.state
        const { candidatesCreated, candidatesSuccessPercent, candidatesAlignedFeedback, resultCoordinationC, resultCoordinationJ, resultSuccessRecruiter, resultSuccessRecruiterC, resultSuccessRecruiterJ } = reports;
        const { user } = this.props;
        const weekStart = dayjs(`${selectYear}-${selectMonth}-1`).week();


        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        let _cTL: any = {};
        let _monthlyTL: any = {};
        if (resultCoordinationC) {
            const candidatesTable = resultCoordinationC.map((c: any) => {
                _cTL[c.week] = _cTL[c.week] || {};
                _cTL[c.week][c.team_leader_sub] = c;

                if (_monthlyTL[c.team_leader_sub]) {
                    _monthlyTL[c.team_leader_sub].candidatesAdded=_monthlyTL[c.team_leader_sub].candidatesAdded||0;
                    _monthlyTL[c.team_leader_sub].candidatesAdded += (c.candidatesAdded||0)
                } else {
                    _monthlyTL[c.team_leader_sub] = c;
                }

            });

            resultCoordinationJ.map((c: any) => {
                _cTL[c.week] = _cTL[c.week] || {};
                _cTL[c.week][c.tl_sub] = _cTL[c.week][c.tl_sub] || {};
                _cTL[c.week][c.tl_sub] = { ..._cTL[c.week][c.tl_sub], ...c }

                if (_monthlyTL[c.tl_sub]) {
                    _monthlyTL[c.tl_sub].success = _monthlyTL[c.tl_sub].success || 0;
                    _monthlyTL[c.tl_sub].success += c.success;
                } else {
                    _monthlyTL[c.tl_sub] = c;
                }

            });
        }
        console.log(_cTL);
        const monthlyValuesTL = Object.values(_monthlyTL).flat().map((m: any) => {
            m.success = m.success || 0;
            return m
        });;
        console.log("m", monthlyValuesTL);

        let weeklyValuesTL = [];
        for (let w in _cTL) {
            weeklyValuesTL.push(Object.values(_cTL[w]))
        };
        console.log(weeklyValuesTL)



        let _cRECRUITER: any = {};
        let _monthlyRECRUITER: any = {};
        if (resultSuccessRecruiterC) {
            const candidatesTable = resultSuccessRecruiterC.map((c: any) => {
                _cRECRUITER[c.week] = _cRECRUITER[c.week] || {};
                _cRECRUITER[c.week][c.recruiter_sub] = c;

                if (_monthlyRECRUITER[c.recruiter_sub]) {
                    _monthlyRECRUITER[c.recruiter_sub].candidatesAdded=_monthlyRECRUITER[c.recruiter_sub].candidatesAdded||0;
                    _monthlyRECRUITER[c.recruiter_sub].candidatesAdded += (c.candidatesAdded||0)
                } else {
                    _monthlyRECRUITER[c.recruiter_sub] = c;
                }

            });

            resultSuccessRecruiterJ.map((c: any) => {
                _cRECRUITER[c.week] = _cRECRUITER[c.week] || {};
                _cRECRUITER[c.week][c.recruiter_sub] = _cRECRUITER[c.week][c.recruiter_sub] || {};
                _cRECRUITER[c.week][c.recruiter_sub] = { ..._cRECRUITER[c.week][c.recruiter_sub], ...c }

                if (_monthlyRECRUITER[c.recruiter_sub]) {
                    _monthlyRECRUITER[c.recruiter_sub].success = _monthlyRECRUITER[c.recruiter_sub].success || 0;
                    _monthlyRECRUITER[c.recruiter_sub].success += c.success;
                } else {
                    _monthlyRECRUITER[c.recruiter_sub] = c;
                }

            });
        }
        console.log('r', _cRECRUITER);
        const monthlyValuesRECRUITER = Object.values(_monthlyRECRUITER).flat().map((m: any) => {
            m.success = m.success || 0;
            return m
        });
        console.log("mR", monthlyValuesRECRUITER);

        let weeklyValuesRECRUITER = [];
        for (let w in _cRECRUITER) {
            weeklyValuesRECRUITER.push(Object.values(_cRECRUITER[w]))
        };
        console.log(weeklyValuesRECRUITER);


        let _mTL: any = {}
        let _wTL: any = {};
        for (let week in candidatesSuccessPercent) {
            // debugger;
            _wTL[week] = [];
            for (let sub in candidatesSuccessPercent[week]) {
                _wTL[week].push(candidatesSuccessPercent[week][sub]);

                if (!_mTL[sub]) {
                    let _t = candidatesSuccessPercent[week][sub];
                    _t.success = _t.success || 0;
                    _mTL[sub] = _t;
                } else {
                    _mTL[sub].candidatesAdded = _mTL[sub].candidatesAdded || 0;
                    _mTL[sub].success = _mTL[sub].success || 0;

                    _mTL[sub].candidatesAdded += +candidatesSuccessPercent[week][sub].candidatesAdded || 0;
                    _mTL[sub].success += +candidatesSuccessPercent[week][sub].success || 0;
                }
                // _m[sub].candidatesAdded += +candidatesSuccessPercent[week][sub].candidatesAdded;
                // _m[sub].success += +candidatesSuccessPercent[week][sub].success;
            }
        }

        console.log('Monthly', _mTL)
        console.log('Weekly', _wTL)

        let _mREC: any = {}
        let _wREC: any = {};
        for (let week in resultSuccessRecruiter) {

            _wREC[week] = [];
            for (let sub in resultSuccessRecruiter[week]) {
                _wREC[week].push(resultSuccessRecruiter[week][sub]);

                if (!_mREC[sub]) {
                    let _t = { ...resultSuccessRecruiter[week][sub] };
                    _t.success = _t.success || 0;

                    _mREC[sub] = _t
                } else {
                    _mREC[sub].candidatesAdded=_mREC[sub].candidatesAdded||0;
                    
                    _mREC[sub].candidatesAdded += +resultSuccessRecruiter[week][sub].candidatesAdded || 0;
                    _mREC[sub].success += +resultSuccessRecruiter[week][sub].success || 0;
                }
                if (isNaN(_mREC[sub].success)) {

                    console.log('Success not found in recruiter')
                }
                // _m[sub].candidatesAdded += +candidatesSuccessPercent[week][sub].candidatesAdded;
                // _m[sub].success += +candidatesSuccessPercent[week][sub].success;
            }
        }

        console.log('Monthly', _mREC)
        console.log('Weekly', _wREC);


        console.log('DDD',dayjs().subtract(7, 'days').format('YYYY/MM/DD 23:59:59'));

        // debugger;

        return <div className='my-5'>

            <div className='container'>

                <div className='row'>
                    <div className='col-lg-1 col-3'>

                        <div className="dropdown">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                {months[selectMonth - 1]}
                            </button>
                            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                                {/* <li onClick={() => this.selectMonth('NONE')}><a className="dropdown-item" href="#">NONE</a></li> */}
                                <li onClick={() => this.selectMonth(1)}><a className="dropdown-item" href="#">Jan</a></li>
                                <li onClick={() => this.selectMonth(2)}><a className="dropdown-item" href="#">Feb</a></li>
                                <li onClick={() => this.selectMonth(3)}><a className="dropdown-item" href="#">Mar</a></li>
                                <li onClick={() => this.selectMonth(4)}><a className="dropdown-item" href="#">Apr</a></li>
                                <li onClick={() => this.selectMonth(5)}><a className="dropdown-item" href="#">May</a></li>
                                <li onClick={() => this.selectMonth(6)}><a className="dropdown-item" href="#">Jun</a></li>
                                <li onClick={() => this.selectMonth(7)}><a className="dropdown-item" href="#">Jul</a></li>
                                <li onClick={() => this.selectMonth(8)}><a className="dropdown-item" href="#">Aug</a></li>
                                <li onClick={() => this.selectMonth(9)}><a className="dropdown-item" href="#">Sep</a></li>
                                <li onClick={() => this.selectMonth(10)}><a className="dropdown-item" href="#">Oct</a></li>
                                <li onClick={() => this.selectMonth(11)}><a className="dropdown-item" href="#">Nov</a></li>
                                <li onClick={() => this.selectMonth(12)}><a className="dropdown-item" href="#">Dec</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-1 col-3'>
                        <div className="dropdown">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                {selectYear}
                            </button>
                            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                                {/* <li onClick={() => this.selectMonth('NONE')}><a className="dropdown-item" href="#">NONE</a></li> */}
                                {
                                    YEARS.map(year=><li onClick={() => this.selectYear(year)}><a className="dropdown-item" href="#">{year}</a></li>)
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-2 col-2'>
                        <button onClick={() => { this.getData() }} className='btn btn-primary'>Filter</button>
                    </div>
                </div>
                <hr />
                <div className='row justify-content-center'>

                    <div className='col'>
                        <h4>TL Performance</h4>
                        <div id={`table-monthly`}>


                            <table className='table table-bordered' >
                                <thead>
                                    <tr>
                                        <th>
                                            TL
                                            <i className="bi bi-sort-alpha-down sort" data-sort="name"></i>
                                        </th>
                                        <th>
                                            Count
                                            <i className="bi bi-sort-alpha-down sort" data-sort="added"></i>
                                        </th>
                                        <th>
                                            Success
                                            <i className="bi bi-sort-alpha-down sort" data-sort="success"></i>
                                        </th>
                                        <th>%
                                            <i className="bi bi-sort-alpha-down sort" data-sort="percent"></i>
                                        </th>
                                        {/* <th>Date</th> */}
                                    </tr>
                                </thead>
                                <tbody className="list">
                                    {Object.values(_mTL).sort((a: any, b: any) => +b.success - +a.success).map((c: any, idx: any) => <tr key={'row-' + idx}>
                                        {/* <td>{((c.week + 1) - weekStart) + 1}</td> */}
                                        <td className='name'>{formatEmployeeName(c.name || c.tl)}</td>
                                        <td className='added'>{c.candidatesAdded}</td>
                                        <td className='success'>{c.success || 0}</td>
                                        <td className='percent'>{(!c.success || !c.candidatesAdded) ? 0 : Number(100 * c.success / c.candidatesAdded).toFixed(2)}</td>
                                        {/* <td>{dayjs(c.aligned_on).format('DD/MM/YYYY')}</td> */}
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>

                        <div className='col'>
                            {Object.values(_wTL).map((data: any, idx: number) => <div id={`table${data[0].week}`}>
                                <h4>Week {idx + 1} - Performance</h4>

                                <table className='table table-bordered' key={data[0].week}>
                                    <thead>
                                        <tr>
                                            <th>
                                                TL
                                                <i className="bi bi-sort-alpha-down sort" data-sort="name"></i>
                                            </th>
                                            <th>
                                                Count
                                                <i className="bi bi-sort-alpha-down sort" data-sort="added"></i>
                                            </th>
                                            <th>
                                                Success
                                                <i className="bi bi-sort-alpha-down sort" data-sort="success"></i>
                                            </th>
                                            <th>%
                                                <i className="bi bi-sort-alpha-down sort" data-sort="percent"></i>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="list">
                                        {data.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((c: any, idx: any) => <tr key={'row-' + idx}>
                                            <td className='name'>{formatEmployeeName(c.name || c.tl)}</td>
                                            <td className='added'>{c.candidatesAdded}</td>
                                            <td className='success'>{c.success || 0}</td>
                                            <td className='percent'>{(!c.success || !c.candidatesAdded) ? 0 : Number(100 * c.success / c.candidatesAdded).toFixed(2)}</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>)}
                        </div>


                    </div>
                    <div className='col'>
                        <h4>Recruiter Performance</h4>
                        <div id={`table-monthly-rec`}>


                            <table className='table table-bordered' >
                                <thead>
                                    <tr>
                                        <th>
                                            Recruiter
                                            <i className="bi bi-sort-alpha-down sort" data-sort="name"></i>
                                        </th>
                                        <th>
                                            Count
                                            <i className="bi bi-sort-alpha-down sort" data-sort="added"></i>
                                        </th>
                                        <th>
                                            Success
                                            <i className="bi bi-sort-alpha-down sort" data-sort="success"></i>
                                        </th>
                                        <th>%
                                            <i className="bi bi-sort-alpha-down sort" data-sort="percent"></i>
                                        </th>
                                        {/* <th>Date</th> */}
                                    </tr>
                                </thead>
                                <tbody className="list">
                                    {Object.values(_mREC).sort((a: any, b: any) => +b.success - +a.success).map((c: any, idx: any) => <tr key={'row-' + idx}>
                                        {/* <td>{((c.week + 1) - weekStart) + 1}</td> */}
                                        <td className='name'>{formatEmployeeName(c.name || c.tl)}</td>
                                        <td className='added'>{c.candidatesAdded}</td>
                                        <td className='success'>{c.success || 0}</td>
                                        <td className='percent'>{(!c.success || !c.candidatesAdded) ? 0 : Number(100 * c.success / c.candidatesAdded).toFixed(2)}</td>
                                        {/* <td>{dayjs(c.aligned_on).format('DD/MM/YYYY')}</td> */}
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>

                        <div className='col'>
                            {Object.values(_wREC).map((data: any, idx: number) => <div id={`table-rec${data[0].week}`}>
                                <h4>Week {idx + 1} - Performance</h4>

                                <table className='table table-bordered' key={data[0].week}>
                                    <thead>
                                        <tr>
                                            <th>
                                                Recruiter
                                                <i className="bi bi-sort-alpha-down sort" data-sort="name"></i>
                                            </th>
                                            <th>
                                                Count
                                                <i className="bi bi-sort-alpha-down sort" data-sort="added"></i>
                                            </th>
                                            <th>
                                                Success
                                                <i className="bi bi-sort-alpha-down sort" data-sort="success"></i>
                                            </th>
                                            <th>%
                                                <i className="bi bi-sort-alpha-down sort" data-sort="percent"></i>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="list">
                                        {data.sort((a: any, b: any) => +b.success - +a.sccess).map((c: any, idx: any) => <tr key={'row-' + idx}>
                                            <td className='name'>{formatEmployeeName(c.name || c.tl)}</td>
                                            <td className='added'>{c.candidatesAdded}</td>
                                            <td className='success'>{c.success || 0}</td>
                                            <td className='percent'>{(!c.success || !c.candidatesAdded) ? 0 : Number(100 * c.success / c.candidatesAdded).toFixed(2)}</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>)}
                        </div>


                    </div>

                    <div className='col'>
                        <h4>Awaited Feedback</h4>
                        <div id='feedback'>

                            <table className='table table-bordered'>
                                <thead>
                                    <tr>
                                        <th>Coordinator
                                            <i className="bi bi-sort-alpha-down sort" data-sort="name"></i>

                                        </th>
                                        <th>Count
                                            <i className="bi bi-sort-alpha-down sort" data-sort="total"></i>

                                        </th>

                                    </tr>
                                </thead>
                                <tbody className="list">
                                    {candidatesAlignedFeedback.map((c: any, idx: any) => <tr key={'row-' + idx}>
                                        <td className='name'>{c.name}</td>
                                        <td className='total'>{c.total}</td>
                                        {/* <td>{dayjs(c.aligned_on).format('DD/MM/YYYY')}</td> */}
                                    </tr>)}

                                </tbody>

                            </table>

                        </div>
                    </div>



                </div>
            </div>
        </div>
    }


}


export default Dashboard;