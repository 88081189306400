import API from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";
// import { toast } from "@aws-amplify/ui";
import { Modal } from "bootstrap";
import dayjs from "dayjs";
import React, { useState } from "react";
import Select from "react-select";
import { EmployeeInterface, EmployeeRoleType, GenericDataNameInterface, GenericObjectType, TeamInterface } from "../types";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { REST_API_NAME, REST_API_PATHS } from "../utils";


const data: GenericDataNameInterface[] = [
    { id: 1, name: "Rashmi" },
    { id: 1, name: "Zubaina" },
    { id: 1, name: "Zubaida" },
    { id: 1, name: "Shruti" },
    { id: 1, name: "Mithi" },
    { id: 1, name: "Yousuf" },
    { id: 1, name: "Srinivas" },
    { id: 1, name: "Poojitha" },
    { id: 1, name: "Raheen" }
]

interface EmployeesStateInterface {
    loading: boolean,
    employees: EmployeeInterface[],
    group: EmployeeRoleType,
    data: EmployeeInterface,
    team: TeamInterface,
    teams: TeamInterface[],
    selectedUsername: string,
    password: string
    groups: { name: string, order: number }[],
    filterRole: string
}


class Employees extends React.Component<any, EmployeesStateInterface>{

    createUserModalRef: any
    createUserModal: any;

    createTeamModalRef: any
    createTeamModal: any;

    resetPasswordModalRef: any
    resetPasswordModal: any;

    addUserToGroupModalRef: any;
    addUserToGroupModal: any;

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            employees: [],
            selectedUsername: '',
            password: '',
            group: 'recruiter',
            groups: [],
            data: {
                id: -1,
                name: '',
                number: '',
                email: '',
                personal_email: '',
                role: "recruiter",
                sub: ''
            } as EmployeeInterface,
            team: {
                id: -1,
                name: '',
                tl_sub: '',
                tl_name: ''
            },
            teams: [],
            filterRole: ''
        }

        this.createUserModalRef = React.createRef();
        this.resetPasswordModalRef = React.createRef();
        this.addUserToGroupModalRef = React.createRef();
        this.createTeamModalRef = React.createRef();

    }

    async componentDidMount() {
        this.createUserModal = new Modal(this.createUserModalRef.current);
        this.resetPasswordModal = new Modal(this.resetPasswordModalRef.current);
        this.addUserToGroupModal = new Modal(this.addUserToGroupModalRef.current);
        this.createTeamModal = new Modal(this.createTeamModalRef.current);

        let apiName = 'AdminQueries';
        let path = '/listGroups';
        let myInit = {
            // body: {...data,username:data.email},
            // queryStringParameters: {
            //     "username": data.email,
            //   },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }

        Promise.all([
            API.get(REST_API_NAME, REST_API_PATHS.TEAMS.LIST, {}),
            API.get(REST_API_NAME, REST_API_PATHS.EMPLOYEES.LIST, {}),
            API.get(apiName, path, myInit)
        ])
            .then(([resultTeams, result, groupsResult]) => {
                const employees = result.result.map((t: any) => ({ ...t, groups: t.role.split(',') }))
                this.setState({ loading: false, teams: resultTeams.result, employees, groups: groupsResult.Groups.map((g: any) => ({ name: g.GroupName, order: g.Precedence })) })
            })
        // const resultTeams = await API.get(REST_API_NAME, REST_API_PATHS.TEAMS.LIST, {});
        // const result = await API.get(REST_API_NAME, REST_API_PATHS.EMPLOYEES.LIST, {});


        // const groupsResult = await API.get(apiName, path, myInit);


    }


    addEmployees = () => {
        const emp = [

            "Ameen",
            "ArshiyaAsha",
            "ArshiyaSabhat",
            "PriyaC",


        ];

        emp.map(async (e) => {
            const employee = {
                email: e + "@onedegreenorth.in",
                name: e,
                number: "+919191919191",
                role: "recruiter",
                username: e + "@onedegreenorth.in"
            };

            let apiName = 'AdminQueries';
            let path = '/createUser';
            let myInit = {
                body: { ...employee },
                // queryStringParameters: {
                //     "username": data.email,
                //   },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                }
            }
            try {
                await API.post(apiName, path, myInit);
                this.setState({ loading: false, data: {} as EmployeeInterface })

            } catch (err) {
                this.setState({ loading: false })
            }

        })


    }


    addTeam = async () => {
        const { team, teams } = this.state;
        if (!team.name.trim()) {
            return;
        }
        this.setState({ loading: true });

        try {
            const { result, success } = await API.post(REST_API_NAME, REST_API_PATHS.TEAMS.CREATE, {
                body: team
            });
            if (success) {

                this.setState({ loading: false, team: { id: -1, name: '', tl_name: '', tl_sub: '' }, teams: [...teams, { ...team, id: result.insertId }] })
                toast.success('Successfully created team')
            }


        } catch (err: any) {
            this.setState({ loading: false });
        }
    }

    selectTeam = (team: TeamInterface) => this.setState({ team })
    updateTeam = async () => {
        const { team, teams } = this.state;
        this.setState({ loading: true });

        try {
            const { result, success } = await API.put(REST_API_NAME, REST_API_PATHS.TEAMS.UPDATE + '/' + team.id, {
                body: team
            });
            if (success) {
                this.setState({ loading: false, team: { id: -1, name: '', tl_name: '', tl_sub: '' }, teams: teams.map((emp) => emp.id == team.id ? team : emp) })
                toast.success('Successfully updated team')
            }


        } catch (err: any) {
            this.setState({ loading: false });
        }

    }
    deleteTeam = async (id: number) => {
        const { teams } = this.state;
        this.setState({ loading: true })
        const team = teams.find(t => t.id == id);



        await API.del(REST_API_NAME, REST_API_PATHS.TEAMS.REMOVE + `/${id}`, {});

        this.setState({ loading: false, teams: teams.filter((emp) => emp.id !== id) })

    }

    addEmployee = async () => {
        const { data } = this.state;
        console.log('Saving', data);
        this.setState({ loading: true });
        var regex = /\+91[\d]{10}/g;
        if (!regex.test(data.number)) {
            alert('Please check phone number format.');
            this.setState({ loading: false });
            return;
        }


        // const result = await API.post('apia2dc579f', '/employees', {
        //     body: data
        // })

        // Working get uset
        // let apiName = 'AdminQueries';
        // let path = '/getUser';
        // let myInit = {
        //     body: {},
        //     queryStringParameters: {
        //         "username": data.email,
        //       },
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        //     }
        // }
        // await API.get(apiName, path, myInit);


        let apiName = 'AdminQueries';
        let path = '/createUser';
        let myInit = {
            body: { ...data, username: data.email },
            // queryStringParameters: {
            //     "username": data.email,
            //   },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        try {
            await API.post(apiName, path, myInit);
            this.setState({ loading: false, data: {} as EmployeeInterface })
            this.createUserModal.toggle();

        } catch (err: any) {
            this.setState({ loading: false });
            // alert(err.message);
        }

        // API.get('AdminQueries','/getUser',{
        //     body:{username:data.email}
        // })
        // this.setState({ joiners: [...this.state.joiners, { ...data, id: result.result.insertId }] })
        // console.log(result)
    }
    updateEmployee = async () => {
        const { data, employees } = this.state;
        console.log('Updating', data);
        this.setState({ loading: true })


        let apiName = 'AdminQueries';
        let path = '/updateAttributes';
        let myInit = {
            body: { ...data, username: data.email },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        await API.post(apiName, path, myInit);
        data.doj = data.doj ? dayjs(data.doj).format('YYYY-MM-DD') : '';
        // await API.put('apia2dc579f', `/employees/number/${data.email}/${data.number}`, {});
        await API.put(REST_API_NAME, `${REST_API_PATHS.EMPLOYEES.UPDATE}/${data.id}`, { body: data });

        this.setState({ loading: false, data: {} as EmployeeInterface, employees: employees.map((emp) => emp.id == data.id ? { ...data } : emp) })
        this.createUserModal.toggle();
    }

    resetPassword = async () => {
        const { data, selectedUsername, password } = this.state;
        console.log('Reset', data);
        this.setState({ loading: true })



        let apiName = 'AdminQueries';
        let path = '/resetPassword';
        let myInit = {
            body: { username: selectedUsername, password: password },
            // queryStringParameters: {
            //     "username": data.email,
            //   },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        let changePasswordResult: any;
        try {
            fetch('https://bhlcrbfthg.execute-api.ap-south-1.amazonaws.com/dev/resetPassword', { ...myInit, body: JSON.stringify({ username: selectedUsername, password: password }), method: 'post' })
                .then(r => {
                    if (r.status == 400) {
                        console.log('Err', r.json().then(d => toast.error(d.message)))
                        this.setState({ loading: false })
                    } else if (r.status == 200) {
                        this.setState({ loading: false, selectedUsername: "", password: "" })
                        this.resetPasswordModal.toggle();
                    }
                })
            return;
            // .then(result => {
            //     this.setState({ loading: false, selectedUsername: "", password: "" })
            //     this.resetPasswordModal.toggle();
            // })

            // changePasswordResult = await API.post(apiName, path, myInit);
            // console.log('result1', changePasswordResult)
        } catch (err: any) {
            // console.log('result',changePasswordResult,err)
            toast.error(err.message)
            this.setState({ loading: false })
            return;
        }
        this.setState({ loading: false, selectedUsername: "", password: "" })
        this.resetPasswordModal.toggle();
    }

    addUserToGroup = async () => {
        const { data, selectedUsername, group, employees: teamLeaders } = this.state;
        console.log('Reset', data);
        this.setState({ loading: true })

        let apiName = 'AdminQueries';
        let path = '/addUserToGroup';
        let myInit = {
            body: { username: selectedUsername, groupname: group },
            // queryStringParameters: {
            //     "username": data.email,
            //   },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        try {
            const user = teamLeaders.find(tl => tl.sub == selectedUsername)!;
            user.groups.push(group);
            //@todo tempoaray. remove when we allow multiple grops
            user.role = user.groups.join(',') as EmployeeRoleType
            await API.put('apia2dc579f', `/employees/group/${selectedUsername}/${user.role}`, {});
            await API.post(apiName, path, myInit);
        } catch (e) {
            console.log(e)
        }
        this.setState({ loading: false, selectedUsername: "", password: "" })
        this.addUserToGroupModal.toggle();
    }

    getGroupsForUser = async (username: string) => {
        let apiName = 'AdminQueries';
        let path = '/listGroupsForUser';
        let myInit = {
            // body: { username: selectedUsername, groupname: group },
            queryStringParameters: {
                "username": username,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        try {
            const groups = await API.get(apiName, path, myInit);
            this.setState({ employees: this.state.employees.map(tl => tl.sub === username ? { ...tl, groups: groups.Groups.map((g: any) => g.GroupName) } : tl) })
        } catch (e) {
            console.log(e)
        }

    }

    setTeamField = (field: string | GenericObjectType, value: string | boolean) => {

        if (typeof field === "string") {
            this.setState({ team: { ...this.state.team, [field]: value } })
        } else {
            this.setState({ team: { ...this.state.team, ...field } })
        }
    }
    setField = (field: string, value: string | boolean) => {
        this.setState({ data: { ...this.state.data, [field]: value } })
    }
    setPasswordField = (value: string) => {
        this.setState({ password: value })
    }
    setGroupField = (value: string) => {
        this.setState({ group: value as EmployeeRoleType, data: { ...this.state.data, role: value as EmployeeRoleType } })
    }

    selectUser = (selectedUsername: string) => {
        const { employees: teamLeaders } = this.state;
        const user = teamLeaders.find(tl => tl.sub === selectedUsername)!

        this.setState({ selectedUsername, data: user })
    }
    unSelectUser = () => {
        this.setState({ selectedUsername: '' })
    }

    removeGroupForUser = async (username: string, group: string) => {

        let apiName = 'AdminQueries';
        let path = '/removeUserFromGroup';
        let myInit = {
            body: { username: username, groupname: group },
            // queryStringParameters: {
            //     "username": username,
            // },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        try {
            const groups = await API.post(apiName, path, myInit);
            this.setState({ employees: this.state.employees.map(tl => tl.sub === username ? { ...tl, groups: tl.groups.filter((g: any) => g != group) } : tl) })
        } catch (e) {
            console.log(e)
        }

    }

    deleteUser = async (username: string) => {

        let apiName = 'AdminQueries';
        let path = '/deleteUser';
        let myInit = {
            body: { username: username },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        try {
            await API.post(apiName, path, myInit);
            await API.del('apia2dc579f', `/employees/${username}`, {});
            this.setState({ employees: this.state.employees.filter(tl => tl.sub !== username) })
        } catch (e) {
            console.log(e)
        }
    }

    filterByRole = (role: string) => {
        this.setState({ filterRole: role })
    }


    updateCell = async (field: keyof EmployeeInterface, employee: EmployeeInterface) => {

        this.setState({ loading: true })
        // console.log(d,field)
        let { employees } = this.state;

        try {
            const result = await API.put(REST_API_NAME, `/employees/update-field/${field}/` + employee.id, {
                body: {
                    value: employee[field]
                }
            });


            this.setState({
                loading: false,
                employees: employees.map(e => e.id == employee.id ? employee : e),
            })

            toast.success('Saved Successfully');

        } catch (e) {
            console.log('Error', e);
            this.setState({ loading: false })
        }



    }



    render() {
        const { employees, data, team, teams, groups, loading, selectedUsername, filterRole } = this.state;
        let user: EmployeeInterface | undefined;
        if (selectedUsername) {
            user = employees.find(tl => tl.sub === selectedUsername)
        } else {
            user = undefined;
        }

        let teamsHash: any = {};
        teams.map(t => teamsHash[t.id] = t.name);

        const teamLeaders = employees.filter(emp => emp.groups.find(f => f == 'teamleader'))

        return (
            <div className='my-5'>
                {/* <button onClick={() => { this.unSelectUser(); this.createUserModal.toggle() }} className='btn btn-primary'>Add <i className="bi bi-person-plus-fill"></i></button> */}
                {/* <button onClick={this.addEmployees}>Add</button> */}

                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                    <div className="btn-group me-2" role="group" aria-label="First group">
                        <button onClick={() => { this.unSelectUser(); this.createUserModal.toggle() }} className='btn btn-primary'>Add Employee<i className="bi bi-person-plus-fill"></i></button>
                    </div>
                    <div className="btn-group me-2" role="group" aria-label="First group">
                        <button onClick={() => { this.createTeamModal.toggle() }} className='btn btn-primary'>Manage Teams<i className="bi bi-people-fill"></i></button>
                    </div>
                    <div className="btn-group me-2" role="group" aria-label="Second group">
                        <select className='form-select' onChange={(evt) => this.filterByRole(evt.target.value)}>
                            <option value=''>Filter By Role</option>
                            {groups.map(tl => <option key={'tl-' + tl.name}>{tl.name}</option>)}
                        </select>
                    </div>

                </div>



                <table className='table table-bordered table-fixedheader'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>#</th>
                            <th>Name</th>
                            <th>Number</th>
                            <th>Email</th>
                            <th>Personal mail</th>
                            <th>Emp Type</th>
                            <th>Team</th>
                            <th>Role</th>
                            {/* <th>Office</th> */}
                            <th>Personal</th>
                            <th>DoJ</th>
                            <th>Salary</th>
                            <th>Target</th>
                            <th>Docs</th>
                            <th>WFH</th>
                            <th>Network</th>
                            <th>Entity</th>
                            <th>IR Sent</th>
                            <th>IR Ack</th>
                            <th>Disclaimer Sent</th>
                            <th>Disclaimer Ack</th>
                        </tr>
                    </thead>
                    <tbody>
                        {employees.filter(t => filterRole == '' ? t : t.role.indexOf(filterRole) != -1).map((d, idx) => <tr key={d.id}>
                            <td>
                                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                    <div className="btn-group me-2" role="group" aria-label="First group">
                                        <button onClick={() => { this.selectUser(d.sub); this.createUserModal.toggle() }} disabled={loading} className='btn btn-warning btn-sm'>
                                            <i className="bi bi-pencil-square"></i>
                                        </button>
                                        <button onClick={() => { this.selectUser(d.sub); this.resetPasswordModal.toggle() }} disabled={loading} className='btn btn-info btn-sm'>
                                            <i className="bi bi-key-fill"></i>
                                        </button>
                                        <button onClick={() => { this.selectUser(d.sub); this.getGroupsForUser(d.sub); this.addUserToGroupModal.toggle() }} disabled={loading} className='btn btn-primary btn-sm'>
                                            <i className="bi bi-person-video2"></i>
                                        </button>
                                        <button onClick={() => { if (window.confirm('Are you sure you want to delete this user?')) { this.selectUser(d.sub); this.deleteUser(d.sub); } }} disabled={loading} className='btn btn-danger btn-sm'>
                                            <i className="bi bi-trash-fill"></i>
                                        </button>
                                    </div>
                                </div>

                            </td>
                            <td>{idx + 1}</td>
                            <td>{d.name}</td>
                            <td>{d.number}</td>
                            <td>{d.email}</td>
                            <td>{d.personal_email}</td>
                            {/* <td>{d.employment_type}</td> */}
                            <Cell data={d} type={"select"} value={d.employment_type} list={[{ label: "PartTime", value: "PartTime" }, { label: "FullTime", value: "FullTime" }]} onChange={(d) => this.updateCell('employment_type', d)} field={'employment_type'} />

                            <Cell data={d} type={"select"} value={teamsHash[d.team_id] || '-'} list={teams.map(t => ({ label: t.name, value: t.id + '' }))} onChange={(d) => this.updateCell('team_id', d)} field={'team_id'} />

                            {/* <td>{d.team_id}</td> */}
                            <td>{d.role}</td>
                            {/* <td>{d.office}</td> */}
                            <td>{d.personal}</td>
                            <td>{!d.doj ? '' : dayjs(d.doj).format('DD/MM/YYYY')}</td>
                            <td>{d.salary}</td>
                            <td>{d.target}</td>
                            <td>{d.docs}</td>
                            <td>{d.wfh ? <i className="bi bi-check font-20 text-success"></i> : <i className="bi bi-x font-20 text-danger"></i>}</td>
                            <td>{d.network}</td>
                            <td>{d.entity}</td>
                            <td>{d.ir_sent ? <i className="bi bi-check font-20 text-success"></i> : <i className="bi bi-x font-20 text-danger"></i>}</td>
                            <td>{d.ir_sent_acknowledgement ? <i className="bi bi-check font-20 text-success"></i> : <i className="bi bi-x font-20 text-danger"></i>}</td>
                            <td>{d.disclaimer_sent ? <i className="bi bi-check font-20 text-success"></i> : <i className="bi bi-x font-20 text-danger"></i>}</td>
                            <td>{d.disclaimer_sent_acknowledgement ? <i className="bi bi-check font-20 text-success"></i> : <i className="bi bi-x font-20 text-danger"></i>}</td>

                        </tr>)}
                    </tbody>
                </table>



                <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.addUserToGroupModalRef}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Manage Roles</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='form'>
                                    <form className=" g-3">
                                        <div className="mb-3 row">
                                            <label htmlFor="inputName" className="col-sm-2 col-form-label">Group</label>
                                            <div className="col-sm-10">
                                                {(user && user.groups)
                                                    ? user.groups.map(group => <span className="badge rounded-pill bg-info text-dark">{group} <i className="bi bi-x-circle text-danger font-larger cursor-pointer" onClick={() => this.removeGroupForUser(user!.sub, group)}></i> </span>)
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="inputName" className="col-sm-2 col-form-label">Group</label>
                                            <div className="col-sm-10">
                                                <select onChange={(evt) => this.setGroupField(evt.target.value)}>
                                                    <option></option>
                                                    {groups.map((g: any) => <option key={g.name} disabled={!!user?.groups.find(ug => ug == g.name)}>{g.name}</option>)}
                                                </select>
                                                {/* <Select options={groups.map((g: any) => ({ label: g.name, value: g.name }))} value={user?.groups.map((g: any) => ({ label: g.name, value: g.name })) || []} onChange={()=>{}} /> */}
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={this.addUserToGroup} disabled={loading}>Change Group</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.resetPasswordModalRef}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Reset Password</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='form'>
                                    <form className=" g-3">

                                        <div className="mb-3 row">
                                            <label htmlFor="inputName" className="col-sm-2 col-form-label">Password</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputName" onChange={(evt) => this.setPasswordField(evt.target.value)} />
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={this.resetPassword} disabled={loading}>Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.createUserModalRef}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{user ? 'Edit' : 'Add'} User</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='form'>
                                    <form className=" g-3">

                                        <div className="mb-3 row">
                                            <label htmlFor="inputName" className="col-sm-2 col-form-label">Name</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputName" onChange={(evt) => this.setField('name', evt.target.value)} value={data.name || ""} />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label htmlFor="inputNumber" className="col-sm-2 col-form-label">Number</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputNumber" onChange={(evt) => this.setField('number', evt.target.value)} value={data.number || ''} />
                                                <p className='form-text'>Please enter in this format +911234567890</p>
                                            </div>
                                        </div>



                                        <div className="mb-3 row">
                                            <label htmlFor="inputCompany" className="col-sm-2 col-form-label">Email</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputCompany" onChange={(evt) => this.setField('email', evt.target.value)} value={data.email || ''} disabled={!!user} />
                                                <p className='form-text'>Please input official email address. This will not be editable later.</p>

                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="inputCompany" className="col-sm-2 col-form-label">Personal Email</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputCompany" onChange={(evt) => this.setField('personal_email', evt.target.value)} value={data.personal_email || ''} />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label htmlFor="inputProcess" className="col-sm-2 col-form-label">Role</label>
                                            <div className="col-sm-10">
                                                <select className='form-select' onChange={(evt) => this.setGroupField(evt.target.value)} disabled={!!user}>
                                                    <option></option>
                                                    {groups.map((g: any) => <option key={g.name}>{g.name}</option>)}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label htmlFor="inputProcess" className="col-sm-2 col-form-label">Team</label>
                                            <div className="col-sm-10">
                                                <select className='form-select' onChange={(evt) => this.setField('team_id', evt.target.value)} disabled={!!!user || user.id === -1}>
                                                    <option></option>
                                                    {teams.map((g: any) => <option key={g.id} value={g.id}>{g.name}</option>)}
                                                </select>
                                            </div>
                                        </div>

                                        {/* <div className="mb-3 row">
                                            <label htmlFor="inputProcess" className="col-sm-2 col-form-label">Office</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputCompany" onChange={(evt) => this.setField('office', evt.target.value)} value={data.office || ""} />
                                            </div>
                                        </div> */}
                                        <div className="mb-3 row">
                                            <label htmlFor="inputProcess" className="col-sm-2 col-form-label">Personal</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputCompany" onChange={(evt) => this.setField('personal', evt.target.value)} value={data.personal || ""} />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="inputProcess" className="col-sm-2 col-form-label">DoJ</label>
                                            <div className="col-sm-10">
                                                <input type="date" className="form-control" id="inputCompany" onChange={(evt) => this.setField('doj', evt.target.value)} value={data.doj ? dayjs(data.doj).format('YYYY-MM-DD') : ""} />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="inputProcess" className="col-sm-2 col-form-label">Salary</label>
                                            <div className="col-sm-10">
                                                <input type="number" className="form-control" id="inputCompany" onChange={(evt) => this.setField('salary', evt.target.value)} value={data.salary || ""} />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="inputProcess" className="col-sm-2 col-form-label">Target</label>
                                            <div className="col-sm-10">
                                                <input type="number" className="form-control" id="inputCompany" onChange={(evt) => this.setField('target', evt.target.value)} value={data.target || ""} />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="inputProcess" className="col-sm-2 col-form-label">Docs</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputCompany" onChange={(evt) => this.setField('docs', evt.target.value)} value={data.docs || ""} />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="inputProcess" className="col-sm-2 col-form-label">Network</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputCompany" onChange={(evt) => this.setField('network', evt.target.value)} value={data.network || ""} />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="inputProcess" className="col-sm-2 col-form-label">Entity</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputCompany" onChange={(evt) => this.setField('entity', evt.target.value)} value={data.entity || ""} />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-6">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" value={1} onChange={(evt) => this.setField('wfh', evt.target.checked ? "1" : "0")} checked={!!Number(data.wfh)} />
                                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">WFH</label>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-6">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" value={1} onChange={(evt) => this.setField('ir_sent', evt.target.checked ? "1" : "0")} checked={!!Number(data.ir_sent)} />
                                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">IR Sent</label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" value={1} onChange={(evt) => this.setField('ir_sent_acknowledgement', evt.target.checked ? "1" : "0")} checked={!!Number(data.ir_sent_acknowledgement)} />
                                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">IR Sent Ack</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-6">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" value={1} onChange={(evt) => this.setField('disclaimer_sent', evt.target.checked ? "1" : "0")} checked={!!Number(data.disclaimer_sent)} />
                                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Disclaimer Sent</label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" value={1} onChange={(evt) => this.setField('disclaimer_sent_acknowledgement', evt.target.checked ? "1" : "0")} checked={!!Number(data.disclaimer_sent_acknowledgement)} />
                                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Disclaimer Sent Ack</label>
                                                </div>
                                            </div>
                                        </div>


                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={user ? this.updateEmployee : this.addEmployee} disabled={loading}>{user ? 'Edit' : 'Add'} Employee</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" ref={this.createTeamModalRef}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{user ? 'Edit' : 'Add'} Team</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='form'>
                                    <form className=" g-3">
                                        <ul className="list-group">
                                            {teams.map(team => <li className="list-group-item" key={'team-'+team.id}>
                                                {team.name} - {team.tl_name}
                                                <i onClick={() => !loading && this.selectTeam(team)} className={"ms-2 cursor-pointer bi bi-pencil-fill" + (loading ? ' ' : ' text-warning')}></i>
                                                <i onClick={() => !loading && this.deleteTeam(team.id)} className={"ms-2 cursor-pointer bi bi-trash-fill" + (loading ? ' ' : ' text-danger')}></i>
                                            </li>)}
                                        </ul>

                                        <div className="mb-3 row">
                                            <label htmlFor="inputName" className="col-sm-2 col-form-label">Name</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" id="inputName" onChange={(evt) => this.setTeamField('name', evt.target.value)} value={team.name || ""} />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="inputName" className="col-sm-2 col-form-label">TL</label>
                                            <div className="col-sm-10">
                                                <select className='form-select' value={team.tl_sub || ""} onChange={(evt) => {
                                                    // this.setTeamField('tl_sub', evt.target.value);
                                                    const tl = employees.find(t => t.sub == evt.target.value)!
                                                    this.setTeamField({ tl_name: tl.name, tl_sub: evt.target.value },'');
                                                }}>
                                                    {teamLeaders.map(tl => <option key={tl.sub} value={tl.sub}>{tl.name}</option>)}
                                                </select>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className={"btn " + (team.id != -1 ? 'btn-warning' : 'btn-primary')} onClick={team.id != -1 ? this.updateTeam : this.addTeam} disabled={loading}>{team.id != -1 ? 'Edit' : 'Add'} Team</button>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />


            </div>
        )
    }
}

export default Employees;


type CellType = "text" | "select" | "number";
type CellOption = { label: string, value: string };
// type CellOptions = {label: string, value: string };
interface CellPropsInterface {
    data: EmployeeInterface
    field: keyof EmployeeInterface
    value: string
    type: CellType
    list?: CellOption[]
    onChange: (d: EmployeeInterface) => void
    enabled?: boolean

}

const Cell = (props: CellPropsInterface) => {

    const [edit, setEdit] = useState(false);
    const [value, setValue] = useState(props.value);
    const enabled = props.enabled === undefined ? true : props.enabled


    const setEditable = () => {
        setEdit(true);
    }
    const changeValue = (e: any) => {
        const target = e.target;
        if (props.type == "select") {
            setValue(target.options[target.selectedIndex].innerText);
        } else {
            setValue(target.value)
        }
        setEdit(false);
        props.onChange({ ...props.data, [props.field]: target.value });
    }

    const renderElement = (type: CellType, list?: CellOption[]) => {
        let ele;
        switch (type) {
            case "text": ele = <input className={'form-control'} type='text' defaultValue={props.value} onBlur={changeValue} />; break;
            case "number": ele = <input className={'form-control'} type='number' defaultValue={props.value} onBlur={changeValue} />; break;
            case "select": ele = <select className={'form-select'} defaultValue={props.data[props.field] as string} onBlur={changeValue} >
                {list?.map(l => <option key={'opt-' + l.label} value={l.value}>{l.label}</option>)}
            </select>; break;
        }

        return ele;
    }


    return (<td onClick={!enabled ? () => { } : setEditable} className={enabled ? 'enabled' : 'bg-secondary'}>
        {edit
            ? renderElement(props.type, props.list)
            : value
        }
    </td>)

}