import React, { Component } from 'react';
import styles from './ATSNav.module.css';
import logo from '../../assets/images/logo.png'
import { Link } from 'react-router-dom';
import { isAdmin, isOnlyRecruiter, isSuperAdmin } from '../../utils';


interface ATSNavState {
    isSidebar: boolean
}
interface ATSNavProps {
    user: any
    signOut: () => void
}

class ATSNav extends Component<ATSNavProps, ATSNavState> {

    state: ATSNavState = {
        isSidebar: false
    }

    componentDidMount() {
        document.querySelector('#menu-container')?.addEventListener('click', (evt) => this.setState({ isSidebar: false }))
    }

    render() {

        const { user, signOut } = this.props;
        const d=new Date();
        const currentMonthName = d.toLocaleString('default', { month: 'short' });
        const currentMonth = d.getMonth()+1;
        const currentYear = d.getFullYear();
        return (
            <nav className={`${styles.navWrapper}`}>
                <div className={styles.logo}>
                    {/* <img src="https://edufin.in/assets/img/new/edufin-logo.png" width='150' height='55' alt="edufin-logo" /> */}
                    {/* <img src={logo} width='150' alt="ats-logo" /> */}
                    <div className='logo'>
                        <span>O</span>
                        <span className='logo-blue'>D</span>
                        <span>N</span>
                    </div>
                </div>
                <div className={styles.toggleIcon} >

                    {!this.state.isSidebar ? (<svg onClick={() => this.setState({ isSidebar: !this.state.isSidebar })} xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#ffffff" className="bi bi-list" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                    </svg>)
                        :
                        (<svg onClick={() => this.setState({ isSidebar: !this.state.isSidebar })} xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#ffffff" className="bi bi-x-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                            <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                        </svg>)}
                </div>
                <ul id='menu-container' className={` ${styles.menuList} ${this.state.isSidebar ? `${styles.show}` : `${styles.hide}`}`}>
                    {/* <img src="https://edufin.in/assets/img/new/edufin-logo.png" width='150' height='55' alt="edufin-logo" /> */}
                    <img src={logo} width='150' alt="ats-logo" />

                    <li className="">
                        <Link className="" to="/dashboard">Dashboard</Link>
                    </li>

                    <li className="">
                        <Link className="" to="/candidates">Candidates</Link>
                    </li>
                    {/* {
                        isOnlyRecruiter(user)
                            ? ''
                            : <li className="">
                                <Link className="" to="/formats">Formats</Link>
                            </li>
                    } */}


                    {/* <li className="">
                        <Link className="" to="/coordination-tracker">Coordination</Link>
                    </li> */}
                    {/* <li className="">
                        <Link className="" to="/coordination-tracker-3">Coordination New</Link>
                    </li> */}
                    <li className="">
                        <Link className="" to="/coordination-tracker">Coordination Old</Link>
                    </li>

                    {
                        isOnlyRecruiter(user)
                            ? ''
                            :
                            <li className="">
                                <Link className="" to={"/joiner-tracker/" + currentMonth + '/' + currentYear}>Joiners</Link>
                            </li>
                    }

                    <li className="">
                        <Link className="" to="/candidate-leads">Candidate Leads</Link>
                    </li>


                    {/* {
                        isOnlyRecruiter(user)
                            ? ''
                            :
                            <li className="">
                                <Link className="" to="/companies">Companies</Link>
                            </li>
                    } */}
                    {/* {
                        isOnlyRecruiter(user)
                            ? ''
                            :
                            <li className="">
                                <Link className="" to="/jobs">Jobs</Link>
                            </li>
                    } */}

                    {
                        !isOnlyRecruiter(user)
                            ?
                            <li className="dropdown ats-dropdown">
                                <a className=" dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Masters
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    {
                                        (isSuperAdmin(user) || isAdmin(user)) ? <>
                                            <li className="">
                                                <Link className="dropdown-item" to="/employees">Employees</Link>
                                            </li>
                                        </>
                                            : ''
                                    }
                                    {
                                        isSuperAdmin(user) ? <>
                                            <li className="">
                                                <Link className="dropdown-item" to="/masters">Masters</Link>
                                            </li>
                                            <li className="">
                                                <Link className="dropdown-item" to="/coordination-tracker-list/1/2">Coordination Tracker List</Link>
                                            </li>
                                            <li className="">
                                                <Link className="dropdown-item" to="/invoices-to-send">Invoices To Send</Link>
                                            </li>

                                            <li className="">
                                                <Link className="dropdown-item" to="/candidates-search">Candidates Search</Link>
                                            </li>
                                            <li className="">
                                                <Link className="dropdown-item" to="/grid">Grid Reports</Link>
                                            </li>
                                        </>
                                            : ''
                                    }
                                    <li className="">
                                        <Link className="dropdown-item" to="/companies">Companies</Link>
                                    </li>
                                    <li className="">
                                        <Link className="dropdown-item" to="/subscribers">Subscribers</Link>
                                    </li>
                                    <li className="">
                                        <Link className="dropdown-item" to="/companies-assigned">Companies Assigned</Link>
                                    </li>
                                    <li className="">
                                        <Link className="dropdown-item" to="/jobs">Jobs</Link>
                                    </li>
                                    <li className="">
                                        <Link className="dropdown-item" to="/formats">Formats</Link>
                                    </li>

                                </ul>
                            </li>
                            : ''
                    }
                    {/* {
                        isSuperAdmin(user)
                            ?
                            <li className="dropdown ats-dropdown">
                                <a className=" dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Masters
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li className="">
                                        <Link className="dropdown-item" to="/employees">Employees</Link>
                                    </li>
                                    <li className="">
                                        <Link className="dropdown-item" to="/jobs">Jobs</Link>
                                    </li>
                                    <li className="">
                                        <Link className="dropdown-item" to="/masters">Masters</Link>
                                    </li>
                                    <li className="">
                                        <Link className="dropdown-item" to="/coordination-tracker-list/1/2">Coordination Tracker List</Link>
                                    </li>

                                </ul>
                            </li>
                            : ''
                    } */}



                    <li className="">
                        <a className="nav-link px-3" href="/" onClick={(e) => { e.stopPropagation(); e.preventDefault(); signOut() }}>({user == null ? '' : user!.attributes.name})Sign out</a>
                    </li>

                </ul>
            </nav>
        );
    }
}

export default ATSNav;