import API from "@aws-amplify/api";
import Storage from "@aws-amplify/storage";
import React from "react";
import { GenericDataNameInterface, RoleInterface } from "../types";
import { CANDIDATE_ROLES, REST_API_NAME } from "../utils";


const data: GenericDataNameInterface[] = [
    { id: 1, name: "Rashmi" },
    { id: 1, name: "Zubaina" },
    { id: 1, name: "Zubaida" },
    { id: 1, name: "Shruti" },
    { id: 1, name: "Mithi" },
    { id: 1, name: "Yousuf" },
    { id: 1, name: "Srinivas" },
    { id: 1, name: "Poojitha" },
    { id: 1, name: "Raheen" }
]



interface MastersStateInterface {
    loading: boolean,
    url: string
    key: string
    image: any
    result: any,
    roles: RoleInterface[],
    role: RoleInterface
}


class Masters extends React.Component<any, MastersStateInterface>{

    createUserModalRef: any
    createUserModal: any;

    resetPasswordModalRef: any
    resetPasswordModal: any;

    addUserToGroupModalRef: any;
    addUserToGroupModal: any;

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            url: '',
            key: '',
            image: '',
            result: {},
            roles: CANDIDATE_ROLES.map(s => ({ role_name: s, id: +s })),
            role: { role_name: '', id: -1 }
        }


    }

    async componentDidMount() {

        const rolesResult: any = await API.get(REST_API_NAME, '/general/roles', {});
        // const result: any = await API.get(REST_API_NAME, '/general/pre-signed', {});
        const result: any = await API.get(REST_API_NAME, '/jobs', {});


        this.setState({
            loading: false,
            // url: result.uploadURL, key: result.Key, 
            // result: result.data,
            roles: rolesResult.result.records
        })
    }


    onFileChange = async (e: any) => {
        let files = e.target.files || e.dataTransfer.files
        if (!files.length) return
        // this.createImage(files[0])
        const { data }: any = await API.get(REST_API_NAME, '/jobs/presigned/' + files[0].name, {});
        this.uploadImage1(files[0], data.url, data.fields)

        // this.uploadImage1(files[0])
    }
    createImage = (file: any) => {
        // var image = new Image()
        let reader = new FileReader()
        reader.onload = (e: any) => {
            console.log('length: ', e.target.result.includes('data:image/jpeg'))
            if (!e.target.result.includes('data:image/jpeg')) {
                return alert('Wrong file type - JPG only.')
            }
            const MAX_IMAGE_SIZE = 1000000
            if (e.target.result.length > MAX_IMAGE_SIZE) {
                return alert('Image is loo large.')
            }
            // this.image = e.target.result
            this.setState({ image: e.target.result })
        }
        reader.readAsDataURL(file)
    }

    removeImage = (e: any) => {
        console.log('Remove clicked')
        this.setState({ image: '' })
    }

    uploadImage1 = async (file: any, url: string, fields: any) => {
        console.log('Upload clicked')
        // Get the presigned URL
        // console.log('Response: ', response)
        // console.log('Uploading: ', this.image)


        const formData = new FormData();

        // formData.append("Content-Type", 'image/jpg');

        Object.entries(fields).forEach(([k, v]) => {
            formData.append(k, v as any);
        });

        // formData.append("key", 'prj1.jpg');
        // formData.append("bucket", 'odnpublicfolder');
        formData.append("file", file);

        const result1 = await fetch(url, {
            // method: 'PUT',
            method: 'POST',
            body: formData
        })
        // Final URL for the user doesn't need the query string params
        console.log('URL ', url.split('?')[0]);
    }

    uploadImage = async (e: any) => {
        console.log('Upload clicked')
        // Get the presigned URL
        const { image, url, key } = this.state;
        // console.log('Response: ', response)
        // console.log('Uploading: ', this.image)
        let binary = atob(image.split(',')[1])
        let array = []
        for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i))
        }
        let blobData = new Blob([new Uint8Array(array)], { type: 'image/jpeg' })
        console.log('Uploading to: ', url)
        const result = await fetch(url, {
            method: 'PUT',
            // method: 'POST',
            body: blobData
        })
        console.log('Result: ', result)
        // Final URL for the user doesn't need the query string params
        console.log('URL ', url.split('?')[0]);
    }


    onChange = async (e: any) => {
        const file = e.target.files[0];
        try {
            await Storage.put(file.name, file, {
                // contentType: file, // contentType is optional
                bucket: 'odnpublicfolder'

            });
        } catch (error) {
            console.log("Error uploading file: ", error);
        }
    }


    saveRole = async () => {
        const { role } = this.state;
        const roleResult = await API.post(REST_API_NAME, '/general/role', {
            body: {
                role: role.role_name
            }
        })
        role.id = roleResult.result.insertId;
        this.setState({ roles: [...this.state.roles, role] })
    }
    deleteRole = async (role: RoleInterface) => {
        const roleResult = await API.del(REST_API_NAME, '/general/role/' + role.id, {})
        this.setState({ roles: this.state.roles.filter(r => r.id != role.id) })
    }

    setRoleName = (role_name: string) => {
        this.setState({ role: { ...this.state.role, role_name } })
    }



    render() {
        const { loading, roles } = this.state;

        return (
            <div className='my-5'>
                <h1>Masters</h1>


                <div className='row'>
                    <div className='col-4'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='card-title'>Roles</div>
                                <ul className='list-group'>
                                    {<li className='list-group-item'>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="New Role" aria-label="New Role" aria-describedby="button-addon2" onChange={(evt) => this.setRoleName(evt.target.value)} />
                                            <button className="btn btn-outline-secondary" type="button" id="button-addon2" disabled={loading} onClick={this.saveRole}>Save</button>
                                        </div>
                                    </li>}
                                    {roles.map(role => <li className='list-group-item' key={role.id + role.role_name}>
                                        {role.role_name}
                                        <div className="position-absolute top-0 end-0">
                                            <i className="bi bi-trash-fill text-danger delete-button" onClick={() => { if (window.confirm('Are you sure you want to delete this role?')) { this.deleteRole(role); } }} ></i>
                                        </div>
                                    </li>)}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                <input type="file" onChange={this.onFileChange} />
                <button type='button' onClick={this.uploadImage}>Upload</button>

            </div>
        )
    }
}

export default Masters;


const EditBox = () => {



    return (<div>

    </div>)
}