import API from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";
import Storage from "@aws-amplify/storage";
import { getRoles } from "@testing-library/dom";
import { Modal } from "bootstrap";
import dayjs from "dayjs";
import React from "react";
import { CandidateInterface, CompanyInterface, CoordinationFormatInterface, CoordinationInterface, EmployeeInterface, EmployeeRoleType, FormatRowInterface, GenericDataNameInterface, GenericObjectType } from "../types";
import { FORMAT_FIELDS, REST_API_NAME } from "../utils";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from "react-select";
import { format } from "path";




interface CandidateSearchStateInterface {
    loading: boolean,
    number: string,
    email: string,
    candidates: any



}


class CandidateSearch extends React.Component<{ user: any, companies: CompanyInterface[], employees: EmployeeInterface[] }, CandidateSearchStateInterface>{



    constructor(props: any) {
        super(props);

        this.state = {
            loading: true,
            number: '',
            email: '',
            candidates: []
        }


    }

    async componentDidMount() {

    }

    setFilter = (key: 'number' | 'email', value: string) => this.setState({ ...this.state, [key]: value })

    search = async () => {
        const { number, email } = this.state;

        const { result } = await API.get(REST_API_NAME, '/candidates/get-all-details/', {
            queryStringParameters: {
                number,
                email
            }
        })

        const cand = result.map((c: any) => {
            const assigned = c.assigned?c.assigned.split(','):[];
            c.assigned = assigned.map((a: any) => {
                const _a = a.split('$')
                return _a;
            })
            const companies = c.companies?c.companies.split(','):[];
            c.companies = companies.map((a: any) => {
                const _a = a.split('$')
                return _a;
            })
            return c


        })
        this.setState({ loading: false, candidates: cand })
    }


    render() {
        const { loading, candidates } = this.state;

        let employeeHash: GenericObjectType = {};
        this.props.employees.map(e => employeeHash[e.sub] = e);



        return (
            <div className='my-5'>
                <div className="container-fluid">

                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <input type='text' placeholder='Phone' onChange={(evt) => this.setFilter('number', evt.target.value)} />
                                </td>
                                <td>
                                    <input type='text' placeholder='Email' onChange={(evt) => this.setFilter('email', evt.target.value)} />
                                </td>
                                <td>
                                    <button onClick={this.search}>Search</button>
                                </td>
                            </tr>

                        </tbody>
                    </table>


                </div>
                <div>
                    <table className="table table-bordered table-condensed table-striped1">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Team</th>
                                <th>Created</th>
                                <th>Deleted</th>
                                <th>Assigned By Rec</th>
                                <th>Assigned By TL</th>
                            </tr>

                        </thead>
                        <tbody>
                            {candidates.map((c: any) => <tr key={'c-' + c.id}>
                                <td>{c.id}</td>
                                <td>{c.name}</td>
                                <td>{c.number}</td>
                                <td>{c.email}</td>
                                <td>{c.recruiter_name}<br />{c.team_leader_name}</td>
                                <td>{dayjs(c.created_on).format('DD-MM-YY hh:mmA')} <br />
                                    {employeeHash[c.created_by].name}</td>
                                <td>
                                    {c.is_deleted == 1
                                        ? <>{dayjs(c.deleted_on).format('DD-MM-YY hh:mmA')}  <br />  {employeeHash[c.deleted_by].name}</>
                                        : ''
                                    }
                                </td>
                                <td>
                                    <div>
                                        <table className="table table-bordered table-striped1">
                                            <thead>
                                                <tr>
                                                    <th>Company</th>
                                                    <th>Date</th>
                                                    <th>Recruiter</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {c.companies.map((a: any) => <tr>
                                                    <td>{a[0]}</td>
                                                    <td>{a[1]}</td>
                                                    <td>{employeeHash[a[2]]?employeeHash[a[2]].name:''}</td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </div>

                                </td>
                                <td>
                                    <div>
                                        <table className="table table-bordered table-striped1">
                                        <thead>
                                                <tr>
                                                    <th>Company</th>
                                                    <th>Date</th>
                                                    <th>Recruiter</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {c.assigned.map((a: any) => <tr>
                                                    <td>{a[0]}</td>
                                                    <td>{a[1]}</td>
                                                    <td>{employeeHash[a[2]]?employeeHash[a[2]].name:''}</td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </div>

                                </td>

                            </tr>)}

                        </tbody>
                    </table>
                </div>




                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        )
    }
}

export default CandidateSearch;