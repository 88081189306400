import API from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";
// import { toast } from "@aws-amplify/ui";
import { Modal } from "bootstrap";
import dayjs from "dayjs";
import React from "react";
import Select from "react-select";
import { EmployeeInterface, EmployeeRoleType, GenericDataNameInterface, UPDATE_FREQUENCY } from "../types";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { REST_API_NAME } from "../utils";


const data: GenericDataNameInterface[] = [
    { id: 1, name: "Rashmi" },
    { id: 1, name: "Zubaina" },
    { id: 1, name: "Zubaida" },
    { id: 1, name: "Shruti" },
    { id: 1, name: "Mithi" },
    { id: 1, name: "Yousuf" },
    { id: 1, name: "Srinivas" },
    { id: 1, name: "Poojitha" },
    { id: 1, name: "Raheen" }
]

interface SubscriberInterface {
    id: number
    phone: string
    email: string
    frequency:number
    created_on: string
}

interface SubscriberStateInterface {
    loading: boolean,
    subscribers: SubscriberInterface[]
}


class Subscribers extends React.Component<any, SubscriberStateInterface>{


    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            subscribers: []
        }


    }

    async componentDidMount() {

        const subscribersResult = await API.get(REST_API_NAME, `/general/subscribers/`, {});
        this.setState({ loading: false, subscribers:subscribersResult.result.records  })
    }

    deleteUser = async (id: number) => {
        this.setState({ loading: true })
        const subscribersResult = await API.del(REST_API_NAME, `/general/subscribers/`+id, {});
        this.setState({ loading: false, subscribers:this.state.subscribers.filter(s=>s.id!=id)  })
    }


    render() {
        const { loading, subscribers } = this.state;

        return (
            <div className='my-5'>

                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>S.No</th>
                            <th>Number</th>
                            <th>Email</th>
                            <th>Frequency</th>
                            <th>Created On</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subscribers.map((d, idx) => <tr key={d.id}>
                            <td>
                                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                    <div className="btn-group me-2" role="group" aria-label="First group">
                                        <button onClick={() => { if (window.confirm('Are you sure you want to delete this user?')) { this.deleteUser(d.id); } }} disabled={loading} className='btn btn-danger btn-sm'>
                                            <i className="bi bi-trash-fill"></i>
                                        </button>
                                    </div>
                                </div>

                            </td>
                            <td>{idx + 1}</td>
                            <td>{d.phone}</td>
                            <td>{d.email}</td>
                            <td>{UPDATE_FREQUENCY[d.frequency]}</td>
                            <td>{dayjs(d.created_on).format('DD/MM/YY hh:mm a')}</td>
                        </tr>)}
                    </tbody>
                </table>


                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />


            </div>
        )
    }
}

export default Subscribers;